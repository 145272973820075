import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import { selectMenuInitQuery, Menu } from './queries';
import ViewMenu from '../../../../components/Global/Menus/View';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  menu_id: string;
}

const ViewMenuOrg = ({ classes, history }: Props): React.ReactElement => {
  const { menu_id } = useParams<UrlParams>();

  const [menu, setMenu] = useState<Menu>({
    name: '',
  });

  const { data: menuInitData } = useQuery(selectMenuInitQuery(menu_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && menuInitData) {
      setMenu(menuInitData.menus_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [menuInitData]);

  const handleEdit = () => {
    history.push(`/org/menus/${menu_id}/edit`);
  };

  const handleViewCategory = (id: string, categoryType: string) => {
    history.push(`/org/menus/${menu_id}/${categoryType}/categories/${id}`);
  };

  const handleCreateCategory = (categoryType: string) => {
    history.push(`/org/menus/${menu_id}/${categoryType}/categories/create`);
  };

  const handleDelete = () => {
    history.push(`/org/menus`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Menus', link: `/org/menus` }, { label: menu.name }]}>
      <ViewMenu menu_id={menu_id} handleEdit={handleEdit} handleViewCategory={handleViewCategory} handleCreateCategory={handleCreateCategory} handleDelete={handleDelete} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewMenuOrg));
