import { types, query, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _voucherCodesInitQuery = () => ({
  locations: [
    {
      currency: types.string,
      locale: types.string,
      voucher_codes: params(
        {
          order_by: { name: 'asc' },
        },
        [
          {
            id: types.string,
            name: types.string,
            code: types.string,
            value: types.number,
            voucher_type: types.string,
            single_use: types.boolean,
            can_expire: types.boolean,
            times_used: types.number,
            start_date: types.string,
            end_date: types.string,
            category_ids: types.custom<string[]>(),
            created_at: types.string,
          },
        ]
      ),
    },
  ],
});

type X = Unpacked<typeof _voucherCodesInitQuery>;
export type Location = X['locations'][0];
export type VoucherCode = Location['voucher_codes'][0];

export const voucherCodesInitQuery = () => gql`
  ${query(_voucherCodesInitQuery())}
`;
