import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertLocationPrinterMutation = gql`
  mutation ($object: location_devices_insert_input!) {
    insert_location_devices_one(object: $object) {
      id
    }
  }
`;

export const createLocationPrinter = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_location_devices_one: {
      id: string;
    };
  }>({ mutation: insertLocationPrinterMutation!, variables });
  return res.data?.insert_location_devices_one.id;
};
