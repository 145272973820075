import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../../layouts/Dashboard';
import ViewLocationIntegrations from '../../../../../../components/Global/LocationIntegrations/View';

import { selectLocationSettingsInitQuery, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  location_id: string;
}

const ViewLocationSettings = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: locationSettingsQuery } = useQuery(selectLocationSettingsInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationSettingsQuery) {
      setLocation(locationSettingsQuery.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationSettingsQuery]);

  const handleEdit = (step: number) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/integrations/edit?step=${step}`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: location.organisation?.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Integrations', link: `/admin/organisations/${organisation_id}/locations/${location_id}/integrations` },
      ]}>
      <ViewLocationIntegrations location_id={location_id} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewLocationSettings));
