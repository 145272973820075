import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  categoryMenu: {
    display: 'flex',
    justifyContent: 'center', // Center-align buttons
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #ddd',
    position: 'sticky',
    top: theme.spacing(8), // Positioned below the header
    zIndex: 100,
    overflowX: 'auto', // Enable horizontal scrolling for small screens
  },
  categoryButton: {
    fontWeight: 'bold',
    fontSize: '1rem', // Match reference font size
    textTransform: 'none',
    padding: theme.spacing(1, 2),
    color: '#000',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&.active': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
}));

interface CategoriesProps {
  categories: any[];
  selectedCategory: string;
  onCategorySelect: (id: string) => void;
}

const Categories: React.FC<CategoriesProps> = ({ categories, selectedCategory, onCategorySelect }) => {
  const classes = useStyles();

  return (
    <Box className={classes.categoryMenu}>
      {categories.map((category) => (
        <Button key={category.id} className={`${classes.categoryButton} ${selectedCategory === category.id ? 'active' : ''}`} onClick={() => onCategorySelect(category.id)}>
          {category.name}
        </Button>
      ))}
    </Box>
  );
};

export default Categories;
