import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { EmptyObject, UnknownObject } from '../../react-app-env';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import { KeyboardArrowUp, KeyboardArrowDown, Search as SearchIcon, ClearOutlined as ClearIcon } from '@material-ui/icons';

import styles from './styles';

interface CustomSortableTableProps extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  noSorting?: boolean;
  searchable?: string[];
  title?: string;
  description?: string;
  loading?: boolean;
  loadingMessage?: string;
  counter?: number | 'filtered';
  orderdBy?: string;
  ordered?: SortableTableOrder;
  actions?: SortableTableAction[];
  filters?: SortableTableFilter[];
  headers: SortableTableHeader[];
  rows: SortableTableRow[];
  columnTotals?: ColumnTotals[];
  footer?: SortableTableFooter;
}

interface CustomSortableHeadProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  headers: SortableTableHeader[];
  noSorting?: boolean;
  hasExpandedRows: boolean;
  hasActionRows: boolean;
  hasRows: boolean;
  order: SortableTableOrder;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

interface SortableTableToolbarProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  title?: string;
  description?: string;
  counter?: number;
  searchable: boolean;
  searchQuery: string;
  rows: SortableTableRow[];
  filters?: SortableTableFilter[];
  actions?: SortableTableAction[];
  handleSearch: (value: string) => void;
}

interface CustomSortableTableRowLoadingProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  message?: string;
}
interface CustomSortableTableRowProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  row: SortableTableRow;
  headers: SortableTableHeader[];
  hasActionRows: boolean;
  isExpanded: boolean;
  lazyLoadCollapsible: boolean;
  isLast: boolean;
  hasExpandedRows: boolean;
  handleExpand: (key: string, lazyLoad: boolean) => Promise<void>;
}

interface CustomSortableTableColumnTotalsProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  columnTotals: ColumnTotals[];
  headers: SortableTableHeader[];
  rows: SortableTableRow[];
  hasExpandedRows?: boolean;
}

interface CustomSortableTableRowCollapsibleProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  title?: string;
  counter?: number;
  colSpan: number;
  columnTotals?: ColumnTotals[];
  rows: SortableTableRow[];
  headers: SortableTableHeader[];
  isExpanded: boolean;
}

interface SortableTableFooterProps extends React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  label?: string;
  rows: SortableTableRow[];
  actions?: SortableTableAction[];
}

export type ColumnTotals = [string, ColumnFormatType?, ColumnAlign?];

type ColumnFormatType = (value: SortableTableLabel, args?: any) => SortableTableLabel;
type ColumnComponentType = (value: SortableTableLabel) => React.ReactElement;

export interface SortableTableFilter {
  key: string;
  label?: string;
  filter: (rows: SortableTableRow[]) => SortableTableRow[];
  component: React.ReactElement;
}

export interface SortableTableAction {
  key: string;
  label: string;
  icon?: React.ReactElement;
  onClick: (rows: SortableTableRow[]) => void;
}

export type SortableTableLabel = string | number | boolean | UnknownObject;

export interface SortableTableFooter {
  label?: string;
  actions?: SortableTableAction[];
}

type ColumnAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export interface SortableTableHeader {
  key: string;
  label: SortableTableLabel;
  format?: ColumnFormatType;
  component?: ColumnComponentType;
  align?: ColumnAlign;
  hidden?: boolean;
  disabled?: boolean;
}

export interface SortableTableRow {
  key: string;
  columns: SortableTableColumn[];
  disabled?: boolean;
  collapsible?: SortableTableCollapsible[];
  lazyLoad?: (row: SortableTableRow) => Promise<void>;
  onClick?: () => void;
  actions?: SortableTableRowAction[];
}

interface SortableTableRowAction {
  label: string;
  show?: boolean;
  destructive?: boolean;
  endIcon?: React.ReactElement;
  onClick?: () => void;
}

interface SortableTableColumn {
  key: string;
  label: SortableTableLabel;
  format?: ColumnFormatType;
  data?: any;
  width?: number;
  state?: 'error' | 'warning' | 'success';
  component?: React.ReactElement;
}

export interface SortableTableCollapsible {
  title?: string;
  counter?: number;
  headers: SortableTableHeader[];
  rows: SortableTableRow[];
  columnTotals?: ColumnTotals[];
}

type SortableTableOrder = 'asc' | 'desc';

function descendingComparator(a: SortableTableLabel, b: SortableTableLabel) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order: SortableTableOrder): (a: SortableTableLabel, b: SortableTableLabel) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b) : (a, b) => -descendingComparator(a, b);
}

function stableSort(array: SortableTableRow[], noSorting: boolean, orderBy: string, comparator: (a: SortableTableLabel, b: SortableTableLabel) => number) {
  if (noSorting) return array;
  const stabilizedThis = array.map((row, index) => [row, row.columns.find((i) => i.key === orderBy)?.label || '', index] as [SortableTableRow, SortableTableLabel, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[1], b[1]);
    if (order !== 0) return order;
    return a[2] - b[2];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CustomSortableTableToolbar = ({
  classes,
  title,
  description,
  counter,
  searchable,
  searchQuery,
  rows = [],
  filters = [],
  actions = [],
  handleSearch,
}: SortableTableToolbarProps): React.ReactElement => {
  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarContainer}>
        <div className={classes.toolbarTitleContainer}>
          {counter !== undefined && (
            <Avatar className={classes.counter} variant="rounded">
              {counter}
            </Avatar>
          )}
          {title && (
            <Typography className={classes.title} variant="h5" id="sortableTableTitle" component="div">
              {title}
            </Typography>
          )}
        </div>
        <div className={classes.toolbarActionsContainer}>
          {searchable && (
            <TextField
              variant="outlined"
              placeholder="Search"
              value={searchQuery}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchQuery ? (
                  <Tooltip title="Clear" arrow>
                    <InputAdornment position="end" className={classes.searchButton}>
                      <IconButton size="small" onClick={() => handleSearch('')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                ) : (
                  <div className={classes.searchButton}></div>
                ),
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSearch(event.target.value)}
            />
          )}
          {actions.map((action: SortableTableAction) => (
            <Button variant="outlined" onClick={() => action.onClick(rows)} color="primary" startIcon={action.icon}>
              {action.label}
            </Button>
          ))}
        </div>
      </div>
      {description && (
        <div className={classes.toolbarDescriptionContainer}>
          <Typography className={classes.description} variant="subtitle2" id="sortableTableTitle" component="div">
            {description}
          </Typography>
        </div>
      )}
      {filters.length > 0 && (
        <Paper className={classes.filterPaper} variant="outlined">
          <Typography className={classes.filtersTitle} variant="h6">
            Filters
          </Typography>
          {filters.map((f: SortableTableFilter) => (
            <FormControl className={classes.filter} key={f.key}>
              {f.label && <FormLabel className={classes.formControlLabel}>{f.label}:</FormLabel>}
              {f.component}
            </FormControl>
          ))}
        </Paper>
      )}
    </Toolbar>
  );
};

const CustomSortableTableFooter = ({ classes, label, rows, actions = [] }: SortableTableFooterProps): React.ReactElement => {
  return (
    <Toolbar className={classes.footer}>
      {label && (
        <Typography className={classes.footerLabel} variant="h6" id="sortableTableFooterLabel" component="div">
          {label}
        </Typography>
      )}
      <div className={classes.footerActionsContainer}>
        {actions.map((action: SortableTableAction) => (
          <Button key={action.label} variant="outlined" onClick={() => action.onClick(rows)} color="primary" startIcon={action.icon}>
            {action.label}
          </Button>
        ))}
      </div>
    </Toolbar>
  );
};

const CustomSortableTableHead = ({
  classes,
  headers,
  noSorting = false,
  hasExpandedRows = false,
  hasActionRows = false,
  hasRows,
  order,
  orderBy,
  onRequestSort,
}: CustomSortableHeadProps): React.ReactElement => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={hasRows ? '' : classes.tableHeaderNoRows}>
        {hasExpandedRows && <TableCell className={classes.collapseCell} />}
        {headers
          .filter((i) => !i.hidden && !i.disabled)
          .map((header) => {
            if (noSorting) {
              return (
                <TableCell key={header.key} align={header.align || 'left'}>
                  {header.label}
                </TableCell>
              );
            }
            return (
              <TableCell key={header.key} align={header.align || 'left'} sortDirection={orderBy === header.key ? order : false}>
                <TableSortLabel active={orderBy === header.key} direction={orderBy === header.key ? order : 'asc'} onClick={createSortHandler(header.key)}>
                  {header.label}
                </TableSortLabel>
              </TableCell>
            );
          })}
        {hasActionRows && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

const CustomSortableTableColumnTotals = ({ classes, columnTotals, headers, rows, hasExpandedRows = false }: CustomSortableTableColumnTotalsProps): React.ReactElement => {
  const getColumnSpans = () => {
    const spans: React.ReactElement[] = [];
    if (hasExpandedRows) {
      spans.push(<TableCell key="totals_collapsible" />);
    }
    let aIndex = 0;
    columnTotals.forEach((ct: ColumnTotals, cIndex: number) => {
      const [ctKey, ctFormat, ctAlign] = ct;
      const i = headers.findIndex((h) => h.key === ctKey);
      if (spans.length === 0 || (cIndex === 0 && i !== 0)) {
        spans.push(<TableCell key="totals_first" align={ctAlign} colSpan={i}></TableCell>);
      } else if (i - aIndex > 1) {
        spans.push(<TableCell key={`totals_next_${cIndex}`} align={ctAlign} colSpan={i - aIndex - (cIndex === 0 ? 0 : 1)}></TableCell>);
      }
      const rowColumns = rows.flatMap((r) => r.columns.filter((c) => c.key === ctKey));
      const value = rowColumns.reduce((a: number, b: SortableTableColumn) => a + (b.label as number), 0);
      spans.push(
        <TableCell key={`totals_${cIndex}`} colSpan={1}>
          <Typography className={classes.totalCell} align={ctAlign} variant="body1">
            {ctFormat ? ctFormat(value) : value}
          </Typography>
        </TableCell>
      );
      if (cIndex + 1 === columnTotals.length && i + 1 < headers.length) {
        spans.push(<TableCell key="totals_last" align={ctAlign} colSpan={headers.length - i}></TableCell>);
      }
      aIndex = i;
    });
    return spans;
  };

  return (
    <>
      <TableRow key="totals_row" className={classes.lastRow}>
        {getColumnSpans()}
      </TableRow>
    </>
  );
};

const CustomSortableTableRowLoading = ({ classes, message }: CustomSortableTableRowLoadingProps): React.ReactElement => {
  return (
    <>
      <TableRow className={classes.lastRow} tabIndex={-1}>
        <TableCell className={classes.lastRow} colSpan={99} align="center">
          <CircularProgress />
          <Typography>Loading</Typography>
          {message && <Typography variant="caption">{message}</Typography>}
        </TableCell>
      </TableRow>
    </>
  );
};

const CustomSortableTableRow = ({
  classes,
  row,
  headers,
  hasActionRows,
  isExpanded,
  lazyLoadCollapsible = false,
  isLast,
  hasExpandedRows,
  handleExpand,
}: CustomSortableTableRowProps): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);

  const getColumnAlign = (key: string) => {
    const header = headers.find((h) => h.key === key);
    return header ? header.align : 'left';
  };
  const toggleExpand = async (key: string, lazyLoad: boolean) => {
    if (row.lazyLoad) setLoading(true);
    await handleExpand(key, lazyLoad);
    if (row.lazyLoad) setLoading(false);
  };
  return (
    <>
      <TableRow
        className={isLast ? classes.lastRow : classes.tableRow}
        hover={row.onClick !== undefined}
        onClick={() => (row.onClick !== undefined ? row.onClick() : null)}
        tabIndex={-1}>
        {((row.collapsible && row.collapsible.length > 0) || lazyLoadCollapsible) && (
          <TableCell className={isExpanded ? classes.lastRow : ''}>
            <IconButton size="small" onClick={() => toggleExpand(row.key, lazyLoadCollapsible)}>
              {loading ? <CircularProgress size={18} /> : isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        {((!lazyLoadCollapsible && !row.collapsible) || row.collapsible?.length === 0) && hasExpandedRows && <TableCell />}

        {/* <TableCell
            className={`${isExpanded ? classes.lastRow : ''} ${row.disabled ? classes.disabled : ''}`}
            key={`${row.key}_${row.columns[0].key}`}
            component="th"
            scope="row">
            {row.columns.filter((i) => !headers.find((h) => h.key === i.key)?.hidden)[0].label}
          </TableCell> */}
        {row.columns
          .filter((i) => !headers.find((h) => h.key === i.key)?.hidden)
          .map((column) => (
            <TableCell
              className={`${isExpanded ? classes.lastRow : ''} ${row.disabled ? classes.disabled : ''} ${column.state ? classes[column.state] : ''}`}
              key={`${row.key}_${column.key}`}
              align={getColumnAlign(column.key)}>
              {column.component ? column.component : column.format ? column.format(column.label, column.data) : column.label}
            </TableCell>
          ))}
        {hasActionRows && (
          <TableCell className={isExpanded ? classes.lastRow : ''} padding="none" key={`${row.key}_actions`} align="right">
            <div className={classes.actionsContainer}>
              {row.actions
                ?.filter((i) => i.show !== false)
                .map((action) => (
                  <Button
                    key={action.label}
                    className={action.destructive ? classes.destructiveButton : ''}
                    size="small"
                    variant="outlined"
                    onClick={action.onClick}
                    endIcon={action.endIcon}>
                    {action.label}
                  </Button>
                ))}
            </div>
          </TableCell>
        )}
      </TableRow>
      {row.collapsible &&
        row.collapsible.map((collapsible: SortableTableCollapsible, index: number) => (
          <CustomSortableTableRowCollapsible
            key={`${row.key}_collapsible_${index}`}
            classes={classes}
            title={collapsible.title}
            counter={collapsible.counter}
            colSpan={headers.length + 1 + (row.actions?.length || 0)}
            columnTotals={collapsible.columnTotals}
            rows={collapsible.rows}
            headers={collapsible.headers}
            isExpanded={isExpanded}
          />
        ))}
    </>
  );
};

const CustomSortableTableRowCollapsible = ({
  classes,
  title,
  counter,
  colSpan,
  rows,
  headers,
  columnTotals = [],
  isExpanded,
}: CustomSortableTableRowCollapsibleProps): React.ReactElement => {
  const getColumnAlign = (key: string) => {
    const header = headers.find((h) => h.key === key);
    return header ? header.align : 'left';
  };

  return (
    <>
      <TableRow className={isExpanded ? classes.collapsibleRowItem : ''}>
        <TableCell className={!isExpanded ? classes.lastRow : ''} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className={classes.collapsibleHeader}>
                {counter !== undefined && (
                  <Avatar className={classes.counter} variant="rounded">
                    {counter}
                  </Avatar>
                )}
                {title && (
                  <Typography variant="h6" component="div">
                    {title}
                  </Typography>
                )}
              </div>
              <Table size="small">
                <TableHead>
                  {headers
                    .filter((i) => !i.disabled)
                    .map((header) => (
                      <TableCell size="medium" key={header.key} align={header.align || 'left'}>
                        {header.label}
                      </TableCell>
                    ))}
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={row.key}>
                      {row.columns.map((column) => (
                        <TableCell
                          className={index + 1 === rows.length && columnTotals.length === 0 ? classes.lastRow : ''}
                          key={column.key}
                          width={column.width}
                          align={getColumnAlign(row.key)}>
                          {column.component ? column.component : column.format ? column.format(column.label, column.data) : column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  {columnTotals.length > 0 && (
                    <CustomSortableTableColumnTotals
                      key="collapsible_totals"
                      classes={classes}
                      columnTotals={columnTotals}
                      headers={headers.filter((i) => !i.disabled)}
                      rows={rows}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const CustomSortableTable = ({
  classes,
  title,
  description,
  counter = 0,
  orderdBy,
  ordered = 'desc',
  noSorting = false,
  searchable = [],
  loading = false,
  loadingMessage,
  columnTotals = [],
  headers = [],
  rows = [],
  filters = [],
  actions = [],
  footer,
}: CustomSortableTableProps): React.ReactElement => {
  const [order, setOrder] = useState<SortableTableOrder>(ordered);
  const [orderBy, setOrderBy] = useState<string>(orderdBy || headers[0]?.key || '');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [rowsFilterd, setRowsFilterd] = useState<SortableTableRow[]>(rows);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpandRow = async (key: string, lazyLoad: boolean, row: SortableTableRow) => {
    if (lazyLoad) {
      await row.lazyLoad!(row);
    }
    setExpandedRows((i) => {
      if (i.includes(key)) {
        return i.filter((a) => a !== key);
      }
      return [...i, key];
    });
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    filterSearch();
  };

  const hasExpandedRows = () => rowsFilterd.some((i) => i.collapsible || i.lazyLoad);

  const hasActionRows = () => rowsFilterd.some((i) => i.actions);

  const filterSearch = useCallback(() => {
    const query = searchQuery.toLowerCase();
    if (searchQuery) {
      setRowsFilterd(
        rows.filter((row) => {
          const values = row.columns.filter((c) => searchable.some((s) => c.key === s)).map((c) => c.label);
          return values.some((v: SortableTableLabel) => `${v}`.toLocaleLowerCase().includes(query));
        })
      );
    } else {
      setRowsFilterd(rows);
    }
  }, [rows, searchQuery, searchable]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      filterSearch();
    }
    return () => {
      mounted = false;
    };
  }, [filterSearch, searchQuery]);

  const sortedRows = stableSort(rowsFilterd, noSorting, orderBy, getComparator(order)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper} variant="outlined">
          {(title || counter === 'filtered' || counter > 0) && (
            <CustomSortableTableToolbar
              classes={classes}
              title={title}
              description={description}
              searchable={searchable.length > 0}
              searchQuery={searchQuery}
              counter={counter === 'filtered' ? rowsFilterd.length : counter}
              rows={rowsFilterd}
              filters={filters}
              actions={actions}
              handleSearch={handleSearch}
            />
          )}
          <TableContainer>
            <Table className={classes.table} size="medium">
              <CustomSortableTableHead
                classes={classes}
                headers={headers.filter((i) => !i.disabled)}
                noSorting={noSorting}
                hasRows={sortedRows.length > 0}
                hasExpandedRows={hasExpandedRows()}
                hasActionRows={hasActionRows()}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {loading && <CustomSortableTableRowLoading key="loading" message={loadingMessage} classes={classes} />}
                {!loading &&
                  sortedRows.map((row: SortableTableRow, index) => {
                    return (
                      <>
                        <CustomSortableTableRow
                          key={row.key}
                          classes={classes}
                          row={row}
                          isLast={index === sortedRows.length - 1}
                          headers={headers.filter((i) => !i.disabled)}
                          isExpanded={expandedRows.includes(row.key)}
                          lazyLoadCollapsible={row.lazyLoad !== undefined}
                          hasActionRows={hasActionRows()}
                          hasExpandedRows={hasExpandedRows()}
                          handleExpand={(key: string, lazyLoad: boolean) => handleExpandRow(key, lazyLoad, row)}
                        />
                      </>
                    );
                  })}
                {!loading && columnTotals.length > 0 && (
                  <CustomSortableTableColumnTotals
                    key="totals"
                    classes={classes}
                    columnTotals={columnTotals.filter((ct) => headers.filter((i) => !i.disabled).some((h) => h.key === ct[0] && !h.hidden))}
                    headers={headers.filter((h) => !h.disabled && !h.hidden)}
                    rows={rowsFilterd}
                    hasExpandedRows={hasExpandedRows()}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && rowsFilterd.length > 10 && (
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={rowsFilterd.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
          {!loading && footer && <CustomSortableTableFooter classes={classes} label={footer.label} rows={rowsFilterd} actions={footer.actions} />}
        </Paper>
      </div>
    </>
  );
};

export default withStyles(styles)(CustomSortableTable);
