import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { InputAdornment, TextField, Typography, withStyles } from '@material-ui/core';

import { KeyboardArrowRight as NextIcon, KeyboardArrowLeft as PreviousIcon } from '@material-ui/icons';

import { Menu, ValidationErrors, UploadMenuReducerAction, ZettleUploadItem, EnumZettleUploadItemType, ZettleUploadItemModifier, ZettleUploadItemModifierGroup } from '../..';

import styles from './styles';
import ReadOnlyBlock from '../../../../../../ReadOnlyBlock';
import ModifierItem from './components/ModifierItem';
import { capitaliseFirstOnly, getCurrencySymbol } from '../../../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  menu: Menu;
  itemIndex: number;
  locale: string;
  currency: string;
  errors: ValidationErrors;
  setItemIndex: React.Dispatch<React.SetStateAction<number>>;
  dispatch: React.Dispatch<{ type: UploadMenuReducerAction; value: any }>;
}

const StepModifiers = ({ classes, menu, itemIndex, locale, currency, errors, setItemIndex, dispatch }: Props): React.ReactElement => {
  const [isInit, setIsInit] = useState<boolean>(true);

  const item: ZettleUploadItem = menu.items[itemIndex];

  const getTypeAsString = () => {
    switch (item.type) {
      case EnumZettleUploadItemType.DISH:
        return 'Dish';
      case EnumZettleUploadItemType.DRINK:
        return 'Drink';
      default:
        return 'Unknown';
    }
  };

  if (isInit) {
    const items = menu.items.map((i) => {
      const { variants, tax, groups, modifiers } = i;
      if (modifiers.length > 0) {
        return i;
      }
      if (groups.length === 1) {
        i.modifiers = variants.map((v) => ({
          id: '',
          name: capitaliseFirstOnly(v.option1.value || v.option1.name),
          price: v.price,
          originalPrice: v.price,
          tax,
          group: groups[0],
        }));
      }
      if (groups.length === 2) {
        const mods1 = variants.map((i) => i.option1.value.toLocaleLowerCase());
        const mods2 = variants.map((i) => i.option2.value.toLocaleLowerCase());
        const mods1S = [...new Set(mods1)];
        const mods2S = [...new Set(mods2)];
        const mods = [
          {
            group: groups[0],
            mods: mods1S,
          },
          {
            group: groups[1],
            mods: mods2S,
          },
        ].filter((i) => i);

        i.modifiers = mods.flatMap((m) =>
          m.mods.map((v) => ({
            id: '',
            name: capitaliseFirstOnly(v),
            price: 0,
            originalPrice: 0,
            tax,
            group: m.group,
          }))
        );
      }
      if (groups.length === 3) {
        const mods1 = variants.map((i) => i.option1.value.toLocaleLowerCase());
        const mods2 = variants.map((i) => i.option2.value.toLocaleLowerCase());
        const mods3 = variants.map((i) => i.option3.value.toLocaleLowerCase());
        const mods1S = [...new Set(mods1)];
        const mods2S = [...new Set(mods2)];
        const mods3S = [...new Set(mods3)];
        const mods = [
          {
            group: groups[0],
            mods: mods1S,
          },
          {
            group: groups[1],
            mods: mods2S,
          },
          {
            group: groups[2],
            mods: mods3S,
          },
        ].filter((i) => i);

        i.modifiers = mods.flatMap((m) =>
          m.mods.map((v) => ({
            id: '',
            name: capitaliseFirstOnly(v),
            price: 0,
            originalPrice: 0,
            tax,
            group: m.group,
          }))
        );
      }
      return i;
    });
    dispatch({ type: UploadMenuReducerAction.SET_ITEMS, value: items });
    setIsInit(false);
  }

  const getModifierGroups = (modifiers: ZettleUploadItemModifier[]): ZettleUploadItemModifierGroup[] => {
    return item.groups.map((g) => {
      const mods: ZettleUploadItemModifier[] = modifiers.filter((i) => i.group === g);
      return {
        name: g,
        modifiers: mods,
      };
    });
  };

  return (
    <>
      <div className={classes.root}>
        {errors.categories && (
          <Typography className={classes.text} color="error">
            {errors.categories}
          </Typography>
        )}
        <ReadOnlyBlock
          title={item.name}
          gridSize={4}
          counter={{
            index: itemIndex + 1,
            total: menu.items.length,
          }}
          items={[
            { label: 'Category', value: item.category },
            { label: 'Type', value: getTypeAsString() },
          ]}
          actions={[
            {
              label: 'Previous',
              icon: <PreviousIcon />,
              disabled: itemIndex === 0,
              onClick: () => setItemIndex((i) => i - 1),
            },
            {
              label: 'Next',
              icon: <NextIcon />,
              iconEnd: true,
              filled: true,
              disabled: itemIndex === menu.items.length - 1,
              onClick: () => setItemIndex((i) => i + 1),
            },
          ]}
        />
        <Typography className={classes.text} variant="h6">
          AccentPOS does variants differently to Zettle. Instead of creating variants for all possible outocomes, AccentPOS has modifiers that have individal prices that are added
          to the item price.
        </Typography>

        <Typography className={classes.text} variant="h5">
          Item
        </Typography>

        <TextField
          className={classes.textField}
          data-qa="description-textfield"
          required
          label="Name"
          variant="outlined"
          style={{ width: 400 }}
          value={item.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: UploadMenuReducerAction.ITEM_NAME, value: { item, name: event.target.value, itemIndex } })}
        />

        <TextField
          className={classes.textField}
          data-qa="description-textfield"
          required
          label="Price"
          variant="outlined"
          type="number"
          inputProps={{ min: 0, step: 0.01 }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency, locale)}</InputAdornment>,
          }}
          style={{ width: 200 }}
          value={item.price / 100 || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: UploadMenuReducerAction.ITEM_PRICE, value: { item, price: +event.target.value } })}
        />

        {getModifierGroups(item.modifiers).length > 0 && (
          <Typography className={classes.text} variant="h5">
            Modifiers
          </Typography>
        )}
        {getModifierGroups(item.modifiers).map((group) => (
          <>
            {item.groups.length > 1 && (
              <Typography className={classes.text} variant="h6">
                {group.name}
              </Typography>
            )}
            {group.modifiers.map((modifier) => (
              <ModifierItem modifier={modifier} item={item} locale={locale} currency={currency} taxRate={item.tax} dispatch={dispatch} />
            ))}
          </>
        ))}
      </div>
    </>
  );
};

export default withStyles(styles)(StepModifiers);
