import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationUserInitQuery = (user_id: string) => ({
  users_location_by_pk: params(
    { id: rawString(user_id) },
    {
      id: types.string,
      first_name: types.string,
      last_name: types.string,
      full_name: types.string,
      email: types.string,
      active: types.boolean,
      user_type: types.string,
      enum_user_type: {
        description: types.string,
      },
    }
  ),
});

type X = Unpacked<typeof _selectLocationUserInitQuery>;
export type LocationUser = X['users_location_by_pk'];

export const selectLocationUserInitQuery = (user_id: string) => gql`
  ${query(_selectLocationUserInitQuery(user_id))}
`;
