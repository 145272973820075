import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectMenuItemInitQuery = (dateFrom: string, dateTo: string) => {
  const itemData = params(
    {
      where: {
        order: { paid: { _eq: true } },
        created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
      },
    },
    [
      {
        item: types.custom<{ id: string }>(),
        price: types.number,
      },
    ]
  );
  return {
    categories: params(
      {
        order_by: { order_index: 'asc' },
      },
      [
        {
          id: types.string,
          name: types.string,
          category_type: types.string,
          dish_assignments: params(
            {
              order_by: { dish: { order_index: 'asc' } },
            },
            [
              {
                dish: {
                  id: types.string,
                  name: types.string,
                  tax: types.number,
                },
              },
            ]
          ),
          drink_assignments: params(
            {
              order_by: { drink: { order_index: 'asc' } },
            },
            [
              {
                drink: {
                  id: types.string,
                  name: types.string,
                  tax: types.number,
                },
              },
            ]
          ),
        },
      ]
    ),
    order_served_dish_items: itemData,
    order_served_drink_items: itemData,
    order_takeaway_dish_items: itemData,
    order_takeaway_drink_items: itemData,
    order_deliverect_dish_items: itemData,
    order_deliverect_drink_items: itemData,
  };
};

export type X = Unpacked<typeof _selectMenuItemInitQuery>;
export type MenuCategroy = X['categories'][0];
export type DishAssignment = MenuCategroy['dish_assignments'][0];
export type DrinkAssignment = MenuCategroy['drink_assignments'][0];
export type Dish = DishAssignment['dish'];
export type Drink = DrinkAssignment['drink'];

export type DishItem = X['order_served_dish_items'][0] | X['order_takeaway_dish_items'][0] | X['order_deliverect_dish_items'][0];
export type DrinkItem = X['order_served_drink_items'][0] | X['order_takeaway_drink_items'][0] | X['order_deliverect_drink_items'][0];

export const selectMenuItemInitQuery = (startDate: string, endDate: string) => gql`
  ${query(_selectMenuItemInitQuery(startDate, endDate))}
`;
