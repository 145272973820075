import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomStepper from '../../../../../CustomStepper';
import { CodeVoucher, Category, validationErrors, ValidationErrors, CodeVoucherReducerAction, validation, UsedCode } from '../..';
import StepDetails from '../StepDetails';
import StepCategoryAssignment from '../StepCategoryAssignment';
import StepReview from '../StepReview';

import styles from './styles';
import { CustomTransferListItem } from '../../../../../CustomTransferList';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  voucher: CodeVoucher;
  categories: Category[];
  used_codes: UsedCode[];
  dispatch: React.Dispatch<{ type: CodeVoucherReducerAction; value: any }>;
  handleSave: () => void;
}

const OrganisationSteps = ({ classes, completeLabel, voucher, categories, used_codes, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    code: false,
    codeLength: false,
    codeUsed: false,
    value: false,
    valueInvalid: false,
    start_date: false,
    end_date: false,
    dateInvalid: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = validation.name(voucher.name);
        errorItems.code = validation.code(voucher.code, used_codes);
        errorItems.value = validation.value(voucher.value);
        if (voucher.can_expire) {
          errorItems.start_date = validation.start_date(voucher.start_date, voucher.end_date);
          errorItems.end_date = validation.end_date(voucher.end_date, voucher.start_date);
        }
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [voucher, used_codes, errors]
  );

  const handleCategoryAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    const assignedIds = assigned.map((i) => i.id);
    dispatch({ type: CodeVoucherReducerAction.UPDATE_CATEGORY_IDS, value: assignedIds });
  };

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            gridSize: 6,
            component: <StepDetails voucher={voucher} used_codes={used_codes} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Categories',
            gridSize: 6,
            component: (
              <StepCategoryAssignment
                voucher={voucher}
                categories={categories.map((cat: Category) => ({ id: cat.id, label: cat.name, subLabel: cat.menu.name }))}
                assignedCategories={categories
                  .filter((i) => voucher.category_ids.includes(i.id))
                  .map((cat: Category) => ({ id: cat.id, label: cat.name, subLabel: cat.menu.name }))}
                handleAssignment={handleCategoryAssignment}
              />
            ),
          },
          {
            label: 'Summary',
            gridSize: 8,
            component: <StepReview voucher={voucher} categories={categories} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationSteps);
