import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { pluralise } from '../../../../utils/stringUtils';

const _editCategoryItemInitQuery = (id: string, categoryType: string) => ({
  [`${pluralise(categoryType, 2)}_by_pk`]: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
      description: types.string,
      tag: types.string,
      active: types.boolean,
      price: types.number,
      tax: types.number,
      prep_time: types.number,
      bypass_prep: types.boolean,
      should_print: types.boolean,
      auto_serve_when_ready: types.boolean,
      order_index: types.number,
      image_added: types.boolean,
      score: types.number,
      modifier_assignments: [
        {
          id: types.string,
          modifier: {
            id: types.string,
            name: types.string,
            price: types.number,
            tax: types.number,
          },
        },
      ],
      allergy_assignments: [
        {
          id: types.string,
          allergy: {
            id: types.string,
            name: types.string,
          },
        },
      ],
    }
  ),
});

export const editCategoryItemInitQuery = (id: string, categoryType: string) => gql`
  ${query(_editCategoryItemInitQuery(id, categoryType))}
`;
