import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { EmptyObject } from '../../../react-app-env';

import { DndContext, closestCenter, DragOverlay, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, arrayMove } from '@dnd-kit/sortable';

import { Button, Grid, GridSize, Typography, withStyles } from '@material-ui/core';

import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import SortableItem from './SortableItem';
import CustomBackdrop from '../../CustomBackdrop';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  title?: string;
  items: SortableGridChild[];
  gridSize?: GridSize;
  handleSave: () => Promise<void>;
  onDragEnd: (result: SortableGridChild[]) => void;
}

export interface SortableGridChild {
  key: string;
  label: string;
  component: React.ReactElement;
}

const SortableGrid = ({ classes, title, items = [], gridSize = 3, handleSave, onDragEnd }: Props): React.ReactElement => {
  const [currentDragged, setCurrentDragged] = useState<string | undefined>();
  const [saving, setSaving] = useState<boolean>(false);

  const handleDragEnd = (e: DragEndEvent) => {
    setCurrentDragged(undefined);

    const { active, over } = e;

    if (over !== null && active.id !== over.id) {
      const activeIndex = items.findIndex((i) => i.key === active.id);
      const overIndex = items.findIndex((i) => i.key === over.id);

      onDragEnd(arrayMove(items, activeIndex, overIndex));
    }
  };

  const handleSaveStart = async () => {
    setSaving(true);
    await handleSave();
    setTimeout(() => {
      setSaving(false);
    }, 700);
  };

  const handleAZ = () => {
    onDragEnd(items.sort((a, b) => a.label.localeCompare(b.label)));
  };

  return (
    <>
      {title && (
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
      )}
      <Typography className={classes.message}>Drag items to arange, then click save</Typography>
      <div className={classes.actionsContainer}>
        <Button variant="outlined" onClick={handleAZ} color="secondary" startIcon={<CompareArrowsIcon />}>
          ABC / 123
        </Button>
        <Button variant="outlined" onClick={handleSaveStart} color="primary">
          Save
        </Button>
      </div>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={(e: DragStartEvent) => setCurrentDragged(e.active.id)}
        onDragEnd={handleDragEnd}
        onDragCancel={() => setCurrentDragged(undefined)}>
        <SortableContext items={items.map((c) => c.key)} strategy={rectSortingStrategy}>
          <Grid container spacing={4}>
            {items.map((item) => (
              <Grid item xs={gridSize}>
                <SortableItem id={item.key} key={item.key}>
                  {item.component}
                </SortableItem>
              </Grid>
            ))}
          </Grid>
        </SortableContext>
        <DragOverlay>{currentDragged && items.find((i) => i.key === currentDragged)?.component}</DragOverlay>
      </DndContext>
      {saving && <CustomBackdrop label="Saving" />}
    </>
  );
};

export default withStyles(styles)(SortableGrid);
