import React from 'react';
import { WithStyles } from '@material-ui/core/styles';

import { withStyles } from '@material-ui/core';
import { EmptyObject } from '../../react-app-env';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {}
const CenteredLayout = ({ classes, children }: Props): React.ReactElement => (
  <div className={classes.root}>
    <div className={classes.container}>{children}</div>
  </div>
);

export default withStyles(styles)(CenteredLayout);
