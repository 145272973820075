import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import ListMenus from '../../../../../components/Global/Menus/List';

import { Menu, menusInitQuery, Organisation } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}
interface UrlParams {
  organisation_id: string;
}

const ListMenusAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    menus: [],
  });

  const { data: menusInitData } = useQuery(menusInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && menusInitData) {
      setOrganisation(menusInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [menusInitData]);

  const handleCreate = () => {
    history.push(`/admin/organisations/${organisation_id}/menus/create`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/menus/${id}`);
  };

  const handleUpload = () => {
    history.push(`/admin/organisations/${organisation_id}/menus/upload`);
  };

  const handleSetMenus = (menus: Menu[]) => setOrganisation((o) => ({ ...o, menus }));

  return (
    <Dashboard
      breadcrumbs={[{ label: 'Organisations', link: '/admin/organisations' }, { label: organisation.name, link: `/admin/organisations/${organisation_id}` }, { label: 'Menus' }]}>
      <ListMenus
        items={organisation.menus}
        organisation_id={organisation_id}
        handleSetMenus={handleSetMenus}
        handleView={handleView}
        handleUpload={handleUpload}
        handleCreate={handleCreate}
      />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListMenusAdmin));
