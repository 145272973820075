import { types, query, rawString, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectOrganisationUsersInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      users: [
        {
          id: types.string,
          full_name: types.string,
          email: types.string,
        },
      ],
    }
  ),
});

type X = Unpacked<typeof _selectOrganisationUsersInitQuery>;
export type Organisation = X['organisations_by_pk'];
export type OrganisationUser = Organisation['users'][0];

export const selectOrganisationUsersInitQuery = (id: string) => gql`
  ${query(_selectOrganisationUsersInitQuery(id))}
`;
