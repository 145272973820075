import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { withStyles } from '@material-ui/core';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';

import styles from './styles';
import { SelfOnboardOrganisation } from '../../../../../../components/Global/Organisations';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisation: SelfOnboardOrganisation;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, organisation, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Organisation name', value: organisation.name },
          { label: 'Email', value: organisation.email },
          { label: 'Phone', value: organisation.phone },
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
