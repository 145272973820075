import React, { useState, useEffect, useContext } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import { Grid, withStyles } from '@material-ui/core';

import { AuthContext, EnumUserRole } from '../../../AuthProvider';
import ReadOnlyBlock from '../../../ReadOnlyBlock';

import { selectLocationSettingsInitQuery, LocationSettings } from './queries';

import styles from './styles';
import { booleanToYesNo } from '../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  location_id: string;
  handleEdit: (step: number) => void;
}

const ViewLocationIntegrations = ({ classes, history, location_id, handleEdit }: Props): React.ReactElement => {
  const authContext = useContext(AuthContext);

  if (authContext === null) {
    throw new Error('No AuthContext');
  }

  const { userRole } = authContext;

  const [settings, setSettings] = useState<LocationSettings | UnknownObject>({});

  const { data: locationSettingsQuery } = useQuery(selectLocationSettingsInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationSettingsQuery) {
      setSettings(locationSettingsQuery.locations_by_pk.integrations[0]);
    }
    return () => {
      mounted = false;
    };
  }, [locationSettingsQuery]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <ReadOnlyBlock
            title="Payments"
            gridSize={3}
            items={
              settings && settings.payment
                ? [
                    { label: 'Provider', value: settings.payment.provider },
                    settings.payment.connection ? { label: 'Account ID', value: settings.payment.connection.accountId } : null,
                    settings.payment.enableTipping !== undefined ? { label: 'Enable Tipping', value: booleanToYesNo(settings.payment.enableTipping) } : null,
                    settings.payment.connection.username ? { label: 'Account username', value: settings.payment.connection.username } : null,
                  ]
                : [{ label: 'No payment details' }]
            }
            handleEdit={() => handleEdit(0)}
          />
          <ReadOnlyBlock
            title="Deliverect"
            gridSize={3}
            items={
              settings && settings.deliverect
                ? [
                    { label: 'Account ID', value: settings.deliverect.accountId },
                    { label: 'Auto accept', value: booleanToYesNo(settings.deliverect.autoAccept || false) },
                  ]
                : [{ label: 'Deliverect is not activated' }]
            }
            handleEdit={() => handleEdit(1)}
          />
          <ReadOnlyBlock
            title="Xero"
            gridSize={3}
            items={settings && settings.xero ? [{ label: 'Xero account is authenticated', value: '' }] : [{ label: 'Xero is not activated' }]}
            handleEdit={() => handleEdit(2)}
          />
          {settings && userRole === EnumUserRole.ADMIN && (
            <ReadOnlyBlock title="Vouchers" gridSize={3} items={[{ label: 'Active', value: booleanToYesNo(settings.vouchers) }]} handleEdit={() => handleEdit(3)} />
          )}
        </>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewLocationIntegrations));
