import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { booleanToYesNo, capitaliseFirst } from '../../../utils/stringUtils';
import { getDate } from '../../../utils/conversions';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import CustomBackdrop from '../../../components/CustomBackdrop';
import CustomSortableTable, { SortableTableFilter, SortableTableHeader, SortableTableRow } from '../../../components/CustomSortableTable';

import { selectReferralsInitQuery } from './queries';
import { createReferalOrganisation, createReferalLocations, updateReferal } from './mutations';
import { Referral, ReferralLocation } from './types';

import styles from './styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ReferralsAdmin = ({ classes, history }: Props): React.ReactElement => {
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [referralFiltered, setReferralFiltered] = useState<string[]>([]);
  const [statusFiltered, setStatusFiltered] = useState<string>('NEW');

  const [saving, setSaving] = useState<boolean>(false);

  const { data: referralsInitQuery } = useQuery(selectReferralsInitQuery(statusFiltered), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && referralsInitQuery) {
      setReferrals(referralsInitQuery.referrals);
    }
    return () => {
      mounted = false;
    };
  }, [referralsInitQuery]);

  const handleCreate = async (referral: Referral) => {
    setSaving(true);

    const { organisation, locations } = referral;

    const createVariables = {
      object: {
        name: organisation.name,
        email: organisation.contact_email,
        phone: organisation.contact_phone,
        organisation_type: 'FREE_TRIAL_14',
        currency: 'GBP',
        locale: 'en-GB',
        tax_rate: 200,
      },
    };
    const organisation_id: string | undefined = await createReferalOrganisation(createVariables);
    if (organisation_id) {
      const createLocationVariables = {
        objects: locations.map((location) => ({
          organisation_id,
          name: location.name,
          email: location.contact_email,
          phone: location.contact_phone,
          tax_number: '00000000',
          number_of_tables: 10,
          tax_rate: 200,
          location_type: 'RESTAURANT',
          address_line_1: location.address_line_1,
          address_line_2: location.address_line_2,
          address_city: location.address_city,
          address_country: location.address_country,
          address_postcode: location.address_postcode,
          country_code: 'GBR',
          currency: 'GBP',
          locale: 'en-GB',
          can_manage_menus: organisation.singleLocation,
          integrations: {
            data: {
              organisation_id,
              previous_report_at: new Date().toISOString(),
            },
          },
        })),
      };
      const locations_created: number | undefined = await createReferalLocations(createLocationVariables);
      if (locations_created) {
        await handleUpdateReferral(referral, 'COMPLETED');
        setSaving(false);
        handleFinish(referral);
      }
    }
  };

  const handleUpdateReferral = async (referral: Referral, status: string) => {
    await updateReferal({
      pk_columns: {
        id: referral.id,
      },
      set: {
        status,
      },
    });
    handleFinish(referral);
  };

  const handleFinish = (referral: Referral) => {
    setReferrals((i) => i.filter((r) => r !== referral));
  };

  const handleStatusFilter = (status: string) => {
    if (status) {
      setStatusFiltered(status);
    }
  };

  const referralTypes: string[] = [...new Set(referrals.map((i) => i.referral))];

  const referralFilter: SortableTableFilter = {
    key: 'referer',
    label: 'Referer',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        let isGood = referralFiltered.length === 0;
        const referer: string = r.columns.find((i) => i.key === 'referer')?.label as string;
        return (isGood = isGood || referralFiltered.includes(referer));
      }),
    component: (
      <ToggleButtonGroup size="small" value={referralFiltered} color="primary" onChange={(_: unknown, value: string[]) => setReferralFiltered(value)}>
        {referralTypes.map((i) => (
          <ToggleButton className={classes.toggleButton} key={i} value={i}>
            {capitaliseFirst(i)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    ),
  };

  const statusTypes: string[] = ['NEW', 'CLOSED', 'COMPLETED'];

  const statusFilter: SortableTableFilter = {
    key: 'status',
    label: 'Status',
    filter: (rows: SortableTableRow[]) => rows,
    component: (
      <ToggleButtonGroup size="small" exclusive value={statusFiltered} color="primary" onChange={(_: unknown, value: string) => handleStatusFilter(value)}>
        {statusTypes.map((i) => (
          <ToggleButton className={classes.toggleButton} key={i} value={i}>
            {capitaliseFirst(i)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    ),
  };

  const filters = [statusFilter];

  if (referrals.length > 0) {
    filters.push(referralFilter);
  }

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  const headers: SortableTableHeader[] = [
    { key: 'created_at', label: 'Requested at' },
    { key: 'referer', label: 'Referer' },
    { key: 'name', label: 'Name' },
    { key: 'locations', label: 'Locations', align: 'center' },
    { key: 'has_note', label: 'Has notes' },
  ];

  const orgHeaders: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'contact_name', label: 'Contact name' },
    { key: 'contact_email', label: 'Contact email' },
    { key: 'contact_phone', label: 'Contact phone' },
  ];

  const locHeaders: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'contact_name', label: 'Contact name' },
    { key: 'contact_email', label: 'Contact email' },
    { key: 'contact_phone', label: 'Contact phone' },
    { key: 'address_line_1', label: 'Address' },
    { key: 'address_city', label: 'City' },
    { key: 'address_postcode', label: 'Postcode' },
  ];

  const rows: SortableTableRow[] = referrals.map((referral: Referral) => ({
    key: referral.id,
    actions: [
      {
        label: 'Close',
        show: statusFiltered === 'NEW',
        destructive: true,
        onClick: () => handleUpdateReferral(referral, 'CLOSED'),
      },
      {
        label: 'Create',
        show: statusFiltered !== 'COMPLETED',
        onClick: () => handleCreate(referral),
      },
    ],
    columns: [
      {
        key: 'created_at',
        label: referral.created_at,
        format: (date) => getDate(date as string, 'DD/MM/yyyy: HH:mm'),
      },
      {
        key: 'referer',
        label: referral.referral,
      },
      {
        key: 'name',
        label: referral.organisation.name,
      },
      {
        key: 'locations',
        label: referral.locations.length,
      },
      {
        key: 'has_note',
        label: booleanToYesNo(referral.note !== ''),
      },
    ],
    collapsible: [
      {
        title: 'Organisation',
        headers: orgHeaders,
        rows: [
          {
            key: referral.organisation.name,
            columns: [
              { key: 'name', label: referral.organisation.name },
              { key: 'contact_name', label: referral.organisation.contact_name },
              { key: 'contact_email', label: referral.organisation.contact_email },
              { key: 'contact_phone', label: referral.organisation.contact_phone },
            ],
          },
        ],
      },
      {
        title: 'Locations',
        counter: referral.locations.length,
        headers: locHeaders,
        rows: referral.locations.map((location: ReferralLocation) => ({
          key: location.id,
          columns: [
            { key: 'name', label: location.name },
            { key: 'contact_name', label: location.contact_name },
            { key: 'contact_email', label: location.contact_email },
            { key: 'contact_phone', label: location.contact_phone },
            { key: 'address_line_1', label: location.address_line_1 },
            { key: 'address_city', label: location.address_city },
            { key: 'address_postcode', label: location.address_postcode },
          ],
        })),
      },
      {
        title: 'Note',
        counter: referral.locations.length,
        headers: [{ key: 'note', label: 'Note' }],
        rows: [
          {
            key: 'note',
            columns: [{ key: 'note', label: referral.note }],
          },
        ],
      },
    ],
  }));

  return (
    <>
      <Dashboard title={'Referrals'}>
        <CustomSortableTable
          title={`${capitaliseFirst(statusFiltered)} Referrals`}
          counter="filtered"
          searchable={['name']}
          filters={filters}
          headers={headers}
          rows={filterRows()}
        />
      </Dashboard>
      {saving && <CustomBackdrop label="Creating Organisation" />}
    </>
  );
};

export default withRouter(withStyles(styles)(ReferralsAdmin));
