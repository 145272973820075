import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import { Chip, Grid, withStyles } from '@material-ui/core';

import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import { GridCard } from '../../../../components/Grid';
import { booleanToYesNo, capitaliseFirst } from '../../../../utils/stringUtils';

import { Location, selectLocationInitQuery } from './queries';

import styles from './styles';
import ViewHours from '../Common/components/ViewHours';
import { initialOpeningHours, initialPreorderHours } from '../Common';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  location_id: string;
  handleEdit: () => void;
  handleCreateUser: () => void;
  handleViewUsers: () => void;
  handleCreateStaff: () => void;
  handleViewStaff: () => void;
  handleViewIntegrations: () => void;
  handleViewStock: () => void;
  handleViewOrders: () => void;
  handleViewVoucherCodes: () => void;
  handleViewPrinters: () => void;
}

const ViewLocation = ({
  classes,
  history,
  location_id,
  handleEdit,
  handleCreateUser,
  handleViewUsers,
  handleCreateStaff,
  handleViewStaff,
  handleViewIntegrations,
  handleViewOrders,
  handleViewVoucherCodes,
  handleViewPrinters,
  handleViewStock,
}: Props): React.ReactElement => {
  const [location, setLocation] = useState<Location | UnknownObject>({});

  const { data: locationInitData } = useQuery(selectLocationInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationInitData) {
      setLocation(locationInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationInitData]);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <ReadOnlyBlock
            title={location.name}
            gridSize={4}
            items={[
              { label: 'Email', value: location.email || 'No email information found' },
              { label: 'Phone', value: location.phone || 'No phone information found' },
              { label: 'Website', value: location.website || 'No website provided' },
              { label: 'Currency', value: location.currency },
              { label: 'Locale', value: location.locale },
              { label: 'Number of tables', value: `${location.number_of_tables}` },
              {
                label: 'Address',
                list: [location.address_line_1, location.address_line_2, location.address_city, location.address_country, location.address_postcode],
              },
              { label: 'Location type', value: location.enum_location_type?.description },
              { label: 'Allow the location to manage their own menu?', value: booleanToYesNo(location.can_manage_menus) },
              { label: 'Enable tablet kitchen view?', value: booleanToYesNo(location.has_kitchen_printer) },
              { label: 'Enable tablet bar view?', value: booleanToYesNo(location.has_bar_printer) },
              { label: 'Staff enabled (PIN access)', value: booleanToYesNo(location.has_staff) },
              location.has_staff ? { label: 'Idle timeout', value: `${location.idle_timeout} seconds` } : null,
              { label: 'Multi station printers', value: booleanToYesNo(location.has_station_printers) },
              {
                label: 'Service charge',
                list: location.service_charge
                  ? [
                      `Order types: ${(location.service_charge!.order_types as String[]).map((i) => capitaliseFirst(i.toLowerCase().replace(/_/g, ' '))).join(', ')}`,
                      `Percentage: ${location.service_charge!.percentage}%`,
                      `Minimum covers: ${location.service_charge!.min_covers}`,
                    ]
                  : ['Service charge is not active'],
              },
              {
                label: 'Location menu',
                value: location.location_menu ? location.location_menu.name : 'Not selected',
              },
              {
                label: '',
                value: '',
                components: [<ViewHours title="Opening Hours" hours={location.ordering_system_open_hours! ?? initialOpeningHours} />],
              },
              {
                label: '',
                value: '',
                components: [<ViewHours title="Preorder Hours" hours={location.ordering_system_preorder_hours! ?? initialPreorderHours} />],
              },
              { label: 'Days limit for preorder', value: location.preorder_days_limit ?? 'not specified' },
              { label: 'Inventory restock type', value: location.enum_inventory_restock_type?.description },
              {
                label: 'Primary colour',
                value: '',
                components: [<Chip style={{ backgroundColor: location.ordering_system_primary_color }} />],
              },
              {
                label: 'Secondary colour',
                value: '',
                components: [<Chip style={{ backgroundColor: location.ordering_system_secondary_color }} />],
              },
              { label: 'Pacing limit for preorder', value: location.preorder_pacing_limit ?? 'not specified' },
              { label: 'Order interval', value: location.order_interval },
            ]}
            handleEdit={handleEdit}
          />
        </>
      </Grid>
      <GridCard
        fill
        gridSize={4}
        key="Users"
        title="Users"
        subTitle="Location users"
        editLabel="Create"
        handleEdit={() => handleCreateUser()}
        handleView={() => handleViewUsers()}
      />
      {location.has_staff && (
        <GridCard fill gridSize={4} key="Staff" title="Staff" subTitle="Manage staff for PIN access" handleEdit={() => handleCreateStaff()} handleView={() => handleViewStaff()} />
      )}
      {location.has_station_printers && (
        <GridCard
          fill
          gridSize={4}
          key="MultiStationPrinting"
          title="Multi station printing"
          subTitle="Manage LAN (WiFi / Network) printers"
          handleView={() => handleViewPrinters()}
        />
      )}
      <GridCard fill gridSize={4} key="Orders" title="Orders" subTitle="Export orders to CSV" handleView={() => handleViewOrders()} />
      <GridCard fill gridSize={4} key="Integrations" title="Integrations" subTitle="Payments, Deliverect, Xero" handleView={() => handleViewIntegrations()} />
      <GridCard fill gridSize={4} key="Stock" title="Stock Managment" subTitle="Menu items stock" handleView={() => handleViewStock()} />
      {location.integrations && location.integrations[0]?.vouchers && (
        <GridCard
          fill
          gridSize={4}
          key="Vouchers"
          title="Vouchers"
          subTitle="Discount voucher codes. Can be used for promotions or marketing"
          handleView={() => handleViewVoucherCodes()}
        />
      )}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewLocation));
