import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Location, LocationMenu, LocationReducerAction, validation, ValidationErrors } from '../..';
import { OrderType, orderTypes } from '../../../../Menus/Common';
import { capitaliseFirst } from '../../../../../../utils/stringUtils';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    sectionDescription: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      marginBottom: theme.spacing(3),
    },
    inputField: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    helperText: {
      color: '#6B7280',
      backgroundColor: 'transparent',
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    switch: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    staff: {
      marginBottom: theme.spacing(3),
    },
    switchHelper: {
      color: '#6B7280',
    },
    formGroup: {
      marginTop: theme.spacing(1),
    },
    orderType: {
      marginBottom: theme.spacing(3),
    },
    active: {
      marginBottom: theme.spacing(3),
    },
    stickyButtonContainer: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      zIndex: 1000,
    },
    stickyButton: {
      paddingInline: theme.spacing(4),
    },
  });

interface Props extends WithStyles<typeof styles> {
  location: Location;
  locationMenus: LocationMenu[];
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: LocationReducerAction; value: any }>;
  handleSave?: () => void;
  isLocationCreate: boolean;
}

const Operations = ({ classes, location, errors, setErrors, dispatch, handleSave, isLocationCreate }: Props): React.ReactElement => {
  const [initialState, setInitialState] = useState<Location | null>(null);
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    setInitialState(location);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialState && JSON.stringify(initialState) !== JSON.stringify(location)) {
      setStateChanged(true);
    } else {
      setStateChanged(false);
    }
  }, [location, initialState]);

  const validate = useCallback(
    (field: string) => {
      const errorItems: { [key: string]: string } = {};
      if (field === 'number_of_tables') {
        errorItems.number_of_tables = validation.number_of_tables(location.number_of_tables) as string;
      }
      if (field === 'tax_number') {
        errorItems.tax_number = validation.tax_number(location.tax_number) as string;
      }
      if (field === 'locale') {
        errorItems.locale = validation.locale(location.locale) as string;
      }
      if (field === 'currency') {
        errorItems.currency = validation.currency(location.currency) as string;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [location, errors, setErrors]
  );

  const currencies = ['GBP', 'USD'];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Operations
        </Typography>
      </Grid>

      {/* Covers Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Covers
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            How many tables you have in total within the location
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <TextField
            label="Number of tables"
            variant="outlined"
            type="number"
            required
            className={classes.inputField}
            value={location.number_of_tables || ''}
            error={errors.number_of_tables as boolean}
            helperText={errors.number_of_tables}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.NUMBER_OF_TABLES,
                value: event.target.value,
              })
            }
            onKeyUp={() => validate('number_of_tables')}
            onBlur={() => validate('number_of_tables')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
        </Paper>
      </Grid>

      {/* Tax Management Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Tax Management
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Alter the percentage tax paid on each item and the default currency displayed
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <TextField
            label="Tax number"
            variant="outlined"
            required
            className={classes.inputField}
            value={location.tax_number}
            error={errors.tax_number as boolean}
            helperText={errors.tax_number}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.TAX_NUMBER,
                value: event.target.value,
              })
            }
            onKeyUp={() => validate('tax_number')}
            onBlur={() => validate('tax_number')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
          <TextField
            label="Currency"
            variant="outlined"
            select
            required
            className={classes.inputField}
            value={location.currency}
            error={errors.currency as boolean}
            helperText={errors.currency}
            onChange={(event: React.ChangeEvent<any>) =>
              dispatch({
                type: LocationReducerAction.CURRENCY,
                value: event.target.value,
              })
            }
            onBlur={() => validate('currency')}>
            {currencies.map((i) => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </TextField>
        </Paper>
      </Grid>

      {/* Switches Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Advanced Settings
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Configure additional settings for the location.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Allow the location to manage their own menu?"
            className={classes.switch}
            control={
              <Switch
                checked={location.can_manage_menus}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationReducerAction.CAN_MANAGE_MENUS,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
          <FormControlLabel
            label="Enable tablet kitchen view?"
            className={classes.switch}
            control={
              <Switch
                checked={location.has_kitchen_printer}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationReducerAction.HAS_KITCHEN_PRINTER,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
          <FormControlLabel
            label="Enable tablet bar view?"
            className={classes.switch}
            control={
              <Switch
                checked={location.has_bar_printer}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationReducerAction.HAS_BAR_PRINTER,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
        </Paper>
      </Grid>

      {/* Staff Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Staff Management
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Configure staff settings and access.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <FormControl className={classes.staff}>
            <FormControlLabel
              label="Staff enabled (PIN access)?"
              className={classes.switch}
              control={
                <Switch
                  checked={location.has_staff}
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch({
                      type: LocationReducerAction.HAS_STAFF,
                      value: event.target.checked,
                    })
                  }
                />
              }
            />
            <FormHelperText className={classes.switchHelper}>
              This enables the management of staff members without user accounts and enables PIN access to the tablet
            </FormHelperText>
          </FormControl>

          {location.has_staff && (
            <FormControl variant="outlined" className={classes.inputField} required error={errors.locale as boolean}>
              <InputLabel id="idle_timeout-select-label">Idle timeout</InputLabel>
              <Select
                labelId="idle_timeout-select-label"
                label="Idle timeout"
                value={location.idle_timeout}
                onChange={(event: React.ChangeEvent<any>) =>
                  dispatch({
                    type: LocationReducerAction.IDLE_TIMEOUT,
                    value: event.target.value,
                  })
                }>
                {[
                  { t: 30, l: '30 seconds' },
                  { t: 45, l: '45 seconds' },
                  { t: 60, l: '1 minute' },
                  { t: 90, l: '1 minute 30 seconds' },
                  { t: 120, l: '2 minutes' },
                ].map((i) => (
                  <MenuItem key={i.t} value={i.t}>
                    {i.l}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>The time the device has to be inactive before it locks and requires a PIN to access</FormHelperText>
            </FormControl>
          )}

          <FormControl className={classes.staff}>
            <FormControlLabel
              label="Multi station printing"
              className={classes.switch}
              control={
                <Switch
                  checked={location.has_station_printers}
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch({
                      type: LocationReducerAction.HAS_STATION_PRINTERS,
                      value: event.target.checked,
                    })
                  }
                />
              }
            />
            <FormHelperText className={classes.switchHelper}>
              This enables the management of LAN (WiFi / Network) printers to allow menu categories to be sent to a specific printer
            </FormHelperText>
          </FormControl>
        </Paper>
      </Grid>
      {/* Service Charge Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Service Charge
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Manage the service charge settings for the location.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Active"
            className={classes.active}
            control={
              <Switch
                checked={location.service_charge != null}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationReducerAction.SERVICE_CHARGE_ACTIVE,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
          {location.service_charge != null && (
            <>
              <FormControl className={classes.orderType} component="fieldset" required error={errors.service_charge_order_types as boolean}>
                <FormLabel component="legend">Order type</FormLabel>
                <FormGroup className={classes.formGroup}>
                  {orderTypes.map((orderType: OrderType) => (
                    <FormControlLabel
                      key={orderType as string}
                      control={
                        <Checkbox
                          name={orderType}
                          color="primary"
                          checked={location.service_charge!.order_types.includes(orderType)}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            dispatch({
                              type: LocationReducerAction.SERVICE_CHARGE_ORDER_TYPES,
                              value: { orderType, active: event.target.checked },
                            })
                          }
                          onBlur={() => validate('menuType')}
                        />
                      }
                      label={capitaliseFirst(orderType.replace(/_/g, ' '))}
                    />
                  ))}
                </FormGroup>
                <FormHelperText>{errors.service_charge_order_types}</FormHelperText>
              </FormControl>

              <TextField
                className={classes.inputField}
                data-qa="percentage-textfield"
                required
                label="Percentage"
                variant="outlined"
                type="number"
                inputProps={{
                  step: 0.1,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                error={errors.service_charge_percentage as boolean}
                helperText={errors.service_charge_percentage}
                value={location.service_charge!.percentage || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationReducerAction.SERVICE_CHARGE_PERCENT,
                    value: event.target.value,
                  })
                }
                onKeyUp={() => validate('percentage')}
                onBlur={() => validate('percentage')}
              />

              <TextField
                className={classes.inputField}
                data-qa="covers-textfield"
                required
                label="Minimum covers"
                type="number"
                variant="outlined"
                value={location.service_charge!.min_covers}
                helperText="Minimum covers to apply service charge. When zero, service charge will be applied to all orders"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationReducerAction.SERVICE_CHARGE_COVERS,
                    value: event.target.value,
                  })
                }
              />
            </>
          )}
        </Paper>
      </Grid>
      {stateChanged && !isLocationCreate && (
        <Box className={classes.stickyButtonContainer}>
          <Button variant="contained" color="primary" className={classes.stickyButton} onClick={handleSave}>
            Save changes
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default withStyles(styles)(Operations);
