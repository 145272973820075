import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      // borderRadius: theme.props?.MuiPaper?.style?.borderRadius,
      // overflow: 'hidden',
      borderWidth: 0,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
    },
    toolbar: {
      // borderBottom: `1px solid ${theme.palette.primary.light}`,
      // overflow: 'hidden',
      paddingLeft: 0,
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
    },
    notificationsButton: {
      marginLeft: 'auto',
    },
    toolbarButtons: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    toolbarAvatar: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
    },
    toolbarUserInfo: {
      textAlign: 'right',
    },
  });

export default styles;
