import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectOrganisationUsersInitQuery = () => ({
  users_organisation: [
    {
      id: types.string,
      full_name: types.string,
      email: types.string,
    },
  ],
});

type X = Unpacked<typeof _selectOrganisationUsersInitQuery>;
export type OrganisationUser = X['users_organisation'][0];

export const selectOrganisationUsersInitQuery = () => gql`
  ${query(_selectOrganisationUsersInitQuery())}
`;
