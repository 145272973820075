import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationUsersInitQuery = () => ({
  locations: [
    {
      devices: params(
        {
          where: { device_type: { _eq: rawString('PRINTER') } },
          order_by: { name: 'asc' },
        },
        [
          {
            id: types.string,
            name: types.string,
            device_config: types.custom<{ location: string; printCopyToTill: boolean; categories: { id: string; name: string }[] }>(),
          },
        ]
      ),
    },
  ],
});

type X = Unpacked<typeof _selectLocationUsersInitQuery>;
export type Location = X['locations'][0];

export const selectLocationUsersInitQuery = () => gql`
  ${query(_selectLocationUsersInitQuery())}
`;
