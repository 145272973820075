import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateLocationIntegrationMutation = gql`
  mutation ($pk_columns: location_integrations_pk_columns_input!, $set: location_integrations_set_input!) {
    update_location_integrations_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateLocationIntegration = async (variables: object) => {
  return await gqlClient.mutate<{
    update_location_integrations_by_pk: {
      id: string;
    };
  }>({ mutation: updateLocationIntegrationMutation!, variables });
};
const insertLocationIntegrationMutation = gql`
  mutation ($object: location_integrations_insert_input!) {
    insert_location_integrations_one(object: $object) {
      id
    }
  }
`;

export const insertLocationIntegration = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_location_integrations_one: {
      id: string;
    };
  }>({ mutation: insertLocationIntegrationMutation!, variables });
};
