import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { generateOnboardingLink } from './queries';
import { LocationIntegrationReducerAction, PaymentIntegration } from '../../../../index';

interface Props {
  handleSave: () => void;
  dispatch: any;
  payment: PaymentIntegration;
  locationId: string;
}

const SellerOnboardingLightbox = ({ handleSave, dispatch, payment, locationId }: Props) => {
  const [actionUrl, setActionUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutateAsync: generateLink, isLoading: generatingLink } = useMutation({
    mutationKey: 'onboarding-link-generate',
    mutationFn: () => generateOnboardingLink(locationId),
  });

  useEffect(() => {
    const loadPayPalScript = () => {
      const scriptId = 'paypal-js';
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js';
        script.async = true;
        document.body.appendChild(script);
      }
    };
    loadPayPalScript();
  }, []);
  const [initialResponse, setInitialResponse] = useState(false);
  const startOnboarding = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await generateLink();

      dispatch({
        type: LocationIntegrationReducerAction.PAYMENT_LINKS,
        value: response.links,
      });
      dispatch({
        type: LocationIntegrationReducerAction.PAYMENT_ISONBOARDED,
        value: false,
      });
      setInitialResponse(true);
    } catch (err) {
      console.error('Onboarding error:', err);
      setError('Failed to start onboarding process.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!payment.isOnboarded && (payment.links ?? []).length > 0 && initialResponse) {
      handleSave();
      const actionUrl = payment.links?.find((link: { rel: string }) => link.rel === 'action_url').href;
      setActionUrl(actionUrl);
    } else if (!payment.isOnboarded && (payment.links ?? []).length > 0) {
      const actionUrl = payment.links?.find((link: { rel: string }) => link.rel === 'action_url').href;
      setActionUrl(actionUrl);
    }
  }, [dispatch, payment, initialResponse]);

  return (
    <div>
      <h1>Seller Onboarding with PayPal</h1>
      {!payment.isOnboarded && (payment.links ?? []).length === 0 && (
        <button onClick={startOnboarding} disabled={loading}>
          {loading ? 'Generating Onboarding Link...' : 'Start Onboarding'}
        </button>
      )}
      {payment.isOnboarded && (payment.links ?? []).length > 0 && <h1>You have been successfully onboarded. You can use paypal payment.</h1>}

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {actionUrl && (
        <div>
          <a data-paypal-button="true" href={`${actionUrl}&displayMode=minibrowser`} target="PPFrame">
            Sign up for PayPal
          </a>
        </div>
      )}
      {generatingLink && <div></div>}
    </div>
  );
};

export default SellerOnboardingLightbox;
