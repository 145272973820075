import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Grid, withStyles } from '@material-ui/core';

import { AddCircle as AddCircleIcon, Publish as UploadIcon } from '@material-ui/icons';

import { GridCard } from '../../../../components/Grid';
import SortableGrid, { SortableGridChild } from '../../../../components/Grid/SortableGrid';
import { booleanToYesNo, pluralise, timeToFormattedString } from '../../../../utils/stringUtils';
import CustomSortableTable, { SortableTableHeader, SortableTableRow } from '../../../../components/CustomSortableTable';
import CustomViewToggle, { ViewType } from '../../../CustomViewToggle';
import { Menu } from './queries';
import { updateMenusOrderIndexMutation } from './mutations';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  organisation_id?: string;
  items: Menu[];
  handleSetMenus: (menus: Menu[]) => void;
  handleView: (id: string) => void;
  handleCreate?: () => void;
  handleUpload?: () => void;
}

const ListMenus = ({ classes, organisation_id, items, handleSetMenus, handleView, handleCreate, handleUpload }: Props): React.ReactElement => {
  const [viewType, setViewType] = useState<ViewType>(ViewType.TABLE);
  const [menusItems, setMenuItems] = useState<Menu[]>(items);

  useEffect(() => {
    let mounted = true;
    if (mounted && items) {
      setMenuItems(items);
    }
    return () => {
      mounted = false;
    };
  }, [items]);

  const onDragEnd = (result: SortableGridChild[]) => {
    setMenuItems((menus) => {
      return result.map((r) => menus.find((m) => m.id === r.key)!).map((m, i) => ({ ...m, order_index: i }));
    });
  };

  const handleSaveOrdering = async () => {
    const objects = menusItems.map((i) => ({ ...i, organisation_id }));

    const updateMenusVariables = {
      objects,
    };

    await updateMenusOrderIndexMutation(updateMenusVariables);
    handleSetMenus(menusItems);
    setViewType(ViewType.TABLE);
  };

  const renderViewType = () => {
    const label = 'Menu';
    const title = pluralise(label, menusItems.length);
    const createLabel = `Create menu`;
    if (viewType === ViewType.TABLE) {
      const headers: SortableTableHeader[] = [
        { key: 'order', label: 'Ordering', hidden: true },
        { key: 'name', label: 'Name' },
        { key: 'description', label: 'Description' },
        { key: 'active', label: 'Active' },
        { key: 'available_from', label: 'Available from' },
        { key: 'available_to', label: 'Available to' },
        { key: 'is_primary', label: 'Is primary' },
      ];

      const rows: SortableTableRow[] = menusItems.map((menu: Menu) => ({
        key: menu.id,
        disabled: !menu.active,
        actions: [
          {
            label: 'View',
            onClick: () => handleView(menu.id),
          },
        ],
        columns: [
          {
            key: 'order',
            label: menu.order_index + 1,
          },
          {
            key: 'name',
            label: menu.name,
          },
          {
            key: 'description',
            label: menu.description,
          },
          {
            key: 'active',
            label: booleanToYesNo(menu.active),
          },
          {
            key: 'available_from',
            label: timeToFormattedString(menu.available_from, 'HM', true),
          },
          {
            key: 'available_to',
            label: timeToFormattedString(menu.available_to, 'HM', true),
          },
          {
            key: 'is_primary',
            label: booleanToYesNo(menu.is_primary),
          },
        ],
      }));

      const actions = [];

      if (handleUpload) {
        actions.push({
          key: 'upload',
          label: 'Upload menu',
          icon: <UploadIcon />,
          onClick: () => handleUpload(),
        });
      }

      if (handleCreate) {
        actions.push({
          key: 'create',
          label: createLabel,
          icon: <AddCircleIcon />,
          onClick: () => handleCreate(),
        });
      }

      return (
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CustomSortableTable title={title} orderdBy="order" ordered="asc" actions={actions} headers={headers} counter={menusItems.length} rows={rows} />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <SortableGrid
          gridSize={3}
          onDragEnd={onDragEnd}
          handleSave={handleSaveOrdering}
          items={menusItems.map((menu: Menu, index: number) => ({
            key: menu.id,
            label: menu.name,
            component: <GridCard fill noGrid title={menu.name} counter={index + 1} subTitle={menu.description} />,
          }))}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item xs={12}>
        <CustomViewToggle value={viewType} sortable handleChangeView={setViewType} />
      </Grid>
      {renderViewType()}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ListMenus));
