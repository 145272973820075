import React, { useState, useEffect, useCallback } from 'react';

import { useQuery } from '@apollo/client';

import { GridCard } from '../../../Grid';

import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';
import { numberToMoney } from '../../../../utils/stringUtils';
import { Grid, Typography } from '@material-ui/core';
import { exactDateString } from '../../../../utils/dateUtils';

const _query = (startDate: string, endDate: string) => ({
  orders_deliverect: params(
    {
      where: {
        paid: { _eq: true },
        _and: [{ created_at: { _gte: rawString(startDate) } }, { created_at: { _lte: rawString(endDate) } }],
      },
    },
    [
      {
        service_charge: types.number,
        tax: types.number,
        total: types.number,
        delivery_by: types.string,
      },
    ]
  ),
});

type OrdersDeliverect = Unpacked<typeof _query>['orders_deliverect'][0];

interface Values {
  service_charge: string;
  tax: string;
  total: string;
  totalOrders: number;
  totalDeliveredBy: TotalDeliveredBy[];
}

interface TotalDeliveredBy {
  name: string;
  count: number;
  total: number;
}

interface Props {
  startDate: Date;
  endDate: Date;
  locale: string;
  currency: string;
}

export const initQuery = (startDate: string, endDate: string) => gql`
  ${query(_query(startDate, endDate))}
`;

const MetricOrders = ({ startDate, endDate, locale, currency }: Props): React.ReactElement => {
  const [value, setValue] = useState<Values>({
    service_charge: '',
    tax: '',
    total: '',
    totalOrders: 0,
    totalDeliveredBy: [],
  });

  const { data, loading } = useQuery(initQuery(exactDateString(startDate), exactDateString(endDate)), { fetchPolicy: 'no-cache' });

  const formatMoney = useCallback((v: number) => numberToMoney(v, currency, locale, true), [currency, locale]);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const orders = data.orders_deliverect;
      const totalOrders = orders.length;

      const s1 = orders.reduce((a: number, b: OrdersDeliverect) => a + b.service_charge, 0);
      const service_charge = formatMoney(s1);

      const tax1 = orders.reduce((a: number, b: OrdersDeliverect) => a + b.tax, 0);
      const tax = formatMoney(tax1);

      const total0 = totalOrders;
      const total1 = orders.reduce((a: number, b: OrdersDeliverect) => a + b.total, 0);
      const total2 = total0 > 0 ? total1 / total0 : 0;
      const total = formatMoney(total2);

      const totalDeliveredBy: TotalDeliveredBy[] = [];
      orders.forEach((o: OrdersDeliverect) => {
        if (!totalDeliveredBy.some((i) => i.name === o.delivery_by)) {
          totalDeliveredBy.push({
            name: o.delivery_by,
            count: 0,
            total: 0,
          });
        }
        const item = totalDeliveredBy.find((i) => i.name === o.delivery_by);
        if (item) {
          item.count += 1;
          item.total += o.total;
        }
      });

      setValue({
        service_charge,
        tax,
        total,
        totalOrders,
        totalDeliveredBy,
      });
    }
    return () => {
      mounted = false;
    };
  }, [data, formatMoney]);

  return (
    <>
      {value.totalOrders > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h4">Deliverect Orders</Typography>
          </Grid>
          <GridCard fill gridSize={4} loading={loading} key="TotalDeliverect" title="Total orders" counterSmall counter={value.totalOrders} />
          <GridCard fill gridSize={4} loading={loading} key="TotalDeliverectGross" title="Total gross" counterSmall counter={value.total} />
          <GridCard fill gridSize={4} loading={loading} key="AppliedDeliverectServiceCharge" title="Applied service charge" counterSmall counter={value.service_charge} />
          <GridCard fill gridSize={4} loading={loading} key="AppliedDeliverectTax" title="Applied tax" counterSmall counter={value.tax} />
          {value.totalDeliveredBy.map((i) => (
            <GridCard fill gridSize={4} loading={loading} key={`total_${i.name}`} title={i.name} counterSmall subCounterLabel={formatMoney(i.total)} counter={i.count} />
          ))}
        </>
      )}
    </>
  );
};

export default MetricOrders;
