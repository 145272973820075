import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectCategoryInitQuery = (id: string, categoryType: string) => ({
  categories_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      active: types.string,
      colour_id: types.string,
      score: types.number,
      organisation: {
        name: types.string,
        currency: types.string,
        locale: types.string,
      },
      menu: {
        name: types.string,
      },
      [`${categoryType}_assignments`]: [
        {
          [categoryType]: {
            id: types.string,
            name: types.string,
            description: types.string,
            tag: types.string,
            active: types.boolean,
            price: types.number,
            tax: types.number,
            prep_time: types.number,
            bypass_prep: types.boolean,
            should_print: types.boolean,
            auto_serve_when_ready: types.boolean,
            order_index: types.number,
            score: types.number,
            modifier_assignments: [
              {
                modifier: {
                  name: types.string,
                  price: types.number,
                  tax: types.number,
                },
              },
            ],
            allergy_assignments: [
              {
                allergy: {
                  name: types.string,
                },
              },
            ],
          },
        },
      ],
    }
  ),
});

type X = Unpacked<typeof _selectCategoryInitQuery>;
export type Category = X['categories_by_pk'];

export const selectCategoryInitQuery = (id: string, categoryType: string) => gql`
  ${query(_selectCategoryInitQuery(id, categoryType))}
`;
