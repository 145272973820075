import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const _updateDishOrderIndexMutation = gql`
  mutation ($objects: [dishes_insert_input!]!) {
    insert_dishes(objects: $objects, on_conflict: { constraint: dishes_pkey, update_columns: [order_index] }) {
      affected_rows
    }
  }
`;

export const updateDishOrderIndexMutation = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_dishes: {
      affected_rows: string;
    };
  }>({ mutation: _updateDishOrderIndexMutation!, variables });
};

const _updateDrinkOrderIndexMutation = gql`
  mutation ($objects: [drinks_insert_input!]!) {
    insert_drinks(objects: $objects, on_conflict: { constraint: drinks_pkey, update_columns: [order_index] }) {
      affected_rows
    }
  }
`;

export const updateDrinkOrderIndexMutation = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_drinks: {
      affected_rows: string;
    };
  }>({ mutation: _updateDrinkOrderIndexMutation!, variables });
};

const updateDrinkScoreMutation = gql`
  mutation ($id: uuid!, $score: Int) {
    update_drinks_by_pk(pk_columns: { id: $id }, _set: { score: $score }) {
      id
    }
  }
`;

export const updateDrinkScore = async (id: string, score: number) => {
  return await gqlClient.mutate<{
    update_drinks_by_pk: {
      id: string;
    };
  }>({ mutation: updateDrinkScoreMutation!, variables: { id, score } });
};

const updateDishScoreMutation = gql`
  mutation ($id: uuid!, $score: Int) {
    update_dishes_by_pk(pk_columns: { id: $id }, _set: { score: $score }) {
      id
    }
  }
`;

export const updateDishScore = async (id: string, score: number) => {
  return await gqlClient.mutate<{
    update_dishes_by_pk: {
      id: string;
    };
  }>({ mutation: updateDishScoreMutation!, variables: { id, score } });
};
