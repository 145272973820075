import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { stringFromArray } from '../../../../../utils/stringUtils';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../.././../layouts/Dashboard';
import CustomBackdrop from '../../../.././../components/CustomBackdrop';
import { organisationUserReducer, OrganisationUserReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import styles from './styles';
import { editOrganisationUserInitQuery, Organisation } from './queries';
import { updateOrganisationUser } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  organisation_user_id: string;
}

const EditOrganisationUser = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, organisation_user_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
  });
  const [user, dispatchUser] = useReducer(organisationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editOrganisationUserInitQueryData } = useQuery(editOrganisationUserInitQuery(organisation_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editOrganisationUserInitQueryData) {
      setOrganisation(editOrganisationUserInitQueryData.users_organisation_by_pk.organisation);
      dispatchUser({ type: OrganisationUserReducerAction.INIT, value: editOrganisationUserInitQueryData.users_organisation_by_pk });
    }
    return () => {
      mounted = false;
    };
  }, [editOrganisationUserInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: organisation_user_id,
      },
      set: {
        ...user,
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
        email: user.email.trim(),
      },
    };
    await updateOrganisationUser(updateVariables);

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    history.push(`/admin/organisations/${organisation_id}/users/${organisation_user_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Users', link: `/admin/organisations/${organisation_id}/users` },
          { label: stringFromArray([user.first_name, user.last_name]), link: `/admin/organisations/users/${organisation_user_id}` },
          { label: 'Edit' },
        ]}>
        <Steps completeLabel="Save" user={user} isEdit dispatch={dispatchUser} handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditOrganisationUser));
