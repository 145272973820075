import { capitaliseFirst, numbersOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';

export interface LocationStaff {
  first_name: string;
  last_name: string;
  pin: string;
  active: boolean;
}

export interface Organisation {
  id: string;
  name: string;
}

export interface ValidationErrors {
  first_name: ValidationType;
  last_name: ValidationType;
  pin: ValidationType;
  pinLength: ValidationType;
  pinUsed: ValidationType;
}

export const validationErrors: ValidationErrors = {
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  pin: 'Please provide a PIN',
  pinLength: 'PIN must equal 4 numbers',
  pinUsed: 'PIN has already been assigned',
};

export interface Validation {
  first_name: (value: string) => ValidationType;
  last_name: (value: string) => ValidationType;
  pin: (value: string, usedPins: string[]) => ValidationType;
}

export const validation: Validation = {
  first_name: (value) => (!value ? validationErrors.first_name : false),
  last_name: (value) => (!value ? validationErrors.last_name : false),
  pin: (value, usedPins) => {
    if (!value) return validationErrors.pin;
    if (value.length !== 4) return validationErrors.pinLength;
    if (usedPins.includes(value)) return validationErrors.pinUsed;
    return false;
  },
};
export enum LocationStaffReducerAction {
  FIRST_NAME,
  LAST_NAME,
  PIN,
  ACTIVE,
  INIT,
}

export const locationStaffReducer = (state: LocationStaff, action: { type: LocationStaffReducerAction; value: any }): LocationStaff => {
  switch (action.type) {
    case LocationStaffReducerAction.FIRST_NAME:
      return { ...state, first_name: capitaliseFirst(action.value) };
    case LocationStaffReducerAction.LAST_NAME:
      return { ...state, last_name: capitaliseFirst(action.value) };
    case LocationStaffReducerAction.PIN:
      return { ...state, pin: numbersOnly(action.value) };
    case LocationStaffReducerAction.ACTIVE:
      return { ...state, active: action.value as boolean };
    case LocationStaffReducerAction.INIT:
      const { first_name, last_name, pin, active } = action.value;
      const obj = {
        first_name,
        last_name,
        pin,
        active,
      };
      return { ...(obj as LocationStaff) };
    default:
      throw new Error();
  }
};
