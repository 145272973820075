import React, { useState } from 'react';
import { Box, Typography, Button, IconButton, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
    backgroundColor: '#FAF9F6',
  },
  imageContainer: {
    position: 'relative',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    color: '#fff',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  contentPadding: {
    padding: theme.spacing(3),
  },
  optionsContainer: {
    marginBottom: theme.spacing(2),
  },
  optionRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  quantityControls: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    border: '1px solid #ddd',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  addButton: {
    padding: theme.spacing(1.5),
    fontWeight: 'bold',
    backgroundColor: '#000',
    color: '#fff',
    textTransform: 'none',
    flex: 1,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));

interface Modifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

interface ModifierAssignment {
  id: string;
  modifier: Modifier;
}

interface Allergy {
  id: string;
  name: string;
  description: string;
  allergy_type: string;
}

interface AllergyAssignment {
  id: string;
  allergy: Allergy;
}

interface MenuItem {
  id: string;
  name: string;
  description: string;
  price: number; // Price in cents
  imageUrl?: string;
  soldOut: boolean;
  item_per_day_left: number;
  modifier_assignments: ModifierAssignment[];
  allergy_assignments: AllergyAssignment[];
}

interface Cart {
  [key: string]: number;
}

type Action =
  | { type: 'ADD_TO_CART'; payload: { cartKey: string; quantity: number } }
  | {
      type: 'REMOVE_FROM_CART';
      payload: { cartKey: string };
    };

interface ModalComponentProps {
  open: boolean;
  onClose: () => void;
  item: MenuItem;
  cart: Cart;
  dispatch: React.Dispatch<Action>;
}

const ModalComponent: React.FC<ModalComponentProps> = ({ open, onClose, item, cart, dispatch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [quantity, setQuantity] = useState<number>(1);

  // Initialize selectedModifiers state
  const [selectedModifiers, setSelectedModifiers] = useState<{
    [modifierName: string]: boolean;
  }>(
    item.modifier_assignments.reduce(
      (acc, mod) => {
        acc[mod.modifier.name] = false;
        return acc;
      },
      {} as { [modifierName: string]: boolean }
    )
  );

  const [showAllergens, setShowAllergens] = useState<boolean>(false);

  const increment = () => setQuantity((prev) => prev + 1);
  const decrement = () => setQuantity((prev) => (prev > 1 ? prev - 1 : 1));

  const calculateTotalPrice = (): number => {
    const basePrice = item.price; // in cents
    const modifiersPrice = item.modifier_assignments.reduce((sum, mod) => {
      if (selectedModifiers[mod.modifier.name]) {
        return sum + mod.modifier.price;
      }
      return sum;
    }, 0);

    const totalPrice = (basePrice + modifiersPrice) * quantity; // in cents

    return totalPrice;
  };

  const handleAddToCart = () => {
    // Generate the modifiers key
    const modifiersKey = Object.keys(selectedModifiers)
      .filter((modName) => selectedModifiers[modName])
      .sort()
      .join(',');

    const cartKey = `${item.id}:${modifiersKey}`;

    // Check stock availability
    if (checkStockAvailability(item.id, quantity)) {
      dispatch({ type: 'ADD_TO_CART', payload: { cartKey, quantity } });
      onClose();
    } else {
      alert("You can't add more of this item. It exceeds the available stock.");
    }
  };

  const checkStockAvailability = (productId: string, quantity: number): boolean => {
    const itemInCartQuantity = Object.keys(cart).reduce((total, cartKey) => {
      const [cartProductId] = cartKey.split(':');
      if (cartProductId === productId) {
        return total + cart[cartKey];
      }
      return total;
    }, 0);

    const totalQuantity = itemInCartQuantity + quantity;

    return totalQuantity <= item.item_per_day_left;
  };

  const { name, description, imageUrl } = item;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" fullScreen={isMobile} aria-labelledby="item-details">
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.imageContainer}>
          <img src={imageUrl} alt={name} className={classes.image} />
          <IconButton onClick={onClose} className={classes.closeButton}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Box className={classes.contentPadding}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {name}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {description}
          </Typography>
          <Typography variant="body2" style={{ color: '#6BBE44', cursor: 'pointer' }} onClick={() => setShowAllergens(!showAllergens)}>
            Allergens
          </Typography>
          {showAllergens && item.allergy_assignments.length > 0 && (
            <Box>
              {item.allergy_assignments.map((allergyAssignment) => (
                <Typography variant="body2" key={allergyAssignment.id}>
                  {allergyAssignment.allergy.name}
                </Typography>
              ))}
            </Box>
          )}

          {/* Modifiers */}
          {item.modifier_assignments.length > 0 && (
            <Box className={classes.optionsContainer}>
              <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: theme.spacing(1) }}>
                Options
              </Typography>
              {item.modifier_assignments.map((modAssignment) => {
                const mod = modAssignment.modifier;
                return (
                  <Box className={classes.optionRow} key={mod.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModifiers[mod.name]}
                          onChange={() =>
                            setSelectedModifiers((prev) => ({
                              ...prev,
                              [mod.name]: !prev[mod.name],
                            }))
                          }
                        />
                      }
                      label={`${mod.name} (+£${(mod.price / 100).toFixed(2)})`}
                    />
                  </Box>
                );
              })}
            </Box>
          )}

          {/* Quantity Controls and Add Button Inline */}
          <Box className={classes.actionContainer}>
            <Box className={classes.quantityControls}>
              <IconButton onClick={decrement}>
                <RemoveIcon />
              </IconButton>
              <Typography variant="body1">{quantity}</Typography>
              <IconButton onClick={increment}>
                <AddIcon />
              </IconButton>
            </Box>
            <Button className={classes.addButton} onClick={handleAddToCart}>
              ADD FOR £{(calculateTotalPrice() / 100).toFixed(2)}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalComponent;
