import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';

import { Divider, TextField, withStyles } from '@material-ui/core';

import { AdminUser, ValidationErrors, validationErrors, AdminUserReducerAction } from '../..';

import styles from './styles';
import { validateEmailAddress } from '../../../../../../../utils/validators';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  user: AdminUser;
  isEdit: boolean;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: AdminUserReducerAction; value: any }>;
}

const StepDetails = ({ classes, user, isEdit, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.first_name = !user.first_name ? validationErrors.first_name : false;
      }
      if (field === 'last_name') {
        errorItems.last_name = !user.last_name ? validationErrors.last_name : false;
      }
      if (field === 'email') {
        errorItems.email_invalid = !validateEmailAddress(user.email) ? validationErrors.email_invalid : false;
        errorItems.email = !user.email ? validationErrors.email : false;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [user, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="first-name-textfield"
          required
          label="First name"
          variant="outlined"
          error={errors.first_name as boolean}
          helperText={errors.first_name}
          value={user.first_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: AdminUserReducerAction.FIRST_NAME, value: event.target.value })}
          onKeyUp={() => validate('first_name')}
          onBlur={() => validate('first_name')}
        />
        <Divider className={classes.divider} />
        <TextField
          className={classes.textField}
          data-qa="last-name-textfield"
          required
          label="Last name"
          variant="outlined"
          error={errors.last_name as boolean}
          helperText={errors.last_name}
          value={user.last_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: AdminUserReducerAction.LAST_NAME, value: event.target.value })}
          onKeyUp={() => validate('last_name')}
          onBlur={() => validate('last_name')}
        />
        <TextField
          className={classes.textField}
          data-qa="email-textfield"
          required
          label="Email"
          variant="outlined"
          disabled={isEdit}
          value={user.email}
          error={(errors.email as boolean) || (errors.email_invalid as boolean)}
          helperText={errors.email || errors.email_invalid}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: AdminUserReducerAction.EMAIL, value: event.target.value })}
          onKeyUp={() => validate('email')}
          onBlur={() => validate('email')}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
