import React from 'react';
import { EmptyObject } from '../../react-app-env';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

interface Props extends React.PropsWithChildren<EmptyObject> {
  title: string;
  message: string;
  actions: CutsomDialogAction[];
  handleClose: () => void;
}

interface CutsomDialogAction {
  label: string;
  onClick: () => void;
}

const CutsomDialog = ({ title, message, actions, handleClose }: Props): React.ReactElement => {
  return (
    <>
      <Dialog open onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions.map((action) => (
            <Button onClick={action.onClick} color="primary">
              {action.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CutsomDialog;
