import { types, query, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../react-app-env';

const _categoryStepsInitQuery = () => ({
  colours: params(
    {
      order_by: { name: 'asc' },
    },
    [
      {
        id: types.string,
        hex: types.string,
        name: types.string,
      },
    ]
  ),
});

type X = Unpacked<typeof _categoryStepsInitQuery>;
export type Colour = X['colours'][0];

export const categoryStepsInitQuery = () => gql`
  ${query(_categoryStepsInitQuery())}
`;
