import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import { createLocationInitQuery, Organisation } from './queries';
import CreateLocation from '../../../../../components/Global/Location/Create';

import styles from './styles';
import { Breadcrum } from '../../../../../layouts/Dashboard/components/Topbar';

interface UrlParams {
  organisation_id: string;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const AdminCreateLocation = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    tax_rate: 0,
  });

  const { data: createLocationInitData } = useQuery(createLocationInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createLocationInitData) {
      setOrganisation(createLocationInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [createLocationInitData]);

  const handleFinish = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${id}`);
  };

  const [breadcrumbsUpdated, setBreadcrumbsUpdated] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrum[]>([
    { label: 'Organisations', link: '/admin/organisations' },
    { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
    { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
  ]);
  useEffect(() => {
    if (organisation.name && organisation_id) {
      setBreadcrumbs([
        { label: 'Organisations', link: '/admin/organisations' },
        { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
      ]);
      setBreadcrumbsUpdated(true);
    }
  }, [organisation, organisation_id]);

  return (
    <>
      {organisation.tax_rate && (
        <CreateLocation
          breadcrumbs={breadcrumbs}
          setBreadcrumbs={setBreadcrumbs}
          breadcrumbsUpdated={breadcrumbsUpdated}
          organisation_id={organisation_id}
          tax_rate={organisation.tax_rate}
          handleFinish={handleFinish}
        />
      )}
    </>
  );
};

export default withRouter(withStyles(styles)(AdminCreateLocation));
