import React, { useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Typography, withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { selfOnboardOrganisationReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { insertSelfOnboardingRequestMutation } from './mutations';

import styles from './styles';
import { useMutation } from '@apollo/client';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateOrganisation = ({ classes, history }: Props): React.ReactElement => {
  const [organisation, dispatchOrganisation] = useReducer(selfOnboardOrganisationReducer, {
    name: '',
    email: '',
    phone: '',
    organisationAdmin: {
      firstName: '',
      lastName: '',
      email: '',
    },
    location: {
      name: '',
      email: '',
      phone: '',
      addressLine1: '',
      city: '',
      country: '',
      postcode: '',
    },
  });

  const [successDialogVisible, setSuccessDialogVisible] = useState<boolean>(false);

  const [insertSelfOnboardingOrganisation, { loading: saving }] = useMutation(insertSelfOnboardingRequestMutation, { onCompleted: () => setSuccessDialogVisible(true) });

  const handleSave = () =>
    insertSelfOnboardingOrganisation({
      variables: {
        object: {
          request: organisation,
        },
      },
    });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={5} style={{ margin: '20px', padding: '30px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Self onboarding</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Steps completeLabel="Create" organisation={organisation} dispatch={dispatchOrganisation} handleSave={handleSave} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {saving && <CustomBackdrop label="Creating Organisation" />}
      <Dialog open={successDialogVisible} onClose={() => setSuccessDialogVisible(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Self onboarding request submitted'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{"An email with further instructions will be sent to the email address you specified for the organisation's administrator."}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.push('/sign-in')} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withRouter(withStyles(styles)(CreateOrganisation));
