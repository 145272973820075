import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Button, Fade, Typography, withStyles } from '@material-ui/core';

import { ErrorOutlineOutlined as ErrorIcon } from '@material-ui/icons';

import Centered from '../../../layouts/Centered';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const NotFound = ({ classes, history }: Props): React.ReactElement => {
  const handleBack = () => {
    history.go(-1);
  };

  return (
    <Centered>
      <Fade in timeout={1700}>
        <div className={classes.root}>
          <div className={classes.container}>
            <ErrorIcon className={classes.icon} color="primary" />
            <div className={classes.text}>
              <Typography variant="h2" color="primary">
                404
              </Typography>
              <Typography variant="subtitle2" color="primary">
                page not found
              </Typography>
            </div>
          </div>
          <div className={classes.action}>
            <Button fullWidth variant="outlined" color="primary" onClick={handleBack}>
              go back
            </Button>
          </div>
        </div>
      </Fade>
    </Centered>
  );
};

export default withRouter(withStyles(styles)(NotFound));
