import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectOrganisationInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
      email: types.string,
      phone: types.string,
      users_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      enum_organisation_type: {
        description: types.string,
      },
      locations_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      menus_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    }
  ),
});

type X = Unpacked<typeof _selectOrganisationInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const selectOrganisationInitQuery = (id: string) => gql`
  ${query(_selectOrganisationInitQuery(id))}
`;
