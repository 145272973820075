import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Accordion, AccordionDetails, AccordionSummary, Switch, Checkbox, FormControlLabel, Paper, Typography, withStyles } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { capitaliseFirstOnly, removeDuplicateWords } from '../../../../../../utils/stringUtils';
import { Report, ValidationErrors, ReportReducerAction, ReportData } from '../..';
import { getTableWithRelationships, SchemaField, SchemaRelationship } from '../../schema/schema';
import { CustomReportTable } from '../../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  report: Report;
  reportData: ReportData;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: ReportReducerAction; value: any }>;
}

const StepFields = ({ classes, report, reportData, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  const [aggregate, setAggregate] = useState<{ [key: string]: boolean }>({});

  // const validate = useCallback((field: string) => {
  //   const errorItems: UnknownObject = {}
  //   if (field === 'name') {
  //     errorItems.name = !report.name ? validationErrors.name : false;
  //   }
  //   if (field === 'table') {
  //     errorItems.table = !report.table ? validationErrors.table : false;
  //   }
  //   setErrors({...errors, ...errorItems});
  // }, [report, errors, setErrors]);

  const handleExpand = (key: string) => {
    setExpanded((i) => ({
      ...i,
      [key]: i[key] ? !i[key] : true,
    }));
  };

  const handleAggregate = (key: string, checked: boolean) => {
    setAggregate((i) => ({
      ...i,
      [key]: i[key] ? !i[key] : true,
    }));
    const k = key.replace(`${report.table}.`, '');
    updateField(k.replace(/\./g, ' '), '', `${k}_aggregate.aggregate.count`, checked);
  };

  const updateField = (name: string, tableName: string, fieldName: string, isChecked: boolean) => {
    // let t = (fieldName || name || tableName);
    let t = `${fieldName ? `${fieldName}.` : ''}${name}`;
    if (t.endsWith('_')) {
      t = t.slice(0, t.length - 1);
    }
    const key = t.replace(`${report.table}.`, '');
    const label = capitaliseFirstOnly(
      removeDuplicateWords(
        t
          .replace(`${report.table}.`, '')
          .replace(/_/g, ' ')
          .replace(/\./g, ' ')
          .replace(/ aggregate/g, '')
      )
    );

    if (isChecked) {
      dispatch({ type: ReportReducerAction.ADD_FIELD, value: { key, label } });
    } else {
      dispatch({ type: ReportReducerAction.REMOVE_FIELD, value: { key, label } });
    }
  };

  const renderTable = (tableName: string, depth: number = 0, fieldName: string = '') => {
    const table = getTableWithRelationships(tableName);
    return (
      <>
        <div className={classes.fields}>{table.fields.map((i: SchemaField) => renderField(i, tableName, fieldName))}</div>
        {depth < 2 && <div className={classes.relationships}>{table.relationships.map((i: SchemaRelationship) => renderRelationship(i, tableName, depth + 1))}</div>}
      </>
    );
  };

  const renderField = (field: SchemaField, tableName: string, fieldName: string) => {
    const { name, type } = field;
    const eName = `${fieldName ? `${fieldName}.` : ''}${name}`;
    const label = name.replace(/_/g, ' ');
    const isChecked = report.fields.some((i) => i.key === eName);
    return (
      <>
        <div className={classes.field}>
          <FormControlLabel
            key={name}
            label={label}
            control={
              <Checkbox
                name={name}
                checked={isChecked}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateField(name, tableName, fieldName, event.target.checked)}
              />
            }
          />
          <Typography className={classes.fieldType} variant="subtitle2">
            {type}
          </Typography>
        </div>
      </>
    );
  };

  const renderRelationship = (relationship: SchemaRelationship, tableName: string, depth: number) => {
    const { name, table, hasAggregate } = relationship;
    const eName = `${tableName}.${name}`;
    const isChecked = aggregate[eName] || false;
    const isExpanded = (expanded[eName] && !isChecked) || false;
    const label = name.replace(/_/g, ' ');
    return (
      <>
        <Accordion key={name} expanded={isExpanded} onChange={() => handleExpand(eName)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">{label}</Typography>
            {hasAggregate && (
              <FormControlLabel
                control={
                  <Switch
                    className={classes.aggregateButon}
                    checked={isChecked}
                    color="primary"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAggregate(eName, event.target.checked)}
                  />
                }
                label="aggregate"
              />
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.fields}>{isExpanded && renderTable(table, depth, name)}</AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <>
      <CustomReportTable data={reportData} noQuery />
      <Paper variant="outlined" className={classes.root}>
        {renderTable(report.table)}
      </Paper>
    </>
  );
};

export default withStyles(styles)(StepFields);
