import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid, withStyles } from '@material-ui/core';

import UsersList from '../../Users/List';
import { selectLocationUsersInitQuery, LocationUser, EnumUserType } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  location_id: string;
  handleCreate: () => void;
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
}

const ListLocationUsers = ({ classes, history, location_id, handleCreate, handleView, handleEdit }: Props): React.ReactElement => {
  const [users, setUsers] = useState<LocationUser[]>([]);
  const [userTypes, setUserTypes] = useState<EnumUserType[]>([]);

  const { data: locationUsersInitQuery } = useQuery(selectLocationUsersInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUsersInitQuery) {
      setUsers(locationUsersInitQuery.locations_by_pk.users);
      setUserTypes(locationUsersInitQuery.enum_user_types);
    }
    return () => {
      mounted = false;
    };
  }, [locationUsersInitQuery]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <UsersList
          users={users.map((i) => ({ id: i.id, full_name: i.full_name, email: i.email, user_type: i.enum_user_type, pin: i.pin }))}
          userTypes={userTypes}
          gridSize={3}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          handleView={handleView}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ListLocationUsers));
