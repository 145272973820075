import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Button, Grid, withStyles } from '@material-ui/core';

import { CategoryItem, CategoryItemReducerAction } from '../..';

import styles from './styles';
import FileInput from '../../../../../FileUpload';
import ImageCropper from '../../../../../ImageCropper';

const { REACT_APP_AWS_IMAGES_URL } = process.env;

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  categoryItem: CategoryItem;
  categoryItemId?: string;
  dispatch: React.Dispatch<{ type: CategoryItemReducerAction; value: any }>;
}

const StepImage = ({ classes, categoryItem, categoryItemId, dispatch }: Props): React.ReactElement => {
  const handleClear = () => {
    dispatch({ type: CategoryItemReducerAction.CLEAR_IMAGE, value: undefined });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button onClick={handleClear} variant="outlined" fullWidth>
          Remove Image
        </Button>
      </Grid>
      {categoryItem.imageData || categoryItem.image_added ? (
        <Grid item xs={12}>
          <ImageCropper
            onCropComplete={(croppedImage) =>
              dispatch({
                type: CategoryItemReducerAction.SET_CROPPED_IMAGE,
                value: croppedImage,
              })
            }
            image={categoryItem.imageData}
            imageUrl={`${REACT_APP_AWS_IMAGES_URL}/${categoryItemId!}.jpeg`}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <FileInput
            onImageSelected={(img) =>
              dispatch({
                type: CategoryItemReducerAction.SET_IMAGE,
                value: img,
              })
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(StepImage);
