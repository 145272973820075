import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomStepper from '../../../../../../../components/CustomStepper';
import { OrganisationUser, validationErrors, ValidationErrors, OrganisationUserReducerAction } from '../../../Common';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  user: OrganisationUser;
  isEdit?: boolean;
  dispatch: React.Dispatch<{ type: OrganisationUserReducerAction; value: any }>;
  handleSave: () => void;
}

const OrganisationUserSteps = ({ classes, completeLabel, user, isEdit = false, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    first_name: false,
    last_name: false,
    email: false,
    email_invalid: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.first_name = !user.first_name ? validationErrors.first_name : false;
        errorItems.last_name = !user.last_name ? validationErrors.last_name : false;
        errorItems.email = !user.email ? validationErrors.email : false;
        errorItems.email_invalid = !user.email ? validationErrors.email : false;
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [user, errors]
  );

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            component: <StepDetails user={user} isEdit={isEdit} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview user={user} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationUserSteps);
