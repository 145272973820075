import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import { Location, selectLocationSettingsInitQuery } from './queries';

import styles from './styles';
import ViewStockComponent from '../../../../../components/Global/Stock';
import { Dashboard } from '../../../../../layouts';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  location_id: string;
}

enum InventoryRestockEnum {
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

const ViewLocationStock = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
    inventory_restock_type: '',
  });

  const { data: locationSettingsQuery } = useQuery(selectLocationSettingsInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationSettingsQuery) {
      setLocation(locationSettingsQuery.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationSettingsQuery]);

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: location.organisation?.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Stock', link: `/admin/organisations/${organisation_id}/locations/${location_id}/stock` },
      ]}>
      <ViewStockComponent classes={{}} location_id={location_id} inventory_restock_type={location.inventory_restock_type as InventoryRestockEnum} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewLocationStock));
