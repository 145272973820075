import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import EditMenu from '../../../../../components/Global/Menus/Edit';
import Dashboard from '../../../../../layouts/Dashboard';

import { editMenuInitQuery, Organisation } from './queries';

interface Props extends RouteComponentProps {}

interface UrlParams {
  organisation_id: string;
  menu_id: string;
}

const EditMenuAdmin = ({ history }: Props): React.ReactElement => {
  const { organisation_id, menu_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
  });

  const { data: editMenuInitData } = useQuery(editMenuInitQuery(menu_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editMenuInitData) {
      setOrganisation(editMenuInitData.organisation_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editMenuInitData]);

  const handleFinish = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/menus/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: '/admin/organisations' },
          { label: 'Menus', link: `/admin/organisations/${organisation_id}/menus` },
          { label: 'Edit' },
        ]}>
        <EditMenu menu_id={menu_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(EditMenuAdmin);
