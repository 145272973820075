import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomStepper from '../../../../../CustomStepper';
import { LocationPrinter, validationErrors, ValidationErrors, LocationPrinterReducerAction, validation, Menu } from '../..';
import StepDetails from '../StepDetails';
import StepCategories from '../StepCategories';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  printer: LocationPrinter;
  locations: string[];
  menus: Menu[];
  dispatch: React.Dispatch<{ type: LocationPrinterReducerAction; value: any }>;
  handleSave: () => void;
}

const LocationPrinterSteps = ({ classes, completeLabel, printer, locations, menus, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    location: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = validation.name(printer.name);
        errorItems.location = validation.location(printer.location);
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [printer, errors]
  );

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            component: <StepDetails printer={printer} locations={locations} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Menu Categories',
            gridSize: 8,
            detail: printer.categories.length > 0 ? `${printer.categories.length} Selected` : '',
            component: <StepCategories printer={printer} menus={menus} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview printer={printer} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(LocationPrinterSteps);
