import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ViewMenu from '../../../../components/Global/Menus/View';

import { selectMenuInitQuery, Menu, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  menu_id: string;
}

const ViewMenuOrg = ({ classes, history }: Props): React.ReactElement => {
  const { menu_id } = useParams<UrlParams>();

  const [menu, setMenu] = useState<Menu>({
    name: '',
  });
  const [location, setLocation] = useState<Location>({
    can_manage_menus: false,
  });

  const { data: menuInitData } = useQuery(selectMenuInitQuery(menu_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && menuInitData) {
      setMenu(menuInitData.menus_by_pk);
      setLocation(menuInitData.locations[0]);
    }
    return () => {
      mounted = false;
    };
  }, [menuInitData]);

  const handleEdit = () => {
    history.push(`/loc/menus/${menu_id}/edit`);
  };

  const handleViewCategory = (id: string, categoryType: string) => {
    history.push(`/loc/menus/${menu_id}/${categoryType}/categories/${id}`);
  };

  const handleCreateCategory = (categoryType: string) => {
    history.push(`/loc/menus/${menu_id}/${categoryType}/categories/create`);
  };

  const handleDelete = () => {
    history.push(`/loc/menus`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Menus', link: `/loc/menus` }, { label: menu.name }]}>
      <ViewMenu
        menu_id={menu_id}
        handleEdit={location.can_manage_menus ? handleEdit : undefined}
        handleViewCategory={handleViewCategory}
        handleCreateCategory={location.can_manage_menus ? handleCreateCategory : undefined}
        handleDelete={location.can_manage_menus ? handleDelete : undefined}
      />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewMenuOrg));
