import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateCategoryScoreMutation = gql`
  mutation ($id: uuid!, $score: Int) {
    update_categories_by_pk(pk_columns: { id: $id }, _set: { score: $score }) {
      id
    }
  }
`;

export const updateCategoryScore = async (id: string, score: number) => {
  return await gqlClient.mutate<{
    update_categories_by_pk: {
      id: string;
    };
  }>({ mutation: updateCategoryScoreMutation!, variables: { id, score } });
};

const updateCategoryDrinksMutation = gql`
  mutation ($where: drinks_bool_exp!, $set: drinks_set_input!) {
    update_drinks(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

export const updateCategoryDrinks = async (variables: { where: object; set: object }) => {
  return await gqlClient.mutate<{
    update_drinks: {
      affected_rows: number;
    };
  }>({
    mutation: updateCategoryDrinksMutation,
    variables,
  });
};

const updateCategoryDishesMutation = gql`
  mutation ($where: dishes_bool_exp!, $set: dishes_set_input!) {
    update_dishes(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

export const updateCategoryDishes = async (variables: { where: object; set: object }) => {
  return await gqlClient.mutate<{
    update_dishes: {
      affected_rows: number;
    };
  }>({
    mutation: updateCategoryDishesMutation,
    variables,
  });
};
