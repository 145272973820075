import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { webhookClient } from '../../../../utils/webhookClient';

import { Chip, Grid, withStyles } from '@material-ui/core';

import { FileCopy as DuplicateIcon } from '@material-ui/icons';

import ReadOnlyBlock, { ReadOnlyBlockAction } from '../../../../components/ReadOnlyBlock';
import ListCategories from '../../../../components/Global/Categories/List';
import { booleanToYesNo, capitaliseFirst, timeToFormattedString } from '../../../../utils/stringUtils';
import CustomViewToggle, { ViewType } from '../../../CustomViewToggle';
import CustomDialog from '../../../CustomDialog';

import { Category, Menu, selectMenuInitQuery } from './queries';
import { deleteMenu, updateCategoryOrderIndexMutation } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  menu_id: string;
  organisation_id?: string;
  handleEdit?: (id?: string) => void;
  handleViewCategory: (id: string, categoryType: string) => void;
  handleCreateCategory?: (categoryType: string) => void;
  handleDelete?: () => void;
}

const ViewMenu = ({ classes, history, menu_id, organisation_id, handleEdit, handleViewCategory, handleCreateCategory, handleDelete }: Props): React.ReactElement => {
  const [dishCategrories, setDishCategrories] = useState<Category[]>([]);
  const [drinkCategrories, setDrinkCategrories] = useState<Category[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [menu, setMenu] = useState<Menu>({
    id: '',
    name: '',
    description: '',
    active: false,
    available_from: '',
    available_to: '',
    menu_types: [],
    categories: [],
    is_primary: false,
  });
  const [view, setView] = useState<ViewType>(ViewType.TABLE);

  const { data: menuInitData, refetch } = useQuery(selectMenuInitQuery(menu_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && menuInitData) {
      setMenu(menuInitData.menus_by_pk);
      setDishCategrories(menuInitData.menus_by_pk.categories.filter((i: Category) => i.category_type === 'DISH').sort((a: Category, b: Category) => a.order_index - b.order_index));
      setDrinkCategrories(
        menuInitData.menus_by_pk.categories.filter((i: Category) => i.category_type === 'DRINK').sort((a: Category, b: Category) => a.order_index - b.order_index)
      );
    }
    return () => {
      mounted = false;
    };
  }, [menuInitData]);
  const handleCategoryScoreUpdate = () => {
    refetch();
  };

  const duplicateMenu = async () => {
    if (handleEdit !== undefined) {
      const newMenuId = await webhookClient.get(`menu/duplicate/${menu_id}`, false);
      if (newMenuId) {
        handleEdit(newMenuId);
      }
    }
  };

  const deleteMenuDialog = () => {
    if (handleDelete !== undefined) {
      setShowDeleteDialog(true);
    }
  };

  const actions: ReadOnlyBlockAction[] = [];

  if (handleEdit !== undefined) {
    actions.push({
      label: 'Duplicate',
      icon: <DuplicateIcon />,
      onClick: duplicateMenu,
    });
  }

  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleDeleteMenu = async () => {
    await deleteMenu({
      id: menu_id,
    });
    if (handleDelete !== undefined) {
      handleDelete();
    }
  };

  const handleSaveOrdering = async (items: Category[]) => {
    const objects = items.map((i) => ({
      id: i.id,
      name: i.name,
      colour_id: i.colour_id,
      active: i.active,
      order_index: i.order_index,
      menu_id: i.menu_id,
      score: i.score,
      organisation_id,
    }));

    const updateVariables = {
      objects,
    };

    await updateCategoryOrderIndexMutation(updateVariables);
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ReadOnlyBlock
            title={menu.name}
            gridSize={3}
            items={[
              { label: 'Description', value: menu.description },
              { label: 'Active', value: booleanToYesNo(menu.active) },
              { label: 'Available from', value: timeToFormattedString(menu.available_from, 'HM', true) },
              { label: 'Available to', value: timeToFormattedString(menu.available_to, 'HM', true) },
              {
                label: 'Menu types',
                components: menu.menu_types?.map((m: string) => (
                  <Chip key={m} className={classes.chip} size="small" variant="outlined" label={capitaliseFirst(m.toLowerCase().replace(/_/g, ' '))} />
                )),
              },
              {
                label: 'Is primary',
                value: booleanToYesNo(menu.is_primary),
              },
            ]}
            actions={actions}
            handleEdit={handleEdit ? () => handleEdit() : undefined}
            handleDelete={handleDelete ? () => deleteMenuDialog() : undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomViewToggle value={view} sortable handleChangeView={setView} />
        </Grid>
        <Grid item xs={12}>
          <ListCategories
            items={dishCategrories}
            counterPath="dish_assignments_aggregate.aggregate.count"
            category_type="dish"
            viewType={view}
            handleSaveOrdering={handleSaveOrdering}
            handleSetItems={setDishCategrories}
            handleViewCategory={handleViewCategory}
            handleCreateCategory={handleCreateCategory}
            handleCategoryScoreUpdate={handleCategoryScoreUpdate}
          />
        </Grid>
        <Grid item xs={12}>
          <ListCategories
            items={drinkCategrories}
            counterPath="drink_assignments_aggregate.aggregate.count"
            category_type="drink"
            viewType={view}
            handleSaveOrdering={handleSaveOrdering}
            handleSetItems={setDrinkCategrories}
            handleViewCategory={handleViewCategory}
            handleCreateCategory={handleCreateCategory}
            handleCategoryScoreUpdate={handleCategoryScoreUpdate}
          />
        </Grid>
      </Grid>
      {showDeleteDialog ? (
        <CustomDialog
          title="Delete menu"
          message="Are you sure you want to delete this menu? All categories and items will also be deleted. This action can not be undone."
          handleClose={handleCloseDeleteDialog}
          actions={[
            { label: 'No', onClick: handleCloseDeleteDialog },
            { label: 'Yes', onClick: handleDeleteMenu },
          ]}
        />
      ) : null}
    </>
  );
};

export default withRouter(withStyles(styles)(ViewMenu));
