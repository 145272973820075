import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      overflow: 'hidden',
      margin: theme.spacing(2),
      marginLeft: 0,
      backgroundColor: theme.palette.background.paper,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(2),
      overflow: 'auto',
    },
    contentContainer: {},
  });

export default styles;
