import React, { useState, useEffect } from 'react';
import { Unpacked } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import { GridCard } from '../../../Grid';

import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import { exactDateString } from '../../../../utils/dateUtils';

const _query = (startDate: string, endDate: string) => ({
  order_served_payments: params(
    {
      where: {
        canceled: { _eq: false },
        order: { paid: { _eq: true } },
        _and: [{ created_at: { _gte: rawString(startDate) } }, { created_at: { _lte: rawString(endDate) } }],
      },
    },
    [
      {
        payment_method: types.string,
      },
    ]
  ),
  order_takeaway_payments: params(
    {
      where: {
        canceled: { _eq: false },
        order: { paid: { _eq: true } },
        _and: [{ created_at: { _gte: rawString(startDate) } }, { created_at: { _lte: rawString(endDate) } }],
      },
    },
    [
      {
        payment_method: types.string,
      },
    ]
  ),
});

type OrdersServed = Unpacked<typeof _query>['order_served_payments'][0];
type OrdersTakeaway = Unpacked<typeof _query>['order_takeaway_payments'][0];

interface Payment {
  paymentMethod: string;
  name: string;
  total: number;
}

interface Props {
  startDate: Date;
  endDate: Date;
}

export const initQuery = (startDate: string, endDate: string) => gql`
  ${query(_query(startDate, endDate))}
`;

const getPaymentMethod = (paymentMethod: string): string => {
  switch (paymentMethod) {
    case 'CASH':
    case 'REFUND':
      return paymentMethod;
    case 'VOUCHER_AT_DOOR':
      return 'VOUCHER';
    default:
      return 'CARD';
  }
};

const MetricPayments = ({ startDate, endDate }: Props): React.ReactElement => {
  const [value, setValue] = useState<Payment[]>([]);

  const { data, loading } = useQuery(initQuery(exactDateString(startDate), exactDateString(endDate)), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const items: Payment[] = [];
      const payments = [...data.order_served_payments, ...data.order_takeaway_payments].map((i) => ({ ...i, payment_method: getPaymentMethod(i.payment_method) }));
      payments.forEach((i: OrdersServed | OrdersTakeaway) => {
        let item = items.find((a) => a.paymentMethod === i.payment_method);
        if (!item) {
          let name = i.payment_method;
          switch (name) {
            case 'CASH':
              name = 'cash';
              break;
            case 'VOUCHER':
              name = 'gift voucher';
              break;
            case 'REFUND':
              name = 'refund';
              break;
            default:
              name = 'card';
              break;
          }
          item = { paymentMethod: i.payment_method, name, total: 0 };
          items.push(item);
        }
        item.total++;
      });
      setValue(items);
    }
    return () => {
      mounted = false;
    };
  }, [data]);

  return (
    <>
      {value.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h4">Payments</Typography>
        </Grid>
      )}
      {value.map((i: Payment) => (
        <GridCard fill gridSize={4} loading={loading} key={i.paymentMethod} title={`Total ${i.name} payments`} counterSmall counter={i.total} />
      ))}
    </>
  );
};

export default MetricPayments;
