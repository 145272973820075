import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

export const homeInitQuery = gql`
  ${query({
    locations_aggregate: {
      aggregate: {
        count: types.number,
      },
    },
    menus_aggregate: {
      aggregate: {
        count: types.number,
      },
    },
    users_organisation_aggregate: {
      aggregate: {
        count: types.number,
      },
    },
  })}
`;
