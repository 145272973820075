import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Card, Chip, IconButton, Typography, withStyles } from '@material-ui/core';

import { Category, LocationPrinter, LocationPrinterReducerAction, Menu } from '../..';

import styles from './styles';
import { CheckCircleOutline, RadioButtonUncheckedOutlined } from '@material-ui/icons';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  printer: LocationPrinter;
  menus: Menu[];
  dispatch: React.Dispatch<{ type: LocationPrinterReducerAction; value: any }>;
}

const StepCategories = ({ classes, printer, menus, dispatch }: Props): React.ReactElement => {
  const renderCategory = (categories: Category[], title: string) => {
    if (categories.length === 0) {
      return <></>;
    }
    return (
      <div className={classes.categoriesContainer}>
        <div className={classes.categoryTitle}>
          <Typography variant="h6">{title}</Typography>
          <IconButton size="medium" className={classes.iconButton} onClick={(_) => dispatch({ type: LocationPrinterReducerAction.CATEGORIES, value: { categories: categories } })}>
            {categories.every((i) => printer.categories.map((p) => p.id).includes(i.id)) ? <CheckCircleOutline /> : <RadioButtonUncheckedOutlined />}
          </IconButton>
        </div>
        <div className={classes.categories}>
          {categories.map((c) => (
            <Chip
              className={classes.category}
              label={c.name}
              variant={printer.categories.some((p) => p.id === c.id) ? 'default' : 'outlined'}
              color={printer.categories.some((p) => p.id === c.id) ? 'primary' : undefined}
              onClick={(_) => dispatch({ type: LocationPrinterReducerAction.CATEGORY, value: { category: c } })}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.root}>
        {menus.map((menu) => (
          <Card variant="outlined" className={classes.menu}>
            <div className={classes.menuTitle}>
              <Typography variant="h5">{menu.name}</Typography>
              <IconButton
                size="medium"
                className={classes.iconButton}
                onClick={(_) => dispatch({ type: LocationPrinterReducerAction.CATEGORIES, value: { categories: menu.categories } })}>
                {menu.categories.every((i) => printer.categories.map((p) => p.id).includes(i.id)) ? <CheckCircleOutline /> : <RadioButtonUncheckedOutlined />}
              </IconButton>
            </div>
            {renderCategory(
              menu.categories.filter((i) => i.category_type === 'DISH'),
              'Dishes'
            )}
            {renderCategory(
              menu.categories.filter((i) => i.category_type === 'DRINK'),
              'Drinks'
            )}
          </Card>
        ))}
      </div>
    </>
  );
};

export default withStyles(styles)(StepCategories);
