import React from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  IconButton,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { OrderDetails, Action } from '../State';
import PayPalCheckout from '../../../Common/components/Checkout';

const useStyles = makeStyles((theme) => ({
  checkoutContainer: {
    padding: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  orderSummary: {
    marginBottom: theme.spacing(2),
  },
  totalPrice: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
}));

interface Modifier {
  id: string;
  name: string;
  price: number;
}

interface MenuItemData {
  id: string;
  name: string;
  price: number;
  modifier_assignments: { modifier: Modifier }[];
}

interface CartItem {
  cartKey: string;
  productId: string;
  modifiersKey: string;
  name: string;
  description: string;
  price: number;
  quantity: number;
}

interface CheckoutProps {
  orderDetails: OrderDetails;
  dispatch: React.Dispatch<Action>;
  totalPrice: number;
  onClose: () => void;
  cartItems: CartItem[];
  cart: { [key: string]: number };
  menuData: any; // Replace with actual type
  createOrder: (request: string) => Promise<any>;
  captureOrder: (orderId: string) => Promise<any>;
  createServedOrderAfterPaymentAsync: (order: any) => Promise<any>;
  createTakeAwayOrderAfterPaymentAsync: (order: any) => Promise<any>;
  createTakeAwayOrderPaymentAsync: (order: any) => Promise<any>;
  createServedOrderPaymentAsync: (order: any) => Promise<any>;
  location_id: string;
  organisation_id: string;
}

const Checkout: React.FC<CheckoutProps> = ({
  orderDetails,
  dispatch,
  totalPrice,
  onClose,
  cartItems,
  cart,
  menuData,
  createOrder,
  captureOrder,
  createServedOrderAfterPaymentAsync,
  createTakeAwayOrderAfterPaymentAsync,
  createTakeAwayOrderPaymentAsync,
  createServedOrderPaymentAsync,
  location_id,
  organisation_id,
}) => {
  const classes = useStyles();

  const handlePlaceOrder = () => {
    // Implement order placement logic
    alert('Order placed successfully!');
    onClose(); // Close the modal after placing the order
  };

  const handleOrderTypeChange = (event: React.MouseEvent<HTMLElement>, newOrderType: 'dinein' | 'delivery' | 'pickup' | null) => {
    if (newOrderType !== null) {
      dispatch({ type: 'UPDATE_ORDER_DETAILS', payload: { orderType: newOrderType } });
    }
  };

  return (
    <div>
      <DialogTitle disableTypography>
        <Typography variant="h6">Checkout</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box className={classes.checkoutContainer}>
        {/* Order Summary */}
        <Typography variant="h6" gutterBottom>
          Order Summary
        </Typography>
        <List className={classes.orderSummary}>
          {cartItems.map((item) => (
            <ListItem key={item.cartKey}>
              <ListItemText primary={`${item.quantity} x ${item.name}`} secondary={item.description} />
              <ListItemSecondaryAction>
                <Typography variant="body2">£{(item.price * item.quantity).toFixed(2)}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Typography variant="body1" className={classes.totalPrice}>
          Total: £{totalPrice.toFixed(2)}
        </Typography>

        {/* Order Type Selection */}
        <Box mt={2}>
          <Typography variant="h6">Select Order Type</Typography>
          <ToggleButtonGroup value={orderDetails.orderType} exclusive onChange={handleOrderTypeChange} aria-label="order type" style={{ marginTop: '8px' }}>
            <ToggleButton value="dinein" aria-label="dine-in">
              Dine-in
            </ToggleButton>
            <ToggleButton value="delivery" aria-label="delivery">
              Delivery
            </ToggleButton>
            <ToggleButton value="pickup" aria-label="pickup">
              Pickup
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Conditional Inputs Based on Order Type */}
        {orderDetails.orderType === 'dinein' && (
          <TextField
            label="Table Number"
            variant="outlined"
            fullWidth
            className={classes.formControl}
            value={orderDetails.tableNumber || ''}
            onChange={(e) =>
              dispatch({
                type: 'UPDATE_ORDER_DETAILS',
                payload: { tableNumber: e.target.value },
              })
            }
          />
        )}

        {orderDetails.orderType === 'pickup' && (
          <Box mt={2}>
            <Typography variant="h6">Pickup Options</Typography>
            <ToggleButtonGroup
              value={orderDetails.timeOption}
              exclusive
              onChange={(e: any, newValue: any) =>
                dispatch({
                  type: 'UPDATE_ORDER_DETAILS',
                  payload: { timeOption: newValue },
                })
              }
              aria-label="time option"
              style={{ marginTop: '8px' }}>
              <ToggleButton value="standard" aria-label="standard">
                Standard
              </ToggleButton>
              <ToggleButton value="schedule" aria-label="schedule">
                Schedule
              </ToggleButton>
            </ToggleButtonGroup>

            {orderDetails.timeOption === 'schedule' && (
              <Box mt={2}>
                {/* Day Selection */}
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <InputLabel id="scheduled-day-label">Select Day</InputLabel>
                  <Select
                    labelId="scheduled-day-label"
                    label="Select Day"
                    value={orderDetails.scheduledDay || ''}
                    onChange={(e) =>
                      dispatch({
                        type: 'UPDATE_ORDER_DETAILS',
                        payload: { scheduledDay: e.target.value as string },
                      })
                    }>
                    {/* Generate days for the next 5 days */}
                    {[...Array(5)].map((_, index) => {
                      const date = new Date();
                      date.setDate(date.getDate() + index);
                      const dateString = date.toISOString().split('T')[0];
                      return (
                        <MenuItem value={dateString} key={dateString}>
                          {date.toDateString()}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* Time Selection */}
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="scheduled-time-label">Select Time</InputLabel>
                  <Select
                    labelId="scheduled-time-label"
                    label="Select Time"
                    value={orderDetails.scheduledTime || ''}
                    onChange={(e) =>
                      dispatch({
                        type: 'UPDATE_ORDER_DETAILS',
                        payload: { scheduledTime: e.target.value as string },
                      })
                    }>
                    {/* Generate time slots */}
                    {generateTimeSlots('09:00', '17:00', 30).map((time) => (
                      <MenuItem value={time} key={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
        )}

        {/* Place Order Button */}
        <PayPalCheckout
          cart={cart}
          menuData={menuData}
          createOrder={createOrder}
          captureOrder={captureOrder}
          createServedOrderAfterPaymentAsync={createServedOrderAfterPaymentAsync}
          createTakeAwayOrderAfterPaymentAsync={createTakeAwayOrderAfterPaymentAsync}
          createTakeAwayOrderPaymentAsync={createTakeAwayOrderPaymentAsync}
          createServedOrderPaymentAsync={createServedOrderPaymentAsync}
          location_id={location_id}
          organisation_id={organisation_id}
          orderType={orderDetails!.orderType!}
          timeOption={orderDetails.timeOption}
          // tableNumber={orderDetails.tableNumber}
          tableNumber={null}
          scheduledDay={orderDetails.scheduledDay}
          scheduledTime={orderDetails.scheduledTime}
        />
      </Box>
    </div>
  );
};

// Reuse the generateTimeSlots function
const generateTimeSlots = (startTime: string, endTime: string, interval: number) => {
  const slots: string[] = [];
  const start = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);

  let current = new Date(start);
  while (current <= end) {
    slots.push(current.toTimeString().slice(0, 5));
    current.setMinutes(current.getMinutes() + interval);
  }
  return slots;
};

export default Checkout;
