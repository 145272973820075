import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationOrdersInitQuery = (location_id: string) => ({
  locations_by_pk: params(
    { id: rawString(location_id) },
    {
      name: types.string,
    }
  ),
});

type X = Unpacked<typeof _selectLocationOrdersInitQuery>;
export type Location = X['locations_by_pk'];

export const selectLocationOrdersInitQuery = (location_id: string) => gql`
  ${query(_selectLocationOrdersInitQuery(location_id))}
`;
