import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import Dashboard from '../../../../layouts/Dashboard';
import EditVoucher from '../../../../components/Global/Vouchers/Edit';

import { editVoucherCodeInitQuery, VoucherCode, CodeVouchers } from './queries';

interface Props extends RouteComponentProps {}

interface UrlParams {
  voucher_id: string;
}

const EditVoucherOrg = ({ history }: Props): React.ReactElement => {
  const { voucher_id } = useParams<UrlParams>();

  const [usedCodes, setUsedCodes] = useState<CodeVouchers[]>([]);
  const [voucher, setVoucher] = useState<VoucherCode>({
    name: '',
    organisation: {
      categories: [],
    },
  });

  const { data: editMenuInitData } = useQuery(editVoucherCodeInitQuery(voucher_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editMenuInitData) {
      setVoucher(editMenuInitData.vouchers_code_by_pk);
      setUsedCodes(editMenuInitData.vouchers_code);
    }
    return () => {
      mounted = false;
    };
  }, [editMenuInitData]);

  const handleFinish = () => {
    history.push(`/loc/vouchers`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Vouchers', link: `/loc/vouchers` }, { label: voucher.name, link: `/loc/vouchers/${voucher_id}` }, { label: 'Edit' }]}>
        <EditVoucher voucher_id={voucher_id} categories={voucher.organisation.categories} used_codes={usedCodes} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(EditVoucherOrg);
