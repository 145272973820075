import * as gql from 'gql-query-builder';
import IQueryBuilderOptions from 'gql-query-builder/build/IQueryBuilderOptions';
import NestedField from 'gql-query-builder/build/NestedField';
import VariableOptions from 'gql-query-builder/build/VariableOptions';
// import { types } from 'typed-graphqlify';
// import { rawString } from 'typed-graphqlify';
import { UnknownObject } from '../react-app-env';

type QueryFilterCondition = 'eq' | 'gt' | 'lt' | 'gte' | 'lte' | 'neq';

export type Query = {
  [key: string]: QueryField[];
};

export type QueryField = string | object | NestedField;

export interface QueryFilter {
  key: string;
  condition: QueryFilterCondition;
  value: string;
}

export interface QueryHeader {
  key: string;
  label: string;
}

export const getWhereKeyValue = (field: string, condition: QueryFilterCondition = 'eq', value: string): UnknownObject => {
  const nesting: string[] = field.split('.');
  if (nesting.length > 1) {
    return {
      [nesting[0]]: getWhereKeyValue(nesting.slice(1).join('.'), condition, value),
    };
  }
  return {
    [field]: { [`_${condition}`]: value },
  };
};

export const getNestedKeyValue = (field: string, value: string): UnknownObject => {
  const nesting: string[] = field.split('.');
  if (nesting.length > 1) {
    return {
      [nesting[0]]: getNestedKeyValue(nesting.slice(1).join('.'), value),
    };
  }
  return {
    [field]: value,
  };
};

export const getNestedFields = (field: string): string[] | UnknownObject => {
  const nesting: string[] = field.split('.');
  if (nesting.length > 1) {
    return {
      [nesting[0]]: getNestedFields(nesting.slice(1).join('.')),
    };
  }
  return [field];
};

export const queryBuilder = (operation: string, filter: QueryFilter[] = [], fields: QueryField[]) => {
  const query: IQueryBuilderOptions = {
    operation,
    fields,
  };

  const returns = {
    [operation]: fields,
  };

  if (filter.length > 0) {
    const where: VariableOptions = filter
      .map((f) => getWhereKeyValue(f.key, f.condition, f.value))
      .reduce((obj, item) => {
        const key = Object.keys(item)[0];
        return { ...obj, [key]: item[key] };
      }, {});

    query.variables = {
      where: {
        type: `${operation}_bool_exp`,
        value: where,
      },
    };
  }
  return {
    returns,
    query_raw: query,
    ...gql.query(query),
  };
};
