import React, { useState, useEffect, useCallback } from 'react';

import { useQuery } from '@apollo/client';

import { GridCard } from '../../../Grid';

import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import { numberToMoney } from '../../../../utils/stringUtils';
import { exactDateString } from '../../../../utils/dateUtils';

const _query1 = () => ({
  vouchers_gift_aggregate: params(
    {
      where: {
        balance: { _gt: 0 },
      },
    },
    {
      aggregate: {
        sum: {
          balance: types.number,
        },
      },
    }
  ),
});

const _query2 = (startDate: string, endDate: string) => ({
  vouchers_gift_aggregate: params(
    {
      where: {
        _and: [{ created_at: { _gte: rawString(startDate) } }, { created_at: { _lte: rawString(endDate) } }],
      },
    },
    {
      aggregate: {
        count: types.number,
        sum: {
          total: types.number,
        },
      },
    }
  ),
});

interface GiftVouchers {
  totalBalanceAmount: string;
  totalSoldCount: number;
  totalSoldAmount: string;
}

interface Props {
  startDate: Date;
  endDate: Date;
  locale: string;
  currency: string;
}

export const initQuery1 = () => gql`
  ${query(_query1())}
`;
export const initQuery2 = (startDate: string, endDate: string) => gql`
  ${query(_query2(startDate, endDate))}
`;

const MetricPayments = ({ startDate, endDate, currency, locale }: Props): React.ReactElement => {
  const [value, setValue] = useState<GiftVouchers>({
    totalBalanceAmount: '',
    totalSoldCount: 0,
    totalSoldAmount: '',
  });

  const { data: data1, loading: loading1 } = useQuery(initQuery1(), { fetchPolicy: 'no-cache' });
  const { data: data2, loading: loading2 } = useQuery(initQuery2(exactDateString(startDate), exactDateString(endDate)), { fetchPolicy: 'no-cache' });

  const formatMoney = useCallback((v: number) => numberToMoney(v, currency, locale, true), [currency, locale]);

  useEffect(() => {
    let mounted = true;
    if (mounted && data1 && data2) {
      const totalBalance = data1['vouchers_gift_aggregate']['aggregate']['sum']['balance'] as number;
      const totalBalanceAmount = formatMoney(totalBalance);

      const totalSoldCount = data2['vouchers_gift_aggregate']['aggregate']['count'] as number;

      const totalSold = data2['vouchers_gift_aggregate']['aggregate']['sum']['total'] as number;
      const totalSoldAmount = formatMoney(totalSold);

      setValue({
        totalBalanceAmount,
        totalSoldCount,
        totalSoldAmount,
      });
    }
  }, [data1, data2, formatMoney]);

  return (
    <>
      {(value.totalBalanceAmount !== '' || value.totalSoldCount > 0 || value.totalSoldAmount !== '') && (
        <>
          <Grid item xs={12}>
            <Typography variant="h4">Gift vouchers</Typography>
          </Grid>
          <GridCard
            fill
            gridSize={4}
            loading={loading1 || loading2}
            key="TotalBalanceAmount"
            title="Unredeemed vouchers"
            subTitle="Total balance of unredeemed gift vouchers regardless of date range"
            counterSmall
            counter={value.totalBalanceAmount}
          />
          <GridCard
            fill
            gridSize={4}
            loading={loading1 || loading2}
            key="TotalSoldAmount"
            title="Total gift voucher sales"
            subTitle="All gift voucher sales"
            counterSmall
            counter={value.totalSoldAmount}
          />
          <GridCard
            fill
            gridSize={4}
            loading={loading1 || loading2}
            key="TotalSoldCount"
            title="Total gift vouchers sold"
            subTitle="All gift vouchers sold"
            counterSmall
            counter={value.totalSoldCount}
          />
        </>
      )}
    </>
  );
};

export default MetricPayments;
