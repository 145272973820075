import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _menusInitQuery = () => ({
  organisations: [
    {
      locale: types.string,
      currency: types.string,
    },
  ],
});

type X = Unpacked<typeof _menusInitQuery>;
export type Organisation = X['organisations'][0];

export const menusInitQuery = () => gql`
  ${query(_menusInitQuery())}
`;
