import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Button, Grid, GridSize, Typography, withStyles } from '@material-ui/core';

import { Edit as EditIcon, VisibilityOutlined as ViewIcon, Delete as DeleteIcon } from '@material-ui/icons';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  title?: string;
  fill?: boolean;
  center?: boolean;
  counter?: ReadOnlyBlockCounter;
  gridSize?: GridSize;
  items?: BlockItem[];
  components?: React.Component[] | any[];
  actions?: ReadOnlyBlockAction[];
  handleEdit?: () => void;
  handleView?: () => void;
  handleDelete?: () => void;
}

type BlockItem = ReadOnlyBlockItem | null;

type BlockItemListItem = string | null;

export interface ReadOnlyBlockAction {
  label: string;
  icon: React.ReactElement;
  disabled?: boolean;
  filled?: boolean;
  iconEnd?: boolean;
  onClick: () => void;
}

export interface ReadOnlyBlockCounter {
  index: number;
  total: number;
}

interface ReadOnlyBlockItem {
  label: string;
  hideLabel?: boolean;
  value?: string;
  image?: string;
  icon?: string;
  components?: React.Component[] | any[];
  list?: BlockItemListItem[];
}

const ReadOnlyBlock = ({
  classes,
  title,
  counter,
  fill = false,
  center = false,
  gridSize = 12,
  items = [],
  components = [],
  actions = [],
  handleDelete,
  handleEdit,
  handleView,
}: Props): React.ReactElement => {
  return (
    <div className={`${classes.root} ${fill ? classes.fill : ''} ${center ? classes.center : ''}`}>
      <div className={classes.titleContainer}>
        {title && (
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>
        )}
        {counter && (
          <Typography className={classes.counter} variant="h5">
            {counter.index} / {counter.total}
          </Typography>
        )}
      </div>
      <div className={classes.content}>
        <Grid container spacing={4}>
          {items
            .filter((i) => i !== null)
            .map((i) => i as ReadOnlyBlockItem)
            .map((i: ReadOnlyBlockItem) => (
              <Grid key={i.label} item xs={gridSize}>
                <div className={classes.item} key={i.label}>
                  {!i.hideLabel && (
                    <Typography className={classes.label} variant="subtitle2">
                      {i.label}:
                    </Typography>
                  )}
                  {i.value && (
                    <Typography className={classes.value} variant="body1">
                      {i.value}
                    </Typography>
                  )}
                  {i.image && <img className={classes.image} src={i.image} alt={i.label} />}
                  {i.icon && <img className={classes.icon} src={i.icon} alt={i.label} />}
                  {i.list && (
                    <div className={classes.list}>
                      {i.list
                        .filter((i) => i)
                        .map((e, i) => (
                          <div key={`${e}_${i}`}>{e}</div>
                        ))}
                    </div>
                  )}
                  {i.components}
                </div>
              </Grid>
            ))}
        </Grid>
        {components}
      </div>
      {(handleEdit || handleView || actions.length > 0) && (
        <div className={classes.actions}>
          {actions.map((action) => (
            <Button
              variant={action.filled ? 'contained' : 'outlined'}
              color="primary"
              disabled={action.disabled}
              startIcon={!action.iconEnd ? action.icon : undefined}
              endIcon={action.iconEnd ? action.icon : undefined}
              onClick={action.onClick}>
              {action.label}
            </Button>
          ))}
          {handleDelete && (
            <Button variant="outlined" color="primary" startIcon={<DeleteIcon />} onClick={handleDelete} className={classes.delete}>
              Delete
            </Button>
          )}
          {handleView && (
            <Button variant="outlined" color="primary" startIcon={<ViewIcon />} onClick={handleView}>
              View
            </Button>
          )}
          {handleEdit && (
            <Button variant="outlined" color="primary" startIcon={<EditIcon />} onClick={handleEdit}>
              Edit
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ReadOnlyBlock);
