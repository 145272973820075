import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[1],
    },
  });

export default styles;
