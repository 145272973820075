import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

export interface Discount {
  id: string;
  type: string;
  total: number;
  value: number;
  before: number;
}
export interface Refund {
  id: string;
  reference_id: string;
}
export interface OrderItemData {
  id: string;
  item: {
    name: string;
  };
  created_at: string;
  served_at: string;
  price: number;
  tax: number;
}

export interface OrderPaymentData {
  id: string;
  order_id: string;
  payment_method: string;
  reference_id: string;
  total: number;
  tip: number;
}

export interface OrderGiftVoucherData {
  id: string;
  total: number;
  balance: number;
}

const getData = (dateFrom: string, dateTo: string) => {
  return {
    integrations: [
      {
        deliverect: types.custom<{ accountId: string; autoAccept: boolean; locationId: string } | null>(),
      },
    ],
    orders_served: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          paid: { _eq: true },
        },
      },
      [
        {
          id: types.string,
          quick_sale: types.boolean,
          paid: types.boolean,
          total: types.number,
          tax: types.number,
          discounts: types.custom<Discount[]>(),
          refunds: types.custom<Discount[]>(),
          service_charge: types.number,
          created_at: types.string,
        },
      ]
    ),
    order_served_dish_items: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
        },
      },
      [
        {
          order_id: types.string,
        },
      ]
    ),
    order_served_drink_items: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
        },
      },
      [
        {
          order_id: types.string,
        },
      ]
    ),
    orders_takeaway: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          paid: { _eq: true },
        },
      },
      [
        {
          id: types.string,
          paid: types.boolean,
          total: types.number,
          tax: types.number,
          discounts: types.custom<Discount[]>(),
          refunds: types.custom<Discount[]>(),
          service_charge: types.number,
          created_at: types.string,
        },
      ]
    ),
    order_takeaway_dish_items: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
        },
      },
      [
        {
          order_id: types.string,
        },
      ]
    ),
    order_takeaway_drink_items: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
        },
      },
      [
        {
          order_id: types.string,
        },
      ]
    ),
    orders_deliverect: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          deliverect_status: { _eq: 'FINALIZED' },
          paid: { _eq: true },
        },
      },
      [
        {
          id: types.string,
          paid: types.boolean,
          total: types.number,
          tax: types.number,
          service_charge: types.number,
          delivery_by: types.string,
          created_at: types.string,
        },
      ]
    ),
    order_deliverect_dish_items: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
        },
      },
      [
        {
          order_id: types.string,
        },
      ]
    ),
    order_deliverect_drink_items: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
        },
      },
      [
        {
          order_id: types.string,
        },
      ]
    ),
    order_served_payments: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          canceled: { _eq: false },
        },
      },
      [
        {
          id: types.string,
          order_id: types.string,
          payment_method: types.string,
          reference_id: types.string,
          total: types.number,
          tip: types.number,
        },
      ]
    ),
    order_takeaway_payments: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          canceled: { _eq: false },
        },
      },
      [
        {
          id: types.string,
          order_id: types.string,
          payment_method: types.string,
          reference_id: types.string,
          total: types.number,
          tip: types.number,
        },
      ]
    ),
    order_deliverect_payments: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          canceled: { _eq: false },
        },
      },
      [
        {
          id: types.string,
          order_id: types.string,
          payment_method: types.string,
          reference_id: types.string,
          total: types.number,
          tip: types.number,
        },
      ]
    ),
  };
};

const _selectLocationOrdersByPkInitQuery = (location_id: string, dateFrom: string, dateTo: string) => ({
  locations_by_pk: params({ id: rawString(location_id) }, { ...getData(dateFrom, dateTo) }),
});

const _selectLocationOrdersInitQuery = (dateFrom: string, dateTo: string) => ({
  locations: [{ ...getData(dateFrom, dateTo) }],
});

export type SelectLocationOrdersByPkInit = Unpacked<typeof _selectLocationOrdersByPkInitQuery>;
export type SelectLocationOrdersInit = Unpacked<typeof _selectLocationOrdersInitQuery>;
export type Location = SelectLocationOrdersByPkInit['locations_by_pk'] | SelectLocationOrdersInit['locations'][0];
export type OrderServed = Location['orders_served'][0];
export type OrderTakeaway = Location['orders_takeaway'][0];
export type OrderDeliverect = Location['orders_deliverect'][0];

export type OrderItem =
  | Location['order_served_dish_items'][0]
  | Location['order_served_drink_items'][0]
  | Location['order_takeaway_dish_items'][0]
  | Location['order_takeaway_drink_items'][0]
  | Location['order_deliverect_dish_items'][0]
  | Location['order_deliverect_drink_items'][0];

export const selectLocationOrdersInitQuery = (location_id: string | undefined, dateFrom: string, dateTo: string) => {
  if (location_id) {
    return gql`
      ${query(_selectLocationOrdersByPkInitQuery(location_id, dateFrom, dateTo))}
    `;
  }
  return gql`
    ${query(_selectLocationOrdersInitQuery(dateFrom, dateTo))}
  `;
};
