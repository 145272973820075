import { gql } from '@apollo/client';
import gqlClient from '../../../utils/apolloClient';

const insertReferalOrganisationMutation = gql`
  mutation ($object: organisations_insert_input!) {
    insert_organisations_one(object: $object) {
      id
    }
  }
`;

export const createReferalOrganisation = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_organisations_one: {
      id: string;
    };
  }>({ mutation: insertReferalOrganisationMutation!, variables });
  return res.data?.insert_organisations_one.id;
};

const insertReferalLocationsMutation = gql`
  mutation ($objects: [locations_insert_input!]!) {
    insert_locations(objects: $objects) {
      affected_rows
    }
  }
`;

export const createReferalLocations = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_locations: {
      affected_rows: number;
    };
  }>({ mutation: insertReferalLocationsMutation!, variables });
  return res.data?.insert_locations.affected_rows;
};

const updateReferalMutation = gql`
  mutation ($pk_columns: referrals_pk_columns_input!, $set: referrals_set_input!) {
    update_referrals_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateReferal = async (variables: object) => {
  return await gqlClient.mutate<{
    update_referrals_by_pk: {
      id: string;
    };
  }>({ mutation: updateReferalMutation!, variables });
};
