import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { EmptyObject } from '../../react-app-env';
import { RouteComponentProps, withRouter } from 'react-router';

import { Grid, GridSize, withStyles } from '@material-ui/core';

import ColumnStepper from './ColumnStepper';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject>, RouteComponentProps {
  classes: ClassNameMap<string>;
  completeLabel: string;
  isNextDisabled: boolean;
  gridSize?: GridSize;
  progressGridSize?: GridSize;
  children: CustomStepperStep[];
  step: number;
  overrideSetStep?: boolean;
  handleStepOverride?: (isNext: boolean) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  validate: (i: number) => boolean;
  handleComplete: () => void;
  handleAnother?: () => void;
  showProgress?: boolean;
}

interface CustomStepperStep {
  label: string;
  detail?: string | null;
  gridSize?: GridSize;
  nextLabel?: string;
  disabled?: boolean;
  handleNext?: () => void;
  component: React.ReactElement;
}

const CustomStepper = ({
  classes,
  history,
  children = [],
  completeLabel,
  isNextDisabled,
  gridSize = 4,
  progressGridSize = 4,
  step,
  overrideSetStep = false,
  handleStepOverride,
  setStep,
  validate,
  handleComplete,
  handleAnother,
  showProgress = true,
}: Props): React.ReactElement => {
  const handleNextStep = () => {
    if (!validate(step)) {
      if (!overrideSetStep) {
        setStep((i: number) => i + 1);
      } else if (handleStepOverride) {
        handleStepOverride(true);
      }
    }
  };

  const handlePreviousStep = () => {
    setStep((i: number) => i - 1);
  };

  return (
    <>
      <Grid container justify="space-between" spacing={2}>
        <Grid item xs={children[step].gridSize || gridSize}>
          <Grid container>
            <Grid item xs={12}>
              {children[step].component}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={progressGridSize}>
          {showProgress && (
            <ColumnStepper
              title="Progress"
              handleStepClick={setStep}
              steps={children
                .filter((i) => !i.disabled)
                .map((i) => ({
                  label: i.label,
                  detail: i.detail,
                  nextLabel: i.nextLabel,
                  handleNext: i.handleNext,
                }))}
              step={step}
              completeLabel={completeLabel}
              isNextDisabled={isNextDisabled}
              handlePrevious={handlePreviousStep}
              handleNext={handleNextStep}
              handleComplete={handleComplete}
              handleAnother={
                handleAnother
                  ? () => {
                      setStep(0);
                      handleAnother();
                    }
                  : undefined
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(withStyles(styles)(CustomStepper));
