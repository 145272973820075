import React, { useCallback, useState, useContext } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';
import { RouteComponentProps, withRouter } from 'react-router';

import { withStyles } from '@material-ui/core';

import { AuthContext, EnumUserRole } from '../../../../../AuthProvider';

import CustomStepper from '../../../../../../components/CustomStepper';
import { ValidationErrors, LocationIntegrationReducerAction, validation, LocationIntegration, validationErrors, PaymentProvider } from '../..';
import StepPayment from '../StepPayment';
import StepDeliverect from '../StepDeliverect';
import StepXero from '../StepXero';
import StepVouchers from '../StepVouchers';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  location_id: String;
  completeLabel: string;
  integration: LocationIntegration;
  dispatch: React.Dispatch<{ type: LocationIntegrationReducerAction; value: any }>;
  handleSave: () => void;
}

const LocationIntegrationsSteps = ({ classes, history, location_id, completeLabel, integration, dispatch, handleSave }: Props): React.ReactElement => {
  const search = new URLSearchParams(history.location.search);
  const stp = search.get('step');
  const authContext = useContext(AuthContext);

  if (authContext === null) {
    throw new Error('No AuthContext');
  }

  const { userRole } = authContext;

  const [step, setStep] = useState<number>(stp ? +stp : 0);

  const [errors, setErrors] = useState<ValidationErrors>({
    deliverectAccountId: false,
    deliverectLocationId: false,
    paymentProvider: false,
    paymentAccountId: false,
    paymentUsername: false,
    paymentPassword: false,
    xeroCode: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        if (integration.payment.active) {
          errorItems.paymentProvider = validation.paymentProvider(integration.payment.provider);
          if ([PaymentProvider.DOJO, PaymentProvider.ZETTLE].includes(integration.payment.provider as PaymentProvider)) {
            errorItems.paymentAccountId = validation.paymentAccountId(integration.payment.accountId);
          }
          if ([PaymentProvider.DOJO].includes(integration.payment.provider as PaymentProvider)) {
            errorItems.paymentUsername = validation.paymentUsername(integration.payment.username);
            errorItems.paymentPassword = validation.paymentPassword(integration.payment.password);
          }
        } else {
          errorItems.paymentProvider = 'You must provide a payment provider';
        }
      }
      if (_step === 1) {
        if (integration.deliverect.active) {
          errorItems.deliverectAccountId = validation.deliverectAccountId(integration.deliverect.accountId);
          errorItems.deliverectLocationId = validation.deliverectLocationId(integration.deliverect.locationId);
        }
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [integration, errors]
  );

  return (
    <>
      <CustomStepper
        gridSize={8}
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Payment',
            gridSize: 6,
            component: <StepPayment payment={integration.payment} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Deliverect',
            gridSize: 6,
            component: <StepDeliverect deliverect={integration.deliverect} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Xero',
            gridSize: 6,
            component: <StepXero xero={integration.xero} history={history} location_id={location_id} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Vouchers',
            gridSize: 6,
            disabled: userRole !== EnumUserRole.ADMIN,
            component: <StepVouchers active={integration.vouchers} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            gridSize: 8,
            component: <StepReview integration={integration} userRole={userRole} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withRouter(withStyles(styles)(LocationIntegrationsSteps));
