import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    locationContainer: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  });

export default styles;
