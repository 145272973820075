import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { Divider, TextField, withStyles } from '@material-ui/core';

import { ValidationErrors, SelfOnboardOrganisationReducerAction, validation } from '../..';

import styles from './styles';
import { SelfOnboardOrganisation } from '../../../../../../components/Global/Organisations';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisation: SelfOnboardOrganisation;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: SelfOnboardOrganisationReducerAction; value: any }>;
}

const StepDetails = ({ classes, organisation, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.name = validation.name(organisation.name);
      }
      if (field === 'email') {
        errorItems.email = validation.email(organisation.email);
      }
      if (field === 'phone') {
        errorItems.phone = validation.phone(organisation.phone);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [organisation, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Organisation Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={organisation.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              type: SelfOnboardOrganisationReducerAction.NAME,
              value: event.target.value,
            })
          }
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />

        <Divider className={classes.divider} />

        <TextField
          className={classes.textField}
          data-qa="email-textfield"
          required
          label="Contact email address"
          variant="outlined"
          error={errors.email as boolean}
          helperText={errors.email}
          value={organisation.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              type: SelfOnboardOrganisationReducerAction.EMAIL,
              value: event.target.value,
            })
          }
          onKeyUp={() => validate('email')}
          onBlur={() => validate('email')}
        />
        <TextField
          className={classes.textField}
          data-qa="phone-textfield"
          required
          label="Contact phone number"
          variant="outlined"
          error={errors.phone as boolean}
          helperText={errors.phone}
          value={organisation.phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              type: SelfOnboardOrganisationReducerAction.PHONE,
              value: event.target.value,
            })
          }
          onKeyUp={() => validate('phone')}
          onBlur={() => validate('phone')}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
