import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectMenuInitQuery = (id: string) => ({
  menus_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation: {
        name: types.string,
      },
    }
  ),
});

type X = Unpacked<typeof _selectMenuInitQuery>;
export type Menu = X['menus_by_pk'];
export type Organisation = Menu['organisation'];

export const selectMenuInitQuery = (id: string) => gql`
  ${query(_selectMenuInitQuery(id))}
`;
