import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ListMenus from '../../../../components/Global/Menus/List';

import { menusInitQuery, Menu, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListMenusOrg = ({ classes, history }: Props): React.ReactElement => {
  const [menus, setMenus] = useState<Menu[]>([]);
  const [location, setLocation] = useState<Location>({
    can_manage_menus: false,
  });

  const { data: menusInitData } = useQuery(menusInitQuery());

  useEffect(() => {
    let mounted = true;
    if (mounted && menusInitData) {
      setMenus(menusInitData.menus);
      setLocation(menusInitData.locations[0]);
    }
    return () => {
      mounted = false;
    };
  }, [menusInitData]);

  const handleCreate = () => {
    history.push(`/loc/menus/create`);
  };

  const handleView = (id: string) => {
    history.push(`/loc/menus/${id}`);
  };

  const handleUpload = () => {
    history.push(`/loc/menus/upload`);
  };

  const handleSetMenus = (menus: Menu[]) => setMenus(() => menus);

  return (
    <Dashboard title="Menus">
      <ListMenus
        items={menus}
        handleSetMenus={handleSetMenus}
        handleUpload={handleUpload}
        handleView={handleView}
        handleCreate={location.can_manage_menus ? handleCreate : undefined}
      />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListMenusOrg));
