import React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Location } from '../../index';
import { Box, Button, Tooltip } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      paddingTop: 0,
      paddingBottom: 0,
      margin: '0 auto',
      backgroundColor: theme.palette.background.default,
    },
    title: {
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    listItem: {
      padding: theme.spacing(1.5, 0),
      borderBottom: '1px solid #E0E0E0',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    },
    primaryText: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: theme.palette.text.primary,
    },
    secondaryText: {
      fontSize: '14px',
      color: theme.palette.text.secondary,
    },
    stickyButtonContainer: {
      position: 'fixed',
      top: theme.spacing(14),
      right: theme.spacing(8),
      zIndex: 1000,
    },
    stickyButton: {
      paddingInline: theme.spacing(4),
    },
  });

interface ConfigurationOptionsProps extends WithStyles<typeof styles> {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setBreadcrumbs: any;
  location: Location;
  organisationId: string | undefined;
  locationId: string | undefined;
  isLocationCreate?: boolean;
  validationStatus: any;
  allStepsValid: boolean;
  handleSave: any;
}

const ConfigurationOptions: React.FC<ConfigurationOptionsProps> = ({
  classes,
  setStep,
  setBreadcrumbs,
  location,
  isLocationCreate = false,
  validationStatus,
  allStepsValid,
  handleSave,
  organisationId,
  locationId,
}) => {
  const options = [
    {
      title: 'General',
      description: 'View and update your store details; contact info, physical address and marketing optin',
    },
    { title: 'Branding', description: 'Customise your store with branded assets and colour schemes' },
    { title: 'Opening Hours', description: 'Control when your customers can place orders' },
    { title: 'Operations', description: 'Manage how you receive, accept and process orders' },
    { title: 'Pre-Orders', description: 'Enable and manage pre-orders slot settings' },
    { title: 'Pacing', description: 'Adjust order pacing settings to optimize kitchen and service efficiency' },
    ...(!isLocationCreate
      ? [
          { title: 'Integrations', description: 'Set up Payments, Deliverect, Xero' },
          { title: 'Orders', description: 'Look the orders table, csv' },
          { title: 'Stock', description: 'Manage stock' },
          { title: 'Users', description: 'Location users' },
          ...(location.has_station_printers
            ? [
                {
                  title: 'Multi station printing',
                  description: 'Manage LAN (WiFi / Network) printers',
                },
              ]
            : []),
          ...(location.has_staff ? [{ title: 'Staff', description: 'Manage staff for PIN access' }] : []),
          // @ts-ignore
          ...(location.integrations && location.integrations[0]?.vouchers
            ? [
                {
                  title: 'Vouchers',
                  description: 'Discount voucher codes. Can be used for promotions or marketing',
                },
              ]
            : []),
        ]
      : []),
  ];

  const handleClick = (index: number, title: string) => {
    //@ts-ignore
    setBreadcrumbs((prev) => [...prev, { label: title }]);
    setStep(index);
  };

  const handlePreviewStore = () => {
    window.open(`/organisations/${organisationId}/locations/${locationId}/ordering/menu1`);
  };

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        Configuration options
      </Typography>
      <Paper className={classes.container}>
        <List>
          {options.map((option, index) => (
            <ListItem
              key={index}
              className={classes.listItem}
              onClick={() => handleClick(index + 1, option.title)}
              style={{
                backgroundColor: validationStatus[index + 1] === false ? '#fff0f0' : 'inherit', // Highlight invalid sections
              }}>
              <ListItemText
                primary={option.title}
                secondary={option.description}
                primaryTypographyProps={{ className: classes.primaryText }}
                secondaryTypographyProps={{ className: classes.secondaryText }}
              />
            </ListItem>
          ))}
        </List>
        {isLocationCreate && (
          <Tooltip title={!allStepsValid ? 'Complete all required sections before creating the location' : ''}>
            <span>
              <Button variant="contained" color="primary" disabled={!allStepsValid} onClick={handleSave} style={{ marginTop: '20px' }}>
                Create Location
              </Button>
            </span>
          </Tooltip>
        )}
        {!isLocationCreate && (
          <Box className={classes.stickyButtonContainer}>
            <Button variant="contained" color="primary" className={classes.stickyButton} onClick={handlePreviewStore}>
              Preview Store
            </Button>
          </Box>
        )}
      </Paper>
    </>
  );
};

export default withStyles(styles)(ConfigurationOptions);
