import React, { useEffect, useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { InventoryRestockType, LocationMenu, locationReducer, LocationReducerAction, LocationType } from '../Common/';
import Steps from '../Common/components/StepsNew';

import { editLocationInitQuery } from './queries';
import { updateLocation } from './mutations';

import styles from './styles';
import { getFileRequest, webhookClient } from '../../../../utils/webhookClient';
import { Breadcrum } from '../../../../layouts/Dashboard/components/Topbar';
import { Dashboard } from '../../../../layouts';
import { locationIntegrationReducer, LocationIntegrationReducerAction, PaymentProvider } from '../../LocationIntegrations/Common';
import { editLocationIntegrationsInitQuery } from '../../LocationIntegrations/Edit/queries';
import { UnknownObject } from '../../../../react-app-env';
import { insertLocationIntegration, updateLocationIntegration } from '../../LocationIntegrations/Edit/mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  location_id: string;
  organisation_id: string | undefined;
  handleFinish: () => void;
  setBreadcrumbs: any;
  breadcrumbs: Breadcrum[];
  breadcrumbsUpdated: boolean;
  handleCreateUser: any;
  handleViewUser: any;
  handleEditUser: any;
  handleCreatePrinter: any;
  handleEditPrinter: any;
  printers: any;
  handleCreateStaff: any;
  handleViewStaff: any;
  handleEditStaff: any;
  handleCreateVoucher: any;
  handleEditVoucher: any;
  currency: any;
  locale: any;
  voucher_codes: any;
}

const EditLocation = ({
  classes,
  history,
  location_id,
  organisation_id,
  handleFinish,
  setBreadcrumbs,
  breadcrumbs,
  breadcrumbsUpdated,
  handleCreateUser,
  handleViewUser,
  handleEditUser,
  handleCreatePrinter,
  handleEditPrinter,
  printers,
  handleCreateStaff,
  handleViewStaff,
  handleEditStaff,
  handleCreateVoucher,
  handleEditVoucher,
  currency,
  locale,
  voucher_codes,
}: Props): React.ReactElement => {
  const [locationTypes, setLocationTypes] = useState<LocationType[]>([]);
  const [inventoryRestockTypes, setInventoryRestockTypes] = useState<InventoryRestockType[]>([]);
  const [locationMenus, setLocationMenus] = useState<LocationMenu[]>([]);

  const [location, dispatchLocation] = useReducer(locationReducer, {
    name: '',
    email: '',
    phone: '',
    website: '',
    tax_number: '',
    number_of_tables: 0,
    location_type: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_country: '',
    address_postcode: '',
    tax_rate: 0,
    currency: '',
    locale: '',
    can_manage_menus: false,
    has_kitchen_printer: false,
    has_bar_printer: false,
    has_staff: false,
    has_station_printers: false,
    service_charge: null,
    idle_timeout: 30,
    location_menu_id: undefined,
    ordering_system_primary_color: undefined,
    ordering_system_secondary_color: undefined,
    header_image_added: false,
    logo_image_added: false,
    ordering_system_preorder_hours: null,
    ordering_system_open_hours: null,
    preorder_days_limit: null,
    preorder_pacing_limit: null,
    order_interval: 15,
    inventory_restock_type: '',
    voucher_codes_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    integrations: {
      vouchers: false,
    },
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editLocationInitData } = useQuery(editLocationInitQuery(location_id, organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationInitData) {
      dispatchLocation({ type: LocationReducerAction.INIT, value: editLocationInitData.locations_by_pk });
      setLocationTypes(editLocationInitData.enum_location_types);
      setInventoryRestockTypes(editLocationInitData.enum_inventory_restock_types);
      setLocationMenus(editLocationInitData.menus);
    }
    return () => {
      mounted = false;
    };
  }, [editLocationInitData]);

  const handleSave = async () => {
    setSaving(true);

    const { header_image_added, header_image, header_image_data, logo_image_added, logo_image, logo_image_data, integrations, voucher_codes_aggregate, ...rest } = location;

    const updateLocationVariables = {
      pk_columns: {
        id: location_id,
      },
      set: {
        ...rest,
        header_image_added: !!location.header_image,
        logo_image_added: !!location.logo_image,
      },
    };
    await updateLocation(updateLocationVariables);

    if (header_image) {
      const headerImageRequest = await getFileRequest(header_image, `${location_id}.jpeg`, 'image/jpeg');
      await webhookClient.postFormData('ordering-system/images-upload', headerImageRequest);
    }

    if (logo_image) {
      const logoImageRequest = await getFileRequest(logo_image, `${location_id}-logo.jpeg`, 'image/jpeg');
      await webhookClient.postFormData('ordering-system/images-upload', logoImageRequest);
    }

    setSaving(false);
    handleFinish();
  };
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (breadcrumbsUpdated) {
      //@ts-ignore
      setBreadcrumbs((prev) => [...prev, { label: 'Configuration options', action: handleResetSteps }]);
    }
    // eslint-disable-next-line
  }, [breadcrumbsUpdated]);
  const handleResetSteps = () => {
    setStep(0);
    //@ts-ignore
    setBreadcrumbs((prevState) => {
      return prevState.slice(0, -1);
    });
  };

  const [integration, dispatchIntegration] = useReducer(locationIntegrationReducer, {
    id: '',
    payment: {
      active: false,
      provider: null,
      accountId: '',
      username: '',
      password: '',
    },
    deliverect: {
      active: false,
      accountId: '',
      locationId: '',
      autoAccept: false,
    },
    xero: {
      active: false,
      code: '',
    },
    vouchers: false,
  });

  const { data: editLocationIntegrationsInitQueryData } = useQuery(editLocationIntegrationsInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationIntegrationsInitQueryData) {
      dispatchIntegration({
        type: LocationIntegrationReducerAction.INIT,
        value: editLocationIntegrationsInitQueryData.locations_by_pk.integrations[0],
      });
    }
    return () => {
      mounted = false;
    };
  }, [editLocationIntegrationsInitQueryData]);

  const handleSaveIntegration = async () => {
    setSaving(true);

    const { provider } = integration.payment;

    let payment = null;
    let deliverect = null;
    let vouchers = integration.vouchers;

    if (integration.payment.active) {
      if (provider === PaymentProvider.DOJO) {
        payment = {
          provider,
          connection: {
            accountId: integration.payment.accountId,
            username: integration.payment.username,
            password: integration.payment.password,
          },
        };
      }
      if (provider === PaymentProvider.ZETTLE) {
        payment = {
          provider,
          enableTipping: integration.payment.enableTipping ?? false,
          connection: {
            accountId: integration.payment.accountId,
          },
        };
      }
      if (provider === PaymentProvider.PAYPAL) {
        payment = {
          provider,
          isOnboarded: integration.payment.isOnboarded ?? false,
          links: integration.payment.links,
        };
      }
    }

    if (integration.deliverect.active) {
      deliverect = {
        accountId: integration.deliverect.accountId,
        locationId: integration.deliverect.locationId,
        autoAccept: integration.deliverect.autoAccept,
      };
    }

    const data: UnknownObject = {
      location_id,
      payment,
      deliverect,
      vouchers,
    };
    if (integration.id) {
      const updateVariables = {
        pk_columns: {
          id: integration.id,
        },
        set: data,
      };
      await updateLocationIntegration(updateVariables);
    } else {
      const insertVariables = {
        object: data,
      };
      await insertLocationIntegration(insertVariables);
    }

    setSaving(false);
    handleFinish();
  };
  return (
    <Dashboard breadcrumbs={breadcrumbs}>
      <Steps
        completeLabel="Save"
        location={location}
        locationId={location_id}
        organisationId={organisation_id}
        locationTypes={locationTypes}
        inventoryRestockTypes={inventoryRestockTypes}
        locationMenus={locationMenus}
        dispatch={dispatchLocation}
        handleSave={handleSave}
        handleSaveIntegration={handleSaveIntegration}
        setBreadcrumbs={setBreadcrumbs}
        step={step}
        setStep={setStep}
        integration={integration}
        dispatchIntegration={dispatchIntegration}
        handleCreateUser={handleCreateUser}
        handleViewUser={handleViewUser}
        handleEditUser={handleEditUser}
        handleCreatePrinter={handleCreatePrinter}
        handleEditPrinter={handleEditPrinter}
        printers={printers}
        handleCreateStaff={handleCreateStaff}
        handleViewStaff={handleViewStaff}
        handleEditStaff={handleEditStaff}
        handleCreateVoucher={handleCreateVoucher}
        handleEditVoucher={handleEditVoucher}
        currency={currency}
        locale={locale}
        voucher_codes={voucher_codes}
      />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(EditLocation));
