import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { capitaliseFirst } from '../../../../../utils/stringUtils';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import ViewCategory from '../../../../../components/Global/Categories/View';

import { selectCategoryInitQuery, Category } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  menu_id: string;
  category_type: 'dish' | 'drink';
  category_id: string;
}

const ViewCategoryOrg = ({ classes, history }: Props): React.ReactElement => {
  const { menu_id, category_type, category_id } = useParams<UrlParams>();

  const [category, setCategory] = useState<Category>({
    name: '',
    menu: {
      name: '',
    },
  });

  const { data: categoryInitData } = useQuery(selectCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryInitData) {
      const cat = categoryInitData.categories_by_pk;
      setCategory(cat);
    }
    return () => {
      mounted = false;
    };
  }, [categoryInitData, category_type]);

  const handleEdit = () => {
    history.push(`/org/menus/${menu_id}/${category_type}/categories/${category_id}/edit`);
  };

  const handleEditItem = (id: string) => {
    history.push(`/org/menus/${menu_id}/${category_type}/categories/${category_id}/items/${id}/edit`);
  };

  const handleCreateItem = () => {
    history.push(`/org/menus/${menu_id}/${category_type}/categories/${category_id}/items/create`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Menus', link: `/org/menus` },
        { label: category.menu.name, link: `/org/menus/${menu_id}` },
        { label: `${capitaliseFirst(category_type)} Categories` },
        { label: category?.name },
      ]}>
      {category.name && (
        <ViewCategory category_id={category_id} category_type={category_type} handleEdit={handleEdit} handleEditItem={handleEditItem} handleCreateItem={handleCreateItem} />
      )}
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewCategoryOrg));
