import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateLocationUserMutation = gql`
  mutation ($pk_columns: users_location_pk_columns_input!, $set: users_location_set_input!) {
    update_users_location_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateLocationUser = async (variables: object) => {
  return await gqlClient.mutate<{
    update_users_location_by_pk: {
      id: string;
    };
  }>({ mutation: updateLocationUserMutation!, variables });
};
