import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import EditLocationPrinter from '../../../../components/Global/LocationPrinters/Edit';

import { editLocationPrinterInitQuery, LocationPrinter } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  printer_id: string;
}

const LocEditLocationPrinter = ({ classes, history }: Props): React.ReactElement => {
  const { printer_id } = useParams<UrlParams>();

  const [printer, setPrinter] = useState<LocationPrinter>({
    name: '',
    location: {
      name: '',
      organisation: {
        menus: [],
      },
      devices: [],
    },
  });

  const { data: editLocationPrinterInitQueryData } = useQuery(editLocationPrinterInitQuery(printer_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationPrinterInitQueryData) {
      setPrinter(editLocationPrinterInitQueryData.location_devices_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editLocationPrinterInitQueryData]);

  const handleFinish = () => {
    history.push(`/loc/printers`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Printers', link: `/loc/printers` }, { label: printer.name }, { label: 'Edit' }]}>
        <EditLocationPrinter
          printer_id={printer_id}
          locations={printer.location.devices.map((i) => i.device_config.location)}
          menus={printer.location.organisation.menus}
          handleFinish={handleFinish}
        />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(LocEditLocationPrinter));
