import { Grid } from '@material-ui/core';
import { HTMLAttributes, useRef, useState } from 'react';
import ReactCrop, { PixelCrop } from 'react-image-crop';
import { setCanvasPreview } from './common';
import 'react-image-crop/dist/ReactCrop.css';

interface Props {
  image?: string;
  imageUrl?: string;
  onCropComplete: (dataUrl: string) => void;
  style?: HTMLAttributes<HTMLCanvasElement>;
}

const ImageCropper = ({ image, imageUrl, onCropComplete, style }: Props) => {
  const [crop, setCrop] = useState<PixelCrop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 200,
    height: 200,
  });

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const handleComplete = (p: PixelCrop) => {
    if (imageRef.current && previewCanvasRef.current) {
      setCanvasPreview(imageRef.current, previewCanvasRef.current, p);
      const dataUrl = previewCanvasRef.current!.toDataURL();
      onCropComplete(dataUrl);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReactCrop crop={crop} onChange={setCrop} aspect={1} minHeight={200} onComplete={(p) => handleComplete(p)}>
          <img crossOrigin="anonymous" src={image ? image : imageUrl} ref={imageRef} alt="cropper" />
        </ReactCrop>
      </Grid>
      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="mt-4"
          style={
            style
              ? style
              : {
                  display: 'none',
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: 150,
                  height: 150,
                }
          }
        />
      )}
    </Grid>
  );
};

export default ImageCropper;
