import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import { Grid, Typography, withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import { selectOrganisationInitQuery, Organisation } from './queries';
import { GridCard } from '../../../../components/Grid';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const ViewOrganisation = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation | UnknownObject>({});

  const { data: organisationsInitData } = useQuery(selectOrganisationInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationsInitData) {
      const org = organisationsInitData.organisations_by_pk;
      setOrganisation(org);
    }
    return () => {
      mounted = false;
    };
  }, [organisationsInitData]);

  const handleEdit = () => {
    history.push(`/admin/organisations/${organisation_id}/edit`);
  };

  const handleCreateUser = () => {
    history.push(`/admin/organisations/${organisation_id}/users/create`);
  };

  const handleViewUsers = () => {
    history.push(`/admin/organisations/${organisation_id}/users`);
  };

  const handleViewLocations = () => {
    history.push(`/admin/organisations/${organisation_id}/locations`);
  };

  const handleCreateLocation = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/create`);
  };

  const handleViewMenus = () => {
    history.push(`/admin/organisations/${organisation_id}/menus`);
  };

  const handleCreateMenu = () => {
    history.push(`/admin/organisations/${organisation_id}/menus/create`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Organisations', link: '/admin/organisations' }, { label: organisation.name }]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.header}>
                  <Typography className={classes.title} variant="h5" component="h2">
                    {organisation.name}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <ReadOnlyBlock
              gridSize={3}
              items={[
                { label: 'Email', value: organisation.email || 'No email information found' },
                { label: 'Phone', value: organisation.phone || 'No phone information found' },
                { label: 'Organisation type', value: organisation.enum_organisation_type?.description || 'No type found' },
              ]}
              handleEdit={handleEdit}
            />
          </>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <GridCard
                fill
                gridSize={12}
                counter={organisation?.users_aggregate?.aggregate.count}
                key="Users"
                title="Admin Users"
                subTitle="Organisation owners"
                editLabel="Create"
                handleEdit={() => handleCreateUser()}
                handleView={() => handleViewUsers()}
              />
            </Grid>
            <Grid item xs={4}>
              <GridCard
                fill
                gridSize={12}
                counter={organisation?.locations_aggregate?.aggregate.count}
                key="Locations"
                title="Locations"
                subTitle="Organisation locations"
                editLabel="Create"
                handleEdit={() => handleCreateLocation()}
                handleView={() => handleViewLocations()}
              />
            </Grid>
            <Grid item xs={4}>
              <GridCard
                fill
                gridSize={12}
                counter={organisation?.menus_aggregate?.aggregate.count}
                key="Menus"
                title="Menus"
                subTitle="Organisation menus"
                editLabel="Create"
                handleEdit={() => handleCreateMenu()}
                handleView={() => handleViewMenus()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewOrganisation));
