import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid, Typography, withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';

import { organisationsInitQuery, Organisation, Location, DeviceApp } from './queries';

import CustomSortableTable, { SortableTableHeader } from '../../../../components/CustomSortableTable';

import styles from './styles';
import moment from 'moment';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const ListDeviceApps = ({ classes, history }: Props): React.ReactElement => {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  const { data: organisationsInitData } = useQuery(organisationsInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationsInitData) {
      setOrganisations(organisationsInitData.organisations);
    }
    return () => {
      mounted = false;
    };
  }, [organisationsInitData]);

  const deviceHeaders: SortableTableHeader[] = [
    { key: 'device_model', label: 'Device model' },
    { key: 'device_type', label: 'Device type' },
    { key: 'app_version', label: 'App version' },
    { key: 'login_total', label: 'Logins', align: 'center' },
    { key: 'login_month', label: 'Logins this month', align: 'center' },
    { key: 'last_login_at', label: 'Last logged in' },
  ];

  const FIRST_OF_THE_MONTH = new Date();
  FIRST_OF_THE_MONTH.setDate(1);
  FIRST_OF_THE_MONTH.setHours(0, 0, 0, 0);

  return (
    <Dashboard title="Organisation Devices">
      <Grid container spacing={4} alignItems="stretch">
        {organisations
          .filter((i) => i.locations.length > 0 && i.locations.some((l) => l.device_apps.length > 0))
          .map((organisation: Organisation) => (
            <Grid key={organisation.id} item xs={12}>
              <Typography variant="h4">{organisation.name}</Typography>
              {organisation.locations
                .filter((i) => i.device_apps.length > 0)
                .map((location: Location, i: number) => (
                  <div key={location.id} className={classes.locationContainer}>
                    <CustomSortableTable
                      key={`${location.id}_${i}`}
                      title={location.name}
                      counter={location.device_apps.length}
                      headers={deviceHeaders}
                      noSorting
                      rows={location.device_apps.map((d: DeviceApp, i: number) => ({
                        key: `${location.id}_${i}`,
                        columns: [
                          { key: 'device_model', label: d.device_model },
                          { key: 'device_type', label: d.device_type },
                          { key: 'app_version', label: d.app_version },
                          { key: 'login_total', label: d.login_total },
                          { key: 'login_month', label: d.login_month },
                          {
                            key: 'last_login_at',
                            label: d.last_login_at,
                            format: (v) => moment(v as string).format('DD/MM HH:mm'),
                            state: moment(d.last_login_at).isBefore(FIRST_OF_THE_MONTH) ? 'error' : undefined,
                          },
                        ],
                      }))}
                    />
                  </div>
                ))}
            </Grid>
          ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListDeviceApps));
