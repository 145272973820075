import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { EmptyObject } from '../../react-app-env';

import { Button, withStyles } from '@material-ui/core';

import { Publish as UploadIcon } from '@material-ui/icons';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  label: string;
  accept: string;
  handleFile?: (i: File) => void;
  handleUpload?: (i: string) => void;
  handleUploads?: (i: any[]) => void;
}

const UploadFileContainer = ({ classes, children, label, accept, handleFile, handleUpload, handleUploads }: Props): React.ReactElement => {
  const handleFileChange = (fileList: FileList | null) => {
    if (fileList) {
      if (handleUploads) {
        const files: any[] = [];
        const fileReader = new FileReader();
        [...fileList].forEach((file: File) => {
          fileReader.readAsDataURL(file);
          fileReader.onload = (event: any) => {
            files.push(event.target.result);
            if (files.length === fileList.length) {
              handleUploads(files);
            }
          };
        });
      } else if (handleFile) {
        handleFile(fileList[0]);
      } else if (handleUpload) {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(fileList[0]);
        fileReader.onload = (event: any) => {
          handleUpload(event.target.result);
        };
      }
    }
  };

  return (
    <div className={classes.root}>
      <input
        accept={accept}
        id="upload-file"
        data-qa="upload-file-input"
        type="file"
        style={{ display: 'none' }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event.target.files)}
      />
      <label htmlFor="upload-file">
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          data-qa="upload-file-button"
          onClick={(event: any) => event.currentTarget.parentElement.click()}
          startIcon={<UploadIcon />}>
          {label}
        </Button>
      </label>
      {children}
    </div>
  );
};

export default withStyles(styles)(UploadFileContainer);
