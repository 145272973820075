import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _locationsInitQuery = () => ({
  locations: params(
    {
      order_by: { name: 'asc' },
    },
    [
      {
        id: types.string,
        name: types.string,
      },
    ]
  ),
});

export const locationsInitQuery = () => gql`
  ${query(_locationsInitQuery())}
`;
