import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateVoucherCodeMutation = gql`
  mutation ($pk_columns: vouchers_code_pk_columns_input!, $set: vouchers_code_set_input!) {
    update_vouchers_code_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateVoucherCode = async (variables: object) => {
  return await gqlClient.mutate<{
    update_vouchers_code_by_pk: {
      id: string;
    };
  }>({ mutation: updateVoucherCodeMutation!, variables });
};
