import React, { useState, useEffect, useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../../../react-app-env';
import {
  exactDateString,
  formatDate,
  getDateSecondsDiff,
  getDaysPast,
  getMonthsPast,
  getStartOfWeek,
  removeDST,
  secondsToTime,
  timeToString,
} from '../../../../../../utils/dateUtils';

import { Chip, FormControl, Grid, Input, MenuItem, Paper, Select, withStyles } from '@material-ui/core';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { GetApp as DownloadIcon } from '@material-ui/icons';

import { getKeyValue, numberToMoney, pluralise } from '../../../../../../utils/stringUtils';
import CustomSortableTable, {
  ColumnTotals,
  SortableTableAction,
  SortableTableCollapsible,
  SortableTableFilter,
  SortableTableHeader,
  SortableTableLabel,
  SortableTableRow,
} from '../../../../../CustomSortableTable';
import {
  selectLocationOrdersInitQuery,
  OrderServed,
  Location,
  OrderTakeaway,
  OrderItemData,
  OrderPaymentData,
  getOrderDetailsQuery,
  Refund,
  OrderGiftVoucherData,
  selectLocationOrdersServedAtInitQuery,
  OrderServedAtItem,
  OrderDeliverect,
} from './queries';

import styles from './styles';
import CustomDatePicker from '../../../../../CustomDatePicker';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  location_id?: string;
}

interface Order {
  id: string;
  covers: number | null;
  table_number: number | null;
  time: OrderTime;
  order_number: string;
  order_type: string;
  order_type_key: string;
  created_at: string;
  created_by_user?: string | null;
  paid: boolean;
  gross: number;
  net: number;
  tax: number;
  discount: number;
  refund: number;
  service_charge: number;
  tip: number;
  tipCard: number;
  tipCash: number;
  cash: number;
  card: number;
  voucher: number;
}

interface OrderTime {
  createdAt: Date;
  servedAt: Date | null;
}

interface OrderItem {
  id: string;
  name: string;
  time: OrderTime;
  gross: number;
  tax: number;
}

interface OrderPayment {
  id: string;
  payment_method: string;
  total: number;
  tip: number;
}

interface OrderGiftVoucher {
  id: string;
  total: number;
  balance: number;
}

enum EnumOrderItemType {
  DISH,
  DRINK,
}

const LocationOrdersStep = ({ classes, location_id, history }: Props): React.ReactElement => {
  const [orderTypesFiltered, setOrderTypesFiltered] = useState<string[]>([]);
  const [historyFiltered, setHistoryFiltered] = useState<string>('ONE');
  const [hiddenFiltered, setHiddenFiltered] = useState<string[]>([]);
  // const [paidFiltered, setPaidFiltered] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [location, setLocation] = useState<Location>({
    name: '',
    currency: 'GBP',
    locale: 'en-gb',
    integrations: [],
    orders_served: [],
    orders_takeaway: [],
    orders_deliverect: [],
    order_served_payments: [],
    order_takeaway_payments: [],
    order_deliverect_payments: [],
  });
  const [orders, setOrders] = useState<Order[]>([]);
  const [customStartDate, setCustomStartDate] = useState<Date | null>(new Date());
  const [customEndDate, setCustomEndDate] = useState<Date | null>(new Date());
  const [customDateErrors, setCustomDateErrors] = useState<{ startDate: string | boolean; endDate: string | boolean }>({ startDate: false, endDate: false });

  const getStartDate = useCallback((): Date => {
    let date: Date = new Date();
    switch (historyFiltered) {
      case 'ONE':
        date = getDaysPast(0);
        break;
      case 'WEEK':
        date = getStartOfWeek(0);
        break;
      case 'MONTH':
        date = getMonthsPast(1);
        break;
      case '3_MONTH':
        date = getMonthsPast(3);
        break;
      case 'CUSTOM':
        date = customStartDate!;
        break;
      default:
        break;
    }
    date.setHours(0, 0, 0);
    return date;
  }, [historyFiltered, customStartDate]);

  const getEndDate = useCallback((): Date => {
    let date: Date = new Date();
    if (historyFiltered === 'CUSTOM') {
      date = customEndDate!;
    }
    date.setHours(23, 59, 59);
    return date;
  }, [historyFiltered, customEndDate]);

  const calculateServedAt = (dish_served_at: string, drink_served_at: string) => {
    let served_at = null;
    if (dish_served_at && drink_served_at) {
      const dish_served_at_date = new Date(dish_served_at);
      const drink_served_at_date = new Date(drink_served_at);
      if (dish_served_at > drink_served_at) {
        served_at = dish_served_at_date;
      } else {
        served_at = drink_served_at_date;
      }
    } else if (dish_served_at) {
      served_at = new Date(dish_served_at);
    } else if (drink_served_at) {
      served_at = new Date(drink_served_at);
    }
    return served_at;
  };

  const updateData = useCallback(async () => {
    if (historyFiltered === 'CUSTOM') {
      if (!customStartDate || !customEndDate) {
        setCustomDateErrors({ startDate: false, endDate: false });
        return;
      }
      if (customStartDate > customEndDate) {
        setCustomDateErrors((i) => ({ ...i, startDate: 'Start date must be before end date' }));
        return;
      } else {
        setCustomDateErrors({ startDate: false, endDate: false });
      }
    }

    const dateFrom: Date = getStartDate();
    const dateTo: Date = getEndDate();

    setLoading(true);

    try {
      const loc = await selectLocationOrdersInitQuery(location_id, exactDateString(dateFrom), exactDateString(dateTo));

      const calculatePayments = (payments: OrderPaymentData[], refunds: Refund[], paymentMethods: string[], notPaymentMethod: boolean) => {
        return payments
          .filter((p) => {
            const id: string = p.id;
            const refId: string | null = p.reference_id || null;
            const paymentMethod: string = p.payment_method;
            if (notPaymentMethod ? !paymentMethods.includes(paymentMethod) : paymentMethods.includes(paymentMethod)) {
              const isRefunded: boolean = refunds.filter((r) => r.id !== id && r.reference_id === refId).length > 0;
              return !isRefunded;
            }
            return false;
          })
          .reduce((a, b) => a + b.total, 0);
      };

      const calculateTips = (payments: OrderPaymentData[], refunds: Refund[], paymentMethods: string[], notPaymentMethod: boolean) => {
        return payments
          .filter((p) => {
            const id: string = p.id;
            const refId: string | null = p.reference_id || null;
            const paymentMethod: string = p.payment_method;
            if (notPaymentMethod ? !paymentMethods.includes(paymentMethod) : paymentMethods.includes(paymentMethod)) {
              const isRefunded: boolean = refunds.filter((r) => r.id !== id && r.reference_id === refId).length > 0;
              return !isRefunded;
            }
            return false;
          })
          .reduce((a, b) => a + b.tip, 0);
      };

      if (loc) {
        setLocation(loc);
        const orders_served: OrderServed[] = loc.orders_served;
        const orders_takeaway: OrderTakeaway[] = loc.orders_takeaway;
        const orders_deliverect: OrderDeliverect[] = loc.orders_deliverect;
        const order_served_payments: OrderPaymentData[] = loc.order_served_payments;
        const order_takeaway_payments: OrderPaymentData[] = loc.order_takeaway_payments;
        const order_deliverect_payments: OrderPaymentData[] = loc.order_deliverect_payments;

        const ords: Order[] = [
          ...orders_served.map((os: OrderServed) => {
            const payments = order_served_payments.filter((e) => e.order_id === os.id);
            const refunds = payments.filter((e) => e.payment_method === 'REFUND');
            const timeDate = new Date(formatDate(removeDST(new Date(os.created_at)), 'YYYY/MM/DD HH:mm:ss'));

            const tipCard = calculateTips(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true);
            const tipCash = calculateTips(payments, refunds, ['CASH'], false);
            const tip = tipCard + tipCash;
            return {
              ...os,
              time: {
                createdAt: timeDate,
                servedAt: null,
              },
              order_type: os.quick_sale ? 'quick sale' : 'dine in',
              order_type_key: 'SERVED',
              paid: os.paid,
              created_by_user: os.created_by_user?.first_name ?? 'ordering system',
              discount: os.discounts.map((d) => d.before - d.total).reduce((a, b) => a + b, 0),
              refund: os.refunds.map((d) => d.before - d.total).reduce((a, b) => a + b, 0),
              service_charge: os.service_charge,
              net: os.total - os.tax + tip,
              tax: os.tax,
              gross: os.total + tip,
              tip,
              tipCard,
              tipCash,
              cash: calculatePayments(payments, refunds, ['CASH'], false) + tipCash,
              card: calculatePayments(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true) + tipCard,
              voucher: calculatePayments(payments, refunds, ['VOUCHER_AT_DOOR'], false),
            };
          }),
          ...orders_takeaway.map((ta: OrderTakeaway) => {
            const payments = order_takeaway_payments.filter((e) => e.order_id === ta.id);
            const refunds = payments.filter((e) => e.payment_method === 'REFUND');
            const timeDate = new Date(formatDate(removeDST(new Date(ta.created_at)), 'YYYY/MM/DD HH:mm:ss'));
            const tipCard = calculateTips(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true);
            const tipCash = calculateTips(payments, refunds, ['CASH'], false);
            const tip = tipCard + tipCash;
            return {
              ...ta,
              time: {
                createdAt: timeDate,
                servedAt: null,
              },
              covers: null,
              table_number: null,
              order_type: 'takeaway',
              order_type_key: 'TAKEAWAY',
              paid: ta.paid,
              created_by_user: ta.created_by_user?.first_name ?? 'ordering system',
              discount: ta.discounts.map((d) => d.before - d.total).reduce((a, b) => a + b, 0),
              refund: ta.refunds.map((d) => d.before - d.total).reduce((a, b) => a + b, 0),
              service_charge: ta.service_charge,
              net: ta.total - ta.tax + tip,
              tax: ta.tax,
              gross: ta.total + tip,
              tip,
              tipCard,
              tipCash,
              cash: calculatePayments(payments, refunds, ['CASH'], false) + tipCash,
              card: calculatePayments(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true) + tipCard,
              voucher: calculatePayments(payments, refunds, ['VOUCHER_AT_DOOR'], false),
            };
          }),
          ...orders_deliverect.map((de: OrderDeliverect) => {
            const payments = order_deliverect_payments.filter((e) => e.order_id === de.id);
            const refunds = payments.filter((e) => e.payment_method === 'REFUND');
            const timeDate = new Date(formatDate(removeDST(new Date(de.created_at)), 'YYYY/MM/DD HH:mm:ss'));
            const tipCard = calculateTips(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true);
            const tipCash = calculateTips(payments, refunds, ['CASH'], false);
            const tip = tipCard + tipCash;

            return {
              ...de,
              time: {
                createdAt: timeDate,
                servedAt: null,
              },
              covers: null,
              table_number: null,
              order_type: 'deliverect',
              order_type_key: 'DELIVERECT',
              paid: de.paid,
              created_by_user: de.delivery_by,
              discount: 0,
              refund: 0,
              service_charge: de.service_charge,
              net: de.total - de.tax + tip,
              tax: de.tax,
              gross: de.total + tip,
              tip,
              tipCard,
              tipCash,
              cash: calculatePayments(payments, refunds, ['CASH'], false) + tipCash,
              card: calculatePayments(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true) + tipCard,
              voucher: calculatePayments(payments, refunds, ['VOUCHER_AT_DOOR'], false),
            };
          }),
        ];

        const servedAtItems = await selectLocationOrdersServedAtInitQuery(location_id, exactDateString(dateFrom), exactDateString(dateTo));

        const servedAtOrdersAll: { id: string; dishServedAt: string; drinkServedAt: string }[] = [];

        const getOrderItem = (servedAtItem: OrderServedAtItem, itemType: EnumOrderItemType) => {
          const item = servedAtOrdersAll.find((s) => s.id === servedAtItem.order.id);
          if (!item) {
            servedAtOrdersAll.push({
              id: servedAtItem.order.id,
              dishServedAt: '',
              drinkServedAt: '',
            });
          }
          const itemIndex = servedAtOrdersAll.findIndex((s) => s.id === servedAtItem.order.id) || -1;
          if (itemIndex >= 0) {
            if (itemType === EnumOrderItemType.DISH) {
              servedAtOrdersAll[itemIndex] = {
                ...servedAtOrdersAll[itemIndex],
                dishServedAt: servedAtItem.served_at,
              };
            }
            if (itemType === EnumOrderItemType.DRINK) {
              servedAtOrdersAll[itemIndex] = {
                ...servedAtOrdersAll[itemIndex],
                drinkServedAt: servedAtItem.served_at,
              };
            }
          }
        };

        servedAtItems.order_served_dish_items.forEach((i) => getOrderItem(i, EnumOrderItemType.DISH));
        servedAtItems.order_served_drink_items.forEach((i) => getOrderItem(i, EnumOrderItemType.DRINK));
        servedAtItems.order_takeaway_dish_items.forEach((i) => getOrderItem(i, EnumOrderItemType.DISH));
        servedAtItems.order_takeaway_drink_items.forEach((i) => getOrderItem(i, EnumOrderItemType.DRINK));

        servedAtOrdersAll.forEach((ord) => {
          const order = ords.find((i) => i.id === ord.id);
          if (order) {
            const dishServedAt = ord.dishServedAt;
            const drinkServedAt = ord.drinkServedAt;
            order.time = {
              ...order.time,
              servedAt: calculateServedAt(dishServedAt, drinkServedAt),
            };
          }
        });

        setOrders(ords);
      }
    } catch (e) {}
    setLoading(false);
  }, [customEndDate, customStartDate, historyFiltered, location_id, getEndDate, getStartDate]);

  useEffect(() => {
    let mounted = true;
    if (mounted && historyFiltered) {
      updateData();
    }
    return () => {
      mounted = false;
    };
  }, [historyFiltered, updateData]);

  const formatMoney = (v: number, isNegative: boolean = false) =>
    location.locale && location.currency ? numberToMoney(isNegative && v > 0 ? -v : v, location.currency, location.locale, true) : v;

  const getPaymentMethodString = (paymentMethod: string): string => {
    if (paymentMethod === 'REFUND') {
      return 'Refund';
    }
    if (paymentMethod === 'CASH') {
      return 'Cash';
    }
    return 'Card';
  };

  const headers: SortableTableHeader[] = [
    { key: 'created_at', label: 'Created at', format: (v) => formatDate(new Date(v as string), 'DD/MM/YYYY HH:mm'), hidden: hiddenFiltered.includes('created_at') },
    { key: 'order_number', label: 'Order number', hidden: hiddenFiltered.includes('order_number') },
    { key: 'table_number', label: 'Table number', hidden: hiddenFiltered.includes('table_number'), align: 'center' },
    {
      key: 'time',
      label: 'Wait time',
      hidden: hiddenFiltered.includes('time'),
      align: 'center',
      format: (v, args) => (args.servedAt ? `${timeToString(secondsToTime(getDateSecondsDiff(args.createdAt, args.servedAt)))}` : 'Not served'),
    },
    { key: 'covers', label: 'Covers', hidden: hiddenFiltered.includes('covers'), align: 'center' },
    // { key: 'quick_sale', label: 'Quick sale', hidden: hiddenFiltered.includes('quick_sale') },
    // { key: 'takeaway', label: 'Takeaway', hidden: hiddenFiltered.includes('takeaway') },
    {
      key: 'order_type',
      label: 'Order type',
      hidden: hiddenFiltered.includes('order_type'),
      align: 'center',
      component: (v) => {
        return <Chip key={v as string} size="small" variant="outlined" label={v} className={classes.chip} />;
      },
    },
    // { key: 'dishes', label: 'Dishes', hidden: hiddenFiltered.includes('dishes') },
    // { key: 'drinks', label: 'Drinks', hidden: hiddenFiltered.includes('drinks') },
    { key: 'created_by_user', label: 'Server', hidden: hiddenFiltered.includes('created_by_user') },
    // { key: 'paid', label: 'Paid', hidden: hiddenFiltered.includes('paid') },
    { key: 'discount', label: 'Discount', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('discount') },
    { key: 'refund', label: 'Refund', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('refund') },
    { key: 'service_charge', label: 'Service charge', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('service_charge') },
    { key: 'net', label: 'Net', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('net') },
    { key: 'tax', label: 'Tax', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('tax') },
    { key: 'gross', label: 'Gross', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('gross') },
    { key: 'tip', label: 'Tip', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('tip') },
    { key: 'tipCard', label: 'Tip Card', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('tipCard') },
    { key: 'tipCash', label: 'Tip Cash', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('tipCash') },
    { key: 'card', label: 'Card', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('card') },
    { key: 'cash', label: 'Cash', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('card') },
    { key: 'voucher', label: 'Voucher', format: (v) => formatMoney(v as number), hidden: hiddenFiltered.includes('voucher') },
  ];

  const itemHeaders: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    // { key: 'tax_percent', label: 'Tax %' },
    { key: 'time', label: 'Time till served' },
    { key: 'net', label: 'Net' },
    { key: 'tax', label: 'Tax' },
    { key: 'gross', label: 'Gross' },
    { key: 'tip', label: 'Tip' },
  ];

  const giftVoucherHeaders: SortableTableHeader[] = [
    { key: 'name', label: '' },
    { key: 'total', label: 'Total' },
    { key: 'balance', label: 'Balance' },
  ];

  const paymentHeaders: SortableTableHeader[] = [
    { key: 'payment_method', label: 'Payment method' },
    { key: 'total', label: 'Total', format: (v) => formatMoney(v as number) },
    { key: 'tip', label: 'Tip', format: (v) => formatMoney(v as number) },
  ];

  const createOrerItemRow = (title: string, items: OrderItem[]): SortableTableCollapsible => ({
    title: pluralise(title, items.length),
    counter: items.length,
    headers: itemHeaders,
    rows: items.map((i: OrderItem) => ({
      key: i.id,
      columns: [
        { key: 'name', label: i.name },
        {
          key: 'time',
          label: i.time.servedAt ? getDateSecondsDiff(i.time.createdAt, i.time.servedAt) : 'Not served',
          data: i.time,
          format: (v: SortableTableLabel, args: any) => (args.servedAt ? `${timeToString(secondsToTime(getDateSecondsDiff(args.createdAt, args.servedAt)))}` : 'Not served'),
        },
        { key: 'net', label: i.gross - i.tax, format: (v: SortableTableLabel) => formatMoney(v as number) },
        { key: 'tax', label: i.tax, format: (v: SortableTableLabel) => formatMoney(v as number) },
        { key: 'gross', label: i.gross, format: (v: SortableTableLabel) => formatMoney(v as number) },
      ],
    })),
    columnTotals: [
      ['net', (v: SortableTableLabel) => formatMoney(v as number)],
      ['tax', (v: SortableTableLabel) => formatMoney(v as number)],
      ['gross', (v: SortableTableLabel) => formatMoney(v as number)],
    ] as ColumnTotals[],
  });

  const rows: SortableTableRow[] = orders.map((o: Order, index: number) => ({
    key: `${o.id}_${o.order_type_key}_${index}`,
    columns: headers
      .filter((i) => !i.disabled)
      .map((h: SortableTableHeader) => {
        const value = getKeyValue(o, h.key, true);
        let label = value;
        if (h.key === 'time') {
          const v = value as UnknownObject;
          label = v.servedAt ? getDateSecondsDiff(v.createdAt, v.servedAt) : 0;
        }
        return {
          key: h.key,
          label,
          format: h.format,
          data: value,
          component: h.component ? h.component!(value) : undefined,
        };
      }),
    lazyLoad: async (row: SortableTableRow) => {
      const [orderId, orderType] = row.key.split('_');
      if (!row.collapsible || row.collapsible.length === 0) {
        const data = await getOrderDetailsQuery(orderId, orderType);
        if (data) {
          const dish_items: OrderItem[] = data.dish_items.map((i: OrderItemData) => ({
            id: i.id,
            name: i.item.name,
            time: {
              servedAt: i.served_at ? new Date(i.served_at) : null,
              createdAt: removeDST(new Date(i.created_at)),
            },
            gross: i.price,
            tax: i.tax,
          }));
          const drink_items: OrderItem[] = data.drink_items.map((i: OrderItemData) => ({
            id: i.id,
            name: i.item.name,
            time: {
              servedAt: i.served_at ? new Date(i.served_at) : null,
              createdAt: removeDST(new Date(i.created_at)),
            },
            gross: i.price,
            tax: i.tax,
          }));
          const payments: OrderPayment[] = data.payments.map((i: OrderPaymentData) => ({
            id: i.id,
            payment_method: i.payment_method,
            total: i.total,
            tip: i.tip,
          }));
          const gift_voucher_items: OrderGiftVoucher[] = data.gift_vouchers_purchased.map((i: OrderGiftVoucherData) => ({
            id: i.id,
            total: i.total,
            balance: i.balance,
          }));

          row.collapsible = [
            createOrerItemRow('Dish', dish_items),
            createOrerItemRow('Drink', drink_items),
            {
              title: pluralise('Gift voucher', gift_voucher_items.length),
              counter: gift_voucher_items.length,
              headers: giftVoucherHeaders,
              rows: gift_voucher_items.map((i) => ({
                key: i.id,
                columns: [
                  { key: 'name', label: '' },
                  { key: 'total', label: i.total, format: (v: SortableTableLabel) => formatMoney(v as number) },
                  { key: 'balance', label: i.balance, format: (v: SortableTableLabel) => formatMoney(v as number) },
                ],
              })),
              columnTotals: [
                ['total', (v: SortableTableLabel) => formatMoney(v as number)],
                ['balance', (v: SortableTableLabel) => formatMoney(v as number)],
              ] as ColumnTotals[],
            },
            {
              title: 'Payments',
              counter: payments.length,
              headers: paymentHeaders,
              rows: payments.map((i: OrderPayment) => ({
                key: i.id,
                columns: [
                  {
                    key: 'payment_method',
                    label: getPaymentMethodString(i.payment_method),
                  },
                  {
                    key: 'total',
                    label: i.total,
                    format: paymentHeaders[1].format,
                  },
                  {
                    key: 'tip',
                    label: i.tip,
                    format: paymentHeaders[2].format,
                  },
                ],
              })),
            },
          ].filter((i) => i.rows.length > 0);
        }
      }
    },
  }));

  const orderTypes = [
    { key: 'SERVED', label: 'Dine In', disabled: orders.every((o) => o.order_type !== 'dine in') },
    { key: 'QUICK_SALE', label: 'Quick sale', disabled: orders.every((o) => o.order_type !== 'quick sale') },
    { key: 'TAKEAWAY', label: 'Takeaway', disabled: orders.every((o) => o.order_type !== 'takeaway') },
  ];

  if (location.integrations.length > 0 && location.integrations[0].deliverect) {
    orderTypes.push({ key: 'DELIVERECT', label: 'Deliverect', disabled: orders.every((o) => o.order_type !== 'deliverect') });
  }

  const orderTypeFilter: SortableTableFilter = {
    key: 'order_type',
    label: 'Order type',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        let isGood = orderTypesFiltered.length === 0;
        if (orderTypesFiltered.includes('SERVED')) {
          const col = r.columns.find((i) => i.key === 'order_type' && i.label === 'dine in') ? true : false;
          isGood = isGood || col;
        }
        if (orderTypesFiltered.includes('QUICK_SALE')) {
          const col = r.columns.find((i) => i.key === 'order_type' && i.label === 'quick sale') ? true : false;
          isGood = isGood || col;
        }
        if (orderTypesFiltered.includes('TAKEAWAY')) {
          const col = r.columns.find((i) => i.key === 'order_type' && i.label === 'takeaway') ? true : false;
          isGood = isGood || col;
        }
        if (orderTypesFiltered.includes('DELIVERECT')) {
          const col = r.columns.find((i) => i.key === 'order_type' && i.label === 'deliverect') ? true : false;
          isGood = isGood || col;
        }
        return isGood;
      }),
    component: (
      <ToggleButtonGroup size="small" value={orderTypesFiltered} color="primary" onChange={(_: unknown, values: string[]) => setOrderTypesFiltered(values)}>
        {orderTypes.map((i) => (
          <ToggleButton className={classes.toggleButton} key={i.key} disabled={i.disabled} value={i.key}>
            {i.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    ),
  };

  const historyTypes = [
    { key: 'ONE', label: 'Today' },
    { key: 'WEEK', label: 'This week' },
    { key: 'MONTH', label: 'This Month' },
    { key: '3_MONTH', label: 'Last 3 Months' },
    { key: 'CUSTOM', label: 'Custom' },
  ];

  const historyFilter: SortableTableFilter = {
    key: 'history',
    label: 'History',
    filter: (rows: SortableTableRow[]) => rows,
    component: (
      <div className={classes.datePicker}>
        <ToggleButtonGroup size="small" value={historyFiltered} exclusive color="primary" onChange={(_: unknown, value: string | null) => setHistoryFiltered(value || 'ONE')}>
          {historyTypes.map((i) => (
            <ToggleButton className={classes.toggleButton} key={i.key} value={i.key}>
              {i.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {historyFiltered === 'CUSTOM' && (
          <div className={classes.customDatePickers}>
            <CustomDatePicker
              small
              label="Start date"
              date={customStartDate}
              allowPastDates
              error={customDateErrors.startDate as boolean}
              helperText={customDateErrors.startDate !== false ? (customDateErrors.startDate as string) : undefined}
              yearsFromNow={2021 - new Date().getFullYear()}
              handleDateChange={(date: Date) => setCustomStartDate(date)}
            />
            <CustomDatePicker
              small
              label="End date"
              date={customEndDate}
              allowPastDates
              error={customDateErrors.endDate as boolean}
              helperText={customDateErrors.endDate !== false ? (customDateErrors.endDate as string) : undefined}
              yearsFromNow={2021 - new Date().getFullYear()}
              handleDateChange={(date: Date) => setCustomEndDate(date)}
            />
          </div>
        )}
      </div>
    ),
  };

  const hiddenFilter: SortableTableFilter = {
    key: 'hidden',
    label: 'Hidden columns',
    filter: (rows: SortableTableRow[]) => rows,
    component: (
      <FormControl className={classes.formControl}>
        <Select
          multiple
          value={hiddenFiltered}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => setHiddenFiltered(event.target.value as string[])}
          input={<Input multiline />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} size="small" label={headers.find((h) => h.key === value)?.label || value} className={classes.chip} />
              ))}
            </div>
          )}>
          {headers.map((header) => (
            <MenuItem key={header.key} value={header.key}>
              {header.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
  };

  const filters = [orderTypeFilter, historyFilter, hiddenFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  const createCsvLines = (rows: SortableTableRow[]) => rows.map((r) => r.columns.map((c) => (c.format ? c.format(c.label, c.data) : c.label)));

  const handleDownloadCsv = (rows: SortableTableRow[]) => {
    const element = document.createElement('a');
    const csvLines = createCsvLines(rows);
    const file = new Blob(
      [
        headers
          .filter((h) => !h.hidden)
          .map((h) => h.key)
          .join(),
        '\n',
        csvLines.map((i) => i.join(',')).join('\n'),
      ],
      { type: 'text/csv;charset=utf-8' }
    );
    element.href = URL.createObjectURL(file);
    const dateFrom = formatDate(getStartDate(), 'YYYY_MM_DD');
    const dateTo = formatDate(new Date(), 'YYYY_MM_DD');
    element.download = `${location.name}-orders-export-${dateFrom}-${dateTo}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const actions: SortableTableAction[] = [{ key: 'export', label: 'Export to CSV', onClick: handleDownloadCsv, icon: <DownloadIcon /> }];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <CustomSortableTable
            loading={loading}
            loadingMessage="This may take a minute to load data for more than one month"
            title="Orders"
            searchable={['order_number', 'order_number', 'created_by_user', 'created_by_user']}
            counter="filtered"
            orderdBy="created_at"
            ordered="asc"
            filters={filters}
            headers={headers}
            rows={filterRows()}
            columnTotals={[
              ['covers', undefined, 'center'],
              ['discount', (v) => formatMoney(v as number)],
              ['refund', (v) => formatMoney(v as number)],
              ['service_charge', (v) => formatMoney(v as number)],
              ['net', (v) => formatMoney(v as number)],
              ['tax', (v) => formatMoney(v as number)],
              ['gross', (v) => formatMoney(v as number)],
              ['tip', (v) => formatMoney(v as number)],
              ['tipCard', (v) => formatMoney(v as number)],
              ['tipCash', (v) => formatMoney(v as number)],
              ['card', (v) => formatMoney(v as number)],
              ['cash', (v) => formatMoney(v as number)],
              ['voucher', (v) => formatMoney(v as number)],
            ]}
            footer={{
              actions,
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(LocationOrdersStep));
