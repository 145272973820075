/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { EmptyObject } from '../../react-app-env';
import { Sidebar, Topbar } from './components';
import { Breadcrum } from './components/Topbar';

import { Paper, withStyles } from '@material-ui/core';

import {
  HomeOutlined as HomeIcon,
  Home as HomeActiveIcon,
  HomeWorkOutlined as OrganisationIcon,
  HomeWork as OrganisationActiveIcon,
  StoreMallDirectoryOutlined as LocationIcon,
  StoreMallDirectory as LocationActiveIcon,
  GroupAddOutlined as UesrsIcon,
  GroupAdd as UesrsActiveIcon,
  MenuBookOutlined as MenuIcon,
  MenuBook as MenuActiveIcon,
  ListAltOutlined as ReportsActiveIcon,
  ListAlt as ReportsIcon,

  // CalendarTodayOutlined as BookingIcon,
  // CalendarToday as BookingActiveIcon,

  // LocalOfferOutlined as VoucherIcon,
  // LocalOffer as VoucherActiveIcon,
  ReorderOutlined as AccountsIcon,
  Reorder as AccountsActiveIcon,
  PlaylistAddCheckOutlined as ReferralsIcon,
  PlaylistAddCheck as ReferralsActiveIcon,
} from '@material-ui/icons';

import { AuthContext, EnumUserRole } from '../../components/AuthProvider';
import { Hierarchy } from './components/Sidebar/components/HierarchyList';
import { ListItemRoles } from './components/Sidebar/components/SidebarItemsList';
import { getAdminHierarchyQuery, getOrganisationHierarchyQuery } from './queries';

import styles from './styles';
import { AppContext } from '../../components/StateProvider';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>;
  title?: string;
  breadcrumbs?: Breadcrum[];
}

const Dashboard = ({ classes, title, breadcrumbs, children }: Props): React.ReactElement => {
  const [listItems, setListItems] = useState<any>({
    admin: {
      key: 'admin',
      title: 'Admin',
      items: [],
    },
    owner: {
      key: 'org',
      title: 'Organisation',
      items: [],
    },
    manager: {
      key: 'loc',
      title: 'Location',
      items: [
        { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/loc/home' },
        { label: 'Accounts', icon: <AccountsIcon />, iconActive: <AccountsActiveIcon />, to: '/loc/orders' },
        { label: 'Menus', icon: <MenuIcon />, iconActive: <MenuActiveIcon />, to: '/loc/menus' },
        { label: 'Reports', icon: <ReportsIcon />, iconActive: <ReportsActiveIcon />, to: '/loc/reports' },
        { label: 'Users', icon: <UesrsIcon />, iconActive: <UesrsActiveIcon />, to: '/loc/users' },
      ],
    },
  });
  const fetchAdminData = async () => {
    const organisations = await getAdminHierarchyQuery();

    // @ts-ignore
    const createTree = (items: any[], depth = 1) =>
      items.map((item) => ({
        ...item,
        style: { paddingLeft: `${depth * 24}px` },
        items: item.items ? createTree(item.items, depth + 1) : undefined,
      }));

    const adminItems = createTree(
      organisations.map((org) => ({
        label: org.name,
        to: `/admin/organisations/${org.id}`,
        items: [
          {
            label: 'Admin Users',
            to: `/admin/organisations/${org.id}/users`,
          },
          {
            label: 'Locations',
            to: `/admin/organisations/${org.id}/locations`,
            items: org.locations.map((location) => ({
              label: location.name,
              to: `/admin/organisations/${org.id}/locations/${location.id}`,
              items: [
                { label: 'Users', to: `/admin/organisations/${org.id}/locations/${location.id}/users` },
                { label: 'Staff', to: `/admin/organisations/${org.id}/locations/${location.id}/staff` },
                { label: 'Orders', to: `/admin/organisations/${org.id}/locations/${location.id}/orders` },
                { label: 'Integrations', to: `/admin/organisations/${org.id}/locations/${location.id}/integrations` },
                { label: 'Stock Management', to: `/admin/organisations/${org.id}/locations/${location.id}/stock` },
              ],
            })),
          },
          // {
          //   label: 'Reports',
          //   to: `/admin/organisations/${org.id}/reports`,
          // },
          {
            label: 'Menus',
            to: `/admin/organisations/${org.id}/menus`,
            items: org.menus.map((menu) => ({
              label: menu.name,
              to: `/admin/organisations/${org.id}/menus/${menu.id}`,
            })),
          },
        ],
      }))
    );

    return adminItems;
  };

  const updateAdminListItems = async () => {
    const adminItems = await fetchAdminData();

    const listItems = {
      admin: {
        key: 'admin',
        title: 'Admin',
        items: [
          { label: 'Home', icon: <HomeIcon />, to: '/admin/home' },
          {
            label: 'Organisations',
            icon: <OrganisationIcon />,
            to: '/admin/organisations',
            items: adminItems,
          },
          { label: 'Users', icon: <UesrsIcon />, to: '/admin/users' },
          { label: 'Referrals', icon: <ReferralsIcon />, to: '/admin/referrals' },
          { label: 'Device Apps', icon: <ReferralsIcon />, to: '/admin/organisations/device-apps' },
        ],
      },
    };

    return listItems;
  };
  const updateOwnerListItems = async () => {
    const ownerItems = await fetchOwnerData();

    const listItems = {
      owner: {
        key: 'org',
        title: 'Organisation',
        items: [{ label: 'Home', icon: <HomeIcon />, to: '/org/home' }, ...ownerItems],
      },
    };

    return listItems;
  };

  const fetchOwnerData = async () => {
    const data = await getOrganisationHierarchyQuery();
    // @ts-ignore
    const createTree = (items: any[], depth = 1) =>
      items.map((item) => ({
        ...item,
        style: { paddingLeft: `${depth * 24}px` },
        items: item.items ? createTree(item.items, depth + 1) : undefined,
      }));

    const ownerItems = createTree(
      [
        {
          label: 'Locations',
          icon: <LocationIcon />,
          to: '/org/locations',
          items: data.locations.map((loc) => ({
            label: loc.name,
            to: `/org/locations/${loc.id}`,
            items: [
              { label: 'Orders', to: `/org/locations/${loc.id}/orders` },
              { label: 'Users', to: `/org/locations/${loc.id}/users` },
              {
                label: 'Stock Management',
                to: `/org/locations/${loc.id}/stock`,
              },
            ],
          })),
        },
        {
          label: 'Menus',
          icon: <MenuIcon />,
          to: '/org/menus',
          items: data.menus.map((menu) => ({
            label: menu.name,
            to: `/org/menus/${menu.id}`,
          })),
        },
        {
          label: 'Reports',
          icon: <ReportsIcon />,
          to: '/org/reports',
        },
        {
          label: 'Users',
          icon: <UesrsIcon />,
          to: '/org/users',
        },
      ],
      1
    );

    return ownerItems;
  };

  const { user, userRole, signOut } = useContext(AuthContext)!;
  const { userRole: appRole, systemId } = useContext(AppContext)!;

  useEffect(() => {
    const initializeOwnerListItems = async () => {
      const updatedListItems = await updateOwnerListItems();
      // @ts-ignore
      setListItems((prev) => ({ ...prev, ...updatedListItems }));
    };
    const initializeListItems = async () => {
      const updatedListItems = await updateAdminListItems();
      // @ts-ignore
      setListItems((prev) => ({ ...prev, ...updatedListItems }));
    };

    if (userRole === 'admin') {
      initializeListItems();
    }

    if (appRole === 'owner') {
      initializeOwnerListItems();
    }
  }, [appRole, systemId]);

  const [hierarchy, setHierarchy] = useState<Hierarchy | undefined>();

  const getHierarchy = useCallback(async () => {
    switch (userRole) {
      case EnumUserRole.ADMIN:
        const orgs = await getAdminHierarchyQuery();
        setHierarchy({
          routes: [
            { key: 'owner', label: 'Organisation', home: '/org/home' },
            { key: 'manager', label: 'Location', home: '/loc/home' },
          ],
          items: orgs.map((o) => ({
            key: o.id,
            label: o.name,
            items: o.locations.map((l) => ({
              key: l.id,
              label: l.name,
            })),
          })),
        });
        break;
      case EnumUserRole.OWNER:
        const data = await getOrganisationHierarchyQuery();
        setHierarchy({
          routes: [{ key: 'manager', label: 'Location', home: '/loc/home' }],
          items: data.locations.map((o) => ({
            key: o.id,
            label: o.name,
          })),
        });
        break;
      default:
        break;
    }
  }, [userRole]);

  useEffect(() => {
    let mounted = true;
    if (mounted && userRole) {
      getHierarchy();
    }
    return () => {
      mounted = false;
    };
  }, [userRole, getHierarchy]);

  return (
    <>
      <div className={classes.root}>
        <Sidebar userRole={userRole as string} signOut={signOut} listItemRoles={listItems} hierarchy={hierarchy} />
        <Paper elevation={0} className={classes.main}>
          <Topbar title={title} user={{ ...user }} breadcrumbs={breadcrumbs} />
          <main className={classes.content}>
            <div className={classes.contentContainer}>{children}</div>
          </main>
        </Paper>
      </div>
    </>
  );
};

export default withStyles(styles)(Dashboard);
