import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    modifiers: {
      marginBottom: theme.spacing(2),
    },
  });

export default styles;
