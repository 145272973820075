import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { TextField, withStyles } from '@material-ui/core';

import { ValidationErrors, SelfOnboardOrganisationReducerAction, validation } from '../..';

import styles from './styles';
import { SelfOnboardOrganisation } from '../../../../../../components/Global/Organisations';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisation: SelfOnboardOrganisation;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: SelfOnboardOrganisationReducerAction; value: any }>;
}

const StepLocation = ({ classes, organisation, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'locationName') {
        errorItems.locationName = validation.locationName(organisation.location.name);
      }
      if (field === 'locationEmail') {
        errorItems.locationEmail = validation.locationEmail(organisation.location.email);
      }
      if (field === 'locationPhone') {
        errorItems.locationPhone = validation.locationPhone(organisation.location.phone);
      }
      if (field === 'locationAddressLine1') {
        errorItems.locationAddressLine1 = validation.locationAddressLine1(organisation.location.addressLine1);
      }
      if (field === 'locationCity') {
        errorItems.locationCity = validation.locationCity(organisation.location.city);
      }
      if (field === 'locationCountry') {
        errorItems.locationCountry = validation.locationCountry(organisation.location.country);
      }
      if (field === 'locationPostcode') {
        errorItems.locationPostcode = validation.locationPostcode(organisation.location.postcode);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [organisation, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="location-name-textfield"
          required
          label="Location Name"
          variant="outlined"
          error={errors.locationName as boolean}
          helperText={errors.locationName}
          value={organisation.location.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SelfOnboardOrganisationReducerAction.SET_LOCATION_NAME, value: event.target.value })}
          onKeyUp={() => validate('locationName')}
          onBlur={() => validate('locationName')}
        />
        <TextField
          className={classes.textField}
          data-qa="location-email-textfield"
          required
          label="Contact email address"
          variant="outlined"
          error={errors.locationEmail as boolean}
          helperText={errors.locationEmail}
          value={organisation.location.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SelfOnboardOrganisationReducerAction.SET_LOCATION_EMAIL, value: event.target.value })}
          onKeyUp={() => validate('locationEmail')}
          onBlur={() => validate('locationEmail')}
        />
        <TextField
          className={classes.textField}
          data-qa="location-phone-textfield"
          required
          label="Contact phone number"
          variant="outlined"
          error={errors.locationPhone as boolean}
          helperText={errors.locationPhone}
          value={organisation.location.phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SelfOnboardOrganisationReducerAction.SET_LOCATION_PHONE, value: event.target.value })}
          onKeyUp={() => validate('locationPhone')}
          onBlur={() => validate('locationPhone')}
        />
        <TextField
          className={classes.textField}
          data-qa="location-address-line-1-textfield"
          required
          label="Address line 1"
          variant="outlined"
          error={errors.locationAddressLine1 as boolean}
          helperText={errors.locationAddressLine1}
          value={organisation.location.addressLine1}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SelfOnboardOrganisationReducerAction.SET_LOCATION_ADDRESS, value: event.target.value })}
          onKeyUp={() => validate('locationAddressLine1')}
          onBlur={() => validate('locationAddressLine1')}
        />
        <TextField
          className={classes.textField}
          data-qa="location-city-textfield"
          required
          label="City"
          variant="outlined"
          error={errors.locationCity as boolean}
          helperText={errors.locationCity}
          value={organisation.location.city}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SelfOnboardOrganisationReducerAction.SET_LOCATION_CITY, value: event.target.value })}
          onKeyUp={() => validate('locationCity')}
          onBlur={() => validate('locationCity')}
        />
        <TextField
          className={classes.textField}
          data-qa="location-country-textfield"
          required
          label="Country"
          variant="outlined"
          error={errors.locationCountry as boolean}
          helperText={errors.locationCountry}
          value={organisation.location.country}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SelfOnboardOrganisationReducerAction.SET_LOCATION_COUNTRY, value: event.target.value })}
          onKeyUp={() => validate('locationCountry')}
          onBlur={() => validate('locationCountry')}
        />
        <TextField
          className={classes.textField}
          data-qa="location-postcode-textfield"
          required
          label="Postcode"
          variant="outlined"
          error={errors.locationPostcode as boolean}
          helperText={errors.locationPostcode}
          value={organisation.location.postcode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: SelfOnboardOrganisationReducerAction.SET_LOCATION_POSTCODE, value: event.target.value })}
          onKeyUp={() => validate('locationPostcode')}
          onBlur={() => validate('locationPostcode')}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepLocation);
