import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    title: {
      fontWeight: 700,
      padding: theme.spacing(2),
      paddingBottom: 0,
      textAlign: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      // padding: theme.spacing(3),
      paddingBottom: 0,
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 0 auto',
      width: theme.spacing(56),
    },
    content: {
      padding: theme.spacing(3),
    },
    logo: {
      width: theme.spacing(30),
      // marginBottom: theme.spacing(4),
      alignSelf: 'center',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    stepper: {
      padding: 0,
      marginBottom: theme.spacing(4),
    },
    passwordDetail: {
      marginBottom: theme.spacing(2),
    },
  });

export default styles;
