import React, { useCallback, useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomStepper from '../../../../../../components/CustomStepper';
import { Organisation, validationErrors, ValidationErrors, OrganisationReducerAction, validation } from '../../../Common';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';
import { createOrganisationInitQuery, EnumOrganisationType } from './queries';
import { useQuery } from '@apollo/client';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  organisation: Organisation;
  dispatch: React.Dispatch<{ type: OrganisationReducerAction; value: any }>;
  handleSave: () => void;
}

const OrganisationSteps = ({ classes, completeLabel, organisation, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    email: false,
    phone: false,
    organisation_type: false,
    currency: false,
    locale: false,
    tax_rate: false,
  });

  const [organisationTypes, setOrganisationTypes] = useState<EnumOrganisationType[]>([]);

  const { data: createOrganisationInitData } = useQuery(createOrganisationInitQuery(), { fetchPolicy: 'cache-first' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createOrganisationInitData) {
      setOrganisationTypes(createOrganisationInitData.enum_organisation_types);
    }
    return () => {
      mounted = false;
    };
  }, [createOrganisationInitData]);

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = validation.name(organisation.name);
        errorItems.email = validation.email(organisation.email);
        errorItems.phone = validation.phone(organisation.phone);
        errorItems.organisation_type = validation.organisation_type(organisation.organisation_type);
        errorItems.currency = validation.currency(organisation.currency);
        errorItems.locale = validation.locale(organisation.locale);
        errorItems.tax_rate = validation.tax_rate(organisation.tax_rate);
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [organisation, errors]
  );

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            detail: organisation.name,
            component: <StepDetails organisation={organisation} organisationTypes={organisationTypes} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview organisation={organisation} organisationTypes={organisationTypes} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationSteps);
