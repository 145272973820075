import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Grid, withStyles } from '@material-ui/core';

import { AddCircle as AddCircleIcon } from '@material-ui/icons';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import styles from './styles';
import CustomSortableTable, { SortableTableAction, SortableTableFilter, SortableTableHeader, SortableTableLabel, SortableTableRow } from '../../../CustomSortableTable';
import { CodeVoucherType, getVoucherValueString } from '../Common';
import { booleanToYesNo } from '../../../../utils/stringUtils';
import { formatDate } from '../../../../utils/dateUtils';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  vouchers: VoucherCode[];
  locale: string;
  currency: string;
  handleCreate: () => void;
  handleEdit: (id: string) => void;
}

export interface VoucherCode {
  id: string;
  name: string;
  code: string;
  value: number;
  voucher_type: string;
  single_use: boolean;
  can_expire: boolean;
  times_used: number;
  start_date: string;
  end_date: string;
  category_ids: string[];
  created_at: string;
}

const ListVouchers = ({ classes, history, vouchers, currency, locale, handleCreate, handleEdit }: Props): React.ReactElement => {
  const [validExpiredFiltered, setValidExpiredFiltered] = useState<string[]>([]);

  const isValidCode = (code: VoucherCode) => {
    const today = new Date();
    const startDate = new Date(code.start_date);
    if (code.can_expire) {
      const endDate = new Date(code.end_date);
      if ((today < startDate || startDate < today) && today > endDate) return false;
    }
    if (code.single_use && code.times_used > 0) return false;
    return true;
  };

  const headers: SortableTableHeader[] = [
    { key: 'created_at', label: 'Created at', hidden: true },
    { key: 'name', label: 'Name' },
    { key: 'code', label: 'Code' },
    { key: 'value', label: 'Value' },
    { key: 'single_use', label: 'Single use' },
    { key: 'can_expire', label: 'Can expire' },
    { key: 'start_date', label: 'Start date' },
    { key: 'end_date', label: 'End date' },
    { key: 'categories', label: 'Categories', align: 'center' },
    { key: 'times_used', label: 'Times used', align: 'center' },
  ];

  const actions: SortableTableAction[] = [
    {
      key: 'create',
      label: 'Create voucher code',
      icon: <AddCircleIcon />,
      onClick: handleCreate,
    },
  ];

  const rows: SortableTableRow[] = vouchers.map((item: VoucherCode) => ({
    key: item.id,
    disabled: !isValidCode(item),
    actions: [
      {
        label: 'Edit',
        onClick: () => handleEdit(item.id),
      },
    ],
    columns: [
      {
        key: 'created_at',
        label: item.created_at,
      },
      {
        key: 'name',
        label: item.name,
      },
      {
        key: 'code',
        label: item.code,
      },
      {
        key: 'value',
        label: item.value,
        format: (v) => getVoucherValueString(v as number, item.voucher_type as CodeVoucherType, locale, currency) as SortableTableLabel,
      },
      {
        key: 'single_use',
        label: item.single_use,
        format: (v) => booleanToYesNo(v as boolean) as SortableTableLabel,
      },
      {
        key: 'can_expire',
        label: item.can_expire,
        format: (v) => booleanToYesNo(v as boolean) as SortableTableLabel,
      },
      {
        key: 'start_date',
        label: item.start_date,
        format: (v) => formatDate(new Date(v as string), 'DD/MM/YYYY') as SortableTableLabel,
      },
      {
        key: 'end_date',
        label: item.end_date,
        format: (v) => (v ? formatDate(new Date(v as string), 'DD/MM/YYYY') : '') as SortableTableLabel,
      },
      {
        key: 'categories',
        label: item.category_ids.length,
      },
      {
        key: 'times_used',
        label: item.times_used,
      },
    ],
  }));

  const validExpiredTypes = [
    { key: 'VALID', label: 'Valid', disabled: rows.every((o) => o.disabled) },
    { key: 'EXPIRED', label: 'Expired', disabled: rows.every((o) => !o.disabled) },
    { key: 'USED', label: 'Used' },
  ];

  const validExpiredFilter: SortableTableFilter = {
    key: 'valid_expired',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        // let isGood = validExpiredFiltered.length === 0;
        let valid = true;
        let expired = true;
        let used = true;
        if (validExpiredFiltered.includes('VALID')) {
          valid = !r.disabled as boolean;
        }
        if (validExpiredFiltered.includes('EXPIRED')) {
          expired = r.disabled as boolean;
        }
        if (validExpiredFiltered.includes('USED')) {
          used = r.columns.filter((i) => i.key === 'times_used' && i.label > 0).length > 0;
        }
        return valid && expired && used;
      }),
    component: (
      <ToggleButtonGroup size="small" value={validExpiredFiltered} color="primary" onChange={(_: unknown, values: string[]) => setValidExpiredFiltered(values)}>
        {validExpiredTypes.map((i) => (
          <ToggleButton className={classes.toggleButton} key={i.key} disabled={i.disabled} value={i.key}>
            {i.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    ),
  };

  const filters = [validExpiredFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item xs={12}>
        <CustomSortableTable
          title="Voucher codes"
          orderdBy="created_at"
          ordered="desc"
          searchable={['code']}
          actions={actions}
          headers={headers}
          filters={filters}
          counter={vouchers.length}
          rows={filterRows()}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ListVouchers));
