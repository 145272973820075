import { params, query, rawString, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _createLocationInitQuery = (organisationId: string | undefined) => ({
  enum_location_types: [
    {
      location_type: types.string,
      description: types.string,
    },
  ],
  enum_inventory_restock_types: [
    {
      inventory_restock_type: types.string,
      description: types.string,
    },
  ],
  menus: params(
    {
      offset: 0,
      ...(organisationId ? { where: { organisation_id: { _eq: rawString(organisationId) } } } : {}),
    },
    [
      {
        id: types.string,
        name: types.string,
        description: types.string,
        is_primary: types.boolean,
      },
    ]
  ),
});

export const createLocationInitQuery = (organisationId: string | undefined) => gql`
  ${query(_createLocationInitQuery(organisationId))}
`;
