import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import ListLocationPrinters from '../../../../../components/Global/LocationPrinters/List';

import { selectLocationUsersInitQuery, Location } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  location_id: string;
}

const OrgListLocationStaff = ({ classes, history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    devices: [],
  });

  const { data: locationUsersInitQuery } = useQuery(selectLocationUsersInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUsersInitQuery) {
      setLocation(locationUsersInitQuery.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationUsersInitQuery]);

  const handleCreate = () => {
    history.push(`/locations/${location_id}/printers/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/locations/${location_id}/printers/${id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Locations', link: `/locations` }, { label: location.name, link: `/locations/${location_id}` }, { label: 'Printers' }]}>
      <ListLocationPrinters printers={location.devices} handleCreate={handleCreate} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(OrgListLocationStaff));
