import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationStaffInitQuery = (location_id: string) => ({
  locations_by_pk: params(
    { id: rawString(location_id) },
    {
      users: params(
        {
          where: {
            staff: { _eq: true },
          },
        },
        [
          {
            id: types.string,
            full_name: types.string,
            pin: types.string,
          },
        ]
      ),
    }
  ),
});

type X = Unpacked<typeof _selectLocationStaffInitQuery>;
export type Location = X['locations_by_pk'];
export type LocationStaff = Location['users'][0];

export const selectLocationStaffInitQuery = (location_id: string) => gql`
  ${query(_selectLocationStaffInitQuery(location_id))}
`;
