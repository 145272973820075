import React, { useState, useCallback, useReducer, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Stepper, Step, StepLabel, Typography, withStyles, Grid, useTheme, useMediaQuery } from '@material-ui/core';

import { Logo } from '../../../components/Logos';
import { DojoLogo, ZettleLogo } from '../../../components/Logos/Partners';

import { referralReducer, ReferralReducerAction, ValidationErrors } from './Common';
import { StepStart, StepOrganisation, StepLocations, StepNotes, StepSummary, StepEnd } from './Common/components';

import { createReferralMutation } from './mutations';

import styles from './styles';
import { capitaliseFirst } from '../../../utils/stringUtils';
import CustomBackdrop from '../../../components/CustomBackdrop';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  referral_name: string;
}

const Referral = ({ classes, history }: Props): React.ReactElement => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

  const { referral_name } = useParams<UrlParams>();
  const [activeStep, setActiveStep] = useState(-1);

  const [referral, dispatch] = useReducer(referralReducer, {
    referral: referral_name,
    organisation: {
      name: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      singleLocation: true,
    },
    locations: [],
    note: '',
  });

  const [errors, setErrors] = useState<ValidationErrors>({
    organisation: {
      name: false,
      contact_name: false,
      contact_phone: false,
      contact_email: false,
    },
    location: {
      name: false,
      contact_name: false,
      contact_phone: false,
      contact_email: false,
      address_line_1: false,
      address_city: false,
      address_postcode: false,
    },
  });

  const [steps] = useState(['Organisation', 'Locations', 'Notes', 'Summary']);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({ type: ReferralReducerAction.ADD_LOCATION, value: {} });
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep((i) => i + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((i) => i - 1);
  }, []);

  const createReferral = useCallback(async () => {
    setSaving(true);
    const referralVariables = {
      objects: [referral],
    };
    await createReferralMutation(referralVariables);

    setSaving(false);
    setActiveStep(4);
  }, [referral]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <StepOrganisation referral={referral} errors={errors} dispatch={dispatch} setErrors={setErrors} handleNext={handleNext} handleBack={handleBack} />;
      case 1:
        return <StepLocations referral={referral} errors={errors} dispatch={dispatch} setErrors={setErrors} handleNext={handleNext} handleBack={handleBack} />;
      case 2:
        return <StepNotes referral={referral} dispatch={dispatch} handleNext={handleNext} handleBack={handleBack} />;
      case 3:
        return <StepSummary referral={referral} setStep={setActiveStep} submit={createReferral} handleBack={handleBack} />;
      default:
        return 'Unknown step';
    }
  };

  const getContent = () => {
    if (activeStep === -1) {
      return <StepStart handleNext={handleNext} />;
    }
    if (activeStep === 4) {
      return <StepEnd />;
    }
    return (
      <>
        {isMobile && <div className={classes.content}>{getStepContent(activeStep)}</div>}
        {!isMobile && (
          <Grid container spacing={4}>
            {activeStep >= 0 && (
              <Grid item xs={2}>
                <div className={classes.stepperContainer}>
                  <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </Grid>
            )}
            <Grid item xs={10}>
              <div className={classes.content}>{getStepContent(activeStep)}</div>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const knownPartners: string[] = ['dojo', 'zettle'];

  return (
    <>
      <div className={`${classes.root} ${isMobile && activeStep === 1 && !referral.organisation.singleLocation ? classes.rootNoHeight : ''}`}>
        <div className={classes.modal}>
          <div className={classes.logos}>
            <div className={classes.aposLogo}>
              <Logo />
            </div>
            {referral_name === 'zettle' && (
              <div className={classes.partnerLogo}>
                <ZettleLogo />
              </div>
            )}
            {referral_name === 'dojo' && (
              <div className={classes.partnerLogo}>
                <DojoLogo />
              </div>
            )}
          </div>
          <Typography className={classes.title} variant="h6">
            {!knownPartners.includes(referral_name) ? capitaliseFirst(referral_name) : ''} Referral
          </Typography>
          {getContent()}
        </div>
      </div>
      {saving && <CustomBackdrop label="Sending Referral" />}
    </>
  );
};

export default withRouter(withStyles(styles)(Referral));
