import React, { useState, useReducer, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';

import CustomBackdrop from '../../../../../components/CustomBackdrop';
import { organisationUserReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createOrganisationUserInitQuery, Organisation } from './queries';
import { createOrganisationUser } from './mutations';
import styles from './styles';
import { useQuery } from '@apollo/client';

interface UrlParams {
  organisation_id: string;
}
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateOrganisationUser = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
  });

  const [user, dispatchUser] = useReducer(organisationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: organisationUserInitData } = useQuery(createOrganisationUserInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitData) {
      setOrganisation(organisationUserInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitData]);

  const handleSave = async () => {
    setSaving(true);

    const createVariables = {
      object: {
        organisation_id,
        ...user,
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
        email: user.email.trim(),
      },
    };
    const user_id: string | undefined = await createOrganisationUser(createVariables);
    if (user_id) {
      setSaving(false);
      handleFinish(user_id);
    }
  };

  const handleFinish = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/users/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Users', link: `/admin/organisations/${organisation_id}/users` },
          { label: 'Create' },
        ]}>
        <Steps completeLabel="Create" user={user} dispatch={dispatchUser} handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateOrganisationUser));
