import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationUsersInitQuery = () => ({
  locations: {
    id: types.string,
    name: types.string,
  },
});

type X = Unpacked<typeof _selectLocationUsersInitQuery>;
export type Location = X['locations'];

export const selectLocationUsersInitQuery = () => gql`
  ${query(_selectLocationUsersInitQuery())}
`;
