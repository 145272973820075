import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 60,
      marginBottom: theme.spacing(1),
    },
    headerTitle: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    counter: {
      marginRight: theme.spacing(2),
    },
    caption: {
      marginLeft: theme.spacing(2),
    },
    toggleButton: {},
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    search: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.75),
    },
    searchInput: {
      width: '100%',
    },
    searchButton: {
      cursor: 'pointer',
      width: 24,
      height: 24,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  });

export default styles;
