import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid, withStyles } from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import GridCard from '../../../components/Grid/GridCard';

import styles from './styles';
import { homeInitQuery } from './queries';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface CardCounter {
  locations: number;
  menus: number;
  users: number;
}

const Home = ({ classes, history }: Props): React.ReactElement => {
  const [counter, setCounter] = useState<CardCounter>({
    locations: 0,
    menus: 0,
    users: 0,
  });

  const { data: homeInitData } = useQuery(homeInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && homeInitData) {
      setCounter({
        locations: homeInitData.locations_aggregate.aggregate.count,
        menus: homeInitData.menus_aggregate.aggregate.count,
        users: homeInitData.users_organisation_aggregate.aggregate.count,
      });
    }
    return () => {
      mounted = false;
    };
  }, [homeInitData]);

  const handleAction = (link: string) => {
    history.push(link);
  };

  const actions = [
    {
      title: 'Locations',
      counter: counter.locations,
      createLink: '/org/locations/create',
      viewLink: '/org/locations',
    },
    {
      title: 'Menus',
      counter: counter.menus,
      createLink: '/org/menus/create',
      viewLink: '/org/menus',
    },
    {
      title: 'Reports',
      viewLink: '/org/reports',
    },
    {
      title: 'Admin Users',
      counter: counter.users,
      createLink: '/org/users/create',
      viewLink: '/org/users',
    },
  ];

  return (
    <Dashboard title="Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            fill
            key={action.title}
            gridSize={4}
            title={action.title}
            counter={action.counter}
            editLabel="Create"
            handleEdit={action.createLink ? () => handleAction(action.createLink) : undefined}
            handleView={() => handleAction(action.viewLink)}
          />
        ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(Home));
