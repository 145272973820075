import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomStepper from '../../../../../../components/CustomStepperNew';
import {
  InventoryRestockType,
  Location,
  LocationMenu,
  LocationReducerAction,
  LocationType,
  validation,
  validationErrors,
  ValidationErrors,
  validationIntegration,
  validationIntegrationErrors,
  ValidationIntegrationErrors,
} from '../..';

import styles from './styles';
import General from '../StepGeneral';
import Operations from '../StepOperations';
import Pacing from '../StepPacing';
import OpeningHours from '../StepOpeningHours';
import PreorderHours from '../StepPreorderHours';
import Branding from '../StepBranding';
import ConfigurationOptions from '../StepConfiguration';
import IntegrationSettings from '../StepIntegrations';
import { LocationIntegration, LocationIntegrationReducerAction, PaymentProvider } from '../../../../LocationIntegrations/Common';
import LocationOrdersStep from '../StepOrders';
import StockComponent from '../StepStockManagment';
import ListLocationUsers from '../../../../LocationUsers/List';
import ListLocationPrinters from '../../../../LocationPrinters/List';
import ListLocationStaff from '../../../../LocationStaff/List';
import { ListVouchers } from '../../../../Vouchers';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  location: Location;
  locationId: string | undefined;
  organisationId: string | undefined;
  locationTypes: LocationType[];
  locationMenus: LocationMenu[];
  inventoryRestockTypes: InventoryRestockType[];
  dispatch: React.Dispatch<{ type: LocationReducerAction; value: any }>;
  handleSave: () => void;
  handleSaveIntegration?: () => void;
  setBreadcrumbs: any;
  step: number;
  setStep: any;
  integration?: LocationIntegration;
  dispatchIntegration?: React.Dispatch<{ type: LocationIntegrationReducerAction; value: any }>;
  handleCreateUser?: any;
  handleViewUser?: any;
  handleEditUser?: any;
  handleCreatePrinter?: any;
  handleEditPrinter?: any;
  printers?: any;
  handleCreateStaff?: any;
  handleViewStaff?: any;
  handleEditStaff?: any;
  handleCreateVoucher?: any;
  handleEditVoucher?: any;
  currency?: any;
  locale?: any;
  voucher_codes?: any;
  isLocationCreate?: boolean;
}

enum InventoryRestockEnum {
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

const OrganisationSteps = ({
  classes,
  completeLabel,
  location,
  locationId,
  organisationId,
  locationTypes,
  inventoryRestockTypes,
  locationMenus,
  dispatch,
  handleSave,
  setBreadcrumbs,
  step,
  setStep,
  integration,
  dispatchIntegration,
  handleSaveIntegration,
  handleCreateUser,
  handleViewUser,
  handleEditUser,
  handleCreatePrinter,
  handleEditPrinter,
  printers,
  handleCreateStaff,
  handleViewStaff,
  handleEditStaff,
  handleCreateVoucher,
  handleEditVoucher,
  currency,
  locale,
  voucher_codes,
  isLocationCreate = false,
}: Props): React.ReactElement => {
  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    email: false,
    email_invalid: false,
    number_of_tables: false,
    phone: false,
    location_type: false,
    address_line_1: false,
    tax_number: false,
    tax_rate: false,
    currency: false,
    locale: false,
    address_city: false,
    address_country: false,
    address_postcode: false,
    service_charge_order_types: false,
    service_charge_percentage: false,
    service_charge_percentage_too_small: false,
    service_charge_percentage_too_large: false,
    stock_type: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = validation.name(location.name);
        errorItems.location_type = validation.location_type(location.location_type);
        errorItems.email = validation.email(location.email);
        errorItems.phone = validation.phone(location.phone);
      }
      if (_step === 1) {
        errorItems.address_line_1 = validation.address_line_1(location.address_line_1);
        errorItems.address_city = validation.address_city(location.address_city);
        errorItems.address_country = validation.address_country(location.address_country);
        errorItems.address_postcode = validation.address_postcode(location.address_postcode);
      }
      if (_step === 2) {
        errorItems.tax_number = validation.tax_number(location.tax_number);
        errorItems.currency = validation.currency(location.currency);
        errorItems.locale = validation.locale(location.locale);
        errorItems.number_of_tables = validation.number_of_tables(location.number_of_tables);
      }
      if (_step === 3) {
        if (location.service_charge != null) {
          errorItems.service_charge_order_types = validation.service_charge_order_types(location.service_charge!.order_types);
          errorItems.service_charge_percentage = validation.service_charge_percentage(location.service_charge!.percentage);
        }
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [location, errors]
  );

  const [validationStatus, setValidationStatus] = useState<{ [key: number]: boolean }>({});

  const validateCreate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      let isValid = true;

      if (_step === 1) {
        errorItems.name = validation.name(location.name);
        errorItems.location_type = validation.location_type(location.location_type);
        errorItems.email = validation.email(location.email);
        errorItems.phone = validation.phone(location.phone);
        errorItems.address_line_1 = validation.address_line_1(location.address_line_1);
        errorItems.address_city = validation.address_city(location.address_city);
        errorItems.address_country = validation.address_country(location.address_country);
        errorItems.address_postcode = validation.address_postcode(location.address_postcode);
        errorItems.locale = validation.locale(location.locale);
        errorItems.stock_type = validation.stock_type(location.inventory_restock_type);
      }

      if (_step === 4) {
        errorItems.tax_number = validation.tax_number(location.tax_number);
        errorItems.currency = validation.currency(location.currency);
        errorItems.number_of_tables = validation.number_of_tables(location.number_of_tables);
        if (location.service_charge != null) {
          errorItems.service_charge_order_types = validation.service_charge_order_types(location.service_charge!.order_types);
          errorItems.service_charge_percentage = validation.service_charge_percentage(location.service_charge!.percentage);
        }
      }

      setErrors((prevErrors) => ({ ...prevErrors, ...errorItems }));

      isValid = !(Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);

      setValidationStatus((prevStatus) => ({ ...prevStatus, [_step]: isValid }));
      return !isValid;
    },
    [location]
  );

  const allStepsValid = Object.values(validationStatus).every((status) => status);

  const [errorsIntegration, setErrorsIntegration] = useState<ValidationIntegrationErrors>({
    deliverectAccountId: false,
    deliverectLocationId: false,
    paymentProvider: false,
    paymentAccountId: false,
    paymentUsername: false,
    paymentPassword: false,
    xeroCode: false,
  });

  const validateIntegration = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        if (integration!.payment.active) {
          errorItems.paymentProvider = validationIntegration.paymentProvider(integration!.payment.provider);
          if ([PaymentProvider.DOJO, PaymentProvider.ZETTLE].includes(integration!.payment.provider as PaymentProvider)) {
            errorItems.paymentAccountId = validationIntegration.paymentAccountId(integration!.payment.accountId);
          }
          if ([PaymentProvider.DOJO].includes(integration!.payment.provider as PaymentProvider)) {
            errorItems.paymentUsername = validationIntegration.paymentUsername(integration!.payment.username);
            errorItems.paymentPassword = validationIntegration.paymentPassword(integration!.payment.password);
          }
        } else {
          errorItems.paymentProvider = 'You must provide a payment provider';
        }
      }
      if (_step === 1) {
        if (integration!.deliverect.active) {
          errorItems.deliverectAccountId = validationIntegration.deliverectAccountId(integration!.deliverect.accountId);
          errorItems.deliverectLocationId = validationIntegration.deliverectLocationId(integration!.deliverect.locationId);
        }
      }
      setErrorsIntegration({ ...errorsIntegration, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationIntegrationErrors>).some((i) => errorItems[i]);
    },
    [integration, errorsIntegration]
  );
  useEffect(() => {
    if (isLocationCreate) {
      for (let i = 0; i <= 6; i++) {
        validateCreate(i);
      }
    }
    // eslint-disable-next-line
  }, [step, isLocationCreate]);
  return (
    <>
      {/* @ts-ignore */}
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        gridSize={10}
        progressGridSize={2}
        handleComplete={handleSave}
        showProgress={false}>
        {[
          {
            label: 'Configuration',
            component: (
              <ConfigurationOptions
                isLocationCreate={isLocationCreate}
                setStep={setStep}
                setBreadcrumbs={setBreadcrumbs}
                location={location}
                locationId={locationId}
                organisationId={organisationId}
                validationStatus={validationStatus}
                allStepsValid={allStepsValid}
                handleSave={handleSave}
              />
            ),
          },
          {
            label: 'General',
            component: (
              <General
                location={location}
                locationMenus={locationMenus}
                inventoryRestockTypes={inventoryRestockTypes}
                locationTypes={locationTypes}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch}
                handleSave={handleSave}
                isLocationCreate={isLocationCreate}
              />
            ),
          },
          {
            label: 'Branding',
            component: <Branding location={location} dispatch={dispatch} locationId={locationId} handleSave={handleSave} isLocationCreate={isLocationCreate} />,
          },
          {
            label: 'OpeningHours',
            component: <OpeningHours location={location} dispatch={dispatch} handleSave={handleSave} isLocationCreate={isLocationCreate} />,
          },
          {
            label: 'Operations',
            component: (
              <Operations
                location={location}
                locationMenus={locationMenus}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch}
                handleSave={handleSave}
                isLocationCreate={isLocationCreate}
              />
            ),
          },
          {
            label: 'PreorderHours',
            component: <PreorderHours location={location} dispatch={dispatch} handleSave={handleSave} isLocationCreate={isLocationCreate} />,
          },
          {
            label: 'Pacing',
            component: <Pacing location={location} errors={errors} setErrors={setErrors} dispatch={dispatch} handleSave={handleSave} isLocationCreate={isLocationCreate} />,
          },
          ...(!isLocationCreate
            ? [
                {
                  label: 'Integration',
                  component: (
                    <IntegrationSettings
                      location_id={locationId!}
                      organisation_id={organisationId!}
                      vouchersActive={integration!.vouchers}
                      payment={integration!.payment}
                      xero={integration!.xero}
                      deliverect={integration!.deliverect}
                      errors={errorsIntegration}
                      setErrors={setErrorsIntegration}
                      dispatch={dispatchIntegration!}
                      handleSave={handleSaveIntegration}
                      validateIntegration={validateIntegration}
                    />
                  ),
                },

                {
                  label: 'Orders',
                  component: <LocationOrdersStep location_id={locationId!} />,
                  gridSize: 12,
                },
                {
                  label: 'Stock',
                  component: <StockComponent location_id={locationId!} inventory_restock_type={location.inventory_restock_type as InventoryRestockEnum} />,
                  gridSize: 12,
                },
                {
                  label: 'Users',
                  component: <ListLocationUsers location_id={locationId!} handleCreate={handleCreateUser} handleView={handleViewUser} handleEdit={handleEditUser} />,
                },

                ...(location.has_station_printers
                  ? [
                      {
                        label: 'Station Printers',
                        component: <ListLocationPrinters printers={printers} handleCreate={handleCreatePrinter} handleEdit={handleEditPrinter} />,
                      },
                    ]
                  : []),
                ...(location.has_staff
                  ? [
                      {
                        label: 'Staff',
                        component: <ListLocationStaff location_id={locationId!} handleCreate={handleCreateStaff} handleView={handleViewStaff} handleEdit={handleEditStaff} />,
                      },
                    ]
                  : []),
                // @ts-ignore
                ...(location.integrations && location.integrations[0]?.vouchers
                  ? [
                      {
                        label: 'Vouchers',
                        component: <ListVouchers vouchers={voucher_codes} locale={locale} currency={currency} handleCreate={handleCreateVoucher} handleEdit={handleEditVoucher} />,
                      },
                    ]
                  : []),
              ]
            : []),
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationSteps);
