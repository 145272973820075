import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import EditLocationIntegrations from '../../../../../components/Global/LocationIntegrations/Edit';

import { editLocationIntegrationsInitQuery, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  location_id: string;
}

const EditOrganisationUser = ({ classes, history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({ name: '' });

  const { data: editLocationIntegrationsInitQueryData } = useQuery(editLocationIntegrationsInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationIntegrationsInitQueryData) {
      setLocation(editLocationIntegrationsInitQueryData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editLocationIntegrationsInitQueryData]);

  const handleFinish = () => {
    history.push(`/org/locations/${location_id}/integrations`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Locations', link: `/org/locations` },
          { label: location.name, link: `/org/locations/${location_id}` },
          { label: 'Integrations', link: `/org/locations/${location_id}/integrations` },
          { label: 'Edit' },
        ]}>
        <EditLocationIntegrations location_id={location_id} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(EditOrganisationUser));
