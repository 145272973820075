import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: theme.spacing(2),
      fontSize: 14,
    },
    dates: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    error: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    day: {
      flexBasis: '20%',
    },
    month: {
      flexBasis: '40%',
    },
    year: {
      flexBasis: '30%',
    },
    dayMenu: {
      maxHeight: theme.spacing(45),
    },
    yearMenu: {
      maxHeight: theme.spacing(45),
    },
  });

export default styles;
