import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { History } from 'history';

import { Button, FormControlLabel, Switch, Typography, withStyles } from '@material-ui/core';

import { XeroIntegration, LocationIntegrationReducerAction, ValidationErrors, validationErrors } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  history: History<unknown>;
  location_id: String;
  xero: XeroIntegration;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: LocationIntegrationReducerAction; value: any }>;
}

const StepXero = ({ classes, history, location_id, xero, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const _xeroLogin = () => {
    const webhookBaseUrl = process.env.REACT_APP_WEBHOOK_API_URL || window.location.origin;
    const uri = `${webhookBaseUrl}/webhook-api/xero/login/${location_id}`;
    const win = window.open(uri, '_blank', 'popup=yes,width=500,height=800');
    const timer = setInterval(() => {
      try {
        if (win) {
          const url = win.location.pathname;
          if (url && url.endsWith('xero/success')) {
            clearInterval(timer);
            setTimeout(() => {
              win?.close();
              dispatch({ type: LocationIntegrationReducerAction.XERO_ACCOUNT_ID, value: 'success' });
            }, 2500);
          }
          if (url && url.endsWith('xero/unsuccessful')) {
            clearInterval(timer);
            setTimeout(() => {
              win?.close();
              dispatch({ type: LocationIntegrationReducerAction.XERO_ACCOUNT_ID, value: '' });
            }, 2500);
          }
        }
      } catch (error) {}
      if (win && win.closed) {
        clearInterval(timer);
      }
    }, 1000);
  };

  return (
    <>
      <div className={classes.root}>
        <FormControlLabel
          label="Active"
          className={classes.active}
          control={
            <Switch
              checked={xero.active}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.XERO_ACTIVE, value: event.target.checked })}
            />
          }
        />
        {xero.active && xero.code && (
          <>
            <Typography>Xero account is authenticated</Typography>
          </>
        )}
        {xero.active && !xero.code && (
          <>
            <Typography color="error">{validationErrors.xeroCode}</Typography>
          </>
        )}
        {xero.active && (
          <>
            <Button className={classes.button} variant="outlined" onClick={_xeroLogin}>
              Xero Login {xero.code ? 'Again' : ''}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(StepXero);
