import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { OpeningHours, ServiceCharge } from '../Common';
import { Unpacked } from '../../../../react-app-env';

const _editLocationInitQuery = (id: string, organisationId: string | undefined) => ({
  locations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      email: types.string,
      phone: types.string,
      website: types.string,
      tax_number: types.string,
      currency: types.string,
      locale: types.string,
      number_of_tables: types.number,
      location_type: types.string,
      address_line_1: types.string,
      address_line_2: types.string,
      address_city: types.string,
      address_country: types.string,
      address_postcode: types.string,
      can_manage_menus: types.boolean,
      has_kitchen_printer: types.boolean,
      has_bar_printer: types.boolean,
      has_staff: types.boolean,
      has_station_printers: types.boolean,
      service_charge: types.custom<ServiceCharge | null>(),
      idle_timeout: types.number,
      location_menu_id: types.string,
      ordering_system_primary_color: types.string,
      ordering_system_secondary_color: types.string,
      header_image_added: types.boolean,
      logo_image_added: types.boolean,
      ordering_system_open_hours: types.custom<OpeningHours[] | null>(),
      ordering_system_preorder_hours: types.custom<OpeningHours[] | null>(),
      preorder_days_limit: types.number,
      preorder_pacing_limit: types.number,
      order_interval: types.number,
      inventory_restock_type: types.string,
      organisation_id: types.string,
      voucher_codes_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
      integrations: {
        vouchers: types.boolean,
      },
    }
  ),
  enum_location_types: [
    {
      location_type: types.string,
      description: types.string,
    },
  ],
  enum_inventory_restock_types: [
    {
      inventory_restock_type: types.string,
      description: types.string,
    },
  ],
  menus: params(
    {
      offset: 0,
      ...(organisationId ? { where: { organisation_id: { _eq: rawString(organisationId) } } } : {}),
    },
    [
      {
        id: types.string,
        name: types.string,
        description: types.string,
        is_primary: types.boolean,
      },
    ]
  ),
});

export const editLocationInitQuery = (id: string, organisationId: string | undefined) => gql`
  ${query(_editLocationInitQuery(id, organisationId))}
`;

const _editLocationIntegrationsInitQuery = (location_id: string) => ({
  locations_by_pk: params(
    { id: rawString(location_id) },
    {
      name: types.string,
      integrations: {
        id: types.string,
        payment: types.custom(),
        deliverect: types.custom(),
        xero: types.custom(),
        vouchers: types.boolean,
      },
    }
  ),
});

type X = Unpacked<typeof _editLocationIntegrationsInitQuery>;
export type Location = X['locations_by_pk'];

export const editLocationIntegrationsInitQuery = (location_id: string) => gql`
  ${query(_editLocationIntegrationsInitQuery(location_id))}
`;
