import React, { useEffect, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useMutation, useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';

import { timeToFormattedString } from '../../../../utils/stringUtils';

import { menuReducer, MenuReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { updateMenuMutation } from './mutations';
import { editMenuInitQuery } from './queries';

import styles from './styles';
import { useSnackbar } from 'notistack';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  menu_id: string;
  handleFinish: (id: string) => void;
}

const EditMenu = ({ classes, history, menu_id, handleFinish }: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [menu, dispatchMenu] = useReducer(menuReducer, {
    name: '',
    description: '',
    active: true,
    all_day: false,
    menu_types: [],
    available_from: '00:00:00:000',
    available_to: '23:59:59:999',
    is_primary: false,
  });

  const { data: editMenuInitData } = useQuery(editMenuInitQuery(menu_id), { fetchPolicy: 'no-cache' });

  const [updateMenu, { loading: saving }] = useMutation(updateMenuMutation, {
    onCompleted: () => handleFinish(menu_id),
    onError: () => enqueueSnackbar('Something went wrong. Please make sure the only menu is in primary mode.', { variant: 'error' }),
  });

  useEffect(() => {
    if (editMenuInitData) {
      dispatchMenu({ type: MenuReducerAction.INIT, value: editMenuInitData.menus_by_pk });
    }
  }, [editMenuInitData]);

  const handleSave = async () => {
    const mData: UnknownObject = { ...menu };
    delete mData.all_day;
    mData.available_from = `${timeToFormattedString(mData.available_from, 'HMS', true)}`;
    mData.available_to = `${timeToFormattedString(mData.available_to, 'HMS', true)}`;

    const updateMenuVariables = {
      pk_columns: {
        id: menu_id,
      },
      set: mData,
    };

    await updateMenu({ variables: updateMenuVariables });
  };

  return (
    <>
      <Steps completeLabel="Save" menu={menu} dispatch={dispatchMenu} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Menu" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditMenu));
