import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid, withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import UsersList from '../../../../../components/Global/Users/List';

import { selectOrganisationUsersInitQuery, Organisation } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
}

const ListOrganisationUsers = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    users: [],
  });

  const { data: organisationUserInitQuery } = useQuery(selectOrganisationUsersInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setOrganisation(organisationUserInitQuery.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitQuery]);

  const handleCreate = () => {
    history.push(`/admin/organisations/${organisation_id}/users/create`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/users/${id}`);
  };

  const handleEdit = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/users/${id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[{ label: 'Organisations', link: '/admin/organisations' }, { label: organisation.name, link: `/admin/organisations/${organisation_id}` }, { label: 'Users' }]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UsersList
            users={organisation.users.map((i) => ({ id: i.id, full_name: i.full_name, email: i.email }))}
            gridSize={3}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleView={handleView}
          />
        </Grid>
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListOrganisationUsers));
