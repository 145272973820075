import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _selectLocationUserInitQuery = (user_id: string) => ({
  users_location_by_pk: params(
    { id: rawString(user_id) },
    {
      full_name: types.string,
      location: {
        name: types.string,
      },
    }
  ),
});

type X = Unpacked<typeof _selectLocationUserInitQuery>;
export type LocationUser = X['users_location_by_pk'];

export const selectLocationUserInitQuery = (user_id: string) => gql`
  ${query(_selectLocationUserInitQuery(user_id))}
`;
