import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
// import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { Add, AddCircleOutline, Delete, Email, ExpandLess, ExpandMore, FavoriteOutlined, Language, LocationOn, Phone, Remove } from '@material-ui/icons';
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Typography,
  withStyles,
} from '@material-ui/core';
import styles from './styles';
import { useMutation as useRestMutation, useQuery as useRestQuery } from 'react-query';
import { capturePayment, createPayment, getMenuQuery } from './queries';
import CustomBackdrop from '../../../../components/CustomBackdrop';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Category, CategoryItem, CategoryType } from '../Common';
import { createServedOrderAfterPayment, createServedOrderPayment, createTakeAwayOrderAfterPayment, createTakeAwayOrderPayment } from './mutations';
import PayPalCheckout from '../Common/components/Checkout';

const { REACT_APP_AWS_IMAGES_URL, REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL } = process.env;

interface UrlParams {
  organisation_id: string;
  location_id: string;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  // classes: ClassNameMap<string>;
}

interface TimeRange {
  day?: string;
  open: string;
  close: string;
}

const useStyles = makeStyles((theme) => ({
  topSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  openAllDay: {
    cursor: 'pointer',
  },
  openAllDayText: {
    display: 'flex',
    alignItems: 'center',
  },
  schedule: {
    paddingLeft: theme.spacing(2),
  },
  address: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      marginTop: theme.spacing(2),
    },
  },
}));

const ViewMenu: React.FC<Props> = ({ history }) => {
  const classes = useStyles();
  const { organisation_id, location_id } = useParams<UrlParams>();

  const {
    data: menuData,
    isLoading,
    refetch,
  } = useRestQuery({
    queryKey: 'menu-data',
    onSuccess: (data) => {},
    queryFn: () => getMenuQuery(organisation_id, location_id),
  });

  const { mutateAsync: createOrder, isLoading: creatingOrder } = useRestMutation({
    mutationKey: 'create-order',
    mutationFn: (request: string) => createPayment(request),
  });

  const { mutateAsync: captureOrder, isLoading: capturingOrder } = useRestMutation({
    mutationKey: 'capture-order',
    mutationFn: (orderId: string) => capturePayment(orderId),
  });

  const { mutateAsync: createServedOrderAfterPaymentAsync, isLoading: creatingOrderAfterPayment } = useRestMutation({
    mutationKey: 'create-order-after-payment',
    mutationFn: (order: any) => createServedOrderAfterPayment(order),
  });

  const { mutateAsync: createTakeAwayOrderAfterPaymentAsync, isLoading: creatingTakeAwayOrderAfterPayment } = useRestMutation({
    mutationKey: 'create-takeaway-order-after-payment',
    mutationFn: (order: any) => createTakeAwayOrderAfterPayment(order),
  });

  const { mutateAsync: createTakeAwayOrderPaymentAsync, isLoading: creatingTakeAwayOrderPayment } = useRestMutation({
    mutationKey: 'create-takeaway-order-payment',
    onSuccess: () => refetch(),
    mutationFn: (order: any) => createTakeAwayOrderPayment(order),
  });

  const { mutateAsync: createServedOrderPaymentAsync, isLoading: creatingServedOrderPayment } = useRestMutation({
    mutationKey: 'create-served-order-payment',
    onSuccess: () => refetch(),
    mutationFn: (order: any) => createServedOrderPayment(order),
  });

  const [selectedCategory, setSelectedCategory] = useState<string>('0');
  const [selectedProduct, setSelectedProduct] = useState<CategoryItem | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCartDialogOpen, setCartDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cart, setCart] = useState<{ [key: string]: number }>({});
  const [isOrderConfirmationOpen, setOrderConfirmationOpen] = useState(false);
  const [tableNumber, setTableNumber] = useState<null | number>(null);
  const [timeOption, setTimeOption] = useState('standard');
  const [selectedModifiers, setSelectedModifiers] = useState<{
    [key: string]: { [modifierName: string]: boolean };
  }>({});
  const [showPayPalButtons, setShowPayPalButtons] = useState(true);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [isOrderTypeModalOpen, setOrderTypeModalOpen] = useState(true);
  const [orderType, setOrderType] = useState<string | null>(null);

  const toggleSchedule = () => {
    setScheduleOpen(!scheduleOpen);
  };

  const getDaysArray = (numDays: number): Date[] => {
    const today = new Date();
    return Array.from({ length: numDays }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      return date;
    });
  };

  const days = getDaysArray(menuData?.location_info.preorder_days_limit ?? 5);
  const [scheduledDay, setScheduledDay] = useState<string>(days[0].toISOString().split('T')[0]);
  const [scheduledTime, setScheduledTime] = useState<string>('');

  useEffect(() => {
    // setShowPayPalButtons(false);
    // setTimeout(() => {
    //   setShowPayPalButtons(true);
    // }, 0);

    if (orderType === 'pickup') {
      const whenFor = timeOption === 'schedule' ? combineDayAndTime(scheduledDay, scheduledTime) : new Date().toISOString();

      const existingOrdersTotalScore = calculateTotalScoreForOrders(menuData?.orders_takeaway ?? [], whenFor);

      const currentOrderTotalScore = calculateTotalScoreOfCurrentOrder(cart);
      if (existingOrdersTotalScore + currentOrderTotalScore > (menuData?.location_info?.preorder_pacing_limit ?? 0)) {
        alert('You cannot place this order as it exceeds the pacing limit for the selected time slot.');
      }
    }
    // eslint-disable-next-line
  }, [orderType, cart, tableNumber, timeOption, scheduledDay, scheduledTime]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedProduct(null);
    setQuantity(1);
  };

  const handleAddButtonClick = (product: CategoryItem) => {
    setSelectedProduct(product);
    setQuantity(cart[product.id] || 1);
    setSelectedModifiers((prev) => ({
      ...prev,
      [product.id]: product.modifier_assignments.reduce(
        (acc, mod) => {
          acc[mod.modifier.name] = false;
          return acc;
        },
        {} as { [modifierName: string]: boolean }
      ),
    }));
    setDialogOpen(true);
  };

  const handleAddToCart = () => {
    if (selectedProduct) {
      const productId = selectedProduct.id;
      const modifiers = selectedProduct.modifier_assignments
        .filter((mod) => selectedModifiers[productId]?.[mod.modifier.name])
        .map((mod) => mod.modifier.name)
        .sort()
        .join(',');

      const cartKey = `${productId}:${modifiers}`;

      if (checkStockAvailability(productId, quantity)) {
        setCart((prevCart) => ({
          ...prevCart,
          [cartKey]: (prevCart[cartKey] || 0) + quantity,
        }));
        setDialogOpen(false);
      } else {
        alert("You can't add more of this item. It exceeds the available stock.");
      }
    }
  };

  const handleQuantityChange = (amount: number) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  const handleCartQuantityChange = (cartKey: string, amount: number) => {
    const [productId] = cartKey.split(':');

    if (amount > 0 && !checkStockAvailability(productId, amount)) {
      alert("You can't add more of this item. It exceeds the available stock.");
      return;
    }

    setCart((prevCart) => {
      const newQuantity = (prevCart[cartKey] || 0) + amount;
      if (newQuantity <= 0) {
        const { [cartKey]: _, ...rest } = prevCart;
        return rest;
      }
      return { ...prevCart, [cartKey]: newQuantity };
    });
  };

  const checkStockAvailability = (productId: string, quantity: number) => {
    const product = menuData?.categories
      .flatMap((category) =>
        category.category_type === CategoryType.DISH ? category.dish_assignments.map((item) => item.dish) : category.drink_assignments.map((item) => item.drink)
      )
      .find((item) => item.id === productId);

    if (!product) return false;

    const itemInCartQuantity = Object.keys(cart).reduce((total, cartKey) => {
      const [cartProductId] = cartKey.split(':');
      if (cartProductId === productId) {
        return total + cart[cartKey];
      }
      return total;
    }, 0);

    const totalQuantity = itemInCartQuantity + quantity;

    return totalQuantity <= product.item_per_day_left;
  };

  const renderCategoryItem = (categoryItem: CategoryItem) => {
    const itemQuantity = Object.keys(cart).reduce((total, cartKey) => {
      const [productId] = cartKey.split(':');
      if (productId === categoryItem.id) {
        return total + cart[cartKey];
      }
      return total;
    }, 0);

    const primaryColor = menuData?.location_info.ordering_system_primary_color;
    const secondaryColor = menuData?.location_info.ordering_system_secondary_color;

    const isOutOfStock = categoryItem.item_per_day_left <= 0;

    return (
      <Card key={categoryItem.id}>
        <CardHeader title={categoryItem.name} subheader="" style={{ color: primaryColor || 'inherit' }} />
        <CardMedia
          component="img"
          height="194"
          image={categoryItem.image_added ? `${REACT_APP_AWS_IMAGES_URL}/${categoryItem.id}.jpeg` : `${REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`}
          alt={categoryItem.name}
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body2" style={{ color: secondaryColor || 'inherit' }}>
                {categoryItem.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              Price: {`${categoryItem.price / 100} ${menuData?.location_info.currency}`}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteOutlined />
          </IconButton>
          {isOutOfStock ? (
            <Typography variant="body2" color="error">
              Out of Stock
            </Typography>
          ) : (
            <IconButton aria-label="add to bucket" onClick={() => handleAddButtonClick(categoryItem)}>
              {itemQuantity > 0 ? (
                <Badge badgeContent={itemQuantity} color="secondary">
                  <AddCircleOutline />
                </Badge>
              ) : (
                <AddCircleOutline />
              )}
            </IconButton>
          )}
        </CardActions>
      </Card>
    );
  };

  const renderCategoryItems = (category: Category, categoryIndex: number) => {
    const categoryItems = category.category_type === CategoryType.DISH ? category.dish_assignments.map((item) => item.dish) : category.drink_assignments.map((item) => item.drink);

    return (
      <Grid item xs={12} key={categoryIndex}>
        <TabPanel value={categoryIndex.toString()}>
          <Grid container>
            {categoryItems.map((item, index) => (
              <Grid item xs={12} md={2} key={index}>
                {renderCategoryItem(item)}
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </Grid>
    );
  };

  const filteredCategories = menuData?.categories.filter((item) => item.active).sort((left, right) => left.order_index - right.order_index) ?? [];

  const calculateTotalPrice = (): number => {
    return Object.keys(cart).reduce((total, cartKey) => {
      const [productId, modifiersKey] = cartKey.split(':');
      const product = menuData?.categories
        .flatMap((category) =>
          category.category_type === CategoryType.DISH ? category.dish_assignments.map((item) => item.dish) : category.drink_assignments.map((item) => item.drink)
        )
        .find((item) => item.id === productId);

      if (!product) return total;

      const selectedModifiers = product.modifier_assignments.filter((mod) => modifiersKey.includes(mod.modifier.name)).map((mod) => mod.modifier);

      const itemPrice = selectedModifiers.reduce((sum, mod) => sum + mod.price, product.price);
      return total + ((itemPrice || 0) / 100) * cart[cartKey];
    }, 0);
  };

  const getCurrentDay = (date: Date): string => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[date.getDay()];
  };

  const isTimeInRange = (currentTime: string, startTime: string, endTime: string): boolean => {
    const current = new Date(`1970-01-01T${currentTime}:00Z`);
    const start = new Date(`1970-01-01T${startTime}:00Z`);
    const end = new Date(`1970-01-01T${endTime}:00Z`);
    return current >= start && current <= end;
  };

  const getDayHours = (hoursArray: TimeRange[], date: Date): TimeRange | null => {
    const currentDay = getCurrentDay(date);
    return hoursArray.find((day) => day.day === currentDay) || null;
  };

  const isStoreOpen = (openHours: TimeRange | null): boolean => {
    if (!openHours) return true;
    const currentTime = new Date().toLocaleTimeString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit' });
    return isTimeInRange(currentTime, openHours.open, openHours.close);
  };

  const getPreorderTimeRange = (preorderHours: TimeRange | null): TimeRange | null => {
    if (!preorderHours) return null;
    return { open: preorderHours.open, close: preorderHours.close };
  };

  const calculateTotalScoreForOrders = (orders: any[], whenFor: string) => {
    return orders
      .filter((order) => {
        const orderDate = new Date(order.when_for);
        const whenForDate = new Date(whenFor);
        return orderDate.getTime() === whenForDate.getTime();
      })
      .reduce((totalScore, order) => {
        const dishScore = order.dish_items.reduce((sum: number, dish: any) => sum + (dish.item.score || 0), 0);
        const drinkScore = order.drink_items.reduce((sum: number, drink: any) => sum + (drink.item.score || 0), 0);
        return totalScore + dishScore + drinkScore;
      }, 0);
  };

  const calculateTotalScoreOfCurrentOrder = (cart: { [key: string]: number }) => {
    return Object.keys(cart).reduce((totalScore, cartKey) => {
      const [productId] = cartKey.split(':');
      const product = menuData?.categories
        .flatMap((category) =>
          category.category_type === CategoryType.DISH ? category.dish_assignments.map((item) => item.dish) : category.drink_assignments.map((item) => item.drink)
        )
        .find((item) => item.id === productId);

      if (!product) return totalScore;

      return totalScore + (product.score || 0) * cart[cartKey];
    }, 0);
  };

  const filterTimeSlots = (open: string, close: string, currentTime: string | null = null, interval: number) => {
    const slots = [];
    const start = new Date(`1970-01-01T${open}:00Z`);
    const end = new Date(`1970-01-01T${close}:00Z`);

    let currentSlot = new Date(start);

    while (currentSlot <= end) {
      const slotString = currentSlot.toTimeString().slice(0, 5);
      if (!currentTime || slotString >= currentTime) {
        slots.push(slotString);
      }
      currentSlot.setMinutes(currentSlot.getMinutes() + interval);
    }

    return slots;
  };

  const combineDayAndTime = (day: string, time: string) => {
    return `${day}T${time}:00Z`;
  };

  const handleOrderTypeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrderType(event.target.value);
    setOrderTypeModalOpen(false);
  };

  const handleChangeOrderType = () => {
    setOrderTypeModalOpen(true);
  };

  const renderCartDialog = () => {
    const today = new Date();
    const currentDay = new Date(scheduledDay);
    const todayOpenHours = getDayHours(menuData?.location_info.ordering_system_open_hours ?? [], currentDay);
    const storeIsOpen = isStoreOpen(todayOpenHours);
    const selectedPreorderHours = getDayHours(menuData?.location_info.ordering_system_preorder_hours ?? [], currentDay);
    const preorderTimeRange = getPreorderTimeRange(selectedPreorderHours);
    const days = getDaysArray(menuData?.location_info.preorder_days_limit ?? 5);
    const formattedDay = (date: Date) =>
      date.toLocaleDateString('en-US', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
      });
    const currentTime = new Date().toLocaleTimeString('en-GB', { hour12: false, hour: '2-digit', minute: '2-digit' });
    const orderInterval = menuData?.location_info.order_interval ?? 15;
    const timeSlots = preorderTimeRange
      ? filterTimeSlots(preorderTimeRange.open, preorderTimeRange.close, scheduledDay === today.toISOString().split('T')[0] ? currentTime : null, orderInterval!)
      : [];
    const timeSlotsWithLimitCheck = timeSlots.map((slot) => {
      const whenFor = combineDayAndTime(scheduledDay, slot);
      const existingOrdersTotalScore = calculateTotalScoreForOrders(menuData?.orders_takeaway ?? [], whenFor);
      const currentOrderTotalScore = calculateTotalScoreOfCurrentOrder(cart);

      const isOverLimit = existingOrdersTotalScore + currentOrderTotalScore > (menuData?.location_info?.preorder_pacing_limit ?? 0);

      return {
        slot,
        isOverLimit,
      };
    });
    const cartItems = Object.keys(cart)
      .map((cartKey) => {
        const [productId, modifiersKey] = cartKey.split(':');
        const product = menuData?.categories
          .flatMap((category) =>
            category.category_type === CategoryType.DISH ? category.dish_assignments.map((item) => item.dish) : category.drink_assignments.map((item) => item.drink)
          )
          .find((item) => item.id === productId);

        if (!product) return null;

        const selectedModifiers = product.modifier_assignments
          .filter((mod) => modifiersKey.includes(mod.modifier.name))
          .map((mod) => ({
            id: mod.modifier.id,
            name: mod.modifier.name,
            originalPrice: mod.modifier.price,
            originalTax: mod.modifier.tax,
            price: mod.modifier.price,
            tax: mod.modifier.tax,
          }));

        const itemPrice = selectedModifiers.reduce((sum, mod) => sum + mod.price, product.price);

        return {
          id: product.id,
          name: product.name,
          cartKey: cartKey,
          quantity: cart[cartKey],
          unit_amount: {
            currency_code: menuData?.location_info.currency,
            value: itemPrice / 100,
          },
          modifiers: selectedModifiers,
          price: itemPrice,
          tax: product.tax,
        };
      })
      .filter((item) => item !== null);

    return (
      <Dialog open={isCartDialogOpen} onClose={() => setCartDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Cart</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Order Type</Typography>
          <RadioGroup value={orderType} onChange={(e) => setOrderType(e.target.value)} row>
            <FormControlLabel value="delivery" control={<Radio />} label="Delivery" />
            <FormControlLabel value="pickup" control={<Radio />} label="Pickup" />
            <FormControlLabel value="dinein" control={<Radio />} label="Dine in" />
          </RadioGroup>
          {orderType !== 'dinein' ? (
            <>
              <Typography variant="h6">{orderType === 'delivery' ? 'Delivery Time' : 'Pickup Time'}</Typography>
              <RadioGroup value={timeOption} onChange={(e) => setTimeOption(e.target.value)} row>
                <FormControlLabel value="standard" control={<Radio />} label="Standard" />
                <FormControlLabel value="schedule" control={<Radio />} label="Schedule" />
              </RadioGroup>
            </>
          ) : (
            <>
              <Typography>Table number</Typography>
              <Input title={'Table number'} type={'number'} value={tableNumber} onChange={(e) => setTableNumber(Number(e.target.value))} />
            </>
          )}

          {timeOption === 'standard' && !storeIsOpen && (
            <Typography variant="body1" color="error">
              Store is closed
            </Typography>
          )}

          {timeOption === 'schedule' && preorderTimeRange && orderType !== 'dinein' && (
            <>
              <Typography variant="body1">{`Preorder available from ${preorderTimeRange.open} to ${preorderTimeRange.close}`}</Typography>
              <TabContext value={scheduledDay}>
                <TabList variant="scrollable" scrollButtons="on" onChange={(_e, v) => setScheduledDay(v)} aria-label="Day selection tabs">
                  {days.map((date, index) => (
                    <Tab label={formattedDay(date)} value={date.toISOString().split('T')[0]} key={index} />
                  ))}
                </TabList>
                <RadioGroup value={scheduledTime} onChange={(e) => setScheduledTime(e.target.value)}>
                  <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {timeSlotsWithLimitCheck.map(({ slot, isOverLimit }, index) => (
                      <FormControlLabel key={index} value={slot} control={<Radio />} label={slot} disabled={isOverLimit} style={isOverLimit ? { color: 'gray' } : {}} />
                    ))}
                  </div>
                </RadioGroup>
              </TabContext>
            </>
          )}

          <Typography variant="h6">Order Summary</Typography>
          {Object.keys(cart).length === 0 ? (
            <Typography variant="body1">Your cart is empty</Typography>
          ) : (
            <>
              {cartItems.map((cartItem, index) => (
                <Grid container spacing={2} alignItems="center" key={index}>
                  <Grid item xs={4}>
                    <Typography variant="body1">{cartItem?.name}</Typography>
                    {cartItem?.modifiers.map((mod, modIndex) => (
                      <Typography variant="body2" key={modIndex}>
                        {mod.name} (+{mod.price / 100} {menuData?.location_info.currency})
                      </Typography>
                    ))}
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1">{`${cartItem?.unit_amount.value} ${menuData?.location_info.currency}`}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        {cartItem!.quantity === 1 ? (
                          <IconButton onClick={() => handleCartQuantityChange(cartItem!.cartKey, -1)}>
                            <Delete />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleCartQuantityChange(cartItem!.cartKey, -1)}>
                            <Remove />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" align={'center'}>
                          {cartItem?.quantity}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton onClick={() => handleCartQuantityChange(cartItem!.cartKey, 1)}>
                          <Add />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Typography variant="h6" align="right" style={{ marginTop: '20px' }}>
                Total Price: {calculateTotalPrice()} {menuData?.location_info.currency}
              </Typography>
            </>
          )}
          {showPayPalButtons && (
            <>
              {orderType === 'dinein' || timeOption === 'standard' || (timeOption === 'schedule' && scheduledTime !== '') ? (
                <PayPalCheckout
                  menuData={menuData}
                  createOrder={createOrder}
                  captureOrder={captureOrder}
                  cart={cart}
                  createServedOrderAfterPaymentAsync={createServedOrderAfterPaymentAsync}
                  createServedOrderPaymentAsync={createServedOrderPaymentAsync}
                  createTakeAwayOrderAfterPaymentAsync={createTakeAwayOrderAfterPaymentAsync}
                  createTakeAwayOrderPaymentAsync={createTakeAwayOrderPaymentAsync}
                  location_id={location_id}
                  orderType={orderType!}
                  organisation_id={organisation_id}
                  scheduledDay={scheduledDay}
                  scheduledTime={scheduledTime}
                  tableNumber={tableNumber}
                  timeOption={timeOption}
                />
              ) : (
                <Button disabled>Please select a time for {orderType}.</Button>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCartDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderOrderConfirmationDialog = () => {
    const orderedItems = Object.keys(cart).map((productId) => {
      const product = menuData?.categories
        .flatMap((category) =>
          category.category_type === CategoryType.DISH ? category.dish_assignments.map((item) => item.dish) : category.drink_assignments.map((item) => item.drink)
        )
        .find((item) => item.id === productId);

      return {
        id: product?.id,
        name: product?.name,
        quantity: cart[productId],
        price: product?.price,
      };
    });

    return (
      <Dialog
        open={isOrderConfirmationOpen}
        onClose={() => {
          setCart({});
          setOrderConfirmationOpen(false);
        }}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>Thank You for Your Order!</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Order Summary</Typography>
          {orderedItems.map((item) => (
            <Grid container spacing={2} key={item.id}>
              <Grid item xs={6}>
                <Typography variant="body1">{item.name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{item.quantity}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">{`${(item.price ?? 0) / 100} ${menuData?.location_info.currency}`}</Typography>
              </Grid>
            </Grid>
          ))}
          <Typography variant="h6" align="right" style={{ marginTop: '20px' }}>
            Total Price: {calculateTotalPrice()} {menuData?.location_info.currency}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCart({});
              setOrderConfirmationOpen(false);
            }}
            color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderOrderTypeModal = () => (
    <Dialog open={isOrderTypeModalOpen} onClose={() => setOrderTypeModalOpen(false)}>
      <DialogTitle>Select Your Order Type</DialogTitle>
      <DialogContent>
        <RadioGroup value={orderType} onChange={handleOrderTypeSelection}>
          <FormControlLabel value="delivery" control={<Radio />} label="Delivery" />
          <FormControlLabel value="pickup" control={<Radio />} label="Pickup" />
          <FormControlLabel value="dinein" control={<Radio />} label="Dine in" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOrderTypeModalOpen(false)} disabled={!orderType}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Container>
        <Paper elevation={3} className={classes.topSection}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Box className={classes.logo}>
                <img
                  alt="Logo"
                  src={
                    menuData?.location_info.logo_image_added ? `${REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL}/${location_id}-logo.jpeg` : `${REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`
                  }
                  style={{ width: '100%', maxHeight: '150px', objectFit: 'contain' }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.openAllDay} onClick={toggleSchedule} style={{ cursor: 'pointer' }}>
                <Typography variant="h6" className={classes.openAllDayText}>
                  Open all day {scheduleOpen ? <ExpandLess /> : <ExpandMore />}
                </Typography>
                <Collapse in={scheduleOpen}>
                  <Box className={classes.schedule}>
                    {menuData?.location_info?.ordering_system_open_hours?.map((day: any, index: any) => (
                      <Typography key={index} variant="body2">
                        {day.day}: {day.open} - {day.close}
                      </Typography>
                    ))}
                  </Box>
                </Collapse>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="body2" align="right">
                Pre-order for Pickup
              </Typography>
            </Grid>
            <Grid item container direction="column" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="body1">
                  <LocationOn /> {menuData?.location_info.address_line_1}, {menuData?.location_info.address_city}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Email /> {menuData?.location_info.email}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Phone /> {1234}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Language /> {'website'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <TabContext value={selectedCategory}>
          <Grid container justify="center" spacing={0}>
            <Grid item xs={12}>
              <img
                alt={'header'}
                width={'100%'}
                style={{ maxHeight: '150px' }}
                src={menuData?.location_info.header_image_added ? `${REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL}/${location_id}.jpeg` : `${REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TabList variant="scrollable" scrollButtons="on" onChange={(_e, v) => setSelectedCategory(v)} aria-label="lab API tabs example">
                {filteredCategories.map((category, categoryIndex) => (
                  <Tab label={category.name} value={categoryIndex.toString()} key={categoryIndex} />
                ))}
              </TabList>
            </Grid>
            {filteredCategories.map((category, categoryIndex) => renderCategoryItems(category, categoryIndex))}
            <Grid item xs={12} style={{ marginTop: '15%' }}>
              <Paper elevation={3} style={{ minHeight: '130px', padding: '30px' }}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{menuData?.location_info.name}</Typography>
                    <Typography variant="body1">{menuData?.location_info.email}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="span">{`${menuData?.location_info.address_city}, ${menuData?.location_info.address_line_1}`}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {(isLoading ||
              creatingOrder ||
              capturingOrder ||
              creatingOrderAfterPayment ||
              creatingTakeAwayOrderAfterPayment ||
              creatingTakeAwayOrderPayment ||
              creatingServedOrderPayment) && <CustomBackdrop label="Loading" />}
          </Grid>
        </TabContext>
      </Container>

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{selectedProduct?.name}</DialogTitle>
        <DialogContent>
          <img
            src={selectedProduct?.image_added ? `${REACT_APP_AWS_IMAGES_URL}/${selectedProduct.id}.jpeg` : `${REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`}
            alt={selectedProduct?.name}
            style={{ width: '100%', height: 'auto' }}
          />
          <Typography variant="body1" style={{ color: menuData?.location_info.ordering_system_secondary_color || 'inherit' }}>
            {selectedProduct?.description}
          </Typography>
          <Typography variant="h6">{`Price: ${(selectedProduct?.price ?? 0) / 100} ${menuData?.location_info.currency}`}</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <IconButton onClick={() => handleQuantityChange(-1)}>
                <Remove />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1">{quantity}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleQuantityChange(1)}>
                <Add />
              </IconButton>
            </Grid>
          </Grid>
          <FormGroup>
            {selectedProduct?.modifier_assignments.map((mod) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedModifiers[selectedProduct.id]?.[mod.modifier.name] || false}
                    onChange={(e) => {
                      setSelectedModifiers((prev) => ({
                        ...prev,
                        [selectedProduct.id]: {
                          ...prev[selectedProduct.id],
                          [mod.modifier.name]: e.target.checked,
                        },
                      }));
                    }}
                  />
                }
                label={`${mod.modifier.name} (+${mod.modifier.price / 100} ${menuData?.location_info.currency})`}
                key={mod.modifier.name}
              />
            ))}
          </FormGroup>
          <Typography variant="h6">Allergies:</Typography>
          {selectedProduct?.allergy_assignments.map((allergy) => (
            <Typography variant="body2" key={allergy.id}>
              {allergy.allergy.name}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddToCart} color="primary">
            Add to Cart
          </Button>
        </DialogActions>
      </Dialog>

      {Object.keys(cart).length > 0 && (
        <Button variant="contained" color="primary" onClick={() => setCartDialogOpen(true)} style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
          View Cart ({Object.values(cart).reduce((a, b) => a + b, 0)})
        </Button>
      )}
      <Button variant="contained" color="secondary" onClick={handleChangeOrderType} style={{ position: 'fixed', bottom: '20px', left: '20px' }}>
        Change Order Type
      </Button>
      {/*{Object.keys(cart).length > 0 && (*/}
      {/*  <Checkout*/}
      {/*    menuData={menuData}*/}
      {/*    createOrder={createOrder}*/}
      {/*    captureOrder={captureOrder}*/}
      {/*    cart={cart}*/}
      {/*    createServedOrderAfterPaymentAsync={createServedOrderAfterPaymentAsync}*/}
      {/*    createServedOrderPaymentAsync={createServedOrderPaymentAsync}*/}
      {/*    createTakeAwayOrderAfterPaymentAsync={createTakeAwayOrderAfterPaymentAsync}*/}
      {/*    createTakeAwayOrderPaymentAsync={createTakeAwayOrderPaymentAsync}*/}
      {/*    location_id={location_id}*/}
      {/*    orderType={orderType!}*/}
      {/*    organisation_id={organisation_id}*/}
      {/*    scheduledDay={scheduledDay}*/}
      {/*    scheduledTime={scheduledTime}*/}
      {/*    tableNumber={tableNumber}*/}
      {/*    timeOption={timeOption}*/}
      {/*  />*/}
      {/*)}*/}

      {renderOrderTypeModal()}
      {renderCartDialog()}
      {renderOrderConfirmationDialog()}
    </>
  );
};

export default withRouter(withStyles(styles)(ViewMenu));
