import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { Button, FormControl, FormControlLabel, Switch, TextField, Typography, withStyles } from '@material-ui/core';

import { Referral, ValidationErrors, ReferralReducerAction, validation } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  referral: Referral;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: ReferralReducerAction; value: any }>;
  handleNext: () => void;
  handleBack: () => void;
}

const StepDetails = ({ classes, referral, errors, setErrors, dispatch, handleNext, handleBack }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string, asOrganisation = false) => {
      const errorItems: UnknownObject = errors;
      if (field === 'name' || asOrganisation) {
        errorItems.organisation.name = validation.organisation.name(referral.organisation.name);
      }
      if (field === 'contact_name' || asOrganisation) {
        errorItems.organisation.contact_name = validation.organisation.contact_name(referral.organisation.contact_name);
      }
      if (field === 'contact_email' || asOrganisation) {
        errorItems.organisation.contact_email = validation.organisation.contact_email(referral.organisation.contact_email);
      }
      if (field === 'contact_phone' || asOrganisation) {
        errorItems.organisation.contact_phone = validation.organisation.contact_phone(referral.organisation.contact_phone);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [referral, errors, setErrors]
  );

  const canGoNext = (): boolean => {
    const { name, contact_name, contact_email, contact_phone } = errors.organisation;
    return name === false && contact_name === false && contact_email === false && contact_phone === false;
  };

  const handleValidateNext = () => {
    validate('', true);
    if (canGoNext()) {
      handleNext();
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title} variant="h6">
          Organisation
        </Typography>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Organisation name"
          variant="outlined"
          error={errors.organisation.name as boolean}
          helperText={errors.organisation.name}
          value={referral.organisation.name}
          tabIndex={0}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ReferralReducerAction.ORGANISATION_NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />
        <TextField
          className={classes.textField}
          data-qa="contact-name-textfield"
          required
          label="Contact name"
          variant="outlined"
          error={errors.organisation.contact_name as boolean}
          helperText={errors.organisation.contact_name}
          value={referral.organisation.contact_name}
          tabIndex={1}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ReferralReducerAction.ORGANISATION_CONTACT_NAME, value: event.target.value })}
          onKeyUp={() => validate('contact_name')}
          onBlur={() => validate('contact_name')}
        />
        <TextField
          className={classes.textField}
          data-qa="contact-email-textfield"
          required
          label="Contact email"
          variant="outlined"
          error={errors.organisation.contact_email as boolean}
          helperText={errors.organisation.contact_email}
          value={referral.organisation.contact_email}
          tabIndex={2}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ReferralReducerAction.ORGANISATION_CONTACT_EMAIL, value: event.target.value })}
          onKeyUp={() => validate('contact_email')}
          onBlur={() => validate('contact_email')}
        />
        <TextField
          className={classes.textField}
          data-qa="contact-phone-textfield"
          required
          label="Contact phone"
          variant="outlined"
          error={errors.organisation.contact_phone as boolean}
          helperText={errors.organisation.contact_phone}
          value={referral.organisation.contact_phone}
          tabIndex={3}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ReferralReducerAction.ORGANISATION_CONTACT_PHONE, value: event.target.value })}
          onKeyUp={() => validate('contact_phone')}
          onBlur={() => validate('contact_phone')}
        />

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Single location"
            labelPlacement="end"
            control={
              <Switch
                checked={referral.organisation.singleLocation}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({ type: ReferralReducerAction.ORGANISATION_SINGLE_LOCATION, value: event.target.checked as boolean })
                }
              />
            }
          />
        </FormControl>

        <div className={classes.buttonContainer}>
          <Button className={classes.button} variant="contained" color="primary" disabled={!canGoNext()} onClick={() => handleValidateNext()}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
