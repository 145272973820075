import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { withStyles } from '@material-ui/core';

import CustomTransferList, { CustomTransferListItem } from '../../../../../CustomTransferList';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  allergies: CustomTransferListItem[];
  assignedAllergies: CustomTransferListItem[];
  handleAllergyAssignment: (left: CustomTransferListItem[], right: CustomTransferListItem[]) => void;
}

const StepAllergies = ({ classes, allergies, assignedAllergies, handleAllergyAssignment }: Props): React.ReactElement => {
  return (
    <>
      <CustomTransferList
        labelLeft="Unassigned allergies"
        labelRight="Assigned allergies"
        allItems={allergies}
        currentItems={assignedAllergies}
        handleTransfer={handleAllergyAssignment}
      />
    </>
  );
};

export default withStyles(styles)(StepAllergies);
