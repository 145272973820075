import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import CreateLocationStaff from '../../../../components/Global/LocationStaff/Create';

import { createLocationUserInitQuery, Location } from './queries';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const LocCreateLocationStaff = ({ classes, history }: Props): React.ReactElement => {
  const [location, setLocation] = useState<Location>({
    id: '',
    name: '',
    users: [],
  });

  const { data: locationUserInitData } = useQuery(createLocationUserInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUserInitData) {
      setLocation(locationUserInitData.locations[0]);
    }
    return () => {
      mounted = false;
    };
  }, [locationUserInitData]);

  const handleFinish = (id: string) => {
    history.push(`/loc/staff/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[{ label: 'Locations', link: `/org/locations` }, { label: location.name, link: `/loc` }, { label: 'Staff', link: `/loc/staff` }, { label: 'Create' }]}>
        <CreateLocationStaff usedPins={location.users.filter((i) => i.pin).map((i) => i.pin)} handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(LocCreateLocationStaff));
