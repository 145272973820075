import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import CreateLocationPrinters from '../../../../../components/Global/LocationPrinters/Create';

import { createLocationPrintersInitQuery, Location } from './queries';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  location_id: string;
}

const OrgCreateLocationPrinters = ({ classes, history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      menus: [],
    },
    devices: [],
  });

  const { data: locationUserInitData } = useQuery(createLocationPrintersInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUserInitData) {
      setLocation(locationUserInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationUserInitData]);

  const handleFinish = () => {
    history.push(`/org/locations/${location_id}/printers`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Locations', link: `/org/locations` },
          { label: location.name, link: `/org/locations/${location_id}` },
          { label: 'Printers', link: `/org/locations/${location_id}/printers` },
          { label: 'Create' },
        ]}>
        <CreateLocationPrinters
          location_id={location_id}
          locations={location.devices.map((d) => d.device_config.location)}
          menus={location.organisation.menus}
          handleFinish={handleFinish}
        />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(OrgCreateLocationPrinters));
