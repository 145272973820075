import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { Button, IconButton, Paper, TextField, Tooltip, Typography, withStyles } from '@material-ui/core';

import { Delete as DeleteIcon } from '@material-ui/icons';

import { Referral, ReferralReducerAction, ValidationErrors, validation } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  referral: Referral;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: ReferralReducerAction; value: any }>;
  handleNext: () => void;
  handleBack: () => void;
}

const StepLocations = ({ classes, referral, errors, setErrors, dispatch, handleNext, handleBack }: Props): React.ReactElement => {
  const [locationIndex, setLocationIndex] = useState<number>(0);

  const validate = useCallback(
    (field: string, index: number = -1, asLocation = false) => {
      const errorItems: UnknownObject = {
        location: {},
      };
      const location = referral.locations[index >= 0 ? index : locationIndex];
      if (field === 'name' || asLocation) {
        errorItems.location.name = validation.location.name(location.name);
      }
      if (field === 'contact_name' || asLocation) {
        errorItems.location.contact_name = validation.location.contact_name(location.contact_name);
      }
      if (field === 'contact_email' || asLocation) {
        errorItems.location.contact_email = validation.location.contact_email(location.contact_email);
      }
      if (field === 'contact_phone' || asLocation) {
        errorItems.location.contact_phone = validation.location.contact_phone(location.contact_phone);
      }
      if (field === 'address_line_1' || asLocation) {
        errorItems.location.address_line_1 = validation.location.address_line_1(location.address_line_1);
      }
      if (field === 'address_city' || asLocation) {
        errorItems.location.address_city = validation.location.address_city(location.address_city);
      }
      if (field === 'address_postcode' || asLocation) {
        errorItems.location.address_postcode = validation.location.address_postcode(location.address_postcode);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [referral, locationIndex, errors, setErrors]
  );

  const canGoNext = (): boolean => {
    if (referral.organisation.singleLocation) {
      return validation.singleLocation(referral.locations[0]) as boolean;
    }
    return validation.locations(referral.locations) as boolean;
  };

  const setLocation = (index: number) => {
    setLocationIndex(index);
    validate('', index, true);
  };

  const addLocation = () => {
    dispatch({ type: ReferralReducerAction.ADD_LOCATION, value: {} });
    setTimeout(() => {
      setLocationIndex(referral.locations.length);
    }, 600);
  };

  const deleteLocation = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    setLocationIndex((i) => i - 1);
    dispatch({ type: ReferralReducerAction.DELETE_LOCATION, value: { id } });
  };

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title} variant="h6">
          {referral.organisation.singleLocation ? 'Location' : `Location ${locationIndex + 1}`}
        </Typography>
        <div className={classes.content}>
          {!referral.organisation.singleLocation && (
            <div className={classes.locationsList}>
              {referral.locations.map((location, index) => (
                <Paper
                  className={`${classes.locationsListItem} ${index === locationIndex ? classes.locationsListItemSelected : ''}`}
                  variant="outlined"
                  onClick={() => setLocation(index)}>
                  {`${index + 1} ${location.name || '???'}`}
                  {index > 0 && (
                    <Tooltip title="Delete location" arrow>
                      <IconButton size="small" onClick={(event: React.MouseEvent<HTMLElement>) => deleteLocation(event, location.id)}>
                        <DeleteIcon className={classes.locationsListItemDeleteIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Paper>
              ))}
              <Button className={classes.locationsListAddButton} variant="outlined" color="primary" onClick={() => addLocation()}>
                Add location
              </Button>
            </div>
          )}
          <div className={classes.locationsEdit}>
            <TextField
              className={classes.textField}
              data-qa="name-textfield"
              required
              label="Location name"
              variant="outlined"
              error={errors.location.name as boolean}
              helperText={errors.location.name}
              value={referral.locations[locationIndex].name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({ type: ReferralReducerAction.LOCATION_NAME, value: { index: locationIndex, value: event.target.value } })
              }
              onKeyUp={() => validate('name')}
              onBlur={() => validate('name')}
            />
            {!referral.organisation.singleLocation && (
              <>
                <TextField
                  className={classes.textField}
                  data-qa="contact-name-textfield"
                  required
                  label="Contact name"
                  variant="outlined"
                  error={errors.location.name as boolean}
                  helperText={errors.location.contact_name}
                  value={referral.locations[locationIndex].contact_name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch({ type: ReferralReducerAction.LOCATION_CONTACT_NAME, value: { index: locationIndex, value: event.target.value } })
                  }
                  onKeyUp={() => validate('contact_name')}
                  onBlur={() => validate('contact_name')}
                />
                <TextField
                  className={classes.textField}
                  data-qa="contact-email-textfield"
                  required
                  label="Contact email"
                  variant="outlined"
                  error={errors.location.contact_email as boolean}
                  helperText={errors.location.contact_email}
                  value={referral.locations[locationIndex].contact_email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch({ type: ReferralReducerAction.LOCATION_CONTACT_EMAIL, value: { index: locationIndex, value: event.target.value } })
                  }
                  onKeyUp={() => validate('contact_email')}
                  onBlur={() => validate('contact_email')}
                />
                <TextField
                  className={classes.textField}
                  data-qa="contact-phone-textfield"
                  required
                  label="Contact phone"
                  variant="outlined"
                  error={errors.location.contact_phone as boolean}
                  helperText={errors.location.contact_phone}
                  value={referral.locations[locationIndex].contact_phone}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch({ type: ReferralReducerAction.LOCATION_CONTACT_PHONE, value: { index: locationIndex, value: event.target.value } })
                  }
                  onKeyUp={() => validate('contact_phone')}
                  onBlur={() => validate('contact_phone')}
                />
              </>
            )}
            <TextField
              className={classes.textField}
              data-qa="address-line-1-textfield"
              required
              label="Address line 1"
              variant="outlined"
              error={errors.location.address_line_1 as boolean}
              helperText={errors.location.address_line_1}
              value={referral.locations[locationIndex].address_line_1}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({ type: ReferralReducerAction.LOCATION_ADDRESS_LINE_1, value: { index: locationIndex, value: event.target.value } })
              }
              onKeyUp={() => validate('address_line_1')}
              onBlur={() => validate('address_line_1')}
            />
            <TextField
              className={classes.textField}
              data-qa="address-line-2-textfield"
              label="Address line 2"
              variant="outlined"
              value={referral.locations[locationIndex].address_line_2}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({ type: ReferralReducerAction.LOCATION_ADDRESS_LINE_2, value: { index: locationIndex, value: event.target.value } })
              }
            />
            <TextField
              className={classes.textField}
              data-qa="address-city-textfield"
              required
              label="Address city"
              variant="outlined"
              error={errors.location.address_city as boolean}
              helperText={errors.location.address_city}
              value={referral.locations[locationIndex].address_city}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({ type: ReferralReducerAction.LOCATION_ADDRESS_CITY, value: { index: locationIndex, value: event.target.value } })
              }
              onKeyUp={() => validate('address_city')}
              onBlur={() => validate('address_city')}
            />
            <TextField
              className={classes.textField}
              data-qa="address-postcode-textfield"
              required
              label="Address postcode"
              variant="outlined"
              error={errors.location.address_postcode as boolean}
              helperText={errors.location.address_postcode}
              value={referral.locations[locationIndex].address_postcode}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({ type: ReferralReducerAction.LOCATION_ADDRESS_POSTCODE, value: { index: locationIndex, value: event.target.value } })
              }
              onKeyUp={() => validate('address_postcode')}
              onBlur={() => validate('address_postcode')}
            />
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button className={classes.button} variant="outlined" color="primary" onClick={() => handleBack()}>
            Back
          </Button>
          <Button className={classes.button} variant="contained" color="primary" disabled={!canGoNext()} onClick={() => handleNext()}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(StepLocations);

// LOCATION_CONTACT_ADDRESS_LINE_1,
// LOCATION_CONTACT_ADDRESS_LINE_2,
// LOCATION_CONTACT_ADDRESS_CITY,
// LOCATION_CONTACT_ADDRESS_POSTCODE,
