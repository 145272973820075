import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const _updateMenusOrderIndexMutation = gql`
  mutation ($objects: [menus_insert_input!]!) {
    insert_menus(objects: $objects, on_conflict: { constraint: menus_pkey, update_columns: [order_index] }) {
      affected_rows
    }
  }
`;

export const updateMenusOrderIndexMutation = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_menus: {
      affected_rows: string;
    };
  }>({ mutation: _updateMenusOrderIndexMutation!, variables });
};
