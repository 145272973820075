import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Chip, Grid, TextField, Tooltip, Typography, withStyles } from '@material-ui/core';

import { AddCircle as AddCircleIcon, Help as InfoIcon } from '@material-ui/icons';

import { GridCard, SortableGrid } from '../../../../components/Grid';
import CustomSortableTable, { SortableTableAction, SortableTableHeader, SortableTableRow } from '../../../../components/CustomSortableTable';

import CustomViewToggle, { ViewType } from '../../../CustomViewToggle';

import { booleanToYesNo, capitaliseFirst, numberToMoney, pluralise, secondsToTime } from '../../../../utils/stringUtils';
import { createPriceTaxString } from '../../../../utils/conversions';

import { CategoryItem, CategoryItemModifierAsignment, CategoryItemAllergyAsignment } from '../../Categories/Common/types';

import { SortableGridChild } from '../../../Grid/SortableGrid';

import { updateDishOrderIndexMutation, updateDishScore, updateDrinkOrderIndexMutation, updateDrinkScore } from './mutations'; // Mutation to update score
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  organisation_id?: string;
  currency: string;
  locale: string;
  items: CategoryItem[];
  category_type: 'dish' | 'drink';
  handleSetItems: React.Dispatch<React.SetStateAction<CategoryItem[]>>;
  handleEditItem?: (id: string) => void;
  handleCreateItem?: () => void;
  handleItemScoreUpdate: () => void;
}

const ListCategoryItems = ({
  classes,
  organisation_id,
  currency,
  locale,
  items,
  category_type,
  handleSetItems,
  handleEditItem,
  handleCreateItem,
  handleItemScoreUpdate,
}: Props): React.ReactElement => {
  const [viewType, setViewType] = useState<ViewType>(ViewType.TABLE);
  const [editedScores, setEditedScores] = useState<Map<string, number>>(new Map());

  const handleScoreChange = (id: string, value: number) => {
    setEditedScores((prev) => new Map(prev).set(id, value));
  };

  const handleSaveScore = async (id: string) => {
    const score = editedScores.get(id);
    if (score !== undefined) {
      try {
        if (category_type === 'dish') {
          await updateDishScore(id, score).then(() => handleItemScoreUpdate());
        } else if (category_type === 'drink') {
          await updateDrinkScore(id, score).then(() => handleItemScoreUpdate());
        }
        setEditedScores((prev) => {
          const newMap = new Map(prev);
          newMap.delete(id);
          return newMap;
        });
      } catch (error) {
        console.error(`Failed to save score for ${category_type} ${id}`, error);
      }
    }
  };

  const renderModifierChips = (item: CategoryItem) => {
    if (item.modifier_assignments.length === 0) {
      return (
        <div className={classes.chips}>
          <Typography className={classes.chipLabel} variant="subtitle2">
            No modifiers
          </Typography>
        </div>
      );
    }
    return (
      <div className={classes.chips}>
        {item.modifier_assignments.map((m: CategoryItemModifierAsignment) => {
          const { name, price, tax } = m.modifier;
          const mPrice = numberToMoney(price, currency, locale, true);
          const mTax = createPriceTaxString(price, tax, currency, locale, true);
          return (
            <Tooltip title={`${mPrice} ~ ${tax / 10}% tax = ${mTax}`} arrow placement="top">
              <Chip key={name} className={classes.chip} deleteIcon={<InfoIcon />} size="small" variant="outlined" label={name} />
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const renderAllergyChips = (item: CategoryItem) => {
    if (item.allergy_assignments.length === 0) {
      return (
        <div className={classes.chips}>
          <Typography className={classes.chipLabel} variant="subtitle2">
            No allergies
          </Typography>
        </div>
      );
    }
    return (
      <div className={classes.chips}>
        {item.allergy_assignments.map((m: CategoryItemAllergyAsignment) => (
          <Chip key={m.allergy.name} className={classes.chip} size="small" variant="outlined" label={m.allergy.name} />
        ))}
      </div>
    );
  };

  const formatTaxAsMoney = (price: number, tax: number) => createPriceTaxString(price, tax, currency, locale, true);

  const formatAsMoney = (price: number) => numberToMoney(price, currency, locale, true);

  const onDragEnd = (result: SortableGridChild[]) => {
    handleSetItems((_: unknown) => {
      return result.map((r) => items.find((i) => i.id === r.key)!).map((m, order_index) => ({ ...m, order_index }));
    });
  };

  const handleSaveOrdering = async () => {
    const objects = items.map((i) => ({
      id: i.id,
      name: i.name,
      order_index: i.order_index,
      description: i.description,
      price: i.price,
      tax: i.tax,
      prep_time: i.prep_time,
      organisation_id,
    }));

    const updateVariables = {
      objects,
    };

    if (category_type === 'dish') {
      await updateDishOrderIndexMutation(updateVariables);
    }
    if (category_type === 'drink') {
      await updateDrinkOrderIndexMutation(updateVariables);
    }
    setViewType(ViewType.TABLE);
  };

  const renderViewType = () => {
    const title = pluralise(capitaliseFirst(category_type), items.length);
    const createLabel = `Create ${category_type}`;
    if (viewType === ViewType.TABLE) {
      const headers: SortableTableHeader[] = [
        { key: 'ordering', label: 'Ordering', hidden: true },
        { key: 'name', label: 'Name' },
        { key: 'score', label: 'Score' },
        { key: 'tag', label: 'Tag' },
        { key: 'active', label: 'Active' },
        { key: 'price', label: 'Price' },
        { key: 'tax', label: 'Tax' },
        { key: 'prep_time', label: 'Prep time' },
        { key: 'modifiers', label: 'Modifiers', align: 'center' },
        { key: 'allergies', label: 'Allergies', align: 'center' },
        { key: 'bypass_prep', label: 'Bypass prep', align: 'center' },
        { key: 'should_print', label: 'Print on ticket', align: 'center' },
        { key: 'auto_serve_when_ready', label: 'Auto serve when ready', align: 'center' },
      ];

      const rows: SortableTableRow[] = items.map((item: CategoryItem) => {
        const score = editedScores.get(item.id) ?? item.score ?? 0;
        return {
          key: item.id,
          disabled: !item.active,
          actions: handleEditItem
            ? [
                {
                  label: 'Edit',
                  onClick: () => handleEditItem(item.id),
                },
                {
                  label: 'Save Score',
                  show: editedScores.has(item.id),
                  onClick: () => handleSaveScore(item.id),
                },
              ]
            : [],
          columns: [
            {
              key: 'ordering',
              label: item.order_index + 1,
            },
            {
              key: 'name',
              label: item.name,
            },
            {
              key: 'score',
              label: score,
              component: <TextField type="number" value={score} onChange={(e) => handleScoreChange(item.id, parseInt(e.target.value, 10))} />,
            },
            {
              key: 'tag',
              label: item.tag || '',
              component: item.tag ? <Chip variant="outlined" size="small" label={item.tag} /> : undefined,
            },
            {
              key: 'active',
              label: booleanToYesNo(item.active),
            },
            {
              key: 'price',
              label: formatAsMoney(item.price),
            },
            {
              key: 'tax',
              label: `${item.tax / 10}% tax = ${formatTaxAsMoney(item.price, item.tax)}`,
            },
            {
              key: 'prep_time',
              label: item.prep_time,
              component: <Chip size="small" label={secondsToTime(item.prep_time)} variant="outlined" />,
            },
            {
              key: 'modifiers',
              label: item.modifier_assignments.length,
            },
            {
              key: 'allergies',
              label: item.allergy_assignments.length,
            },
            {
              key: 'bypass_prep',
              label: booleanToYesNo(item.bypass_prep),
            },
            {
              key: 'should_print',
              label: booleanToYesNo(item.should_print),
            },
            {
              key: 'auto_serve_when_ready',
              label: booleanToYesNo(item.auto_serve_when_ready),
            },
          ],
          collapsible:
            item.modifier_assignments.length > 0 || item.allergy_assignments.length > 0
              ? [
                  {
                    headers: [],
                    rows: [
                      {
                        key: 'modifiers',
                        columns: [
                          {
                            key: 'name',
                            label: 'Modifiers:',
                            width: 100,
                          },
                          {
                            key: 'modifiers',
                            label: '',
                            component: renderModifierChips(item),
                          },
                        ],
                      },
                      {
                        key: 'allergies',
                        columns: [
                          {
                            key: 'name',
                            label: 'Allergies:',
                            width: 100,
                          },
                          {
                            key: 'allergies',
                            label: '',
                            component: renderAllergyChips(item),
                          },
                        ],
                      },
                    ],
                  },
                ]
              : [],
        };
      });

      const actions: SortableTableAction[] = handleCreateItem
        ? [
            {
              key: 'create',
              label: createLabel,
              icon: <AddCircleIcon />,
              onClick: handleCreateItem,
            },
          ]
        : [];

      return (
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CustomSortableTable title={title} orderdBy="ordering" ordered="asc" actions={actions} headers={headers} counter={items.length} rows={rows} />
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        <SortableGrid
          gridSize={3}
          onDragEnd={onDragEnd}
          handleSave={handleSaveOrdering}
          items={items.map((item: CategoryItem, index: number) => ({
            key: item.id,
            label: item.name,
            component: <GridCard fill noGrid title={item.name} counter={index + 1} subTitle={item.description} />,
          }))}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item xs={12}>
        <CustomViewToggle value={viewType} sortable handleChangeView={setViewType} />
      </Grid>
      {renderViewType()}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ListCategoryItems));
