import React, { useEffect, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { Location, LocationReducerAction, ValidationErrors } from '../..';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    sectionDescription: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      marginBottom: theme.spacing(3),
    },
    inputField: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    accordionSummary: {
      backgroundColor: '#EFF7ED',
      padding: theme.spacing(2),
      '& .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    accordionDetails: {
      backgroundColor: '#EFF7ED',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(5),
    },
    stepContainer: {
      display: 'flex',
      alignItems: 'stretch',
      gap: theme.spacing(2),
    },
    stepTitle: {
      fontWeight: 'bold',
      minWidth: '120px',
    },
    stepContent: {
      color: '#4A4A4A',
      flex: 1,
    },
    infoIcon: {
      marginRight: theme.spacing(1),
      color: '#6A6A6A',
    },
    helperText: {
      color: '#6B7280',
      marginTop: theme.spacing(1),
    },
    stickyButtonContainer: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      zIndex: 1000,
    },
    stickyButton: {
      paddingInline: theme.spacing(4),
    },
  });

interface PacingProps extends WithStyles<typeof styles> {
  location: Location;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: LocationReducerAction; value: any }>;
  handleSave?: () => void;
  isLocationCreate: boolean;
}

const Pacing: React.FC<PacingProps> = ({ classes, location, errors, setErrors, dispatch, handleSave, isLocationCreate }) => {
  // const validate = useCallback(
  //   (field: string) => {
  //     const errorItems: { [key: string]: string } = {};
  //     if (field === 'slot_duration') {
  //       errorItems.slot_duration = validation.slot_duration(location.slot_duration) as string;
  //     }
  //     if (field === 'max_orders_per_slot') {
  //       errorItems.max_orders_per_slot = validation.max_orders_per_slot(location.max_orders_per_slot) as string;
  //     }
  //     if (field === 'max_order_score') {
  //       errorItems.max_order_score = validation.max_order_score(location.max_order_score) as string;
  //     }
  //     setErrors({ ...errors, ...errorItems });
  //   },
  //   [location, errors, setErrors]
  // );
  const [initialStateLocation, setInitialStateLocation] = useState<Location | null>(null);
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    setInitialStateLocation(location);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialStateLocation && JSON.stringify(initialStateLocation) !== JSON.stringify(location)) {
      setStateChanged(true);
    } else {
      setStateChanged(false);
    }
  }, [location, initialStateLocation]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Pacing
        </Typography>
      </Grid>

      {/* Accordion Section */}
      {/*<Grid item xs={12}>*/}
      {/*  <Accordion>*/}
      {/*    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummary}>*/}
      {/*      <InfoIcon className={classes.infoIcon} />*/}
      {/*      <Typography variant="subtitle1">How pacing works?</Typography>*/}
      {/*    </AccordionSummary>*/}
      {/*    <Divider />*/}
      {/*    <AccordionDetails className={classes.accordionDetails}>*/}
      {/*      /!* Step 1 *!/*/}
      {/*      <Box className={classes.stepContainer}>*/}
      {/*        <Box display={'flex'} flexDirection={'column'} style={{ maxWidth: '288px' }}>*/}
      {/*          <Typography variant="subtitle2" className={classes.stepTitle}>*/}
      {/*            STEP 1*/}
      {/*          </Typography>*/}
      {/*          <Typography variant="subtitle2" className={classes.stepTitle}>*/}
      {/*            Sed vitae vestibulum odio, eu maximus lorem. Suspendisse justo nunc*/}
      {/*          </Typography>*/}
      {/*        </Box>*/}
      {/*        <Typography className={classes.stepContent}>*/}
      {/*          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut sem eget tellus sodales posuere. Suspendisse suscipit massa dignissim, pharetra velit a, dictum*/}
      {/*          sapien. Sed fermentum facilisis nisl. Curabitur varius consectetur orci, id lobortis diam ultricies sed. Cras vitae rutrum neque. Donec tincidunt felis ex, sed*/}
      {/*          hendrerit ante vehicula sit amet.*/}
      {/*        </Typography>*/}
      {/*      </Box>*/}

      {/*      /!* Step 2 *!/*/}
      {/*      <Box className={classes.stepContainer}>*/}
      {/*        <Box display={'flex'} flexDirection={'column'} style={{ maxWidth: '288px' }}>*/}
      {/*          <Typography variant="subtitle2" className={classes.stepTitle}>*/}
      {/*            STEP 2*/}
      {/*          </Typography>*/}
      {/*          <Typography variant="subtitle2" className={classes.stepTitle}>*/}
      {/*            Sed vitae vestibulum odio, eu maximus lorem. Suspendisse justo nunc*/}
      {/*          </Typography>*/}
      {/*        </Box>*/}

      {/*        <Typography className={classes.stepContent}>*/}
      {/*          Sed vitae vestibulum odio, eu maximus lorem. Suspendisse justo nunc, lobortis et sem eget, congue efficitur ante. Duis sed nisl hendrerit, sagittis mi ut, iaculis*/}
      {/*          tellus. Phasellus vulputate nunc eu leo vestibulum laoreet.*/}
      {/*        </Typography>*/}
      {/*      </Box>*/}

      {/*      /!* Step 3 *!/*/}
      {/*      <Box className={classes.stepContainer}>*/}
      {/*        <Box display={'flex'} flexDirection={'column'} style={{ maxWidth: '288px' }}>*/}
      {/*          <Typography variant="subtitle2" className={classes.stepTitle}>*/}
      {/*            STEP 3*/}
      {/*          </Typography>*/}
      {/*          <Typography variant="subtitle2" className={classes.stepTitle}>*/}
      {/*            Sed vitae vestibulum odio, eu maximus lorem. Suspendisse justo nunc*/}
      {/*          </Typography>*/}
      {/*        </Box>*/}
      {/*        <Typography className={classes.stepContent}>*/}
      {/*          Duis et tellus ultricies, convallis arcu non, pretium elit. Phasellus gravida fringilla sapien quis consectetur. Vivamus pulvinar dolor eget quam auctor facilisis.*/}
      {/*          Etiam vitae tortor eros. Nam efficitur mauris sed quam tempor efficitur vitae at odio. Morbi augue nulla, pharetra a odio at, maximus convallis sem.*/}
      {/*        </Typography>*/}
      {/*      </Box>*/}
      {/*    </AccordionDetails>*/}
      {/*  </Accordion>*/}
      {/*</Grid>*/}

      {/* Enable Pacing Section */}
      {/*<Grid item xs={12}>*/}
      {/*  <Paper className={classes.paper}>*/}
      {/*    <Box display="flex" alignItems="center">*/}
      {/*      <Typography variant="body1" className={classes.helperText}>*/}
      {/*        Enable pacing*/}
      {/*      </Typography>*/}
      {/*      <Switch*/}
      {/*        color="primary"*/}
      {/*        // checked={location.pacing_enabled}*/}
      {/*        // onChange={(event: React.ChangeEvent<HTMLInputElement>) =>*/}
      {/*        //   dispatch({ type: LocationReducerAction.SET_PACING_ENABLED, value: event.target.checked })*/}
      {/*        // }*/}
      {/*      />*/}
      {/*    </Box>*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}

      {/* Time Slot Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Time slot
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Set the duration for each time slot.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <FormControl
            variant="outlined"
            className={classes.inputField}
            // required
            // error={errors.slot_duration as boolean}
          >
            <InputLabel id="slot-duration-label">Slot duration</InputLabel>
            <Select
              labelId="slot-duration-label"
              label="Slot duration"
              value={location.order_interval}
              onChange={(event: React.ChangeEvent<any>) =>
                dispatch({
                  type: LocationReducerAction.SET_ORDER_INTERVAL,
                  value: event.target.value,
                })
              }
              // onBlur={() => validate('slot_duration')}
              // onKeyUp={() => validate('slot_duration')}
            >
              <MenuItem value={15}>15 minutes</MenuItem>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              {/* Add more options as needed */}
            </Select>
            {/*<FormHelperText className={classes.helperText}>{errors.slot_duration}</FormHelperText>*/}
          </FormControl>
        </Paper>
      </Grid>

      {/* Maximum Orders Section */}
      {/*<Grid item xs={12} sm={4}>*/}
      {/*  <Box>*/}
      {/*    <Typography variant="subtitle1" className={classes.sectionTitle}>*/}
      {/*      Maximum orders*/}
      {/*    </Typography>*/}
      {/*    <Typography variant="body2" className={classes.sectionDescription}>*/}
      {/*      Set the maximum number of orders per slot.*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} sm={8}>*/}
      {/*  <Paper className={classes.paper}>*/}
      {/*    <TextField*/}
      {/*      label="Number of orders per slot"*/}
      {/*      variant="outlined"*/}
      {/*      type="number"*/}
      {/*      required*/}
      {/*      className={classes.inputField}*/}
      {/*      value={location.preorder_pacing_limit || ''}*/}
      {/*      // error={errors.max_orders_per_slot as boolean}*/}
      {/*      // helperText={errors.max_orders_per_slot}*/}
      {/*      onChange={(e) => dispatch({ type: LocationReducerAction.SET_PREORDER_PACING_LIMIT, value: e.target.value })}*/}
      {/*      // onBlur={() => validate('max_orders_per_slot')}*/}
      {/*      // onKeyUp={() => validate('max_orders_per_slot')}*/}
      {/*      FormHelperTextProps={{ className: classes.helperText }}*/}
      {/*    />*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}

      {/* Maximum Order Score Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Maximum order score
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Set the maximum order score per slot.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <TextField
            label="Order score per slot"
            variant="outlined"
            type="number"
            required
            className={classes.inputField}
            value={location.preorder_pacing_limit || ''}
            // error={errors.max_order_score as boolean}
            // helperText={errors.max_order_score}
            onChange={(e) => dispatch({ type: LocationReducerAction.SET_PREORDER_PACING_LIMIT, value: e.target.value })}
            // onBlur={() => validate('max_order_score')}
            // onKeyUp={() => validate('max_order_score')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
        </Paper>
      </Grid>
      {stateChanged && !isLocationCreate && (
        <Box className={classes.stickyButtonContainer}>
          <Button variant="contained" color="primary" className={classes.stickyButton} onClick={handleSave}>
            Save changes
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default withStyles(styles)(Pacing);
