import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { locationPrintersReducer, LocationPrinterReducerAction, Menu } from '../Common';
import Steps from '../Common/components/Steps';

import styles from './styles';
import { editLocationPrinterInitQuery } from './queries';
import { updateLocationPrinter } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  printer_id: string;
  locations: string[];
  menus: Menu[];
  handleFinish: () => void;
}

const EditLocationPrinter = ({ classes, history, printer_id, locations, menus, handleFinish }: Props): React.ReactElement => {
  const [printer, dispatchPrinter] = useReducer(locationPrintersReducer, {
    name: '',
    location: '',
    printCopyToTill: false,
    categories: [],
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editLocationUserInitQueryData } = useQuery(editLocationPrinterInitQuery(printer_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationUserInitQueryData) {
      dispatchPrinter({ type: LocationPrinterReducerAction.INIT, value: editLocationUserInitQueryData.location_devices_by_pk });
    }
    return () => {
      mounted = false;
    };
  }, [editLocationUserInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const { location, categories, printCopyToTill, name } = printer;

    const updateVariables = {
      pk_columns: {
        id: printer_id,
      },
      set: {
        name: name.trim(),
      },
      append: {
        device_config: {
          location: location.trim(),
          categories,
          printCopyToTill,
        },
      },
    };

    await updateLocationPrinter(updateVariables);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps completeLabel="Save" printer={printer} locations={[...new Set(locations)]} menus={menus} dispatch={dispatchPrinter} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditLocationPrinter));
