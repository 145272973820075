import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';

const { REACT_APP_API_URL: API_URL } = process.env;
const httpLink = createHttpLink({
  uri: API_URL,
});

const authLink = setContext((_, { headers }) => {
  Auth.currentSession().then((res) => {
    let accessToken = res.getAccessToken();
    let jwt = accessToken.getJwtToken();
    localStorage.setItem('token', jwt);
  });

  const token = localStorage.getItem('token') || '';
  const userRole = localStorage.getItem('user_role') || '';
  const systemId = localStorage.getItem('system_id') || '';
  let bearer = token ? `Bearer ${token}` : '';
  if (userRole && systemId) {
    bearer += `|||${btoa(userRole)}|||${btoa(systemId)}`;
  }
  return {
    headers: {
      ...headers,
      Authorization: bearer,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;
