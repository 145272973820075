import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    toolbar: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    toolbarContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    toolbarTitleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    toolbarDescriptionContainer: {
      marginTop: theme.spacing(1),
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    toolbarActionsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button': {
        marginLeft: theme.spacing(1),
      },
    },
    footer: {
      marginTop: theme.spacing(2),
    },
    footerActionsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      '& > button': {
        marginLeft: theme.spacing(1),
      },
    },
    footerLabel: {
      marginLeft: theme.spacing(2),
    },
    collapsibleHeader: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      '& > $counter': {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    title: {
      flexGrow: 1,
    },
    description: {
      flexGrow: 1,
    },
    counter: {
      marginRight: theme.spacing(2),
      minWidth: theme.spacing(5),
      width: 'unset',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    collapseCell: {
      width: 60,
    },
    totalCell: {
      fontWeight: 'bold',
    },
    collapsibleRowItem: {
      borderBottomWidth: 0,
      '& td': {
        borderBottomWidth: 0,
      },
      '& + $tableRow, + $lastRow': {
        borderTopWidth: 1,
        borderTopColor: theme.palette.divider,
        borderTopStyle: 'solid',
      },
    },
    tableHeaderNoRows: {
      '&:last-of-type': {
        borderBottomWidth: 0,
        '& th': {
          borderBottomWidth: 0,
        },
      },
    },
    tableRow: {
      borderBottomWidth: 0,
    },
    lastRow: {
      borderBottomWidth: 0,
      '& td': {
        borderBottomWidth: 0,
      },
    },
    disabled: {
      opacity: 0.6,
    },
    error: {
      color: theme.palette.error.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    success: {
      color: theme.palette.success.main,
    },
    formControlLabel: {
      marginBottom: theme.spacing(2),
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(2),
      '& > button': {
        marginLeft: theme.spacing(1),
      },
    },
    filterPaper: {
      width: '100%',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    filtersTitle: {
      marginBottom: theme.spacing(1),
    },
    filter: {
      marginRight: theme.spacing(4),
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    searchButton: {
      cursor: 'pointer',
      width: 24,
      height: 24,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    destructiveButton: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        color: theme.palette.error.contrastText,
        // backgroundColor: theme.palette.error.dark,
      },
    },
  });

export default styles;
