import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    list: {
      flexGrow: 1,
    },
    listItem: {
      cursor: 'pointer',
      color: theme.palette.grey[700],
      '&:hover': {
        '& $listItemIcon': {
          color: theme.palette.primary.main,
        },
        '& $listItemText': {
          color: theme.palette.primary.main,
        },
      },
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    listItemIcon: {
      alignSelf: 'flex-start',
      color: theme.palette.grey[700],
      marginRight: theme.spacing(1),
      minWidth: 24,
    },
    listItemText: {
      fontWeight: 500,
      color: theme.palette.grey[700],
      // marginLeft: theme.spacing(1),
      // height: 24,
      marginTop: 0,
      marginBottom: 0,
    },
    listItemActive: {
      color: theme.palette.secondary.main,
      '& $listItemText': {
        color: theme.palette.secondary.main,
      },
      '& $listItemIcon': {
        color: theme.palette.secondary.main,
      },
    },
    clearIcon: {
      padding: 0,
      alignSelf: 'flex-start',
      color: theme.palette.grey[700],
      minWidth: 24,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    listDivider: {
      margin: theme.spacing(1),
    },
    menu: {
      position: 'absolute',
      marginLeft: theme.spacing(2),
      zIndex: 99,
    },
    paper: {
      backgroundColor: theme.palette.background.default,
    },
  });

export default styles;
