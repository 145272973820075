import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Button, Typography, withStyles } from '@material-ui/core';

import { Referral } from '../../../Common';

import styles from './styles';
import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { pluralise } from '../../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  referral: Referral;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  submit: () => void;
  handleBack: () => void;
}

const StepSummary = ({ classes, referral, setStep, submit, handleBack }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title} variant="h6">
          Summary
        </Typography>
        <div className={classes.content}>
          <ReadOnlyBlock
            title="Organisation"
            gridSize={4}
            items={[
              { label: 'Name', value: referral.organisation.name },
              { label: 'Contact name', value: referral.organisation.contact_name },
              { label: 'Contact email', value: referral.organisation.contact_email },
              { label: 'Contact phone', value: referral.organisation.contact_phone },
            ]}
            handleEdit={() => setStep(0)}
          />

          <ReadOnlyBlock
            title={pluralise('Location', referral.organisation.singleLocation ? 1 : 2)}
            gridSize={4}
            items={
              referral.organisation.singleLocation
                ? [
                    { label: 'Name', value: referral.locations[0].name },
                    { label: 'Address line 1', value: referral.locations[0].address_line_1 },
                    { label: 'Address line 2', value: referral.locations[0].address_line_2 },
                    { label: 'Address city', value: referral.locations[0].address_city },
                    { label: 'Address postcode', value: referral.locations[0].address_postcode },
                  ]
                : referral.locations.map((i) => ({
                    label: i.name,
                    list: [
                      `Contact name: ${i.contact_name}`,
                      `Contact email: ${i.contact_email}`,
                      `Contact phone: ${i.contact_phone}`,
                      `Address line 1: ${i.address_line_1}`,
                      i.address_line_2 ? `Address line 2: ${i.address_line_2}` : null,
                      `Address city: ${i.address_city}`,
                      `Address postcode: ${i.address_postcode}`,
                    ],
                  }))
            }
            handleEdit={() => setStep(1)}
          />
          <ReadOnlyBlock title="Note" gridSize={4} items={[{ label: 'Optional note', value: referral.note }]} handleEdit={() => setStep(2)} />
        </div>
        <div className={classes.buttonContainer}>
          <Button className={classes.button} variant="outlined" color="primary" onClick={() => handleBack()}>
            Back
          </Button>
          <Button className={classes.button} variant="contained" color="primary" onClick={() => submit()}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(StepSummary);

//booleanToYesNo(category.active)
