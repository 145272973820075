import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

const updateAdminUserMutation = gql`
  mutation ($pk_columns: users_admin_pk_columns_input!, $set: users_admin_set_input!) {
    update_users_admin_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateAdminUser = async (variables: object) => {
  return await gqlClient.mutate<{
    update_users_admin_by_pk: {
      id: string;
    };
  }>({ mutation: updateAdminUserMutation!, variables });
};
