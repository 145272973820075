import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectMenuInitQuery = (id: string) => ({
  menus_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
      description: types.string,
      active: types.boolean,
      available_from: types.string,
      available_to: types.string,
      menu_types: types.custom<string[]>(),
      is_primary: types.boolean,
      categories: [
        {
          id: types.string,
          name: types.string,
          active: types.boolean,
          colour_id: types.string,
          menu_id: types.string,
          order_index: types.number,
          category_type: types.string,
          score: types.number,
          organisation: {
            id: types.string,
            name: types.string,
            currency: types.string,
            locale: types.string,
          },
          colour: {
            hex: types.string,
            name: types.string,
          },
          dish_assignments_aggregate: {
            aggregate: {
              count: types.number,
            },
          },
          drink_assignments_aggregate: {
            aggregate: {
              count: types.number,
            },
          },
        },
      ],
    }
  ),
});

type X = Unpacked<typeof _selectMenuInitQuery>;
export type Menu = X['menus_by_pk'];
export type Category = Menu['categories'][0];

export const selectMenuInitQuery = (id: string) => gql`
  ${query(_selectMenuInitQuery(id))}
`;
