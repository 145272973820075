import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Chip, withStyles } from '@material-ui/core';

import ReadOnlyBlock from '../../../../../../../components/ReadOnlyBlock';
import { booleanToYesNo, capitaliseFirst, timeToFormattedString } from '../../../../../../../utils/stringUtils';
import { EnumZettleUploadItemType, Menu } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  menu: Menu;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, menu, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        gridSize={6}
        title={menu.name}
        items={[
          { label: 'Description', value: menu.description },
          {
            label: 'Menu types',
            components: menu.menu_types?.map((m: string) => (
              <Chip key={m} className={classes.chip} size="small" variant="outlined" label={capitaliseFirst(m.toLowerCase().replace(/_/g, ' '))} />
            )),
          },
          { label: 'Active', value: booleanToYesNo(menu.active) },
          { label: 'All day', value: booleanToYesNo(menu.all_day) },
          { label: 'Available from', value: timeToFormattedString(menu.available_from, 'HM', true) },
          { label: 'Available to', value: timeToFormattedString(menu.available_to, 'HM', true) },
        ]}
        handleEdit={() => setStep(0)}
      />
      <ReadOnlyBlock
        gridSize={6}
        items={[
          { label: 'Dishes', value: `${menu.items.filter((i) => i.type === EnumZettleUploadItemType.DISH).length}` },
          { label: 'Drinks', value: `${menu.items.filter((i) => i.type === EnumZettleUploadItemType.DRINK).length}` },
        ]}
        handleEdit={() => setStep(1)}
      />
      <ReadOnlyBlock
        gridSize={6}
        items={[
          { label: 'Dish categories', list: [...new Set(menu.items.filter((i) => i.type === EnumZettleUploadItemType.DISH).map((i) => i.category))] },
          { label: 'Drink categories', list: [...new Set(menu.items.filter((i) => i.type === EnumZettleUploadItemType.DRINK).map((i) => i.category))] },
        ]}
        handleEdit={() => setStep(2)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
