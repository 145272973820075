import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    listContainer: {
      // flexGrow: 1,
      borderRightWidth: 1,
      borderRightColor: theme.palette.divider,
      borderRightStyle: 'solid',
      '&:last-of-type': {
        borderRightWidth: 0,
      },
    },
    list: {
      maxHeight: theme.spacing(50),
      overflow: 'auto',
    },
    listItem: {
      flexGrow: 1,
      borderRightWidth: 1,
      borderRightColor: theme.palette.divider,
      borderRightStyle: 'solid',
      '&:last-of-type': {
        borderRightWidth: 0,
      },
    },
    listTitle: {
      marginLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    listItemText: {
      fontWeight: 500,
      color: theme.palette.grey[700],
      marginLeft: theme.spacing(1),
      height: 24,
      marginTop: 0,
      marginBottom: 0,
    },
    listItemIcon: {
      color: theme.palette.grey[700],
      minWidth: 24,
    },
    active: {
      '& > $listItemText, $listItemIcon': {
        color: theme.palette.primary.main,
      },
    },
    selected: {
      '& > $listItemText, $listItemIcon': {
        color: theme.palette.secondary.main,
      },
    },
  });

export default styles;
