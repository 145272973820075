import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    menu: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menuTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      flexGrow: 1,
    },
    categoriesContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexWrap: 'wrap',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    categories: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      flexGrow: 1,
    },
    category: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    categoryTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      flexGrow: 1,
    },
    iconButton: {
      marginLeft: theme.spacing(1),
      width: 30,
      height: 30,
    },
  });

export default styles;
