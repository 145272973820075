import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      borderWidth: 1,
      borderStyle: 'dashed',
      borderSpacing: '10px 10px',
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(4),
      '&:only-child': {
        marginBottom: 0,
      },
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    counter: {
      justifySelf: 'flex-end',
    },
    fill: {
      height: '100%',
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center',
      '& $content': {
        flexGrow: 0,
      },
    },
    content: {
      flexGrow: 1,
    },
    item: {
      // marginBottom: theme.spacing(2),
    },
    label: {
      marginBottom: theme.spacing(1),
    },
    image: {
      height: theme.spacing(16),
    },
    icon: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      filter: theme.palette.type === 'dark' ? 'invert(1)' : '',
      opacity: theme.palette.type === 'dark' ? 1 : 0.87,
    },
    list: {
      paddingLeft: theme.spacing(1),
    },
    actions: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      justifySelf: 'flex-end',
      '& > button': {
        marginLeft: theme.spacing(2),
      },
    },
    delete: {
      marginLeft: theme.spacing(2),
      color: theme.palette.error.main,
      borderColor: `${theme.palette.error.main}AA`,
      '&:hover': {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
    },
  });

export default styles;
