import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertLocationUserMutation = gql`
  mutation ($object: users_location_insert_input!) {
    insert_users_location_one(object: $object) {
      id
    }
  }
`;

export const createLocationUser = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_users_location_one: {
      id: string;
    };
  }>({ mutation: insertLocationUserMutation!, variables });
  return res.data?.insert_users_location_one.id;
};
