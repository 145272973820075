import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { FormControl, FormControlLabel, FormHelperText, InputAdornment, Switch, TextField, withStyles } from '@material-ui/core';

import { getPercentageValueOfTotal } from '../../../../../../utils/conversions';
import { getCurrencySymbol, numberToMoney, secondsToTime } from '../../../../../../utils/stringUtils';
import { CategoryItem, ValidationErrors, CategoryItemReducerAction, validation } from '../..';
import CustomTimePicker from '../../../../../CustomTimePicker';

import styles from './styles';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  categoryItem: CategoryItem;
  category_type: string;
  categoryTags: string[];
  currency: string;
  locale: string;
  taxRate: number;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: CategoryItemReducerAction; value: any }>;
}

const filter = createFilterOptions<string>();

const StepDetails = ({ classes, categoryItem, category_type, categoryTags, currency, locale, taxRate, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      switch (field) {
        case 'name':
          errorItems.name = validation.name(categoryItem.name);
          break;
        case 'description':
          errorItems.description = validation.description(categoryItem.description);
          break;
        case 'tag':
          errorItems.tag = validation.tag(categoryItem.tag);
          break;
        case 'tax':
          errorItems.tax = validation.tax(categoryItem.tax);
          break;
        default:
          break;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [categoryItem, errors, setErrors]
  );

  const getBypassLabel = () => {
    switch (category_type) {
      case 'dish':
        return 'kitchen';
      case 'drink':
        return 'bar';
      default:
        return '';
    }
  };

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={categoryItem.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryItemReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />

        <TextField
          className={classes.textField}
          data-qa="description-textfield"
          required
          label="Description"
          variant="outlined"
          error={errors.description as boolean}
          helperText={errors.description}
          value={categoryItem.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryItemReducerAction.DESCRIPTION, value: event.target.value })}
          onKeyUp={() => validate('description')}
          onBlur={() => validate('description')}
        />

        <Autocomplete
          className={classes.textField}
          id="categoryItemTagList"
          options={categoryTags}
          value={categoryItem.tag}
          onBlur={() => validate('tag')}
          onChange={(event, newValue) => {
            if (newValue?.startsWith('Add')) {
              dispatch({ type: CategoryItemReducerAction.TAG, value: newValue?.replace('Add -- ', '') || '' });
            } else {
              dispatch({ type: CategoryItemReducerAction.TAG, value: newValue || '' });
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push(`Add -- ${params.inputValue}`);
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option;
          }}
          renderInput={(params) => (
            <TextField
              className={classes.textField}
              {...params}
              data-qa="tag-textfield"
              label="Tag"
              variant="outlined"
              error={errors.tag as boolean}
              helperText={errors.tag || '30 character limit'}
            />
          )}
        />

        <TextField
          className={classes.textField}
          data-qa="price-textfield"
          required
          label="Gross Price"
          variant="outlined"
          type="number"
          value={categoryItem.price / 100 || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryItemReducerAction.PRICE, value: event.target.value })}
          inputProps={{
            min: 0,
            step: 0.01,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency, locale)}</InputAdornment>,
          }}
          onKeyUp={() => validate('price')}
          onBlur={() => validate('price')}
        />
        <TextField
          className={classes.textField}
          data-qa="tax-textfield"
          required
          label="Tax"
          variant="outlined"
          value={categoryItem.tax / 10 || ''}
          type="number"
          error={errors.tax as boolean}
          helperText={errors.tax}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryItemReducerAction.TAX, value: event.target.value })}
          inputProps={{
            min: 0,
            max: 100,
            step: 0.1,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                {numberToMoney(getPercentageValueOfTotal(categoryItem.price, categoryItem.tax > 0 ? categoryItem.tax / 1000 : 0), currency, locale, true)}
              </InputAdornment>
            ),
          }}
          onKeyUp={() => validate('tax')}
          onBlur={() => validate('tax')}
        />
        <TextField
          className={classes.textField}
          data-qa="score-textfield"
          required
          label="Item score"
          variant="outlined"
          type="number"
          value={categoryItem.score}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryItemReducerAction.SET_SCORE, value: event.target.value })}
          inputProps={{
            min: 0,
            step: 1,
          }}
        />
        <FormControl className={classes.prep_time} component="fieldset">
          <CustomTimePicker
            data-qa="prep_time-textfield"
            label="Prep time"
            time={secondsToTime(categoryItem.prep_time)}
            format="HMS"
            handleTimeChange={(value: string) => dispatch({ type: CategoryItemReducerAction.PREP_TIME, value })}
            onValidate={['availableFrom', validate]}
          />
        </FormControl>

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label={`Bypass ${getBypassLabel()}`}
            labelPlacement="end"
            control={
              <Switch
                checked={categoryItem.bypass_prep}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryItemReducerAction.BYPASS_PREP, value: event.target.checked as boolean })}
              />
            }
          />
          {categoryItem.bypass_prep ? (
            <FormHelperText>This {category_type} will be automatically made ready and serverd</FormHelperText>
          ) : (
            <FormHelperText>
              This {category_type} will be sent to the {getBypassLabel()}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.switch} disabled={categoryItem.bypass_prep} component="fieldset">
          <FormControlLabel
            label={`Auto serve ${category_type} when ${getBypassLabel()} is ready`}
            labelPlacement="end"
            control={
              <Switch
                checked={categoryItem.auto_serve_when_ready}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({ type: CategoryItemReducerAction.AUTO_SERVE_WHEN_READY, value: event.target.checked as boolean })
                }
              />
            }
          />
          {categoryItem.bypass_prep && (
            <FormHelperText>
              This {category_type} will not be sent to the {getBypassLabel()}{' '}
            </FormHelperText>
          )}
          {!categoryItem.bypass_prep && categoryItem.auto_serve_when_ready && (
            <FormHelperText>
              This {category_type} will be automatically set as serverd when made ready in the {getBypassLabel()}
            </FormHelperText>
          )}
          {!categoryItem.bypass_prep && !categoryItem.auto_serve_when_ready && (
            <FormHelperText>
              This {category_type} will be set as ready in the {getBypassLabel()} and will need to served from the order
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label={`Print ${category_type} on ticket sent to ${getBypassLabel()} `}
            labelPlacement="end"
            control={
              <Switch
                checked={categoryItem.should_print}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryItemReducerAction.SHOULD_PRINT, value: event.target.checked as boolean })}
              />
            }
          />
          {categoryItem.bypass_prep ? (
            categoryItem.should_print ? (
              <FormHelperText>{`Regardless of bypass ${getBypassLabel()}, this ${category_type} will be printed`}</FormHelperText>
            ) : (
              <FormHelperText>{`This ${category_type} will not be printed on the ${getBypassLabel()} ticket`}</FormHelperText>
            )
          ) : categoryItem.should_print ? (
            <FormHelperText>{`This ${category_type} will be printed on the ${getBypassLabel()} ticket`}</FormHelperText>
          ) : (
            <FormHelperText>{`This ${category_type} will not be printed on the ${getBypassLabel()} ticket`}</FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Active"
            labelPlacement="end"
            control={
              <Switch
                checked={categoryItem.active}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryItemReducerAction.ACTIVE, value: event.target.checked as boolean })}
              />
            }
          />
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
