import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../../layouts/Dashboard';
import ListVouchers from '../../../../../../components/Global/Vouchers/List';

import { voucherCodesInitQuery, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}
interface UrlParams {
  organisation_id: string;
  location_id: string;
}

const ListVouchersAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    currency: '',
    locale: '',
    organisation: {
      name: '',
    },
    voucher_codes: [],
  });

  const { data: voucherCodesInitData } = useQuery(voucherCodesInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && voucherCodesInitData) {
      setLocation(voucherCodesInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [voucherCodesInitData]);

  const handleCreate = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/vouchers/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/vouchers/${id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: location.organisation.name, link: '/admin/organisations' },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Vouchers' },
      ]}>
      <ListVouchers vouchers={location.voucher_codes} locale={location.locale} currency={location.currency} handleCreate={handleCreate} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListVouchersAdmin));
