import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      display: 'block',
      '& svg': {
        width: '28rem',
        height: '28rem',
        flex: '0 0 auto',
        padding: '0 3rem',
      },
    },
  });

export default styles;
