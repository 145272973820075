import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Typography, withStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { WithStyles } from '@material-ui/core/styles';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  title?: string;
  subtitle?: string;
  listItems: ListItemRoleItem[];
  isExpanded: boolean;
}

export interface ListItemRoles {
  admin: ListItemRole;
  owner: ListItemRole;
  manager: ListItemRole;
}

export interface ListItemRole {
  key: string;
  title: string;
  items: ListItemRoleItem[];
}

export interface ListItemRoleItem {
  label: string;
  icon: React.ReactElement;
  iconActive: React.ReactElement;
  to: string;
  items?: ListItemRoleItem[];
}

const SidebarListItems = ({ classes, history, title, subtitle, listItems, isExpanded }: Props): React.ReactElement => {
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const expandActiveNodes = (items: ListItemRoleItem[], parentKey = '') => {
      items.forEach((item, index) => {
        const itemKey = `${parentKey}-${index}`;
        const isActive = history.location.pathname.startsWith(item.to);
        if (isActive) {
          setOpenItems((prev) => ({ ...prev, [itemKey]: true }));
        }
        if (item.items) {
          expandActiveNodes(item.items, itemKey);
        }
      });
    };

    expandActiveNodes(listItems);
  }, [history.location.pathname, listItems]);

  const toggleItem = (key: string, to?: string) => {
    setOpenItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

    if (to) {
      history.push(to);
    }
  };

  const renderListItems = (items: ListItemRoleItem[], parentKey = ''): React.ReactElement[] => {
    return items.map((item, index) => {
      const itemKey = `${parentKey}-${index}`;
      const hasChildren = item.items && item.items.length > 0;
      const isActive = history.location.pathname.startsWith(item.to);

      return (
        <div key={itemKey}>
          <ListItem
            button
            //@ts-ignore
            style={item.style}
            className={`${classes.listItem} ${isActive ? classes.activeListItem : ''}`}
            onClick={() => (hasChildren ? toggleItem(itemKey, item.to) : history.push(item.to))}>
            {isExpanded ? (
              <>
                <ListItemIcon className={classes.listItemIcon}>{isActive ? item.iconActive : item.icon}</ListItemIcon>
                <ListItemText primary={item.label} className={classes.listItemText} />
                {hasChildren && (openItems[itemKey] ? <ExpandLess /> : <ExpandMore />)}
              </>
            ) : (
              <ListItemIcon className={classes.listItemIcon}>{isActive ? item.iconActive : item.icon}</ListItemIcon>
            )}
          </ListItem>
          {hasChildren && (
            <Collapse
              in={openItems[itemKey]}
              timeout={0} // Disable animations for open/close
            >
              <List component="div" disablePadding>
                {renderListItems(item.items!, itemKey)}
              </List>
            </Collapse>
          )}
        </div>
      );
    });
  };

  return (
    <div className={classes.root}>
      {isExpanded && title && <Typography variant="h6">{title}</Typography>}
      {isExpanded && subtitle && (
        <Typography variant="subtitle1" color="textSecondary">
          {subtitle}
        </Typography>
      )}
      <List className={classes.list} component="div" disablePadding>
        {renderListItems(listItems)}
      </List>
    </div>
  );
};

export default withRouter(withStyles(styles)(SidebarListItems));
