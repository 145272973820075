import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',
    },
    text: {
      marginTop: theme.spacing(3),
      width: theme.spacing(48),
    },
    buttonContainer: {
      display: 'flex',
      marginTop: theme.spacing(3),
      flexDirection: 'column',
      flex: '1 1 auto',
      width: theme.spacing(36),
    },
  });

export default styles;
