import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _createLocationUserInitQuery = (id: string) => ({
  locations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      has_staff: types.boolean,
      organisation: {
        name: types.string,
      },
      users: params(
        {
          where: { pin: { _is_null: false } },
        },
        [
          {
            pin: types.string,
          },
        ]
      ),
    }
  ),
  enum_user_types: [
    {
      user_type: types.string,
      description: types.string,
    },
  ],
});

type X = Unpacked<typeof _createLocationUserInitQuery>;
export type Location = X['locations_by_pk'];

export const createLocationUserInitQuery = (id: string) => gql`
  ${query(_createLocationUserInitQuery(id))}
`;
