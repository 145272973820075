import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ViewLocation from '../../../../components/Global/Location/View';

import { selectLocationInitQuery, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  location_id: string;
}

const OrgViewLocation = ({ classes, history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({ name: '' });

  const { data: locationInitData } = useQuery(selectLocationInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationInitData) {
      setLocation(locationInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationInitData]);

  const handleEdit = () => {
    history.push(`/org/locations/${location_id}/edit`);
  };

  const handleCreateUser = () => {
    history.push(`/org/locations/${location_id}/users/create`);
  };

  const handleViewUsers = () => {
    history.push(`/org/locations/${location_id}/users`);
  };

  const handleCreateStaff = () => {
    history.push(`/org/locations/${location_id}/staff/create`);
  };

  const handleViewStaff = () => {
    history.push(`/org/locations/${location_id}/staff`);
  };

  const handleViewIntegrations = () => {
    history.push(`/org/locations/${location_id}/integrations`);
  };

  const handleViewStock = () => {
    history.push(`/org/locations/${location_id}/stock`);
  };

  const handleViewOrders = () => {
    history.push(`/org/locations/${location_id}/orders`);
  };

  const handleViewVoucherCodes = () => {
    history.push(`/org/locations/${location_id}/vouchers`);
  };

  const handleViewPrinters = () => {
    history.push(`/org/locations/${location_id}/printers`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Locations', link: `/org/locations` }, { label: location.name }]}>
      <ViewLocation
        location_id={location_id}
        handleEdit={handleEdit}
        handleCreateUser={handleCreateUser}
        handleViewUsers={handleViewUsers}
        handleCreateStaff={handleCreateStaff}
        handleViewStaff={handleViewStaff}
        handleViewIntegrations={handleViewIntegrations}
        handleViewStock={handleViewStock}
        handleViewOrders={handleViewOrders}
        handleViewVoucherCodes={handleViewVoucherCodes}
        handleViewPrinters={handleViewPrinters}
      />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(OrgViewLocation));
