import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';

import { WithStyles } from '@material-ui/core/styles';

import moment from 'moment';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  label: string;
  date?: Date | null;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  yearsFromNow: number;
  allowPastDates?: boolean;
  small?: boolean;
  handleDateChange: (newDate: Date) => void;
  onValidate?: [fieldName: string, validate: (field: string, value: Date, errorText?: string) => void];
}

const today = new Date();
const createYearArray = (years: number) => {
  const year = today.getFullYear();
  if (years > 0) {
    return Array.from(new Array(years), (_, index) => index + year);
  }
  return Array.from(new Array(Math.abs(years)), (_, index) => year - index);
};
interface DatePicker {
  day: number | null;
  month: number | null;
  year: number | null;
  date: Date | null;
}

export enum DatePickerReducerAction {
  DAY,
  MONTH,
  YEAR,
  DATE,
  INIT,
}

export interface ValidationErrors {
  past: string;
  future: string;
  invalid: string;
}

export const validationErrors: ValidationErrors = {
  past: 'Date needs to be in the past',
  future: 'Date needs to be in the future',
  invalid: 'Invalid date',
};

interface DatePickerValidate {
  year?: number;
  month?: number;
  day?: number;
}

const datePickerReducer = (state: DatePicker, action: { type: DatePickerReducerAction; value: any }): DatePicker => {
  switch (action.type) {
    case DatePickerReducerAction.DAY:
      return { ...state, day: action.value as number };
    case DatePickerReducerAction.MONTH:
      return { ...state, month: action.value as number };
    case DatePickerReducerAction.YEAR:
      return { ...state, year: action.value as number };
    case DatePickerReducerAction.DATE:
      return { ...state, date: action.value };
    case DatePickerReducerAction.INIT:
      const { day, month, year, date } = action.value;
      const obj = {
        day,
        month,
        year,
        date,
      };
      return { ...(obj as DatePicker) };
    default:
      throw new Error();
  }
};

const CustomDatePicker = ({
  classes,
  label,
  required = false,
  disabled = false,
  date,
  error,
  helperText,
  yearsFromNow,
  allowPastDates = false,
  small = false,
  handleDateChange,
  onValidate,
}: Props): React.ReactElement => {
  const [nDate, dispatch] = useReducer(datePickerReducer, {
    day: null,
    month: null,
    year: null,
    date: null,
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && date) {
      dispatch({
        type: DatePickerReducerAction.INIT,
        value: {
          day: date.getDate() as number,
          month: date.getMonth() as number,
          year: date.getFullYear() as number,
          date,
        },
      });
    }
    return () => {
      mounted = false;
    };
  }, [date]);

  const pastDate = !(yearsFromNow > 0);
  const [errorText, setErrorText] = useState<string>('');

  const days = Array(31)
    .fill(1)
    .map((i, index) => i + index);
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const years = createYearArray(yearsFromNow);

  const handleValidation = useCallback(
    (value: Date, error: string) => {
      if (onValidate) {
        onValidate[1](onValidate[0], value, error);
      }
    },
    [onValidate]
  );

  const handleChange = (type: DatePickerReducerAction, value: number) => {
    dispatch({ type, value });
    switch (type) {
      case DatePickerReducerAction.DAY:
        validate({ day: value });
        break;
      case DatePickerReducerAction.MONTH:
        validate({ month: value });
        break;
      case DatePickerReducerAction.YEAR:
        validate({ year: value });
        break;
      default:
        break;
    }
  };

  const validate = useCallback(
    (values: DatePickerValidate) => {
      setErrorText('');
      let error = '';
      const year = values.year || nDate.year;
      const month = values.month !== undefined ? values.month! : nDate.month ?? 0;
      const day = values.day || nDate.day;
      if (year !== null && month !== null && day !== null) {
        const value = new Date(year, month, day);
        const mnth = `${month + 1}`.padStart(2, '0');
        const dy = `${day}`.padStart(2, '0');
        const dteTest = moment(`${year}/${mnth}/${dy}`, 'YYYY/MM/DD');
        if (dteTest.isValid()) {
          if (pastDate && !allowPastDates && moment(dteTest).isBefore(new Date())) {
            error = validationErrors.past;
          } else if (!pastDate && !allowPastDates && date && moment().isAfter(dteTest) && today.getDate() !== date.getDate()) {
            error = validationErrors.future;
          } else {
            dispatch({ type: DatePickerReducerAction.DATE, value });
            handleDateChange(value);
            handleValidation(value, error);
          }
        } else {
          error = validationErrors.invalid;
          handleValidation(value, error);
        }
      }
      setErrorText(error);
    },
    [nDate, pastDate, date, allowPastDates, handleDateChange, handleValidation]
  );

  return (
    <>
      <div className={classes.root}>
        <FormControl component="fieldset" required={required} disabled={disabled} error={errorText !== '' || error}>
          <FormLabel className={classes.label} component="label">
            {label}
          </FormLabel>
          <div className={classes.dates}>
            <FormControl variant="outlined" size={small ? 'small' : 'medium'} className={classes.day} disabled={disabled} error={errorText !== '' || error}>
              <InputLabel id="day-select-label">Day</InputLabel>
              <Select
                labelId="day-select-label"
                label="Day"
                autoWidth
                value={nDate.day || ''}
                MenuProps={{ classes: { paper: classes.dayMenu } }}
                onChange={(event: React.ChangeEvent<any>) => handleChange(DatePickerReducerAction.DAY, event.target.value)}>
                {days.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size={small ? 'small' : 'medium'} className={classes.month} disabled={disabled} error={errorText !== '' || error}>
              <InputLabel id="month-select-label">Month</InputLabel>
              <Select
                labelId="month-select-label"
                label="Month"
                autoWidth
                value={nDate.month === null ? '' : nDate.month}
                onChange={(event: React.ChangeEvent<any>) => handleChange(DatePickerReducerAction.MONTH, event.target.value)}>
                {months.map((i, index) => (
                  <MenuItem key={i} value={index}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" size={small ? 'small' : 'medium'} className={classes.year} disabled={disabled} error={errorText !== '' || error}>
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                labelId="year-select-label"
                label="Year"
                autoWidth
                value={nDate.year || ''}
                MenuProps={{ classes: { paper: classes.yearMenu } }}
                onChange={(event: React.ChangeEvent<any>) => handleChange(DatePickerReducerAction.YEAR, event.target.value)}>
                {years.map((i) => (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {errorText !== '' ? (
            <FormHelperText className={classes.error} error>
              {errorText}
            </FormHelperText>
          ) : (
            <FormHelperText className={classes.error} error>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(CustomDatePicker);
