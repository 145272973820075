import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Grid, TextField, withStyles } from '@material-ui/core';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { GridCard, SortableGrid } from '../../../../components/Grid';
import { booleanToYesNo, capitaliseFirst, getKeyValue, pluralise } from '../../../../utils/stringUtils';
import CustomSortableTable, { SortableTableAction, SortableTableHeader, SortableTableRow } from '../../../../components/CustomSortableTable';
import { SortableGridChild } from '../../../Grid/SortableGrid';
import ColourPickerItem from '../../ColourPicker/item';
import { ViewType } from '../../../CustomViewToggle';
import { Category } from './queries';
import styles from './styles';
import { updateCategoryScore } from './mutations';
import { updateCategoryDishes, updateCategoryDrinks } from '../Edit/mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  items: Category[];
  counterPath: string;
  category_type: string;
  viewType: ViewType;
  handleSaveOrdering: (items: Category[]) => Promise<void>;
  handleSetItems: React.Dispatch<React.SetStateAction<Category[]>>;
  handleViewCategory: (id: string, category_type: string) => void;
  handleCreateCategory?: (category_type: string) => void;
  handleCategoryScoreUpdate: () => void;
}

const ListCategories = ({
  classes,
  items,
  counterPath,
  viewType = ViewType.TABLE,
  category_type,
  handleSaveOrdering,
  handleSetItems,
  handleViewCategory,
  handleCreateCategory,
  handleCategoryScoreUpdate,
}: Props): React.ReactElement => {
  const [editedScores, setEditedScores] = useState<Map<string, number>>(new Map());

  const onDragEnd = (result: SortableGridChild[]) => {
    handleSetItems((categories) => {
      return result.map((r) => items.find((c) => c.id === r.key)!).map((c, i) => ({ ...c, order_index: i }));
    });
  };

  const handleScoreChange = (id: string, value: number) => {
    setEditedScores((prev) => new Map(prev).set(id, value));
  };

  const handleSaveScore = async (id: string) => {
    const score = editedScores.get(id);
    if (score !== undefined) {
      try {
        await updateCategoryScore(id, score);
        const whereCondition = {
          category_assignments: {
            category: {
              id: {
                _eq: id,
              },
            },
          },
        };

        const setValues = {
          score: score,
        };
        await updateCategoryDrinks({ where: whereCondition, set: setValues });
        await updateCategoryDishes({ where: whereCondition, set: setValues });
        handleCategoryScoreUpdate();
        setEditedScores((prev) => {
          const newMap = new Map(prev);
          newMap.delete(id);
          return newMap;
        });
      } catch (error) {
        console.error(`Failed to save score for category ${id}`, error);
      }
    }
  };

  const renderViewType = () => {
    const label = capitaliseFirst(category_type);
    const title = pluralise([`${label} Category`, `${label} Categories`], items.length);
    const createLabel = `Create ${category_type} category`;
    if (viewType === ViewType.TABLE) {
      const headers: SortableTableHeader[] = [
        { key: 'order', label: 'Ordering', hidden: true },
        { key: 'name', label: 'Name' },
        { key: 'active', label: 'Active' },
        { key: 'items', label: `${pluralise(label, 2)}`, align: 'center' },
        { key: 'colour', label: 'Colour' },
        { key: 'score', label: 'Score' },
      ];

      const rows: SortableTableRow[] = items.map((category: Category) => {
        const score = editedScores.get(category.id) ?? category.score;
        return {
          key: category.id,
          disabled: !category.active,
          actions: [
            {
              label: 'View',
              onClick: () => handleViewCategory(category.id, category_type),
            },
            {
              label: 'Save Score',
              show: editedScores.has(category.id),
              onClick: () => handleSaveScore(category.id),
            },
          ],
          columns: [
            {
              key: 'order',
              label: category.order_index + 1,
            },
            {
              key: 'name',
              label: category.name,
            },
            {
              key: 'active',
              label: booleanToYesNo(category.active),
            },
            {
              key: 'items',
              label: getKeyValue(category, counterPath),
            },
            {
              key: 'colour',
              label: 'No colour',
              component: category.colour_id ? <ColourPickerItem key="colour_picker" small colour_id={category.colour_id} /> : undefined,
            },
            {
              key: 'score',
              label: score,
              component: <TextField type="number" value={score} onChange={(e) => handleScoreChange(category.id, parseInt(e.target.value, 10))} />,
            },
          ],
        };
      });

      const actions: SortableTableAction[] = handleCreateCategory
        ? [
            {
              key: 'create',
              label: createLabel,
              icon: <AddCircleIcon />,
              onClick: () => handleCreateCategory(category_type),
            },
          ]
        : [];

      return (
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CustomSortableTable title={title} orderdBy="ordering" ordered="asc" actions={actions} headers={headers} counter={items.length} rows={rows} />
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <SortableGrid
          title={title}
          gridSize={3}
          onDragEnd={onDragEnd}
          handleSave={async () => handleSaveOrdering(items)}
          items={items.map((category: Category, index: number) => ({
            key: category.id,
            label: category.name,
            component: <GridCard fill gridSize={3} noGrid counter={index + 1} title={category.name} />,
          }))}
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={4} alignItems="stretch">
      {renderViewType()}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ListCategories));
