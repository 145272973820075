import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { Divider, FormControlLabel, Switch, TextField, withStyles } from '@material-ui/core';

import { LocationStaff, ValidationErrors, LocationStaffReducerAction, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  user: LocationStaff;
  usedPins: string[];
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: LocationStaffReducerAction; value: any }>;
}

const StepDetails = ({ classes, user, errors, usedPins, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.first_name = validation.first_name(user.first_name);
      }
      if (field === 'last_name') {
        errorItems.last_name = validation.last_name(user.last_name);
      }
      if (field === 'pin') {
        errorItems.pin = validation.pin(user.pin, usedPins);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [user, usedPins, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="first-name-textfield"
          required
          label="First name"
          variant="outlined"
          error={errors.first_name as boolean}
          helperText={errors.first_name}
          value={user.first_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationStaffReducerAction.FIRST_NAME, value: event.target.value })}
          onKeyUp={() => validate('first_name')}
          onBlur={() => validate('first_name')}
        />
        <TextField
          className={classes.textField}
          data-qa="last-name-textfield"
          required
          label="Last name"
          variant="outlined"
          error={errors.last_name as boolean}
          helperText={errors.last_name}
          value={user.last_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationStaffReducerAction.LAST_NAME, value: event.target.value })}
          onKeyUp={() => validate('last_name')}
          onBlur={() => validate('last_name')}
        />
        <TextField
          className={classes.textField}
          data-qa="pin-textfield"
          required
          label="PIN"
          variant="outlined"
          value={user.pin}
          error={errors.pin as boolean}
          helperText={errors.pin}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationStaffReducerAction.PIN, value: event.target.value })}
          onKeyUp={() => validate('pin')}
          onBlur={() => validate('pin')}
        />

        <Divider className={classes.divider} />

        <FormControlLabel
          label="Active"
          className={classes.switch}
          control={
            <Switch
              checked={user.active}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationStaffReducerAction.ACTIVE, value: event.target.checked })}
            />
          }
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
