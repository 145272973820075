import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { Divider, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField, withStyles } from '@material-ui/core';

import { Organisation, ValidationErrors, OrganisationReducerAction, validation } from '../..';
import { EnumOrganisationType } from '../Steps/queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisation: Organisation;
  organisationTypes: EnumOrganisationType[];
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: OrganisationReducerAction; value: any }>;
}

const StepDetails = ({ classes, organisation, organisationTypes = [], errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.name = validation.name(organisation.name);
      }
      if (field === 'email') {
        errorItems.email = validation.email(organisation.email);
      }
      if (field === 'phone') {
        errorItems.phone = validation.phone(organisation.phone);
      }
      if (field === 'currency') {
        errorItems.currency = validation.currency(organisation.currency);
      }
      if (field === 'locale') {
        errorItems.locale = validation.locale(organisation.locale);
      }
      if (field === 'organisation_type') {
        errorItems.organisation_type = validation.organisation_type(organisation.organisation_type);
      }
      if (field === 'tax_rate') {
        errorItems.tax_rate = validation.tax_rate(organisation.tax_rate);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [organisation, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={organisation.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />

        <FormControl variant="outlined" required className={classes.textField} error={errors.organisation_type as boolean}>
          <InputLabel id="organisation_type-select-label">Organisation type</InputLabel>
          <Select
            labelId="organisation_type-select-label"
            label="Organisation type"
            autoWidth
            value={organisation.organisation_type}
            onChange={(event: React.ChangeEvent<any>) => dispatch({ type: OrganisationReducerAction.ORGANISATION_TYPE, value: event.target.value })}
            onBlur={() => validate('organisation_type')}>
            {organisationTypes.map((i: EnumOrganisationType) => (
              <MenuItem key={i.organisation_type} value={i.organisation_type}>
                {i.description}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.organisation_type}</FormHelperText>
        </FormControl>

        <Divider className={classes.divider} />

        <TextField
          className={classes.textField}
          data-qa="email-textfield"
          required
          label="Contact email address"
          variant="outlined"
          error={errors.email as boolean}
          helperText={errors.email}
          value={organisation.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.EMAIL, value: event.target.value })}
          onKeyUp={() => validate('email')}
          onBlur={() => validate('email')}
        />
        <TextField
          className={classes.textField}
          data-qa="phone-textfield"
          required
          label="Contact phone number"
          variant="outlined"
          error={errors.phone as boolean}
          helperText={errors.phone}
          value={organisation.phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.PHONE, value: event.target.value })}
          onKeyUp={() => validate('phone')}
          onBlur={() => validate('phone')}
        />

        <Divider className={classes.divider} />

        {/* <TextField
          className={classes.textField}
          data-qa="currency-textfield"
          required
          label="Currency"
          variant="outlined"
          error={errors.currency as boolean}
          helperText={errors.currency || 'e.g. GBP, USD'}
          value={organisation.currency}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.CURRENCY, value: event.target.value })}
          onKeyUp={() => validate('currency')}
          onBlur={() => validate('currency')} /> */}

        <FormControl variant="outlined" className={classes.textField} error={errors.currency as boolean}>
          <InputLabel id="currency-select-label">Currency</InputLabel>
          <Select
            labelId="currency-select-label"
            label="Currency"
            autoWidth
            value={organisation.currency}
            // MenuProps={{ classes: { paper: classes.select } }}
            onChange={(event: React.ChangeEvent<any>) => dispatch({ type: OrganisationReducerAction.CURRENCY, value: event.target.value })}
            onBlur={() => validate('currency')}>
            {['GBP', 'USD'].map((i) => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.currency}</FormHelperText>
        </FormControl>

        <FormControl variant="outlined" className={classes.textField} error={errors.locale as boolean}>
          <InputLabel id="locale-select-label">Locale</InputLabel>
          <Select
            labelId="locale-select-label"
            label="Locale"
            autoWidth
            value={organisation.locale}
            // MenuProps={{ classes: { paper: classes.select } }}
            onChange={(event: React.ChangeEvent<any>) => dispatch({ type: OrganisationReducerAction.LOCALE, value: event.target.value })}
            onBlur={() => validate('locale')}>
            {['en-GB', 'en-US'].map((i) => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.locale}</FormHelperText>
        </FormControl>

        {/* <TextField
          className={classes.textField}
          data-qa="locale-textfield"
          required
          label="Locale"
          variant="outlined"
          error={errors.locale as boolean}
          helperText={errors.locale || 'e.g. en-GB, en-US'}
          value={organisation.locale}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.LOCALE, value: event.target.value })}
          onKeyUp={() => validate('locale')}
          onBlur={() => validate('locale')} /> */}
        <TextField
          className={classes.textField}
          data-qa="tax-rate-textfield"
          required
          label="Tax rate"
          variant="outlined"
          type="number"
          inputProps={{
            min: 0,
            max: 100,
            step: 0.1,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          error={errors.tax_rate as boolean}
          helperText={errors.tax_rate || 'VAT'}
          value={organisation.tax_rate / 10 || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationReducerAction.TAX_RATE, value: event.target.value })}
          onKeyUp={() => validate('tax_rate')}
          onBlur={() => validate('tax_rate')}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
