import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Grid, withStyles } from '@material-ui/core';

import { GridCard, GridActionCard } from '../../../../components/Grid';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  locations: Location[];
  handleCreate: () => void;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
}

export interface Location {
  id: string;
  name: string;
}

const ListLocations = ({ classes, history, locations, handleCreate, handleEdit, handleView }: Props): React.ReactElement => {
  return (
    <Grid container spacing={4} alignItems="stretch">
      <GridActionCard onClick={handleCreate} fill />
      {locations.map((location: Location) => (
        <GridCard fill key={location.id} title={location.name} handleEdit={() => handleEdit(location.id)} handleView={() => handleView(location.id)} />
      ))}
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ListLocations));
