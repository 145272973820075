import React, { useEffect, useState, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { CodeVoucherType, Category, codeVoucherReducer, CodeVoucherReducerAction, UsedCode } from '../Common';
import Steps from '../Common/components/Steps';

import { updateVoucherCode } from './mutations';
import { editVoucherCodeInitQuery } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  voucher_id: string;
  categories: Category[];
  used_codes: UsedCode[];
  handleFinish: () => void;
}

const EditVoucher = ({ classes, history, voucher_id, categories, used_codes, handleFinish }: Props): React.ReactElement => {
  const [voucher, dispatchVoucher] = useReducer(codeVoucherReducer, {
    name: '',
    code: '',
    value: 0,
    voucher_type: CodeVoucherType.PERCENT,
    single_use: false,
    can_expire: false,
    start_date: new Date(),
    end_date: new Date(),
    category_ids: [],
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editVoucherCodeInitData } = useQuery(editVoucherCodeInitQuery(voucher_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editVoucherCodeInitData) {
      dispatchVoucher({ type: CodeVoucherReducerAction.INIT, value: editVoucherCodeInitData.vouchers_code_by_pk });
    }
    return () => {
      mounted = false;
    };
  }, [editVoucherCodeInitData]);

  const handleSave = async () => {
    setSaving(true);

    const sd: Date = voucher.start_date;
    const start_date = voucher.can_expire
      ? `${[sd.getFullYear(), `${sd.getMonth() + 1}`.padStart(2, '0'), `${sd.getDate()}`.padStart(2, '0')].join('-')}T${[0, 0, 0].join(':')}`
      : undefined;
    const ed: Date = voucher.end_date;
    const end_date = voucher.can_expire
      ? `${[ed.getFullYear(), `${ed.getMonth() + 1}`.padStart(2, '0'), `${ed.getDate()}`.padStart(2, '0')].join('-')}T${[23, 59, 59].join(':')}`
      : null;

    const updateVoucherVariables = {
      pk_columns: {
        id: voucher_id,
      },
      set: {
        ...voucher,
        start_date,
        end_date,
      },
    };
    await updateVoucherCode(updateVoucherVariables);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps completeLabel="Save" voucher={voucher} categories={categories} used_codes={used_codes} dispatch={dispatchVoucher} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditVoucher));
