import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  ThemeOptions,
  createMuiTheme,
  // useMediaQuery,
} from '@material-ui/core';

import theme, { ThemeModes } from './theme';
import { EmptyObject } from '../../react-app-env';
import useLocalStorage from '../../hooks/useLocalStorage';

interface Props extends React.PropsWithChildren<EmptyObject> {
  modes: ThemeModes;
}

interface CutsomThemeContextItems {
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  toggleDarkMode: () => void;
}

export const CustomThemeContext = React.createContext<CutsomThemeContextItems | null>(null);

const CutsomThemeProvider = ({ children, modes }: Props): React.ReactElement => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useLocalStorage<boolean>('dark-mode', false);

  const mainTheme = createMuiTheme(theme(darkMode, modes) as ThemeOptions);

  const handleToggleDarkMode = () => {
    setDarkMode((i) => !i);
  };

  const contextItems: CutsomThemeContextItems = {
    darkMode,
    setDarkMode,
    toggleDarkMode: handleToggleDarkMode,
  };

  return (
    <CustomThemeContext.Provider value={contextItems}>
      <MuiThemeProvider theme={mainTheme}>{children}</MuiThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CutsomThemeProvider;
