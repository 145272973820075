import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { booleanToYesNo } from '../../../../../utils/stringUtils';

import { useQuery } from '@apollo/client';

import { Grid, withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import ReadOnlyBlock from '../../../../../components/ReadOnlyBlock';

import { selectOrganisationUserInitQuery, OrganisationUser } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  organisation_user_id: string;
}

const ViewOrganisationUser = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, organisation_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<OrganisationUser>({
    full_name: '',
    email: '',
    active: false,
    organisation: {
      name: '',
    },
  });

  const { data: organisationUserInitQuery } = useQuery(selectOrganisationUserInitQuery(organisation_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationUserInitQuery) {
      setUser(organisationUserInitQuery.users_organisation_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [organisationUserInitQuery]);

  const handleEdit = () => {
    history.push(`/admin/organisations/${organisation_id}/users/${organisation_user_id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: user.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Users', link: `/admin/organisations/${organisation_id}/users` },
        { label: user ? user.full_name : '' },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <>
            <ReadOnlyBlock
              gridSize={3}
              items={
                user
                  ? [
                      { label: 'Name', value: user.full_name },
                      { label: 'Email', value: user.email },
                      { label: 'Active', value: booleanToYesNo(user.active) },
                    ]
                  : []
              }
              handleEdit={handleEdit}
            />
          </>
        </Grid>
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewOrganisationUser));
