import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ViewLocationUser from '../../../../components/Global/LocationUsers/View';

import { selectLocationUserInitQuery, LocationUser } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  location_user_id: string;
}

const OrgViewLocationUser = ({ classes, history }: Props): React.ReactElement => {
  const { location_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<LocationUser>({
    full_name: '',
    location: {
      name: '',
    },
  });

  const { data: locationUserInitQuery } = useQuery(selectLocationUserInitQuery(location_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUserInitQuery) {
      setUser(locationUserInitQuery.users_location_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationUserInitQuery]);

  const handleEdit = () => {
    history.push(`/loc/users/${location_user_id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Users', link: `/loc/users` }, { label: user.full_name }]}>
      <ViewLocationUser location_user_id={location_user_id} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(OrgViewLocationUser));
