import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../react-app-env';

export const _homeInitQuery = () => ({
  locations: [
    {
      has_staff: types.boolean,
      has_station_printers: types.boolean,
    },
  ],
  location_integrations: [
    {
      vouchers: types.boolean,
    },
  ],
});

type X = Unpacked<typeof _homeInitQuery>;
export type Location = X['locations'][0];
export type Integration = X['location_integrations'][0];

export const homeInitQuery = () => gql`
  ${query(_homeInitQuery())}
`;
