import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

const updateOrganisationUserMutation = gql`
  mutation ($pk_columns: users_organisation_pk_columns_input!, $set: users_organisation_set_input!) {
    update_users_organisation_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateOrganisationUser = async (variables: object) => {
  return await gqlClient.mutate<{
    update_users_organisation_by_pk: {
      id: string;
    };
  }>({ mutation: updateOrganisationUserMutation!, variables });
};
