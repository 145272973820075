import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { pluralise } from '../../utils/stringUtils';

import { Button, Checkbox, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, withStyles, Typography, InputBase } from '@material-ui/core';

import { ChevronLeft as MoveLeftIcon, ChevronRight as MoveRightIcon, FirstPage as MoveAllLeftIcon, LastPage as MoveAllRightIcon } from '@material-ui/icons';

import { Search as SearchIcon, Clear as ClearSearchIcon } from '@material-ui/icons/';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  labelLeft: string;
  labelRight: string;
  description?: string;
  currentItems: CustomTransferListItem[];
  allItems: CustomTransferListItem[];
  handleTransfer: (left: CustomTransferListItem[], right: CustomTransferListItem[]) => void;
}

export interface CustomTransferListItem {
  id: string;
  label: string;
  subLabel?: string;
}

const not = (a: CustomTransferListItem[], b: CustomTransferListItem[]) => a.filter((value) => b.indexOf(value) === -1);

const intersection = (a: CustomTransferListItem[], b: CustomTransferListItem[]) => a.filter((value) => b.indexOf(value) !== -1);

const CustomTransferList = ({ classes, labelLeft, labelRight, description, currentItems, allItems, handleTransfer }: Props): React.ReactElement => {
  const [checked, setChecked] = useState<CustomTransferListItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [left, setLeft] = useState<CustomTransferListItem[]>(allItems.filter((i) => !currentItems.map((e) => e.id).includes(i.id)));
  const [right, setRight] = useState<CustomTransferListItem[]>(currentItems);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    let mounted = true;
    const rightIds = right.map((i) => i.id);
    const currentIds = currentItems.map((i) => i.id);
    const areSame = currentIds.every((id) => rightIds.includes(id)) && rightIds.every((id) => currentIds.includes(id));
    if (mounted && !areSame) {
      handleTransfer(left, right);
    }
    return () => {
      mounted = false;
    };
  }, [left, right, currentItems, handleTransfer]);

  const handleToggle = (value: CustomTransferListItem) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    if (searchValue.length > 0) {
      setRight((i) => [...i, ...left.filter((i) => i.label.toLowerCase().includes(searchValue.toLowerCase()))]);
      setLeft(left.filter((i) => !i.label.toLowerCase().includes(searchValue.toLowerCase())));
    } else {
      setRight((i) => [...i, ...left]);
      setLeft([]);
    }
    setChecked([]);
  };

  const handleCheckedRight = () => {
    setRight((i) => [...i, ...leftChecked]);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft((i) => [...i, ...rightChecked]);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft((i) => [...i, ...right]);
    setRight([]);
    setChecked([]);
  };

  const customList = (title: string, items: CustomTransferListItem[]) => (
    <Paper className={classes.paper} elevation={0} variant="outlined">
      <Typography className={classes.listTitle} variant="subtitle1">
        {items.length} {pluralise(title, items.length)}
      </Typography>
      <Divider />
      <List dense component="div" role="list">
        {items
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((item) => {
            const labelId = `transfer-list-item-${item.id}-label`;
            return (
              <ListItem key={item.id} role="listitem" button onClick={handleToggle(item)}>
                <ListItemIcon>
                  <Checkbox
                    data-qa="transfer-list-item-check-box"
                    checked={checked.indexOf(item) !== -1}
                    color="primary"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText data-qa="transfer-list-item-name" id={labelId} primary={item.label} secondary={item.subLabel} />
              </ListItem>
            );
          })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <>
      <Grid container spacing={2} alignItems="center" className={classes.root}>
        <Grid item xs={5}>
          <Paper variant="outlined" className={classes.search}>
            <InputBase
              className={classes.searchInput}
              placeholder="Search"
              value={searchValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || '')}
              startAdornment={<SearchIcon className={classes.searchButton} />}
              endAdornment={<ClearSearchIcon className={classes.searchButton} onClick={() => setSearchValue('')} />}
              inputProps={{ 'aria-label': 'search list' }}
            />
          </Paper>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <Typography className={classes.description} variant="caption">
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" className={classes.root}>
        <Grid item xs={5} className={classes.list}>
          {customList(
            labelLeft,
            left.filter((i) => i.label.toLowerCase().includes(searchValue.toLowerCase()))
          )}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Button
              data-qa="movel-all-right-button"
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right">
              <MoveAllRightIcon />
            </Button>
            <Button
              data-qa="move-selected-right-button"
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              <MoveRightIcon />
            </Button>
            <Button
              data-qa="move-selected-left-button"
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              <MoveLeftIcon />
            </Button>
            <Button
              data-qa="move-all-left-button"
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left">
              <MoveAllLeftIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5} className={classes.list}>
          {customList(labelRight, right)}
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CustomTransferList);
