import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[1],
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 60,
      marginBottom: theme.spacing(1),
    },
    caption: {
      marginLeft: theme.spacing(2),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    customDatePickers: {
      marginTop: theme.spacing(1),
      display: 'flex',
      gap: theme.spacing(2),
    },
    formControl: {
      minWidth: 120,
    },
    toggleButton: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },
    },
  });

export default styles;
