import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _createCategoryInitQuery = (id: string) => ({
  menus_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    }
  ),
});

type X = Unpacked<typeof _createCategoryInitQuery>;
export type Menu = X['menus_by_pk'];

export const createCategoryInitQuery = (id: string) => gql`
  ${query(_createCategoryInitQuery(id))}
`;
