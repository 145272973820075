import React, { useState, useEffect, useCallback } from 'react';

import { useQuery } from '@apollo/client';

import { GridCard } from '../../../Grid';

import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';
import { numberToMoney } from '../../../../utils/stringUtils';
import { Grid, Typography } from '@material-ui/core';
import { exactDateString } from '../../../../utils/dateUtils';

const _query = (startDate: string, endDate: string) => ({
  orders_served: params(
    {
      where: {
        paid: { _eq: true },
        _and: [{ created_at: { _gte: rawString(startDate) } }, { created_at: { _lte: rawString(endDate) } }],
      },
    },
    [
      {
        covers: types.number,
        quick_sale: types.boolean,
        discounts: types.custom<Discount[]>(),
        service_charge: types.number,
        tax: types.number,
        total: types.number,
        payments_aggregate: {
          aggregate: {
            sum: {
              tip: types.number,
            },
          },
        },
      },
    ]
  ),
  orders_takeaway: params(
    {
      where: {
        paid: { _eq: true },
        _and: [{ created_at: { _gte: rawString(startDate) } }, { created_at: { _lte: rawString(endDate) } }],
      },
    },
    [
      {
        discounts: types.custom<Discount[]>(),
        service_charge: types.number,
        tax: types.number,
        total: types.number,
        payments_aggregate: {
          aggregate: {
            sum: {
              tip: types.number,
            },
          },
        },
      },
    ]
  ),
});

type OrdersServed = Unpacked<typeof _query>['orders_served'][0];
type OrdersTakeaway = Unpacked<typeof _query>['orders_takeaway'][0];

interface Discount {
  value: number;
  total: number;
  before: number;
}

interface Values {
  average_covers: number;
  total_covers: number;
  discounts: string;
  service_charge: string;
  tax: string;
  total: string;
  totalTips: string;
  totalDineIn: number;
  totalQuickSale: number;
  totalTakeaway: number;
}

interface Props {
  startDate: Date;
  endDate: Date;
  locale: string;
  currency: string;
}

export const initQuery = (startDate: string, endDate: string) => gql`
  ${query(_query(startDate, endDate))}
`;

const MetricOrders = ({ startDate, endDate, locale, currency }: Props): React.ReactElement => {
  const [value, setValue] = useState<Values>({
    average_covers: 0,
    total_covers: 0,
    discounts: '',
    service_charge: '',
    tax: '',
    total: '',
    totalTips: '',
    totalDineIn: 0,
    totalQuickSale: 0,
    totalTakeaway: 0,
  });

  const { data, loading } = useQuery(initQuery(exactDateString(startDate), exactDateString(endDate)), { fetchPolicy: 'no-cache' });

  const formatMoney = useCallback((v: number) => numberToMoney(v, currency, locale, true), [currency, locale]);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const average_covers0 = data.orders_served.filter((i: OrdersServed) => !i.quick_sale).length;
      const average_covers1 = data.orders_served.filter((i: OrdersServed) => !i.quick_sale).reduce((a: number, b: OrdersServed) => a + b.covers, 0);
      const average_covers2 = average_covers0 > 0 ? average_covers1 / average_covers0 : 0;
      const average_covers = Math.round(average_covers2);
      const total_covers = average_covers1;

      const totalDineIn = average_covers0;
      const totalQuickSale = data.orders_served.filter((i: OrdersServed) => i.quick_sale).length;
      const totalTakeaway = data.orders_takeaway.length;

      const d1 = data.orders_served.reduce((a: number, b: OrdersServed) => a + b.discounts.reduce((c, d) => c + (d.before - d.total), 0), 0);
      const d2 = data.orders_takeaway.reduce((a: number, b: OrdersTakeaway) => a + b.discounts.reduce((c, d) => c + (d.before - d.total), 0), 0);
      const discounts = formatMoney(d1 + d2);

      const s1 = data.orders_served.reduce((a: number, b: OrdersServed) => a + b.service_charge, 0);
      const s2 = data.orders_takeaway.reduce((a: number, b: OrdersTakeaway) => a + b.service_charge, 0);
      const service_charge = formatMoney(s1 + s2);

      const tax1 = data.orders_served.reduce((a: number, b: OrdersServed) => a + b.tax, 0);
      const tax2 = data.orders_takeaway.reduce((a: number, b: OrdersTakeaway) => a + b.tax, 0);
      const tax = formatMoney(tax1 + tax2);

      const total0 = data.orders_served.length + data.orders_takeaway.length;
      const total1 = data.orders_served.reduce((a: number, b: OrdersServed) => a + b.total, 0);
      const total2 = data.orders_takeaway.reduce((a: number, b: OrdersTakeaway) => a + b.total, 0);
      const total3 = total0 > 0 ? (total1 + total2) / total0 : 0;
      const total = formatMoney(total3);

      const totalOrderServedTips = data.orders_served.reduce((a: number, b: OrdersServed) => a + b.payments_aggregate.aggregate.sum.tip, 0);
      const totalOrderTakeawayTips = data.orders_takeaway.reduce((a: number, b: OrdersTakeaway) => a + b.payments_aggregate.aggregate.sum.tip, 0);
      const totalTips = formatMoney(totalOrderServedTips + totalOrderTakeawayTips);
      setValue({
        average_covers,
        total_covers,
        discounts,
        service_charge,
        tax,
        total,
        totalTips,
        totalDineIn,
        totalQuickSale,
        totalTakeaway,
      });
    }
    return () => {
      mounted = false;
    };
  }, [data, formatMoney]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4">Orders</Typography>
      </Grid>
      <GridCard fill gridSize={4} loading={loading} key="TotalCustomers" title="Total customers" subTitle="Total covers by table" counterSmall counter={value.total_covers} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="AverageTableSize"
        title="Average table size"
        subTitle="Average covers by table"
        counterSmall
        counter={value.average_covers}
      />
      <GridCard fill gridSize={4} loading={loading} key="TotalDineIn" title="Total dine in orders" subTitle="All dine in orders" counterSmall counter={value.totalDineIn} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="TotalQuickSale"
        title="Total quick sale orders"
        subTitle="All quick sale orders"
        counterSmall
        counter={value.totalQuickSale}
      />
      <GridCard fill gridSize={4} loading={loading} key="TotalTakeaway" title="Total takeaway orders" subTitle="All takeaway orders" counterSmall counter={value.totalTakeaway} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="AppliedDiscount"
        title="Applied discount"
        subTitle="Applied discount of all orders types"
        counterSmall
        counter={value.discounts}
      />
      <GridCard fill gridSize={4} loading={loading} key="TotalTips" title="Total Tips" counterSmall counter={value.totalTips} />
      <GridCard fill gridSize={4} loading={loading} key="AppliedServiceCharge" title="Applied service charge" counterSmall counter={value.service_charge} />
      <GridCard fill gridSize={4} loading={loading} key="AppliedTax" title="Applied tax" subTitle="Applied tax of all orders types" counterSmall counter={value.tax} />
      <GridCard fill gridSize={4} loading={loading} key="AverageSpend" title="Average spend" subTitle="Average total of all orders types" counterSmall counter={value.total} />
    </>
  );
};

export default MetricOrders;
