import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { booleanToYesNo, stringFromArray } from '../../../../../../utils/stringUtils';

import { withStyles } from '@material-ui/core';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { OrganisationUser } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  user: OrganisationUser;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, user, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Name', value: stringFromArray([user.first_name, user.last_name]) },
          { label: 'Email', value: user.email },
          { label: 'Active', value: booleanToYesNo(user.active) },
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
