import React, { useState, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { organisationUserReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createOrganisationUser } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateOrganisationUser = ({ classes, history }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(organisationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    active: true,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const createVariables = {
      object: {
        ...user,
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
        email: user.email.trim(),
      },
    };
    const user_id: string | undefined = await createOrganisationUser(createVariables);
    if (user_id) {
      setSaving(false);
      handleFinish(user_id);
    }
  };

  const handleFinish = (id: string) => {
    history.push(`/org/users/${id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Users', link: `/org/users` }, { label: 'Create' }]}>
        <Steps completeLabel="Create" user={user} dispatch={dispatchUser} handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateOrganisationUser));
