import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertCategoryMutation = gql`
  mutation ($object: categories_insert_input!) {
    insert_categories_one(object: $object) {
      id
    }
  }
`;

export const createCategory = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_categories_one: {
      id: string;
    };
  }>({ mutation: insertCategoryMutation!, variables });
  return res.data?.insert_categories_one.id;
};
