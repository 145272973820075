import { Button, Grid, Input } from '@material-ui/core';
import { ChangeEvent, useRef } from 'react';

interface Props {
  onImageSelected: (imagePath: string) => void;
  label?: string;
}

const FileInput = ({ onImageSelected, label }: Props) => {
  const inputRef = useRef<HTMLInputElement>();

  const handleOnClick = () => {
    inputRef.current?.click();
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (_e) {
        onImageSelected(reader.result as string);
      };
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Input type="file" inputProps={{ accept: 'image/*' }} inputRef={inputRef} onChange={handleOnChange} style={{ display: 'none' }} />
          <Button color="primary" fullWidth variant="outlined" onClick={handleOnClick}>
            {label ? label : 'Choose Image'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FileInput;
