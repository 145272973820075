import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Avatar, Breadcrumbs, Link, Toolbar, Typography, withStyles } from '@material-ui/core';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { generateShortUuid, wordsToAcronym } from '../../../../utils/stringUtils';

import styles from './styles';
import { UnknownObject } from '../../../../react-app-env';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  title?: string;
  user: UnknownObject;
  breadcrumbs?: Breadcrum[];
}

export interface Breadcrum {
  label: string;
  link?: string;
  action?: any;
}

const Topbar = ({ classes, title, user, breadcrumbs }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        <Toolbar className={classes.toolbar}>
          {title && (
            <Typography data-qa="topbar-title" className={classes.title} variant="h2">
              {title}
            </Typography>
          )}
          {breadcrumbs && (
            <Breadcrumbs separator={<NavigateNextIcon key={generateShortUuid()} fontSize="small" />} itemsAfterCollapse={2} aria-label="breadcrumb">
              {breadcrumbs
                .slice(0, -1)
                .filter((b: Breadcrum) => b.label)
                .map((b: Breadcrum) => {
                  return b.link ? (
                    <Link key={generateShortUuid()} underline="hover" color="inherit" href={b.link}>
                      {b.label}
                    </Link>
                  ) : (
                    <Typography key={generateShortUuid()} color="inherit" onClick={b.action ? b.action : undefined} style={{ cursor: b.action ? 'pointer' : 'default' }}>
                      {b.label}
                    </Typography>
                  );
                })}
              <Typography key="last" color="textPrimary" aria-current="page">
                {breadcrumbs.slice(-1)[0].label}
              </Typography>
            </Breadcrumbs>
          )}
          <div className={classes.toolbarButtons}>
            <div className={classes.toolbarUserInfo}>
              <Typography color="textPrimary">{user?.full_name}</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {user?.user_type}
              </Typography>
            </div>
            <Avatar className={classes.toolbarAvatar} variant="rounded">
              {user ? wordsToAcronym(user.full_name) : ''}
            </Avatar>
          </div>
        </Toolbar>
      </div>
    </>
  );
};

export default withRouter(withStyles(styles)(Topbar));
