import React, { useState, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';

import CustomBackdrop from '../../../../../components/CustomBackdrop';
import { adminUserReducer } from '../Common';
import Steps from '../Common/components/Steps';

import styles from './styles';
import { createAdminUser } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const CreateAdminUser = ({ classes, history }: Props): React.ReactElement => {
  const [user, dispatchUser] = useReducer(adminUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const createVariables = {
      object: user,
    };
    const user_id: string | undefined = await createAdminUser(createVariables);
    if (user_id) {
      setSaving(false);
      handleFinish(user_id);
    }
  };

  const handleFinish = (id: string) => {
    history.push(`/admin/users/${id}`);
  };

  return (
    <>
      <Dashboard breadcrumbs={[{ label: 'Admin' }, { label: 'Users', link: '/admin/users' }, { label: 'Create' }]}>
        <Steps completeLabel="Create" user={user} dispatch={dispatchUser} handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateAdminUser));
