import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { FormControl, FormControlLabel, FormHelperText, InputLabel, Radio, RadioGroup, Switch, TextField, withStyles } from '@material-ui/core';

import { CodeVoucher, ValidationErrors, CodeVoucherReducerAction, validation, CodeVoucherType, UsedCode } from '../..';

import styles from './styles';
import CustomDatePicker from '../../../../../CustomDatePicker';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  voucher: CodeVoucher;
  used_codes: UsedCode[];
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: CodeVoucherReducerAction; value: any }>;
}

const StepDetails = ({ classes, voucher, used_codes, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string, value?: string | number | Date, errorText?: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.name = validation.name(voucher.name);
      }
      if (field === 'code') {
        errorItems.code = validation.code(voucher.code, used_codes);
      }
      if (field === 'value') {
        errorItems.value = validation.value(voucher.value);
      }
      if (field === 'start_date') {
        errorItems.start_date = errorText || validation.start_date((value as Date) || voucher.start_date, voucher.end_date);
      }
      if (field === 'end_date') {
        errorItems.end_date = errorText || validation.end_date((value as Date) || voucher.end_date, voucher.start_date);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [voucher, errors, used_codes, setErrors]
  );

  const voucherTypes = [
    { value: CodeVoucherType.PERCENT, label: 'Percent' },
    { value: CodeVoucherType.AMOUNT, label: 'Amount' },
  ];

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={voucher.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CodeVoucherReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />

        <TextField
          className={classes.textField}
          data-qa="code-textfield"
          required
          label="Voucher code"
          variant="outlined"
          error={errors.code as boolean}
          helperText={errors.code}
          value={voucher.code}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CodeVoucherReducerAction.CODE, value: event.target.value })}
          onKeyUp={() => validate('code')}
          onBlur={() => validate('code')}
        />

        <TextField
          className={classes.textField}
          data-qa="value-textfield"
          required
          label="Voucher value"
          variant="outlined"
          type="number"
          error={errors.value as boolean}
          helperText={errors.value}
          value={voucher.value / 100 || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CodeVoucherReducerAction.VALUE, value: event.target.value })}
          onKeyUp={() => validate('value')}
          onBlur={() => validate('value')}
        />

        <FormControl variant="outlined" required className={classes.textField}>
          <InputLabel id="voucher_type-select-label" shrink>
            Voucher type
          </InputLabel>
          <RadioGroup
            className={classes.radioGroup}
            value={voucher.voucher_type}
            row
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CodeVoucherReducerAction.VOUCHER_TYPE, value: event.target.value })}>
            {voucherTypes.map((i) => (
              <FormControlLabel key={i.value} value={i.value} className={classes.radio} control={<Radio color="primary" />} label={i.label} />
            ))}
          </RadioGroup>
          {voucher.voucher_type === CodeVoucherType.AMOUNT ? (
            <FormHelperText>Voucher will be discounted off the total order value</FormHelperText>
          ) : (
            <FormHelperText>
              Voucher will be discounted off the selected categories on the next step. If no categories are selected then the discount will be taken off the total order value
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Single use"
            labelPlacement="end"
            control={
              <Switch
                checked={voucher.single_use}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CodeVoucherReducerAction.SINGLE_USE, value: event.target.checked as boolean })}
              />
            }
          />
          {voucher.single_use ? (
            <FormHelperText>Voucher will no longer be valid after it has been redeemed</FormHelperText>
          ) : (
            <FormHelperText>Voucher can be used unlimited times</FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Can expire"
            labelPlacement="end"
            control={
              <Switch
                checked={voucher.can_expire}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CodeVoucherReducerAction.CAN_EXPIRE, value: event.target.checked as boolean })}
              />
            }
          />
          {voucher.can_expire ? (
            <FormHelperText>Voucher will only be valid between the start and end dates</FormHelperText>
          ) : (
            <FormHelperText>Voucher can be used indefinitely</FormHelperText>
          )}
        </FormControl>

        <CustomDatePicker
          small
          label="Start date"
          disabled={!voucher.can_expire}
          date={voucher.start_date}
          allowPastDates
          error={errors.start_date as boolean}
          helperText={errors.start_date !== false ? (errors.start_date as string) : undefined}
          yearsFromNow={5}
          handleDateChange={(date: Date) => dispatch({ type: CodeVoucherReducerAction.START_DATE, value: date })}
          onValidate={['start_date', validate]}
        />

        <CustomDatePicker
          small
          label="End date"
          disabled={!voucher.can_expire}
          date={voucher.end_date}
          error={errors.end_date as boolean}
          helperText={errors.end_date !== false ? (errors.end_date as string) : undefined}
          yearsFromNow={5}
          handleDateChange={(date: Date) => dispatch({ type: CodeVoucherReducerAction.END_DATE, value: date })}
          onValidate={['end_date', validate]}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
