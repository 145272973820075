import { capitaliseFirst } from '../../../../../utils/stringUtils';
import { ValidationType } from '../../../../../react-app-env';

export interface AdminUser {
  first_name: string;
  last_name: string;
  email: string;
}

export interface ValidationErrors {
  first_name: ValidationType;
  last_name: ValidationType;
  email: ValidationType;
  email_invalid: ValidationType;
}

export const validationErrors: ValidationErrors = {
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  email: 'Please provide an email address',
  email_invalid: 'Please provide a valid email address',
};

export enum AdminUserReducerAction {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  INIT,
}

export const adminUserReducer = (state: AdminUser, action: { type: AdminUserReducerAction; value: any }): AdminUser => {
  switch (action.type) {
    case AdminUserReducerAction.FIRST_NAME:
      return { ...state, first_name: capitaliseFirst(action.value) };
    case AdminUserReducerAction.LAST_NAME:
      return { ...state, last_name: capitaliseFirst(action.value) };
    case AdminUserReducerAction.EMAIL:
      return { ...state, email: action.value?.toLowerCase() };
    case AdminUserReducerAction.INIT:
      const { first_name, last_name, email } = action.value;
      const obj = {
        first_name,
        last_name,
        email,
      };
      return { ...(obj as AdminUser) };
    default:
      throw new Error();
  }
};
