import { removeTimeZone } from '../../../../../utils/stringUtils';
import { ValidationType } from '../../../../../react-app-env';

export interface Menu {
  name: string;
  description: string;
  active: boolean;
  all_day: boolean;
  available_from: string;
  available_to: string;
  menu_types: OrderType[];
  items: ZettleUploadItem[];
}

// export interface ZettleUpload {
//   name: string,
//   customUnit: string,
//   vat: number,
//   option1Name: string,
//   option1Value: string,
//   option2Name: string,
//   option2Value: string,
//   option3Name: string,
//   option3Value: string,
//   sku: string,
//   price: number,
//   costPrice: number,
//   barcode: string,
//   inStock: string,
//   variantId: string,
//   productId: string,
//   id: string,
// }

export interface ZettleUpload {
  Name: string;
  'Option1 Name': string;
  'Option1 Value': string;
  'Option2 Name': string;
  'Option2 Value': string;
  'Option3 Name': string;
  'Option3 Value': string;
  Price: number;
  VAT: number;
}

export interface ZettleUploadItem {
  name: string;
  variants: ZettleUploadItemVariant[];
  modifiers: ZettleUploadItemModifier[];
  groups: string[];
  price: number;
  tax: number;
  type: EnumZettleUploadItemType;
  category: string;
}

export enum EnumZettleUploadItemType {
  UNKNOWN,
  DISH,
  DRINK,
}

export interface ZettleUploadItemVariant {
  option1: ZettleUploadItemVariantGroup;
  option2: ZettleUploadItemVariantGroup;
  option3: ZettleUploadItemVariantGroup;
  price: number;
}

export interface ZettleUploadItemVariantGroup {
  name: string;
  value: string;
}

export interface ZettleUploadItemModifierGroup {
  name: string;
  modifiers: ZettleUploadItemModifier[];
}

export interface ZettleUploadItemModifier {
  id: string;
  name: string;
  price: number;
  originalPrice: number;
  tax: number;
  group: string;
}

export enum EnumZettleUploadProps {
  NAME = 'Name',
  PRICE = 'Price',
  VAT = 'VAT',
  OPTION1_NAME = 'Option1 Name',
  OPTION1_VALUE = 'Option1 Value',
  OPTION2_NAME = 'Option2 Name',
  OPTION2_VALUE = 'Option2 Value',
  OPTION3_NAME = 'Option3 Name',
  OPTION3_VALUE = 'Option3 Value',
}

export interface ValidationErrors {
  name: ValidationType;
  description: ValidationType;
  availableFrom: ValidationType;
  availableTo: ValidationType;
  menuType: ValidationType;
  incompatibleTimes: ValidationType;
  fileUpload: ValidationType;
  itemsNotAssigned: ValidationType;
  categories: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  description: 'Please provide a description',
  availableFrom: 'Please provide a time the menu is avaliable from',
  availableTo: 'Please provide a time the menu is avaliable to',
  menuType: 'Please select at least one menu type',
  incompatibleTimes: 'Please provide an avaliable from time that starts before avaliable to',
  fileUpload: 'Please upload a file',
  itemsNotAssigned: 'Please assign all items to either a dish, drink or delete the item',
  categories: 'Please select a category for each item',
};

export interface Validation {
  name: (name: string) => ValidationType;
  description: (description: string) => ValidationType;
  availableFrom: (availableFrom: string) => ValidationType;
  availableTo: (availableTo: string) => ValidationType;
  menuType: (menuTypes: OrderType[]) => ValidationType;
  fileUpload: (fileUpload: ZettleUploadItem[]) => ValidationType;
  itemsNotAssigned: (fileUpload: ZettleUploadItem[]) => ValidationType;
  categories: (fileUpload: ZettleUploadItem[]) => ValidationType;
  incompatibleTimes: (availableFrom: string, availableTo: string) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
  description: (description) => (!description ? validationErrors.description : false),
  availableFrom: (availableFrom) => (!availableFrom ? validationErrors.availableFrom : false),
  availableTo: (availableTo) => (!availableTo ? validationErrors.availableTo : false),
  menuType: (menuTypes) => (menuTypes.length === 0 ? validationErrors.menuType : false),
  fileUpload: (fileUpload) => {
    if (fileUpload.length === 0) return validationErrors.fileUpload;
    return false;
  },
  itemsNotAssigned: (fileUpload) => {
    if (fileUpload.some((i) => i.type === EnumZettleUploadItemType.UNKNOWN)) return validationErrors.itemsNotAssigned;
    return false;
  },
  categories: (fileUpload) => {
    if (fileUpload.some((i) => i.category === '')) return validationErrors.categories;
    return false;
  },
  incompatibleTimes: (availableFrom, availableTo) => {
    const [hF, mF, sF, msF] = availableFrom.split(':');
    const [hT, mT, sT, msT] = availableTo.split(':');
    if (hT < hF || (hT === hF && mT < mF) || (hT === hF && mT === mF && sT < sF) || (hT === hF && mT === mF && sT === sF && msT < msF)) {
      return validationErrors.incompatibleTimes;
    }
    return false;
  },
};

export enum OrderType {
  DINE_IN = 'DINE_IN',
  QUICK_SALE = 'QUICK_SALE',
  TAKEAWAY = 'TAKEAWAY',
  DELIVERECT = 'DELIVERECT',
}

export const orderTypes = [OrderType.DINE_IN, OrderType.QUICK_SALE, OrderType.TAKEAWAY, OrderType.DELIVERECT];

export enum UploadMenuReducerAction {
  NAME,
  DESCRIPTION,
  ACTIVE,
  ALL_DAY,
  AVAILABLE_FROM,
  AVAILABLE_TO,
  MENU_TYPE,
  SET_ITEMS,
  ITEM_NAME,
  ITEM_TYPE,
  ITEM_UPDATE_MODIFIER,
  ITEM_DELETE_MODIFIER,
  ITEM_PRICE,
  ITEM_CATEGORY,
  ITEM_DELETE,
  FILE_UPLOAD,
  INIT,
}

export const uploadMenuReducer = (state: Menu, action: { type: UploadMenuReducerAction; value: any }): Menu => {
  switch (action.type) {
    case UploadMenuReducerAction.NAME:
      return { ...state, name: action.value };
    case UploadMenuReducerAction.DESCRIPTION:
      return { ...state, description: action.value };
    case UploadMenuReducerAction.ACTIVE:
      return { ...state, active: action.value };
    case UploadMenuReducerAction.ALL_DAY:
      const af = '00:00:00:000';
      const at = '23:59:59:999';
      const ad = action.value as boolean;
      return { ...state, all_day: ad, available_from: af, available_to: at };
    case UploadMenuReducerAction.AVAILABLE_FROM:
      return { ...state, available_from: action.value };
    case UploadMenuReducerAction.AVAILABLE_TO:
      return { ...state, available_to: action.value };
    case UploadMenuReducerAction.MENU_TYPE:
      let mTypes = state.menu_types;
      if (action.value.active) {
        mTypes.push(action.value.menuType as OrderType);
      } else {
        mTypes = mTypes.filter((m) => m !== action.value.menuType);
      }
      return { ...state, menu_types: mTypes };
    case UploadMenuReducerAction.SET_ITEMS:
      return { ...state, items: action.value };
    case UploadMenuReducerAction.ITEM_NAME:
      return {
        ...state,
        items: state.items.map((item, index) => {
          if (index === action.value.itemIndex) {
            item.name = action.value.name;
          }
          return item;
        }),
      };
    case UploadMenuReducerAction.ITEM_TYPE:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.name === action.value.item.name) {
            item.type = action.value.type;
          }
          return item;
        }),
      };
    case UploadMenuReducerAction.ITEM_CATEGORY:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.name === action.value.item.name) {
            item.category = action.value.category;
          }
          return item;
        }),
      };
    case UploadMenuReducerAction.ITEM_PRICE:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.name === action.value.item.name) {
            const price = action.value.price * 100;
            item.price = price;
            if (item.groups.length === 1) {
              item.modifiers = item.modifiers.map((m) => {
                let p = m.originalPrice - price;
                if (p < 0) p = 0;
                return { ...m, price: p };
              });
            }
          }
          return item;
        }),
      };
    case UploadMenuReducerAction.ITEM_UPDATE_MODIFIER:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.name === action.value.item.name) {
            item.modifiers = item.modifiers.map((m) => {
              if (m.name === action.value.modifier.name) {
                // const { modifier } = action.value;
                return action.value.modifier;
                // return {
                //   ...modifier,
                //   price: modifier.price * 100,
                // }
              }
              return m;
            });
          }
          return item;
        }),
      };
    case UploadMenuReducerAction.ITEM_DELETE_MODIFIER:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.name === action.value.item.name) {
            item.modifiers = item.modifiers.filter((m) => m.name !== action.value.modifier.name);
          }
          return item;
        }),
      };
    case UploadMenuReducerAction.ITEM_DELETE:
      return { ...state, items: state.items.filter((item) => item.name !== action.value.name) };
    case UploadMenuReducerAction.FILE_UPLOAD:
      return { ...state, items: action.value };
    case UploadMenuReducerAction.INIT:
      const { name, description, active, available_from, available_to, menu_types } = action.value;
      let all_day = false;
      if (available_from && available_to) {
        const [hF, mF, sF] = removeTimeZone(available_from).split(':');
        const [hT, mT, sT] = removeTimeZone(available_to).split(':');
        all_day = hF === '00' && hT === '23' && mF === '00' && mT === '59' && sF === '00' && sT === '59';
      }
      const obj = {
        name,
        description,
        active,
        all_day,
        available_from,
        available_to,
        menu_types,
      };
      return { ...(obj as Menu) };
    default:
      throw new Error();
  }
};
