import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid, withStyles } from '@material-ui/core';

import UsersList from '../../Users/List';
import { selectLocationStaffInitQuery, LocationStaff } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  location_id: string;
  handleCreate: () => void;
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
}

const ListLocationStaff = ({ classes, history, location_id, handleCreate, handleView, handleEdit }: Props): React.ReactElement => {
  const [staff, setStaff] = useState<LocationStaff[]>([]);

  const { data: locationStaffInitQuery } = useQuery(selectLocationStaffInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationStaffInitQuery) {
      setStaff(locationStaffInitQuery.locations_by_pk.users);
    }
    return () => {
      mounted = false;
    };
  }, [locationStaffInitQuery]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <UsersList
          title="Staff"
          pluraliseTitle={false}
          users={staff.map((i: LocationStaff) => ({ id: i.id, full_name: i.full_name, pin: i.pin }))}
          gridSize={3}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
          handleView={handleView}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ListLocationStaff));
