import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateOrganisationMutation = gql`
  mutation ($pk_columns: organisations_pk_columns_input!, $set: organisations_set_input!) {
    update_organisations_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateOrganisation = async (variables: object) => {
  return await gqlClient.mutate<{
    update_organisations_by_pk: {
      id: string;
    };
  }>({ mutation: updateOrganisationMutation!, variables });
};
