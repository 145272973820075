import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ListLocationStaff from '../../../../components/Global/LocationStaff/List';

import { selectLocationUsersInitQuery, Location } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const LocListLocationStaff = ({ classes, history }: Props): React.ReactElement => {
  const [location, setLocation] = useState<Location>({
    id: '',
    name: '',
  });

  const { data: locationUsersInitQuery } = useQuery(selectLocationUsersInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUsersInitQuery) {
      setLocation(locationUsersInitQuery.locations[0]);
    }
    return () => {
      mounted = false;
    };
  }, [locationUsersInitQuery]);

  const handleCreate = () => {
    history.push(`/loc/staff/create`);
  };

  const handleView = (id: string) => {
    history.push(`/loc/staff/${id}`);
  };

  const handleEdit = (id: string) => {
    history.push(`/loc/staff/${id}/edit`);
  };

  return (
    <Dashboard breadcrumbs={[{ label: 'Staff' }]}>
      <ListLocationStaff location_id={location.id} handleCreate={handleCreate} handleView={handleView} handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(LocListLocationStaff));
