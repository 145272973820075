import React from 'react';
import { Box, Typography, Button, Grid, IconButton, makeStyles, useTheme, useMediaQuery, Dialog } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkout from '../Checkout';

const useStyles = makeStyles((theme) => ({
  cartContainer: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    position: 'sticky',
    top: theme.spacing(2),
    maxWidth: '100%',
  },
  cartItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  itemDetails: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  itemName: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  itemDescription: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  itemPrice: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  quantityControls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
  },
  cartFooter: {
    marginTop: theme.spacing(2),
    borderTop: '1px solid #ddd',
    paddingTop: theme.spacing(2),
  },
  checkoutButton: {
    backgroundColor: '#000',
    color: '#fff',
    width: '100%',
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}));

interface Modifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

interface MenuItem {
  id: string;
  name: string;
  description: string;
  price: number;
  imageUrl?: string;
  modifier_assignments: { modifier: Modifier }[];
}

interface CartItem {
  cartKey: string;
  productId: string;
  modifiersKey: string;
  name: string;
  description: string;
  price: number;
  quantity: number;
}

interface CartProps {
  cart: { [key: string]: number };
  dispatch: React.Dispatch<Action>;
  menuData: any; // Replace with your actual menu data type
  totalPrice: number;
  orderDetails: OrderDetails;
  createOrder: (request: string) => Promise<any>;
  captureOrder: (orderId: string) => Promise<any>;
  createServedOrderAfterPaymentAsync: (order: any) => Promise<any>;
  createTakeAwayOrderAfterPaymentAsync: (order: any) => Promise<any>;
  createTakeAwayOrderPaymentAsync: (order: any) => Promise<any>;
  createServedOrderPaymentAsync: (order: any) => Promise<any>;
  location_id: string;
  organisation_id: string;
}

type Action =
  | { type: 'ADD_TO_CART'; payload: { cartKey: string; quantity: number } }
  | {
      type: 'REMOVE_FROM_CART';
      payload: { cartKey: string };
    };

interface OrderDetails {
  orderType: 'delivery' | 'pickup' | 'dinein' | null;
  timeOption: 'standard' | 'schedule';
  scheduledDay: string;
  scheduledTime: string;
  tableNumber: number | null;
}

const Cart: React.FC<CartProps> = ({
  cart,
  dispatch,
  menuData,
  totalPrice,
  orderDetails,
  createOrder,
  captureOrder,
  createTakeAwayOrderAfterPaymentAsync,
  createServedOrderAfterPaymentAsync,
  createTakeAwayOrderPaymentAsync,
  createServedOrderPaymentAsync,
  location_id,
  organisation_id,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [checkoutOpen, setCheckoutOpen] = React.useState<boolean>(false);

  const handleOpenCheckout = () => {
    setCheckoutOpen(true);
  };

  const handleCloseCheckout = () => {
    setCheckoutOpen(false);
  };

  // Function to generate cart items with detailed information
  const cartItems: CartItem[] = React.useMemo(() => {
    return Object.keys(cart)
      .map((cartKey) => {
        const quantity = cart[cartKey];
        const [productId, modifiersKey] = cartKey.split(':');
        const product: MenuItem | undefined = menuData?.categories
          .flatMap((category: any) =>
            category.category_type === 'DISH' ? category.dish_assignments.map((item: any) => item.dish) : category.drink_assignments.map((item: any) => item.drink)
          )
          .find((item: any) => item.id === productId);

        if (!product) {
          return null;
        }

        // Parse modifiersKey into an array of modifier names
        const modifiersArray = modifiersKey ? modifiersKey.split(',') : [];

        // Find the selected modifiers by matching modifier names
        const selectedModifiers = product.modifier_assignments.filter((mod) => modifiersArray.includes(mod.modifier.name)).map((mod) => mod.modifier);

        const itemPrice = selectedModifiers.reduce((sum: number, mod: Modifier) => sum + mod.price, product.price) / 100;

        const modifiersDescription = selectedModifiers.map((mod) => mod.name).join(', ');

        return {
          cartKey,
          productId,
          modifiersKey,
          name: product.name,
          description: modifiersDescription,
          price: itemPrice,
          quantity,
        };
      })
      .filter((item): item is CartItem => item !== null);
  }, [cart, menuData]);

  const handleAdd = (cartKey: string) => {
    dispatch({ type: 'ADD_TO_CART', payload: { cartKey, quantity: 1 } });
  };

  const handleRemove = (cartKey: string) => {
    if (cart[cartKey] > 1) {
      dispatch({ type: 'ADD_TO_CART', payload: { cartKey, quantity: -1 } });
    } else {
      dispatch({ type: 'REMOVE_FROM_CART', payload: { cartKey } });
    }
  };

  if (isMobile) {
    return (
      <>
        <Box
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000',
            color: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1, 2),
            zIndex: 1000,
          }}
          onClick={handleOpenCheckout}>
          <Typography variant="body1">{cartItems.length} items</Typography>
          <Typography variant="body1">Total: £{totalPrice.toFixed(2)}</Typography>
        </Box>
        {/* Checkout Modal */}
        <Dialog open={checkoutOpen} onClose={handleCloseCheckout} fullWidth fullScreen maxWidth="sm" aria-labelledby="checkout-dialog">
          {/*@ts-ignore*/}
          <Checkout
            //@ts-ignore
            orderDetails={orderDetails}
            //@ts-ignore
            dispatch={dispatch}
            totalPrice={totalPrice}
            onClose={handleCloseCheckout}
            cartItems={cartItems}
            cart={cart}
            menuData={menuData}
            createOrder={createOrder}
            captureOrder={captureOrder}
            createServedOrderAfterPaymentAsync={createServedOrderAfterPaymentAsync}
            createTakeAwayOrderAfterPaymentAsync={createTakeAwayOrderAfterPaymentAsync}
            createTakeAwayOrderPaymentAsync={createTakeAwayOrderPaymentAsync}
            createServedOrderPaymentAsync={createServedOrderPaymentAsync}
            location_id={location_id}
            organisation_id={organisation_id}
          />
        </Dialog>
      </>
    );
  }

  return (
    <Box className={classes.cartContainer}>
      {cartItems.length === 0 ? (
        <Typography variant="body1">Your cart is empty</Typography>
      ) : (
        <>
          {cartItems.map((item) => (
            <Grid key={item.cartKey} container className={classes.cartItem}>
              <Grid item className={classes.itemDetails}>
                <Typography className={classes.itemName}>{item.name}</Typography>
                {item.description && <Typography className={classes.itemDescription}>{item.description}</Typography>}
                <Box className={classes.quantityControls}>
                  <IconButton size="small" onClick={() => handleRemove(item.cartKey)}>
                    {item.quantity > 1 ? <RemoveIcon fontSize="small" /> : <DeleteIcon fontSize="small" />}
                  </IconButton>
                  <Typography variant="body2">{item.quantity}</Typography>
                  <IconButton size="small" onClick={() => handleAdd(item.cartKey)}>
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item>
                <Typography className={classes.itemPrice}>£{(item.price * item.quantity).toFixed(2)}</Typography>
              </Grid>
            </Grid>
          ))}
          <Box className={classes.cartFooter}>
            <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: theme.spacing(1) }}>
              Total: £{totalPrice.toFixed(2)}
            </Typography>
            <Button className={classes.checkoutButton} onClick={handleOpenCheckout}>
              Next: Checkout
            </Button>
          </Box>
        </>
      )}
      {/* Checkout Modal */}
      <Dialog open={checkoutOpen} onClose={handleCloseCheckout} maxWidth={'sm'} fullWidth aria-labelledby="checkout-dialog">
        {/*@ts-ignore*/}
        <Checkout
          //@ts-ignore
          orderDetails={orderDetails}
          //@ts-ignore
          dispatch={dispatch}
          totalPrice={totalPrice}
          onClose={handleCloseCheckout}
          cartItems={cartItems}
          cart={cart}
          menuData={menuData}
          createOrder={createOrder}
          captureOrder={captureOrder}
          createServedOrderAfterPaymentAsync={createServedOrderAfterPaymentAsync}
          createTakeAwayOrderAfterPaymentAsync={createTakeAwayOrderAfterPaymentAsync}
          createTakeAwayOrderPaymentAsync={createTakeAwayOrderPaymentAsync}
          createServedOrderPaymentAsync={createServedOrderPaymentAsync}
          location_id={location_id}
          organisation_id={organisation_id}
        />
      </Dialog>
    </Box>
  );
};

export default Cart;
