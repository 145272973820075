import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { TextField, withStyles } from '@material-ui/core';

import { ValidationErrors, SelfOnboardOrganisationReducerAction, validation } from '../..';

import styles from './styles';
import { SelfOnboardOrganisation } from '../../../../../../components/Global/Organisations';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisation: SelfOnboardOrganisation;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{
    type: SelfOnboardOrganisationReducerAction;
    value: any;
  }>;
}

const StepUser = ({ classes, organisation, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'firstName') {
        errorItems.firstName = validation.firstName(organisation.organisationAdmin.firstName);
      }
      if (field === 'lastName') {
        errorItems.lastName = validation.lastName(organisation.organisationAdmin.lastName);
      }
      if (field === 'organisationAdminEmail') {
        errorItems.organisationAdminEmail = validation.organisationAdminEmail(organisation.organisationAdmin.email);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [organisation, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="first-name-textfield"
          required
          label="Organisation Admin First Name"
          variant="outlined"
          error={errors.firstName as boolean}
          helperText={errors.firstName}
          value={organisation.organisationAdmin.firstName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              type: SelfOnboardOrganisationReducerAction.SET_FIRST_NAME,
              value: event.target.value,
            })
          }
          onKeyUp={() => validate('firstName')}
          onBlur={() => validate('firstName')}
        />
        <TextField
          className={classes.textField}
          data-qa="last-name-textfield"
          required
          label="Organisation Admin Last Name"
          variant="outlined"
          error={errors.lastName as boolean}
          helperText={errors.lastName}
          value={organisation.organisationAdmin.lastName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              type: SelfOnboardOrganisationReducerAction.SET_LAST_NAME,
              value: event.target.value,
            })
          }
          onKeyUp={() => validate('lastName')}
          onBlur={() => validate('lastName')}
        />
        <TextField
          className={classes.textField}
          data-qa="org-admin-email-textfield"
          required
          label="Organisation Admin Email"
          variant="outlined"
          error={errors.organisationAdminEmail as boolean}
          helperText={errors.organisationAdminEmail}
          value={organisation.organisationAdmin.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({
              type: SelfOnboardOrganisationReducerAction.SET_ORG_ADMIN_EMAIL,
              value: event.target.value,
            })
          }
          onKeyUp={() => validate('organisationAdminEmail')}
          onBlur={() => validate('organisationAdminEmail')}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepUser);
