import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertMenuMutation = gql`
  mutation ($object: menus_insert_input!) {
    insert_menus_one(object: $object) {
      id
    }
  }
`;

export const createMenu = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_menus_one: {
      id: string;
    };
  }>({ mutation: insertMenuMutation!, variables });
  return res.data?.insert_menus_one.id;
};
