import { UnknownObject } from '../../../../../react-app-env';
import schema_raw from './schema_raw.json';
import schema from './schema.json';
import tableData from './data.json';

export interface SchemaTable {
  name: string;
  label: string;
  fields: SchemaField[];
  relationships: SchemaRelationship[];
}

export interface SchemaField {
  name: string;
  // kind: string,
  type: string;
}

export interface SchemaRelationship {
  // name: string,
  // kind: string,
  // type: string,
  name: string;
  type: string;
  table: string;
  from: string;
  to: string;
  hasAggregate: boolean;
}

export const getSchema = () => schema;

export const getSchemaTables = () => {
  const d: SchemaTable[] = tableData.tables as SchemaTable[];
  return d.map((t) => ({ key: t.name, label: t.label }));
};

// export const getSchemaTables = () => {
//   const d: UnknownObject = schema;
//   return Object.keys(schema).map((key) => ({ key, label: d[key].label }));
// }

export const refactorSchema = () => {
  const d: UnknownObject = {};
  schema_raw.__schema.types.filter(filterSchema).forEach((i: UnknownObject) => {
    d[i.name] = {
      aggregate: i.name.includes('aggregate'),
      fields: i.fields.filter(filterFields).map((f: UnknownObject) => ({
        name: f.name,
        ...fixType(f.type),
      })),
    };
  });
  return d;
};

const fixType = (data: UnknownObject) => {
  let t: UnknownObject = {};
  if (data) {
    if (data.ofType) {
      t = fixType(data.ofType);
    } else {
      t = data;
    }
  }
  return {
    kind: t.kind || null,
    type: t.name || t.type || null,
  };
};

const filterSchema = (f: UnknownObject) => !['query', 'mutation', 'subscription'].some((i) => f.name.startsWith(i));
const filterFields = (f: UnknownObject) => !['aggregate', 'nodes'].includes(f.name);

export const getTableWithRelationships = (table: string): SchemaTable => {
  const d: SchemaTable[] = tableData.tables as SchemaTable[];
  const t = d.find((i) => i.name === table);
  // const f = t && t.fields ? [...t.fields] : [];
  const a: SchemaTable = {
    ...t!,
    fields: t!.fields.filter((i: UnknownObject) => {
      switch (i.type) {
        case 'boolean':
          i.type = 'yes / no';
          break;
        case 'string':
          i.type = 'text';
          break;
        case 'timetz':
          i.type = 'time';
          break;
        case 'timestamptz':
          i.type = 'date time';
          break;
        default:
          break;
      }
      return true;
    }),
  };
  return a;
};

// export const getTableWithRelationships = (table: string) : SchemaTable => {
//   const d: UnknownObject = schema;
//   const t = d[table];
//   const f = t && t.fields ? [...t.fields] : [];
//   const a = {
//     ...t,
//     relationships: f.filter((i: UnknownObject) => i.kind === 'OBJECT' && !i.type.endsWith('aggregate')).map((i) => ({ ...i, hasAggregate: d[`${i.type}_aggregate`] !== undefined })),
//     fields: f.filter((i: UnknownObject) => {
//       if (i.kind === 'OBJECT' || ['uuid'].includes(i.type) || i.name.includes('index') || i.type.startsWith('enum')) {
//         return false;
//       }
//       switch (i.type) {
//         case 'Boolean':
//           i.type = 'yes / no';
//           break;
//         case 'Int':
//           i.type = 'number';
//           break;
//         case 'String':
//           i.type = 'text';
//           break;
//         case 'timetz':
//           i.type = 'time';
//           break;
//         case 'timestamptz':
//           i.type = 'date time';
//           break;
//         default:
//           break;
//       }
//       return true;
//     }),
//   };
//   return a;
// };

export const getTableWithDeepRelationships = (table: string, roots: string[] = []) => {
  if (roots.includes(table)) {
    return null;
  }
  roots.push(table);
  const d: UnknownObject = schema;
  const a = d[table];
  const f = a.fields;
  a.fields = f.map((i: UnknownObject) => {
    const b = i;
    if (i.kind === 'OBJECT') {
      b.relationship = getTableWithDeepRelationships(i.type, roots);
    }
    return b;
  });
  return a;
};
