import React, { useReducer, useEffect } from 'react';
import { AppBar, Box, Grid, Typography, Container, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Header from './Common/Header';
import Categories from './Common/Categories';
import MenuCard from './Common/MenuCard';
import Cart from './Common/Cart';
import { useParams } from 'react-router-dom';
import { useMutation as useRestMutation, useQuery as useRestQuery } from 'react-query';
import { capturePayment, createPayment, getMenuQuery } from '../Menu/queries';
import { createServedOrderAfterPayment, createServedOrderPayment, createTakeAwayOrderAfterPayment, createTakeAwayOrderPayment } from '../Menu/mutations';
import { initialState, reducer } from './Common/State';

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: '#FAF9F6', // Matches reference app
    height: '100%',
  },
  pageContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  menuSection: {
    flex: 1,
    paddingRight: theme.spacing(3),
  },
  menuGrid: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)', // Two cards per row
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr', // One card per row for mobile
    },
  },
  categoryHeader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
  },
  cartContainer: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Subtle shadow for the cart
    position: 'sticky',
    top: theme.spacing(2),
    height: 'fit-content',
  },
  categoriesBar: {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: '#FAF9F6',
    borderBottom: '1px solid #ddd',
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
}));

interface UrlParams {
  organisation_id: string;
  location_id: string;
}

interface MenuData {
  categories: Category[];
  location_info: any; // Replace with actual type
}

interface Category {
  id: string;
  name: string;
  category_type: 'DISH' | 'DRINK';
  dish_assignments: { dish: any }[]; // Replace with actual types
  drink_assignments: { drink: any }[]; // Replace with actual types
}

const MainLayout: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { cart, orderDetails } = state;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { organisation_id, location_id } = useParams<UrlParams>();

  const {
    data: menuData,
    isLoading,
    refetch,
  } = useRestQuery<MenuData>({
    queryKey: 'menu-data',
    queryFn: () => getMenuQuery(organisation_id, location_id),
  });

  const { mutateAsync: createOrder, isLoading: creatingOrder } = useRestMutation({
    mutationKey: 'create-order',
    mutationFn: (request: string) => createPayment(request),
  });

  const { mutateAsync: captureOrder, isLoading: capturingOrder } = useRestMutation({
    mutationKey: 'capture-order',
    mutationFn: (orderId: string) => capturePayment(orderId),
  });

  const { mutateAsync: createServedOrderAfterPaymentAsync, isLoading: creatingOrderAfterPayment } = useRestMutation({
    mutationKey: 'create-order-after-payment',
    mutationFn: (order: any) => createServedOrderAfterPayment(order),
  });

  const { mutateAsync: createTakeAwayOrderAfterPaymentAsync, isLoading: creatingTakeAwayOrderAfterPayment } = useRestMutation({
    mutationKey: 'create-takeaway-order-after-payment',
    mutationFn: (order: any) => createTakeAwayOrderAfterPayment(order),
  });

  const { mutateAsync: createTakeAwayOrderPaymentAsync, isLoading: creatingTakeAwayOrderPayment } = useRestMutation({
    mutationKey: 'create-takeaway-order-payment',
    onSuccess: () => refetch(),
    mutationFn: (order: any) => createTakeAwayOrderPayment(order),
  });

  const { mutateAsync: createServedOrderPaymentAsync, isLoading: creatingServedOrderPayment } = useRestMutation({
    mutationKey: 'create-served-order-payment',
    onSuccess: () => refetch(),
    mutationFn: (order: any) => createServedOrderPayment(order),
  });

  const [selectedCategory, setSelectedCategory] = React.useState<string>('');

  useEffect(() => {
    if (menuData?.categories?.[0]?.id) {
      setSelectedCategory(menuData.categories[0].id);
    }
  }, [menuData]);

  const handleCategorySelect = (categoryId: string) => {
    setSelectedCategory(categoryId);
  };

  // Filter menu items (dishes and drinks) by selected category
  const filteredMenuItems = React.useMemo(() => {
    const selectedCategoryData = menuData?.categories.find((category) => category.id === selectedCategory);

    if (!selectedCategoryData) return [];

    return selectedCategoryData.category_type === 'DISH'
      ? selectedCategoryData.dish_assignments.map((item) => item.dish)
      : selectedCategoryData.drink_assignments.map((item) => item.drink);
  }, [menuData, selectedCategory]);

  // Function to calculate total price of items in the cart
  const calculateTotalPrice = (): number => {
    return Object.keys(cart).reduce((total, cartKey) => {
      const [productId, modifiersKey] = cartKey.split(':');
      const product = menuData?.categories
        .flatMap((category) => (category.category_type === 'DISH' ? category.dish_assignments.map((item) => item.dish) : category.drink_assignments.map((item) => item.drink)))
        .find((item) => item.id === productId);

      if (!product) return total;

      const selectedModifiers = product.modifier_assignments
        .filter((mod: { modifier: { id: string } }) => modifiersKey.includes(mod.modifier.id))
        .map((mod: { modifier: any }) => mod.modifier);

      const itemPrice = selectedModifiers.reduce((sum: number, mod: any) => sum + mod.price, product.price);
      return total + (itemPrice / 100) * cart[cartKey];
    }, 0);
  };

  const totalPrice = calculateTotalPrice();

  return (
    <>
      {/* Header Section */}
      <div className={classes.layout}>
        {menuData?.location_info && <Header locationInfo={menuData.location_info} location_id={location_id} />}

        {/* Categories Bar */}
        <Box className={classes.categoriesBar}>
          {menuData?.categories && <Categories categories={menuData.categories} selectedCategory={selectedCategory} onCategorySelect={handleCategorySelect} />}
        </Box>

        {/* Main Content */}
        <Container maxWidth={false} className={classes.pageContainer}>
          <Grid container spacing={4}>
            {/* Menu Section */}
            <Grid item xs={12} md={8} className={classes.menuSection}>
              {selectedCategory && <Typography className={classes.categoryHeader}>{menuData?.categories.find((category) => category.id === selectedCategory)?.name}</Typography>}
              <div className={classes.menuGrid}>
                {filteredMenuItems.map((item) => (
                  <MenuCard
                    key={item.id}
                    item={{
                      ...item,
                      imageUrl: item.image_added ? `${process.env.REACT_APP_AWS_IMAGES_URL}/${item.id}.jpeg` : `${process.env.REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`,
                    }}
                    cart={cart}
                    dispatch={dispatch}
                    // menuData={menuData}
                  />
                ))}
              </div>
            </Grid>

            {/* Cart Section */}
            <Grid item xs={12} md={4}>
              <div className={classes.cartContainer}>
                <Cart
                  cart={cart}
                  dispatch={dispatch}
                  menuData={menuData}
                  totalPrice={totalPrice}
                  //@ts-ignore
                  orderDetails={orderDetails}
                  createOrder={createOrder}
                  captureOrder={captureOrder}
                  createServedOrderAfterPaymentAsync={createServedOrderAfterPaymentAsync}
                  createTakeAwayOrderAfterPaymentAsync={createTakeAwayOrderAfterPaymentAsync}
                  createTakeAwayOrderPaymentAsync={createTakeAwayOrderPaymentAsync}
                  createServedOrderPaymentAsync={createServedOrderPaymentAsync}
                  location_id={location_id}
                  organisation_id={organisation_id}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default MainLayout;
