import gql from 'graphql-tag';

export const GET_MENU_LOCATION_STOCK = gql`
  query GetMenuLocationStock($locationId: uuid!) {
    menu_location_stock(where: { location_id: { _eq: $locationId } }) {
      id
      menu_id
      dish_id
      drink_id
      total_stock
      limit_per_day
      item_per_day_left
      dish_item {
        name
        category_assignments {
          category_id
          category {
            name
          }
        }
      }
      drink_item {
        name
        category_assignments {
          category_id
          category {
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_MENU_LOCATION_STOCK = gql`
  mutation UpdateMenuLocationStock($id: uuid!, $total_stock: Int!, $limit_per_day: jsonb!, $item_per_day_left: Int!) {
    update_menu_location_stock_by_pk(pk_columns: { id: $id }, _set: { total_stock: $total_stock, limit_per_day: $limit_per_day, item_per_day_left: $item_per_day_left }) {
      id
      total_stock
      limit_per_day
      item_per_day_left
    }
  }
`;
