import React, { useState, useReducer, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { initialOpeningHours, initialPreorderHours, InventoryRestockType, LocationMenu, locationReducer, LocationType } from '../Common';
import Steps from '../Common/components/StepsNew';

import { createLocationInitQuery } from './queries';
import { insertLocationMutation, InsertLocationMutationResult } from './mutations';

import styles from './styles';
import { getFileRequest, webhookClient } from '../../../../utils/webhookClient';
import { Dashboard } from '../../../../layouts';
import { Breadcrum } from '../../../../layouts/Dashboard/components/Topbar';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  organisation_id?: string;
  tax_rate: number;
  handleFinish: (id: string) => void;
  setBreadcrumbs: any;
  breadcrumbs: Breadcrum[];
  breadcrumbsUpdated: boolean;
}

const CreateLocation = ({ classes, history, organisation_id, tax_rate = 0, handleFinish, setBreadcrumbs, breadcrumbsUpdated, breadcrumbs }: Props): React.ReactElement => {
  const [locationTypes, setLocationTypes] = useState<LocationType[]>([]);
  const [inventoryRestockTypes, setInventoryRestockTypes] = useState<InventoryRestockType[]>([]);
  const [locationMenus, setLocationMenus] = useState<LocationMenu[]>([]);

  const [location, dispatchLocation] = useReducer(locationReducer, {
    name: '',
    email: '',
    phone: '',
    website: '',
    tax_number: '',
    number_of_tables: 0,
    location_type: 'RESTAURANT',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_country: '',
    address_postcode: '',
    tax_rate: tax_rate,
    currency: '',
    locale: '',
    can_manage_menus: false,
    has_kitchen_printer: false,
    has_bar_printer: false,
    has_staff: false,
    has_station_printers: false,
    service_charge: null,
    idle_timeout: 30,
    location_menu_id: undefined,
    ordering_system_primary_color: '#3b4d19',
    ordering_system_secondary_color: '#7bbf40',
    header_image_added: false,
    logo_image_added: false,
    ordering_system_open_hours: initialOpeningHours,
    ordering_system_preorder_hours: initialPreorderHours,
    preorder_days_limit: null,
    preorder_pacing_limit: null,
    order_interval: 15,
    inventory_restock_type: '',
    voucher_codes_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    integrations: {
      vouchers: false,
    },
  });

  const { data: createLocationInitData } = useQuery(createLocationInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  const afterLocationCreated = async (locationData: InsertLocationMutationResult) => {
    const {
      insert_locations_one: { id: locationId },
    } = locationData;

    if (location.header_image && location.logo_image) {
      const headerImageRequest = await getFileRequest(location.header_image, `${locationId}.jpeg`, 'image/jpeg');
      await webhookClient.postFormData('ordering-system/images-upload', headerImageRequest);

      const logoImageRequest = await getFileRequest(location.logo_image, `${locationId}-logo.jpeg`, 'image/jpeg');
      await webhookClient.postFormData('ordering-system/images-upload', logoImageRequest);
    }

    handleFinish(locationId);
  };

  const [createLocation, { loading: saving }] = useMutation<InsertLocationMutationResult>(insertLocationMutation, {
    onError: () => {},
    onCompleted: (locationData) => afterLocationCreated(locationData),
  });

  useEffect(() => {
    if (createLocationInitData) {
      setLocationTypes(createLocationInitData.enum_location_types);
      setInventoryRestockTypes(createLocationInitData.enum_inventory_restock_types);
      setLocationMenus(createLocationInitData.menus);
    }
  }, [createLocationInitData]);

  const handleSave = async () => {
    const { header_image_added, header_image, header_image_data, logo_image_added, logo_image, logo_image_data, voucher_codes_aggregate, integrations, ...rest } = location;

    const insertLocationVariables = {
      object: {
        organisation_id,
        ...rest,
        header_image_added: !!header_image,
        logo_image_added: !!logo_image,
        integrations: {
          data: {
            organisation_id,
            previous_report_at: new Date().toISOString(),
          },
        },
      },
    };
    await createLocation({ variables: insertLocationVariables });
  };

  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (breadcrumbsUpdated) {
      //@ts-ignore
      setBreadcrumbs((prev) => [...prev, { label: 'Configuration options', action: handleResetSteps }]);
    }
    // eslint-disable-next-line
  }, [breadcrumbsUpdated]);
  const handleResetSteps = () => {
    setStep(0);
    //@ts-ignore
    setBreadcrumbs((prevState) => {
      return prevState.slice(0, -1);
    });
  };

  return (
    <Dashboard breadcrumbs={breadcrumbs}>
      <Steps
        completeLabel="Create"
        location={location}
        locationId={undefined}
        organisationId={undefined}
        locationTypes={locationTypes}
        inventoryRestockTypes={inventoryRestockTypes}
        locationMenus={locationMenus}
        dispatch={dispatchLocation}
        handleSave={handleSave}
        isLocationCreate
        setBreadcrumbs={setBreadcrumbs}
        step={step}
        setStep={setStep}
      />
      {saving && <CustomBackdrop label="Creating Location" />}
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(CreateLocation));
