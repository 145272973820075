import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationSettingsInitQuery = (location_id: string) => ({
  locations_by_pk: params(
    { id: rawString(location_id) },
    {
      integrations: {
        id: types.string,
        location_id: types.custom(),
        payment: types.custom(),
        deliverect: types.custom(),
        xero: types.custom(),
        vouchers: types.boolean,
      },
    }
  ),
});

type X = Unpacked<typeof _selectLocationSettingsInitQuery>;
export type Location = X['locations_by_pk'];
export type LocationSettings = Location['integrations'];

export const selectLocationSettingsInitQuery = (location_id: string) => gql`
  ${query(_selectLocationSettingsInitQuery(location_id))}
`;
