import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomStepper from '../../../../../../components/CustomStepper';
import { validationErrors, ValidationErrors, SelfOnboardOrganisationReducerAction, validation } from '../../../Common';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';
import { SelfOnboardOrganisation } from '../../../../../../components/Global/Organisations';
import StepUser from '../StepUser';
import StepLocation from '../StepLocation';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  organisation: SelfOnboardOrganisation;
  dispatch: React.Dispatch<{
    type: SelfOnboardOrganisationReducerAction;
    value: any;
  }>;
  handleSave: () => void;
}

const OrganisationSteps = ({ classes, completeLabel, organisation, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    email: false,
    phone: false,
    firstName: false,
    lastName: false,
    organisationAdminEmail: false,
    locationName: false,
    locationEmail: false,
    locationPhone: false,
    locationAddressLine1: false,
    locationCity: false,
    locationCountry: false,
    locationPostcode: false,
  });

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = validation.name(organisation.name);
        errorItems.email = validation.email(organisation.email);
        errorItems.phone = validation.phone(organisation.phone);
      }
      if (_step === 1) {
        errorItems.firstName = validation.firstName(organisation.organisationAdmin.firstName);
        errorItems.lastName = validation.lastName(organisation.organisationAdmin.lastName);
        errorItems.organisationAdminEmail = validation.organisationAdminEmail(organisation.organisationAdmin.email);
      }
      if (_step === 2) {
        errorItems.locationName = validation.locationName(organisation.location.name);
        errorItems.locationEmail = validation.locationEmail(organisation.location.email);
        errorItems.locationPhone = validation.locationPhone(organisation.location.phone);
        errorItems.locationAddressLine1 = validation.locationAddressLine1(organisation.location.addressLine1);
        errorItems.locationCity = validation.locationCity(organisation.location.city);
        errorItems.locationCountry = validation.locationCountry(organisation.location.country);
        errorItems.locationPostcode = validation.locationPostcode(organisation.location.postcode);
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [organisation, errors]
  );

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            detail: organisation.name,
            component: <StepDetails organisation={organisation} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Organisation Admin',
            component: <StepUser organisation={organisation} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Location',
            component: <StepLocation organisation={organisation} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview organisation={organisation} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationSteps);
