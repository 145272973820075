import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _voucherCodesInitQuery = (id: string) => ({
  locations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      currency: types.string,
      locale: types.string,
      organisation: {
        name: types.string,
      },
      voucher_codes: params(
        {
          order_by: { name: 'asc' },
        },
        [
          {
            id: types.string,
            name: types.string,
            code: types.string,
            value: types.number,
            voucher_type: types.string,
            single_use: types.boolean,
            can_expire: types.boolean,
            times_used: types.number,
            start_date: types.string,
            end_date: types.string,
            category_ids: types.custom<string[]>(),
            created_at: types.string,
          },
        ]
      ),
    }
  ),
});

type X = Unpacked<typeof _voucherCodesInitQuery>;
export type Location = X['locations_by_pk'];
export type VoucherCode = Location['voucher_codes'][0];

export const voucherCodesInitQuery = (id: string) => gql`
  ${query(_voucherCodesInitQuery(id))}
`;
