interface LocationInfo {
  name: string;
  address_line_1: string;
  address_city: string;
  currency: string;
  email: string;
  header_image_added: boolean;
  logo_image_added: boolean;
  ordering_system_open_hours: any;
  ordering_system_preorder_hours: any;
  ordering_system_primary_color: string;
  ordering_system_secondary_color: string;
  preorder_days_limit: number | null;
  location_menu_id: string;
  order_interval: number;
  preorder_pacing_limit: number | null;
  integrations: IntegrationInfo;
}
export interface IntegrationInfo {
  payment: PaymentInfo;
  id: string;
}

export interface PaymentInfo {
  isOnboarded: boolean;
  merchant_id: string;
}

export interface Menu {
  name: string;
  description: string;
  active: boolean;
  all_day: boolean;
  available_from: string;
  available_to: string;
  menu_types: OrderType[];
  is_primary: boolean;
  categories: Category[];
  location_info: LocationInfo;
  orders_takeaway: any;
}

enum OrderType {
  DINE_IN = 'DINE_IN',
  QUICK_SALE = 'QUICK_SALE',
  TAKEAWAY = 'TAKEAWAY',
  DELIVERECT = 'DELIVERECT',
}

export interface Category {
  id: string;
  name: string;
  colour_id: string;
  active: boolean;
  category_type: CategoryType;
  order_index: number;
  dish_assignments: DishAssignments[];
  drink_assignments: DrinkAssignment[];
}

interface DishAssignments {
  dish: CategoryItem;
}

interface DrinkAssignment {
  drink: CategoryItem;
}

export enum CategoryType {
  DISH = 'DISH',
  DRINK = 'DRINK',
}

export interface CategoryItem {
  id: string;
  name: string;
  description: string;
  active: boolean;
  tag: string;
  price: number;
  tax: number;
  prep_time: number;
  bypass_prep: boolean;
  should_print: boolean;
  auto_serve_when_ready: boolean;
  croppedImageData?: string;
  image_added: boolean;
  item_per_day_left: number;
  score: number;
  modifier_assignments: ModifierAssignment[];
  allergy_assignments: AllergyAssignment[];
}

interface AllergyAssignment {
  id: string;
  allergy: Allergy;
}

interface ModifierAssignment {
  id: string;
  modifier: Modifier;
}

interface Modifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

interface Allergy {
  id: string;
  name: string;
  description: string;
  allergy_type: string;
}
