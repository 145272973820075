import moment from 'moment';

export const getStartOfDay = () => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const getEndOfDay = () => {
  const date = new Date();
  date.setUTCHours(23, 59, 59, 59);
  return date;
};

export const getDaysPast = (days: number) => {
  const date = getStartOfDay();
  date.setDate(date.getDate() - days);
  return date;
};

export const getWeeksPast = (weeks: number) => {
  const date = getStartOfDay();
  date.setDate(date.getDate() - weeks * 7);
  return date;
};

export const getStartOfWeek = (weeks: number) => {
  const d = getWeeksPast(weeks);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  const nd = new Date(d.setDate(diff));
  nd.setUTCHours(0, 0, 0, 0);
  return nd;
};

export const getMonthsPast = (months: number) => {
  const date = getStartOfDay();
  date.setMonth(date.getMonth() - (months - 1));
  date.setDate(1);
  return date;
};

export const formatDate = (date: Date, format: string) => {
  return moment(date).format(format);
};

export const secondsToTime = (seconds: number) => new Date(seconds * 1000).toISOString().slice(11, -1).split('.')[0];

export const timeToString = (time: string) => {
  let timeString = '';
  if (time) {
    const ts = time.split(':').map((i) => +i);
    const [h, m, s] = ts;
    if (h > 0) {
      timeString += `${h}h `;
    }
    if (m > 0) {
      timeString += `${m}m `;
    }
    if (s > 0) {
      timeString += `${s}s`;
    }
  }
  return timeString;
};

export const compensateDST = (dateIn: Date) => {
  const janOffset = new Date(dateIn.getFullYear(), 0, 1).getTimezoneOffset();
  const julOffset = new Date(dateIn.getFullYear(), 6, 1).getTimezoneOffset();
  const dstMinutes = dateIn.getTimezoneOffset() - Math.max(janOffset, julOffset);
  const d = new Date(dateIn);
  d.setMinutes(d.getMinutes() - dstMinutes);
  const dString = `${d.getFullYear()}-${`${d.getMonth() + 1}`.padStart(2, '0')}-${`${d.getDate() + 1}`.padStart(2, '0')}T${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.000+00:00`;
  return new Date(dString);
};

export const removeDST = (dateIn: Date) => {
  if (dateIn.getMonth() > 4 && dateIn.getMonth() < 10) {
    dateIn.setHours(dateIn.getHours() + 1);
  }
  return dateIn;
};

export const getDateSecondsDiff = (startDate: Date, endDate: Date) => {
  return Math.floor(Math.abs(endDate.getTime() - startDate.getTime()) / 1000);
};

export const exactDateString = (date: Date) => {
  const nDate = date; //compensateDST(date);
  const year = nDate.getFullYear();
  const month = `${nDate.getMonth() + 1}`.padStart(2, '0');
  const day = `${nDate.getDate()}`.padStart(2, '0');
  const hours = `${nDate.getHours()}`.padStart(2, '0');
  const mins = `${nDate.getMinutes()}`.padStart(2, '0');
  const secs = `${nDate.getSeconds()}`.padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${mins}:${secs}`;
};
