import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Button, Collapse, Grid, useMediaQuery, makeStyles, Theme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useTheme from '@material-ui/core/styles/useTheme';
import { Email } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: '#000', // Matches reference app
    color: '#fff',
  },
  subHeader: {
    backgroundColor: '#FAF9F6', // Matches reference app
    color: '#000',
    padding: theme.spacing(2, 4), // Compact padding
  },
  businessHours: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1), // Slight spacing between "Open all day" and hours
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'flex-start', // Left-align the icons with the text
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1), // Spacing between icon and text
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center', // Center-align logo
    alignItems: 'center',
  },
  hoursCollapse: {
    marginTop: theme.spacing(1),
  },
  preOrderButton: {
    backgroundColor: '#fff',
    color: '#000',
    textTransform: 'none',
    fontWeight: 'bold',
  },
  toggleButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    maxHeight: '50px',
    objectFit: 'contain',
  },
}));

const Header: React.FC<{ locationInfo: any; location_id: string }> = ({ locationInfo, location_id }) => {
  const classes = useStyles();
  const [showHours, setShowHours] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const businessHours = [
    { day: 'Monday', hours: 'Closed' },
    { day: 'Tuesday', hours: '6:00 pm - 11:59 pm' },
    { day: 'Wednesday', hours: '12:00 am - 11:59 pm' },
    { day: 'Thursday', hours: '12:00 am - 11:59 pm' },
    { day: 'Friday', hours: '12:00 am - 11:59 pm' },
    { day: 'Saturday', hours: '12:00 am - 11:59 pm' },
    { day: 'Sunday', hours: '12:00 am - 11:59 pm' },
  ];

  return (
    <AppBar position="static" className={classes.header}>
      <Toolbar>
        <img
          src={
            locationInfo.header_image_added
              ? `${process.env.REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL}/${location_id}.jpeg`
              : `${process.env.REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`
          }
          alt="Logo"
          className={classes.logo}
        />

        {/* Center: Business Name */}
        <Typography variant={isMobile ? 'h6' : 'h5'} style={{ flexGrow: 1, textAlign: 'center' }}>
          {locationInfo.name}
        </Typography>

        {/* Right Side: Pre-order Button */}
        {!isMobile && (
          <Button variant="contained" className={classes.preOrderButton}>
            Pre-order for Pickup
          </Button>
        )}
      </Toolbar>

      {/* Sub-header */}
      <Box className={classes.subHeader}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <div className={classes.businessHours}>
              <Typography variant="body2">Open all day</Typography>
              <IconButton size="small" onClick={() => setShowHours(!showHours)} className={classes.toggleButton}>
                {showHours ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
            <Collapse in={showHours} className={classes.hoursCollapse}>
              {locationInfo.ordering_system_open_hours.map((day: any, index: any) => (
                <Typography key={index} variant="body2">
                  {day.day}: {day.open} - {day.close}
                </Typography>
              ))}
            </Collapse>

            {!isMobile && (
              <div className={classes.contactDetails}>
                <div className={classes.contactItem}>
                  <Typography variant="body2">📞</Typography>
                  <Typography variant="body2">{locationInfo.phone || 'N/A'}</Typography>
                </div>
                <Box className={classes.contactItem}>
                  <Email />
                  <Typography variant="body2">{locationInfo.email}</Typography>
                </Box>
                {/*<div className={classes.contactItem}>*/}
                {/*  <Typography variant="body2">🌍</Typography>*/}
                {/*  <Typography variant="body2">roguesarnies.uk</Typography>*/}
                {/*</div>*/}
                <div className={classes.contactItem}>
                  <Typography variant="body2">📍</Typography>
                  <Typography variant="body2">{`${locationInfo.address_line_1}, ${locationInfo.address_city}`}</Typography>
                </div>
              </div>
            )}
          </Grid>

          {/* Right Section: Logo */}
          <Grid item xs={12} md={4} className={classes.logoContainer}>
            <img
              src={
                locationInfo.logo_image_added
                  ? `${process.env.REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL}/${location_id}-logo.jpeg`
                  : `${process.env.REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`
              }
              alt="Logo"
              className={classes.logo}
            />
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default Header;
