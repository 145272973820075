import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: theme.spacing(3),
      // marginBottom: theme.spacing(3),
      flexDirection: 'column',
      flex: '1 1 auto',
      justifyContent: 'flex-end',
      minHeight: theme.spacing(5),
      '& button': {
        width: '100%',
        marginBottom: theme.spacing(3),
      },
      '& button:last-child': {
        marginBottom: 0,
      },
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

export default styles;
