import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: theme.spacing(2),
    },
    dates: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    error: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    hours: {
      flexBasis: '25%',
      marginRight: theme.spacing(2),
    },
    minutes: {
      flexBasis: '25%',
      marginRight: theme.spacing(2),
    },
    seconds: {
      flexBasis: '25%',
      marginRight: theme.spacing(2),
    },
    milliseconds: {
      flexBasis: '30%',
    },
    time: {
      flexBasis: '100%',
      marginLeft: theme.spacing(2),
    },
    hoursMenu: {
      height: theme.spacing(45),
    },
    minutesMenu: {
      height: theme.spacing(45),
    },
    secondsMenu: {
      height: theme.spacing(45),
    },
    millisecondsMenu: {
      height: theme.spacing(45),
    },
  });

export default styles;
