import React from 'react';
import { WithStyles } from '@material-ui/core/styles';

import { formatDate } from '../../../../../../utils/dateUtils';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withStyles } from '@material-ui/core';

import ReadOnlyBlock from '../../../../../ReadOnlyBlock';
import { CodeVoucher, Category, getVoucherValueString, CodeVoucherType } from '../..';

import styles from './styles';
import { booleanToYesNo } from '../../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  voucher: CodeVoucher;
  categories: Category[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, voucher, categories, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        title={voucher.name}
        gridSize={6}
        items={[
          { label: 'Voucher code', value: voucher.code },
          { label: 'Voucher value', value: getVoucherValueString(voucher.value, voucher.voucher_type, 'en-GB', 'GBP') },
          { label: 'Single use', value: booleanToYesNo(voucher.single_use) },
          { label: 'Can expire', value: booleanToYesNo(voucher.can_expire) },
          voucher.can_expire ? { label: 'Start date', value: formatDate(voucher.start_date, 'DD/MM/YYYY') } : null,
          voucher.can_expire ? { label: 'End date', value: formatDate(voucher.end_date, 'DD/MM/YYYY') } : null,
          voucher.voucher_type === CodeVoucherType.PERCENT
            ? { label: 'Category assignments', list: categories.filter((i) => voucher.category_ids.includes(i.id)).map((i) => i.name) }
            : null,
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
