import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _createOrganisationInitQuery = () => ({
  enum_organisation_types: [
    {
      organisation_type: types.string,
      description: types.string,
    },
  ],
});

type X = Unpacked<typeof _createOrganisationInitQuery>;
export type EnumOrganisationType = X['enum_organisation_types'][0];

export const createOrganisationInitQuery = () => gql`
  ${query(_createOrganisationInitQuery())}
`;
