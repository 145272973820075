import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { Divider, FormControlLabel, Switch, TextField, withStyles } from '@material-ui/core';

import { OrganisationUser, ValidationErrors, OrganisationUserReducerAction, validation } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  user: OrganisationUser;
  isEdit: boolean;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: OrganisationUserReducerAction; value: any }>;
}

const StepDetails = ({ classes, user, isEdit, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.first_name = validation.first_name(user.first_name);
      }
      if (field === 'last_name') {
        errorItems.last_name = validation.last_name(user.last_name);
      }
      if (field === 'email') {
        errorItems.email = validation.email(user.email);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [user, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="first-name-textfield"
          required
          label="First name"
          variant="outlined"
          error={errors.first_name as boolean}
          helperText={errors.first_name}
          value={user.first_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationUserReducerAction.FIRST_NAME, value: event.target.value })}
          onKeyUp={() => validate('first_name')}
          onBlur={() => validate('first_name')}
        />
        <TextField
          className={classes.textField}
          data-qa="last-name-textfield"
          required
          label="Last name"
          variant="outlined"
          error={errors.last_name as boolean}
          helperText={errors.last_name}
          value={user.last_name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationUserReducerAction.LAST_NAME, value: event.target.value })}
          onKeyUp={() => validate('last_name')}
          onBlur={() => validate('last_name')}
        />
        <TextField
          className={classes.textField}
          data-qa="email-textfield"
          required
          disabled={isEdit}
          label="Email"
          variant="outlined"
          value={user.email}
          error={errors.email as boolean}
          helperText={errors.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationUserReducerAction.EMAIL, value: event.target.value })}
          onKeyUp={() => validate('email')}
          onBlur={() => validate('email')}
        />

        <Divider className={classes.divider} />

        <FormControlLabel
          label="Active"
          className={classes.active}
          control={
            <Switch
              checked={user.active}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: OrganisationUserReducerAction.ACTIVE, value: event.target.checked })}
            />
          }
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
