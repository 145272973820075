import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../react-app-env';

import { Paper, withStyles } from '@material-ui/core';

import { Chart } from 'react-chartjs-2';
import { buildQuery, EnumReportOutputCalc, EnumReportOutputType, getNamedChartColor, getNestedObjectValue, groupReportData, ReportData } from '../../Common';

// import data from './data.json';
import styles from './styles';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

export interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  data: ReportData;
  type: 'bar' | 'line' | 'pie' | 'radar';
}

interface ReportChartData {
  title: string;
  labels: string[];
  datasets: ReportChartDataset[];
}

interface ReportChartDataset {
  label: string;
  data: Array<string | number>;
  backgroundColor?: string;
  borderColor?: string;
}

const createData = (data: UnknownObject, report: ReportData): ReportChartData => {
  const chart: ReportChartData = {
    title: report.name,
    labels: [],
    datasets: [],
  };

  if (report.output.calc === EnumReportOutputCalc.SUM) {
    if (report.output.type === EnumReportOutputType.GROUP) {
      report.headers.forEach((header, hIndex: number) => {
        const key: string = header.key;
        const groups = groupReportData(data[report.table], key);
        const colour = getNamedChartColor(hIndex);
        chart.datasets.push({
          label: header.label,
          backgroundColor: colour,
          borderColor: colour,
          data: [],
        });

        Object.keys(groups).forEach((label) => {
          const value = groups[label];
          chart.labels.push(label);
          chart.datasets[hIndex].data.push(value);
        });
      });
    }
  }
  if (report.output.calc === EnumReportOutputCalc.DATA) {
    if (report.output.type === EnumReportOutputType.GROUP) {
      const colour = getNamedChartColor(0);
      chart.datasets.push({
        label: '',
        backgroundColor: colour,
        borderColor: colour,
        data: [],
      });
      report.headers.forEach((header, hIndex: number) => {
        const key: string = header.key;
        const groups = groupReportData(data[report.table], key);
        chart.labels.push(header.label);

        const value = Object.keys(groups)
          .map((i) => Number(i) * groups[i])
          .reduce((a, b) => a + b, 0);
        chart.datasets[0].data.push(value);

        // Object.keys(groups).forEach((label) => {
        //   const value = groups[label];
        //   chart.datasets[0].data.push(label);
        // });
      });
    }
    if (report.output.type === EnumReportOutputType.DATA) {
      const table = data[report.table];

      report.headers.forEach((header) => {
        chart.labels.push(header.label);
      });
      table.forEach((t: UnknownObject, rIndex: number) => {
        const colour = getNamedChartColor(rIndex);
        chart.datasets.push({
          label: '',
          backgroundColor: colour,
          borderColor: colour,
          data: [],
        });
        report.headers.forEach((header, hIndex: number) => {
          const value = getNestedObjectValue(header.key, t, true);
          chart.datasets[rIndex].data[hIndex] = value as string;
        });
      });
    }
  }
  return chart;
};

const CustomReportChart = ({ classes, data, type }: Props): React.ReactElement => {
  const [chart, setChart] = useState<ReportChartData>({
    title: '',
    labels: [],
    datasets: [],
  });

  const options = {
    responsive: true,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      x: {
        // stacked: true,
      },
      y: {
        // stacked: true,
        min: 0,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const init = useCallback(async () => {
    const queryRes = await buildQuery(data);
    setChart(createData(queryRes, data));
  }, [data]);

  useEffect(() => {
    let mounted = true;
    if (mounted && chart.title === '') {
      init();
    }
    return () => {
      mounted = false;
    };
  }, [chart, init]);

  return (
    <Paper className={classes.root} variant="outlined">
      <Chart type={type} data={chart} options={options} />
    </Paper>
  );
};

export default withStyles(styles)(CustomReportChart);
