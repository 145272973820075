import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { withStyles } from '@material-ui/core';

import { ViewList, ViewModule } from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  value: ViewType;
  sortable?: boolean;
  handleChangeView: (viewType: ViewType) => void;
}

export enum ViewType {
  TABLE,
  GRID,
  SORTABLE,
}

const CustomViewToggle = ({ classes, value = ViewType.TABLE, sortable = false, handleChangeView }: Props): React.ReactElement => {
  const handleChange = (_: unknown, nextView: ViewType) => {
    handleChangeView(nextView);
  };

  return (
    <div className={classes.root}>
      <ToggleButtonGroup value={value} exclusive onChange={handleChange}>
        <ToggleButton value={ViewType.TABLE} title="Table" aria-label="table">
          <ViewList />
        </ToggleButton>
        {!sortable && (
          <ToggleButton value={ViewType.GRID} title="Grid" aria-label="grid">
            <ViewModule />
          </ToggleButton>
        )}
        {sortable && (
          <ToggleButton value={ViewType.SORTABLE} title="Sortable" aria-label="grid">
            Arange
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </div>
  );
};

export default withStyles(styles)(CustomViewToggle);
