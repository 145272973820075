import React, { useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';

import { timeToFormattedString } from '../../../../utils/stringUtils';

import { EnumZettleUploadItemType, uploadMenuReducer, UploadMenuReducerAction, ZettleUploadItem } from './Common';
import Steps from './Common/components/Steps';

import { createMenu } from './mutations';
import { getColours, getMenuNextOrderIndex, getOrgMenuNextOrderIndex } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  organisation_id?: string;
  locale: string;
  currency: string;
  handleFinish: (id: string) => void;
}

const UploadMenu = ({ classes, history, organisation_id, locale, currency, handleFinish }: Props): React.ReactElement => {
  const defaultData = {
    name: '',
    description: '',
    active: true,
    all_day: true,
    menu_types: [],
    available_from: '00:00:00:000',
    available_to: '23:59:59:999',
    items: [],
  };

  const [menu, dispatchMenu] = useReducer(uploadMenuReducer, { ...defaultData });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async (another: boolean) => {
    setSaving(true);

    const order_index: number = organisation_id ? await getOrgMenuNextOrderIndex({ organisation_id }) : await getMenuNextOrderIndex();
    const colours: string[] = await (await getColours()).map((c) => c.id);

    const { all_day, items, ...data } = menu;

    data.available_from = `${timeToFormattedString(data.available_from, 'HMS')}`;
    data.available_to = `${timeToFormattedString(data.available_to, 'HMS')}`;

    const categories = [...new Set(items.map((i) => i.category))];
    const itemsByCategory = categories.map((category) => ({
      name: category,
      dishes: items.filter((i) => i.type === EnumZettleUploadItemType.DISH && i.category === category),
      drinks: items.filter((i) => i.type === EnumZettleUploadItemType.DRINK && i.category === category),
    }));

    const dishCategories = itemsByCategory.filter((i) => i.dishes.length > 0);
    const drinkCategories = itemsByCategory.filter((i) => i.drinks.length > 0);

    const createCategory = (category: string, items: ZettleUploadItem[], category_type: string, order_index: number) => ({
      name: category,
      organisation_id,
      order_index,
      category_type,
      colour_id: colours[order_index % colours.length],
      active: true,
      dish_assignments:
        category_type === 'DISH'
          ? {
              data: items.map((dish, index) => ({
                organisation_id,
                dish: {
                  data: createItem(dish, index),
                },
              })),
            }
          : undefined,
      drink_assignments:
        category_type === 'DRINK'
          ? {
              data: items.map((drink, index) => ({
                organisation_id,
                drink: {
                  data: createItem(drink, index),
                },
              })),
            }
          : undefined,
    });

    const createItem = (item: ZettleUploadItem, order_index: number) => ({
      name: item.name,
      description: item.name,
      tag: '',
      price: item.price,
      tax: item.tax,
      prep_time: 0,
      order_index,
      organisation_id,
      active: true,
      bypass_prep: false,
      auto_serve_when_ready: true,
      should_print: true,
      modifier_assignments: {
        data: item.modifiers.map((m) => ({
          organisation_id,
          modifier: {
            data: {
              organisation_id,
              name: m.name,
              price: m.price,
              tax: m.tax,
            },
          },
        })),
      },
    });

    const insertMenuVariables = {
      object: {
        ...data,
        order_index: order_index + 1,
        organisation_id,
        categories: {
          data: [
            ...dishCategories.map((c, index) => createCategory(c.name, c.dishes, 'DISH', index)),
            ...drinkCategories.map((c, index) => createCategory(c.name, c.drinks, 'DRINK', index)),
          ],
        },
      },
    };
    const menu_id: string | undefined = await createMenu(insertMenuVariables);
    if (menu_id) {
      setSaving(false);
      if (another) {
        dispatchMenu({ type: UploadMenuReducerAction.INIT, value: defaultData });
      } else {
        handleFinish(menu_id);
      }
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        menu={menu}
        locale={locale}
        currency={currency}
        dispatch={dispatchMenu}
        handleSave={() => handleSave(false)}
        handleAnother={() => handleSave(true)}
      />
      {saving && <CustomBackdrop label="Creating Menu" />}
    </>
  );
};

export default withRouter(withStyles(styles)(UploadMenu));
