import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../../layouts/Dashboard';
import { capitaliseFirst } from '../../../../../../utils/stringUtils';
import { selectCategoryInitQuery, Category } from './queries';
import ViewCategory from '../../../../../../components/Global/Categories/View';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  menu_id: string;
  category_type: 'dish' | 'drink';
  category_id: string;
}

const AdminViewCategory = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, menu_id, category_type, category_id } = useParams<UrlParams>();

  const [category, setCategory] = useState<Category>({
    name: '',
    organisation: {
      name: '',
    },
    menu: {
      name: '',
    },
  });

  const { data: categoryInitData } = useQuery(selectCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryInitData) {
      setCategory(categoryInitData.categories_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [categoryInitData, category_type]);

  const handleEdit = () => {
    history.push(`/admin/organisations/${organisation_id}/menus/${menu_id}/${category_type}/categories/${category_id}/edit`);
  };

  const handleEditItem = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/menus/${menu_id}/${category_type}/categories/${category_id}/items/${id}/edit`);
  };

  const handleCreateItem = () => {
    history.push(`/admin/organisations/${organisation_id}/menus/${menu_id}/${category_type}/categories/${category_id}/items/create`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: category.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Menus', link: `/admin/organisations/${organisation_id}/menus` },
        { label: category.menu.name, link: `/admin/organisations/${organisation_id}/menus/${menu_id}` },
        { label: `${capitaliseFirst(category_type)} Categories` },
        { label: category.name },
      ]}>
      <ViewCategory
        organisation_id={organisation_id}
        category_id={category_id}
        category_type={category_type}
        handleEdit={handleEdit}
        handleEditItem={handleEditItem}
        handleCreateItem={handleCreateItem}
      />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(AdminViewCategory));
