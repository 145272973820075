import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Switch, TextField, withStyles } from '@material-ui/core';

import { Menu, ValidationErrors, MenuReducerAction, validation, OrderType, orderTypes } from '../..';
import CustomTimePicker from '../../../../../CustomTimePicker';

import styles from './styles';
import { capitaliseFirst } from '../../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  menu: Menu;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: MenuReducerAction; value: any }>;
}

const StepDetails = ({ classes, menu, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string, value?: string) => {
      const errorItems: UnknownObject = {};
      switch (field) {
        case 'name':
          errorItems.name = validation.name(menu.name);
          break;
        case 'description':
          errorItems.description = validation.description(menu.description);
          break;
        case 'availableFrom':
          errorItems.availableFrom = validation.availableFrom(value || menu.available_from);
          errorItems.incompatibleTimes = validation.incompatibleTimes(value || menu.available_from, menu.available_to);
          break;
        case 'availableTo':
          errorItems.availableTo = validation.availableTo(value || menu.available_to);
          errorItems.incompatibleTimes = validation.incompatibleTimes(menu.available_from, value || menu.available_to);
          break;
        case 'menuType':
          errorItems.menuType = validation.menuType(menu.menu_types);
          break;
        default:
          break;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [menu, errors, setErrors]
  );

  const handleAllDay = async (value: boolean) => {
    dispatch({ type: MenuReducerAction.ALL_DAY, value });
    validate('availableFrom');
  };

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={menu.name || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: MenuReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />
        <TextField
          className={classes.textField}
          data-qa="description-textfield"
          required
          label="Description"
          variant="outlined"
          error={errors.description as boolean}
          helperText={errors.description}
          value={menu.description || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: MenuReducerAction.DESCRIPTION, value: event.target.value })}
          onKeyUp={() => validate('description')}
          onBlur={() => validate('description')}
        />
        <Divider className={classes.divider} />

        <FormControl className={classes.menuType} component="fieldset" required error={errors.menuType as boolean}>
          <FormLabel component="legend">Menu type</FormLabel>
          <FormGroup className={classes.formGroup}>
            {orderTypes.map((menuType: OrderType) => (
              <FormControlLabel
                key={menuType as string}
                control={
                  <Checkbox
                    name={menuType}
                    color="primary"
                    checked={menu.menu_types ? menu.menu_types.includes(menuType) : false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: MenuReducerAction.MENU_TYPE, value: { menuType, active: event.target.checked } })}
                    onBlur={() => validate('menuType')}
                  />
                }
                label={capitaliseFirst(menuType.replace(/_/g, ' '))}
              />
            ))}
          </FormGroup>
          <FormHelperText>{errors.menuType || menu.menu_types.includes(OrderType.DELIVERECT) ? 'Deliverect must be enabled for each location' : ''}</FormHelperText>
        </FormControl>

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Available all day"
            labelPlacement="end"
            control={
              <Switch checked={menu.all_day || false} color="primary" onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleAllDay(event.target.checked as boolean)} />
            }
          />
        </FormControl>

        <FormControl className={classes.availableFrom} component="fieldset" error={errors.availableFrom !== undefined}>
          <CustomTimePicker
            data-qa="availableFrom-textfield"
            label="Available from"
            time={menu.available_from || ''}
            disabled={menu.all_day}
            format="HM"
            error={(errors.availableFrom as boolean) || (errors.incompatibleTimes as boolean)}
            helperText={(errors.availableFrom as string) || (errors.incompatibleTimes as string)}
            handleTimeChange={(value: string) => dispatch({ type: MenuReducerAction.AVAILABLE_FROM, value })}
            onValidate={['availableFrom', validate]}
          />
          <FormHelperText>{errors.availableFrom}</FormHelperText>
        </FormControl>

        <FormControl className={classes.availableTo} component="fieldset" error={errors.availableTo !== undefined}>
          <CustomTimePicker
            data-qa="availableTo-textfield"
            label="Available to"
            time={menu.available_to || ''}
            disabled={menu.all_day}
            format="HM"
            error={(errors.availableTo as boolean) || (errors.incompatibleTimes as boolean)}
            helperText={(errors.availableTo as string) || (errors.incompatibleTimes as string)}
            handleTimeChange={(value: string) => dispatch({ type: MenuReducerAction.AVAILABLE_TO, value })}
            onValidate={['availableTo', validate]}
          />
          <FormHelperText>{errors.availableFrom}</FormHelperText>
        </FormControl>

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Active"
            labelPlacement="end"
            control={
              <Switch
                checked={menu.active || false}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: MenuReducerAction.ACTIVE, value: event.target.checked as boolean })}
              />
            }
          />
        </FormControl>

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Is Primary"
            labelPlacement="end"
            control={
              <Switch
                checked={menu.is_primary || false}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: MenuReducerAction.SET_IS_PRIMARY, value: event.target.checked as boolean })}
              />
            }
          />
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
