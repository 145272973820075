import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Button, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from '@material-ui/core';
import {
  DeliverectIntegration,
  LocationIntegrationReducerAction,
  PaymentIntegration,
  PaymentProvider,
  validationIntegration,
  ValidationIntegrationErrors,
  XeroIntegration,
} from '../..';
import SellerOnboardingLightbox from './components/PayPalLink';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    sectionDescription: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      marginBottom: theme.spacing(3),
    },
    inputField: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    helperText: {
      color: '#6B7280',
      backgroundColor: 'transparent',
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    switch: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    active: {
      marginBottom: theme.spacing(3),
    },
    stickyButtonContainer: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      zIndex: 1000,
    },
    stickyButton: {
      paddingInline: theme.spacing(4),
    },
  });

interface Props extends WithStyles<typeof styles> {
  payment: PaymentIntegration;
  deliverect: DeliverectIntegration;
  xero: XeroIntegration;
  vouchersActive: boolean;
  errors: ValidationIntegrationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationIntegrationErrors>>;
  dispatch: React.Dispatch<{ type: LocationIntegrationReducerAction; value: any }>;
  handleSave?: () => void;
  location_id: string;
  organisation_id: string;
  validateIntegration: any;
}

const IntegrationSettings = ({ classes, payment, deliverect, xero, vouchersActive, errors, setErrors, dispatch, handleSave, location_id }: Props): React.ReactElement => {
  const [initialState, setInitialState] = useState<{
    payment: PaymentIntegration;
    deliverect: DeliverectIntegration;
    xero: XeroIntegration;
    vouchersActive: boolean;
  } | null>(null);
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    if (!stateChanged) setInitialState({ payment, deliverect, xero, vouchersActive });
    // eslint-disable-next-line
  }, [stateChanged]);

  useEffect(() => {
    if (
      initialState &&
      JSON.stringify(initialState) !==
        JSON.stringify({
          payment,
          deliverect,
          xero,
          vouchersActive,
        })
    ) {
      setStateChanged(true);
    } else {
      setStateChanged(false);
    }
  }, [payment, deliverect, xero, vouchersActive, initialState]);

  const validate = useCallback(
    (field: string, section: 'payment' | 'deliverect' | 'xero') => {
      const errorItems: { [key: string]: string } = {};
      if (section === 'payment') {
        if (field === 'accountId') {
          errorItems.paymentAccountId = validationIntegration.paymentAccountId(payment.accountId) as string;
        }
        if (field === 'provider') {
          errorItems.paymentProvider = validationIntegration.paymentProvider(payment.provider) as string;
        }
        if (field === 'username') {
          errorItems.paymentUsername = validationIntegration.paymentUsername(payment.username) as string;
        }
        if (field === 'password') {
          errorItems.paymentPassword = validationIntegration.paymentPassword(payment.password) as string;
        }
      } else if (section === 'deliverect') {
        if (field === 'accountId') {
          errorItems.accountId = validationIntegration.deliverectAccountId(deliverect.accountId) as string;
        }
        if (field === 'locationId') {
          errorItems.locationId = validationIntegration.deliverectLocationId(deliverect.locationId) as string;
        }
      }
      setErrors({ ...errors, ...errorItems });
    },
    [payment, deliverect, errors, setErrors]
  );

  const paymentProviders = ['Dojo', 'Zettle', 'PayPal'];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Payment Integration
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Active"
            className={classes.active}
            control={
              <Switch
                checked={payment.active}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.PAYMENT_ACTIVE,
                    value: event.target.checked,
                  })
                }
              />
            }
          />

          {payment.active && (
            <>
              <FormControl className={classes.inputField}>
                <InputLabel id="provider-select-label">Payment provider</InputLabel>
                <Select
                  labelId="provider-select-label"
                  id="provider-select"
                  value={payment.provider || ''}
                  onChange={(event: React.ChangeEvent<any>) =>
                    dispatch({
                      type: LocationIntegrationReducerAction.PAYMENT_PROVIDER,
                      value: event.target.value,
                    })
                  }
                  onBlur={() => validate('provider', 'payment')}>
                  {paymentProviders.map((p: string) => (
                    <MenuItem key={p} value={p.toUpperCase()}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {payment.provider && [PaymentProvider.DOJO, PaymentProvider.ZETTLE].includes(payment.provider as PaymentProvider) && (
                <>
                  <Divider className={classes.divider} />
                  <TextField
                    className={classes.inputField}
                    data-qa="accountId-textfield"
                    required
                    label="Account ID"
                    variant="outlined"
                    error={errors.paymentAccountId as boolean}
                    helperText={errors.paymentAccountId}
                    value={payment.accountId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch({
                        type: LocationIntegrationReducerAction.PAYMENT_ACCOUNT_ID,
                        value: event.target.value,
                      })
                    }
                    onKeyUp={() => validate('accountId', 'payment')}
                    onBlur={() => validate('accountId', 'payment')}
                  />
                </>
              )}

              {payment.provider && [PaymentProvider.ZETTLE].includes(payment.provider as PaymentProvider) && (
                <>
                  <FormControlLabel
                    label="Enable tipping"
                    className={classes.active}
                    control={
                      <Switch
                        checked={payment.enableTipping}
                        color="primary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          dispatch({
                            type: LocationIntegrationReducerAction.PAYMENT_TIPPING,
                            value: event.target.checked,
                          })
                        }
                      />
                    }
                  />
                </>
              )}
              {payment.provider && [PaymentProvider.DOJO].includes(payment.provider as PaymentProvider) && (
                <>
                  <TextField
                    className={classes.inputField}
                    data-qa="username-textfield"
                    required
                    label="Username"
                    variant="outlined"
                    error={errors.paymentUsername as boolean}
                    helperText={errors.paymentUsername}
                    value={payment.username}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch({
                        type: LocationIntegrationReducerAction.PAYMENT_USERNAME,
                        value: event.target.value,
                      })
                    }
                    onKeyUp={() => validate('username', 'payment')}
                    onBlur={() => validate('username', 'payment')}
                  />
                  <TextField
                    className={classes.inputField}
                    data-qa="password-textfield"
                    required
                    label="Password"
                    variant="outlined"
                    error={errors.paymentPassword as boolean}
                    helperText={errors.paymentPassword}
                    value={payment.password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch({
                        type: LocationIntegrationReducerAction.PAYMENT_PASSWORD,
                        value: event.target.value,
                      })
                    }
                    onKeyUp={() => validate('password', 'payment')}
                    onBlur={() => validate('password', 'payment')}
                  />
                </>
              )}
              {payment.provider && [PaymentProvider.PAYPAL].includes(payment.provider as PaymentProvider) && (
                <SellerOnboardingLightbox handleSave={handleSave!} dispatch={dispatch} payment={payment} locationId={location_id} />
              )}
            </>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Deliverect Integration
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Active"
            className={classes.active}
            control={
              <Switch
                checked={deliverect.active}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.DELIVERECT_ACTIVE,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
          {deliverect.active && (
            <>
              <TextField
                className={classes.inputField}
                data-qa="accountId-textfield"
                required
                label="Account ID"
                variant="outlined"
                error={errors.deliverectAccountId as boolean}
                helperText={errors.deliverectAccountId}
                value={deliverect.accountId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.DELIVERECT_ACCOUNT_ID,
                    value: event.target.value,
                  })
                }
                onKeyUp={() => validate('accountId', 'deliverect')}
                onBlur={() => validate('accountId', 'deliverect')}
              />
              <TextField
                className={classes.inputField}
                data-qa="locationId-textfield"
                required
                label="Location ID"
                variant="outlined"
                error={errors.deliverectLocationId as boolean}
                helperText={errors.deliverectLocationId}
                value={deliverect.locationId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.DELIVERECT_LOCATION_ID,
                    value: event.target.value,
                  })
                }
                onKeyUp={() => validate('locationId', 'deliverect')}
                onBlur={() => validate('locationId', 'deliverect')}
              />
              <FormControlLabel
                label="Auto accept"
                className={classes.active}
                control={
                  <Switch
                    checked={deliverect.autoAccept}
                    color="primary"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch({
                        type: LocationIntegrationReducerAction.DELIVERECT_AUTO_ACCEPT,
                        value: event.target.checked,
                      })
                    }
                  />
                }
              />
            </>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Xero Integration
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Active"
            className={classes.active}
            control={
              <Switch
                checked={xero.active}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.XERO_ACTIVE,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
          {xero.active && xero.code && <Typography>Xero account is authenticated</Typography>}
          {xero.active && !xero.code && <Typography color="error">{errors.xeroCode}</Typography>}
          {xero.active && (
            <Button
              // className={classes.button}
              variant="outlined"
              onClick={() => {
                const webhookBaseUrl = process.env.REACT_APP_WEBHOOK_API_URL || window.location.origin;
                const uri = `${webhookBaseUrl}/webhook-api/xero/login/${location_id}`;
                const win = window.open(uri, '_blank', 'popup=yes,width=500,height=800');
                const timer = setInterval(() => {
                  try {
                    if (win) {
                      const url = win.location.pathname;
                      if (url && url.endsWith('xero/success')) {
                        clearInterval(timer);
                        setTimeout(() => {
                          win?.close();
                          dispatch({ type: LocationIntegrationReducerAction.XERO_ACCOUNT_ID, value: 'success' });
                        }, 2500);
                      }
                      if (url && url.endsWith('xero/unsuccessful')) {
                        clearInterval(timer);
                        setTimeout(() => {
                          win?.close();
                          dispatch({ type: LocationIntegrationReducerAction.XERO_ACCOUNT_ID, value: '' });
                        }, 2500);
                      }
                    }
                  } catch (error) {}
                  if (win && win.closed) {
                    clearInterval(timer);
                  }
                }, 1000);
              }}>
              Xero Login {xero.code ? 'Again' : ''}
            </Button>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Vouchers Integration
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Active"
            className={classes.active}
            control={
              <Switch
                checked={vouchersActive}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.VOUCHERS,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
        </Paper>
      </Grid>

      {stateChanged && (
        <Box className={classes.stickyButtonContainer}>
          <Button variant="contained" color="primary" className={classes.stickyButton} onClick={handleSave}>
            Save
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default withStyles(styles)(IntegrationSettings);
