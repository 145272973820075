import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';
import EditLocation from '../../../../../components/Global/Location/Edit';
import { editLocationInitQuery, Location, LocationPrinters, LocationVoucher, selectLocationUsersInitQuery, voucherCodesInitQuery } from './queries';

import styles from './styles';
import { Breadcrum } from '../../../../../layouts/Dashboard/components/Topbar';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

interface UrlParams {
  organisation_id: string;
  location_id: string;
}

const AdminEditLocation = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
      id: '',
    },
  });

  const { data: editLocationInitData } = useQuery(editLocationInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationInitData) {
      setLocation(editLocationInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [editLocationInitData]);

  const handleFinish = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}`);
  };
  const [breadcrumbsUpdated, setBreadcrumbsUpdated] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrum[]>([
    { label: 'Organisations', link: '/admin/organisations' },
    { label: location.organisation.name, link: `/admin/organisations/${organisation_id}` },
    { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
    { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
  ]);
  useEffect(() => {
    if (location.name && location_id && organisation_id) {
      setBreadcrumbs([
        { label: 'Organisations', link: '/admin/organisations' },
        { label: location.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
      ]);
      setBreadcrumbsUpdated(true);
    }
  }, [location, location_id, organisation_id]);

  const handleCreateUser = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/users/create`);
  };

  const handleViewUser = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/users/${id}`);
  };

  const handleEditUser = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/users/${id}/edit`);
  };

  const [locationPrinter, setLocationPrinter] = useState<LocationPrinters>({
    name: '',
    organisation: {
      name: '',
      id: '',
    },
    devices: [],
  });

  const { data: locationUsersInitQuery } = useQuery(selectLocationUsersInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUsersInitQuery) {
      setLocationPrinter(locationUsersInitQuery.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationUsersInitQuery]);

  const handleCreatePrinter = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/printers/create`);
  };

  const handleEditPrinter = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/printers/${id}/edit`);
  };

  const handleCreateStaff = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/staff/create`);
  };

  const handleViewStaff = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/staff/${id}`);
  };

  const handleEditStaff = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/staff/${id}/edit`);
  };

  const [locationVoucher, setLocationVoucher] = useState<LocationVoucher>({
    name: '',
    currency: '',
    locale: '',
    voucher_codes: [],
  });

  const { data: voucherCodesInitData } = useQuery(voucherCodesInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && voucherCodesInitData) {
      setLocationVoucher(voucherCodesInitData.locations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [voucherCodesInitData]);

  const handleCreateVoucher = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/vouchers/create`);
  };

  const handleEditVoucher = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/vouchers/${id}/edit`);
  };
  return (
    <>
      <EditLocation
        location_id={location_id}
        organisation_id={location.organisation.id}
        handleFinish={handleFinish}
        breadcrumbs={breadcrumbs}
        setBreadcrumbs={setBreadcrumbs}
        breadcrumbsUpdated={breadcrumbsUpdated}
        handleCreateUser={handleCreateUser}
        handleViewUser={handleViewUser}
        handleEditUser={handleEditUser}
        handleCreatePrinter={handleCreatePrinter}
        handleEditPrinter={handleEditPrinter}
        printers={locationPrinter.devices}
        handleCreateStaff={handleCreateStaff}
        handleViewStaff={handleViewStaff}
        handleEditStaff={handleEditStaff}
        handleCreateVoucher={handleCreateVoucher}
        handleEditVoucher={handleEditVoucher}
        currency={locationVoucher.currency}
        locale={locationVoucher.locale}
        voucher_codes={locationVoucher.voucher_codes}
      />
    </>
  );
};

export default withRouter(withStyles(styles)(AdminEditLocation));
