import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { withStyles } from '@material-ui/core';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Organisation } from '../..';
import { EnumOrganisationType } from '../Steps/queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  organisation: Organisation;
  organisationTypes: EnumOrganisationType[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, organisation, organisationTypes, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Organisation name', value: organisation.name },
          { label: 'Email', value: organisation.email },
          { label: 'Phone', value: organisation.phone },
          { label: 'Organisation type', value: organisationTypes.find((i) => i.organisation_type === organisation.organisation_type)?.description },
          { label: 'Currency', value: organisation.currency },
          { label: 'Locale', value: organisation.locale },
          { label: 'Tax rate', value: `${organisation.tax_rate / 10}%` },
        ]}
        handleEdit={() => setStep(0)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
