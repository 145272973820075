import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationUsersInitQuery = () => ({
  locations: {
    id: types.string,
    name: types.string,
  },
  enum_user_types: [
    {
      user_type: types.string,
      description: types.string,
    },
  ],
});

type X = Unpacked<typeof _selectLocationUsersInitQuery>;
export type Location = X['locations'];
export type EnumUserType = X['enum_user_types'][0];

export const selectLocationUsersInitQuery = () => gql`
  ${query(_selectLocationUsersInitQuery())}
`;
