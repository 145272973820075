import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomStepper from '../../../../../../components/CustomStepper';
import { Report, validationErrors, ValidationErrors, ReportReducerAction, ReportData } from '../../../Common';
import { getSchemaTables } from '../../schema/schema';
import StepDetails from '../StepDetails';
import StepFields from '../StepFields';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  report: Report;
  reportData: ReportData;
  dispatch: React.Dispatch<{ type: ReportReducerAction; value: any }>;
  handleSave: () => void;
}

const ReportSteps = ({ classes, completeLabel, report, reportData, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    table: false,
    fields: false,
  });

  const [tables, setTables] = useState<{ key: string; label: string }[]>([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTables(getSchemaTables());
    }
    return () => {
      mounted = false;
    };
  }, []);

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = !report.name ? validationErrors.name : false;
        errorItems.table = !report.table ? validationErrors.table : false;
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [report, errors]
  );

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}>
        {[
          {
            label: 'Details',
            detail: report.name && report.table ? `${report.name} / ${report.table}` : '',
            component: (
              <StepDetails
                report={report}
                tables={tables.filter((i) => !i.key.includes('aggregate') && !i.key.includes('assignments') && !i.key.includes('enum'))}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch}
              />
            ),
          },
          {
            label: 'Fields',
            gridSize: 8,
            component: <StepFields report={report} reportData={reportData} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Summary',
            component: <StepReview report={report} setStep={setStep} />,
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(ReportSteps);
