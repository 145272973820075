import React, { useEffect } from 'react';

type LoadApplePaySDKCallback = () => Promise<void>;

const loadApplePaySDK = (onApplePayLoadedCallback: LoadApplePaySDKCallback): void => {
  const script = document.createElement('script');
  script.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js';
  script.async = true;
  script.onload = () => {
    //@ts-ignore
    if (window.ApplePaySession?.supportsVersion(4) && window.ApplePaySession?.canMakePayments()) {
      onApplePayLoadedCallback().catch(console.error);
    }
  };
  document.body.appendChild(script);
};

interface PaymentData {
  token: any;
  billingContact: any;
  shippingContact: any;
}

interface ApplePayConfig {
  isEligible: boolean;
  countryCode: string;
  currencyCode: string;
  merchantCapabilities: string[];
  supportedNetworks: string[];
}

interface ApplePayButtonProps {
  createOrderFunc: () => Promise<string | null>;
  onApproveFunc: (data: any, actions: any) => Promise<void>;
  cartItems: any;
  totalPrice: number;
  tax: number;
  currency: string;
}

const ApplePayButton: React.FC<ApplePayButtonProps> = ({ createOrderFunc, onApproveFunc, cartItems, totalPrice, tax, currency }) => {
  useEffect(() => {
    loadApplePaySDK(onApplePayLoaded);
  }, []);

  const onApplePayLoaded = async (): Promise<void> => {
    try {
      //@ts-ignore
      const applepay = window.paypal.Applepay();
      const { isEligible, countryCode, currencyCode, merchantCapabilities, supportedNetworks } = await applepay.config();

      if (!isEligible) {
        throw new Error('Apple Pay is not eligible');
      }

      const container = document.getElementById('applepay-container');
      if (container) {
        container.innerHTML = '<apple-pay-button id="btn-appl" buttonstyle="black" type="buy" locale="en"></apple-pay-button>';
        document.getElementById('btn-appl')?.addEventListener('click', () => onClick(applepay, countryCode, currencyCode, merchantCapabilities, supportedNetworks));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClick = async (applepay: any, countryCode: string, currencyCode: string, merchantCapabilities: string[], supportedNetworks: string[]): Promise<void> => {
    try {
      const paymentRequest = {
        countryCode,
        currencyCode,
        merchantCapabilities,
        supportedNetworks,
        requiredBillingContactFields: ['name', 'phone', 'email', 'postalAddress'],
        requiredShippingContactFields: [],
        total: {
          label: 'Order Total',
          amount: (totalPrice / 100).toFixed(2),
          type: 'final',
        },
      };
      //@ts-ignore
      const session = new window.ApplePaySession(4, paymentRequest);

      session.onvalidatemerchant = (event: any) => {
        applepay
          .validateMerchant({ validationUrl: event.validationURL })
          .then((payload: any) => {
            session.completeMerchantValidation(payload.merchantSession);
          })
          .catch((err: Error) => {
            console.error(err);
            session.abort();
          });
      };

      session.onpaymentmethodselected = () => {
        session.completePaymentMethodSelection({ newTotal: paymentRequest.total });
      };

      session.onpaymentauthorized = async (event: any) => {
        try {
          const orderId = await createOrderFunc();
          if (!orderId) {
            throw new Error('Order creation failed');
          }

          await applepay.confirmOrder({
            orderId,
            token: event.payment.token,
            billingContact: event.payment.billingContact,
            shippingContact: event.payment.shippingContact,
          });

          await onApproveFunc({ orderID: orderId }, {});

          //@ts-ignore
          session.completePayment({ status: window.ApplePaySession.STATUS_SUCCESS });
        } catch (err) {
          console.error(err);
          //@ts-ignore
          session.completePayment({ status: window.ApplePaySession.STATUS_FAILURE });
        }
      };

      session.oncancel = () => {
        console.log('Apple Pay Cancelled !!');
      };

      session.begin();
    } catch (error) {
      console.error(error);
    }
  };

  return <div id="applepay-container"></div>;
};

export default ApplePayButton;
