import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      width: theme.spacing(30),
      alignSelf: 'center',
    },
    xeroLogo: {
      width: theme.spacing(15),
      alignSelf: 'center',
    },
    text: {
      fontSize: theme.spacing(4),
    },
    unsuccessful: {
      fontSize: theme.spacing(3),
    },
  });

export default styles;
