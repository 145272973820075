import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _menusInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      menus: [
        {
          id: types.string,
          name: types.string,
          description: types.string,
          active: types.boolean,
          order_index: types.number,
          available_from: types.string,
          available_to: types.string,
          is_primary: types.boolean,
        },
      ],
    }
  ),
});

type X = Unpacked<typeof _menusInitQuery>;
export type Organisation = X['organisations_by_pk'];
export type Menu = Organisation['menus'][0];

export const menusInitQuery = (id: string) => gql`
  ${query(_menusInitQuery(id))}
`;
