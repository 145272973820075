import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import ListLocations from '../../../../../components/Global/Location/List';

import { locationsInitQuery, Organisation } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}
interface UrlParams {
  organisation_id: string;
}

const AdminListLocations = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();
  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    locations: [],
  });

  const { data: locationsInitData } = useQuery(locationsInitQuery(organisation_id));

  useEffect(() => {
    let mounted = true;
    if (mounted && locationsInitData) {
      setOrganisation(locationsInitData.organisations_by_pk);
    }
    return () => {
      mounted = false;
    };
  }, [locationsInitData]);

  const handleCreate = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${id}/edit`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${id}`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations' },
      ]}>
      <ListLocations locations={organisation.locations} handleCreate={handleCreate} handleEdit={handleEdit} handleView={handleView} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(AdminListLocations));
