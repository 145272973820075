const { REACT_APP_WEBHOOK_API_URL: API_URL } = process.env;

class WebhookClient {
  baseUrl: string = `${API_URL || ''}/webhook-api`;

  getAuth = () => {
    const token = localStorage.getItem('token');
    return `Bearer ${token}`;
  };

  get = async (endpoint: string, isJson: boolean) => {
    const resp = await fetch(`${this.baseUrl}/${endpoint}`, {
      mode: 'cors',
      headers: {
        Authorization: this.getAuth(),
      },
    });
    if (isJson) {
      return resp.json();
    }
    return resp.text();
  };

  getAnon = async (endpoint: string) => {
    const resp = await fetch(`${this.baseUrl}/${endpoint}`, {
      mode: 'cors',
    });

    return resp.json();
  };

  postAnon = async (endpoint: string, formData: string) => {
    const resp = await fetch(`${this.baseUrl}/${endpoint}`, {
      mode: 'cors',
      method: 'POST',
      body: formData,
    });

    return resp.json();
  };

  postNoDataAnon = async (endpoint: string) => {
    const resp = await fetch(`${this.baseUrl}/${endpoint}`, {
      mode: 'cors',
      method: 'POST',
    });

    return resp.json();
  };

  post = async (endpoint: string) => {
    const resp = await fetch(`${this.baseUrl}/${endpoint}`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        Authorization: this.getAuth(),
      },
    });

    return resp.json();
  };

  postFormData = async (endpoint: string, formData: FormData) => {
    const resp = await fetch(`${this.baseUrl}/${endpoint}`, {
      mode: 'cors',
      method: 'POST',
      body: formData,
      headers: {
        Authorization: this.getAuth(),
      },
    });

    return resp.text();
  };
}

export const webhookClient = new WebhookClient();

export const getFileRequest = async (fileData: string, fileName: string, fileType: string) => {
  const request = new FormData();
  const headerImageFile = await fetch(fileData)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], fileName, { type: fileType });
    });
  request.append('file', headerImageFile);

  return request;
};
