import { gql } from '@apollo/client';
import { params, query, rawString, types } from 'typed-graphqlify';
import { Unpacked } from '../../../../../../react-app-env';

const _createCategoryItemInitQuery = (id: string) => ({
  allergies: params({ order_by: { name: 'asc' } }, [
    {
      id: types.string,
      name: types.string,
      description: types.string,
      allergy_type: types.string,
    },
  ]),
  categories_by_pk: params(
    { id: rawString(id) },
    {
      dish_assignments: [
        {
          dish: {
            tag: types.string,
          },
        },
      ],
      drink_assignments: [
        {
          drink: {
            tag: types.string,
          },
        },
      ],
    }
  ),
});

type X = Unpacked<typeof _createCategoryItemInitQuery>;
export type Category = X['categories_by_pk'];
export type DishAssignments = Category['dish_assignments'];
export type DishAssignment = DishAssignments[0];

export type DrinkAssignments = Category['drink_assignments'];
export type DrinkAssignment = DrinkAssignments[0];

export const createCategoryItemInitQuery = (id: string) => gql`
  ${query(_createCategoryItemInitQuery(id))}
`;
