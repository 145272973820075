import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../../../../react-app-env';

import { IconButton, InputAdornment, TextField, Tooltip, withStyles } from '@material-ui/core';

import { Modifier, ModifierItemReducerAction, modifierValidation as validation, ModifierValidationErrors as ValidationErrors } from '../../../../../../../CategoryItem/Common';

import { ZettleUploadItem, UploadMenuReducerAction } from '../../../../';

import { getPercentageValueOfTotal } from '../../../../../../../../../utils/conversions';
import { getCurrencySymbol, numberToMoney } from '../../../../../../../../../utils/stringUtils';

import styles from './styles';
import { Delete } from '@material-ui/icons';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  item: ZettleUploadItem;
  modifier: Modifier;
  currency: string;
  locale: string;
  taxRate: number;
  dispatch: React.Dispatch<{ type: UploadMenuReducerAction; value: any }>;
}

const ModifierItem = ({ classes, item, modifier, currency, taxRate, locale, dispatch }: Props): React.ReactElement => {
  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    price: false,
    tax: false,
  });

  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      switch (field) {
        case 'name':
          errorItems.name = validation.name(modifier.name);
          break;
        case 'price':
          errorItems.price = validation.price(modifier.price);
          break;
        case 'tax':
          errorItems.tax = validation.tax(modifier.tax);
          break;
        default:
          break;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [modifier, errors]
  );

  const handleChange = (type: ModifierItemReducerAction, value: any) => {
    const mod = { ...modifier };
    if (type === ModifierItemReducerAction.NAME) mod.name = value as string;
    if (type === ModifierItemReducerAction.PRICE) mod.price = value as number;
    if (type === ModifierItemReducerAction.TAX) mod.tax = value as number;

    dispatch({ type: UploadMenuReducerAction.ITEM_UPDATE_MODIFIER, value: { item, modifier: { ...mod, price: +mod.price * 100 } } });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && modifier) {
      dispatch({ type: UploadMenuReducerAction.ITEM_UPDATE_MODIFIER, value: { item, modifier } });
    }
    return () => {
      mounted = false;
    };
  }, [dispatch, item, modifier]);

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={`${classes.textField} ${classes.name}`}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          fullWidth
          error={errors.name as boolean}
          helperText={errors.name}
          value={modifier.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(ModifierItemReducerAction.NAME, event.target.value)}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />
        <TextField
          className={`${classes.textField} ${classes.price}`}
          data-qa="price-textfield"
          required
          label="Gross Price"
          variant="outlined"
          type="number"
          value={modifier.price / 100 || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(ModifierItemReducerAction.PRICE, event.target.value)}
          inputProps={{
            min: 0,
            step: 0.01,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency, locale)}</InputAdornment>,
          }}
          onKeyUp={() => validate('price')}
          onBlur={() => validate('price')}
        />
        <TextField
          className={`${classes.textField} ${classes.tax}`}
          data-qa="tax-textfield"
          required
          label="Tax"
          variant="outlined"
          value={modifier.tax / 10 || ''}
          type="number"
          error={errors.tax as boolean}
          helperText={errors.tax}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(ModifierItemReducerAction.TAX, event.target.value)}
          inputProps={{
            min: 0,
            max: 100,
            step: 0.1,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
            endAdornment: <InputAdornment position="end">{numberToMoney(getPercentageValueOfTotal(modifier.price, modifier.tax / 1000), currency, locale, true)}</InputAdornment>,
          }}
          onKeyUp={() => validate('tax')}
          onBlur={() => validate('tax')}
        />
        <Tooltip title="Delete modifier" placement="left">
          <IconButton className={classes.delete} onClick={() => dispatch({ type: UploadMenuReducerAction.ITEM_DELETE_MODIFIER, value: { item, modifier } })}>
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
};

export default withStyles(styles)(ModifierItem);
