import React from 'react';
import useLocalStorage from '../../../hooks/useLocalStorage';

const ZettleLogo = (): React.ReactElement => {
  const [darkMode] = useLocalStorage<boolean>('dark-mode', false);

  const textColour = darkMode ? '#FFFFFF' : '#192550';
  const zColour = darkMode ? '#FFFFFF' : '#000000';

  return (
    <svg id="ZETTLE_LOGO" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221.34 98.39">
      <path
        style={{ fill: zColour }}
        d="M201.09 64.1c7.26 0 13.99-2.78 17.99-6.86.75-.75.75-1.63 0-2.38l-4.35-4.28c-.88-.88-1.77-.88-2.78 0-1.7 1.49-5.43 4.07-10.46 4.07-6.38 0-11.47-4.35-12.22-11.27h29.6c1.56 0 2.44-.48 2.44-2.24.48-14.66-9.3-24.58-22-24.58-13.31 0-21.93 10.93-21.93 23.83.01 14.27 10.33 23.71 23.71 23.71zm-11.34-28.85c0-3.53 3.26-9.23 9.78-9.23 6.92 0 9.3 6.04 9.3 8.35 0 .41-.34.88-.95.88h-18.13zM2.4 10.93h43.86V1.5c0-.83-.67-1.5-1.5-1.5H2.4C1.57 0 .9.67.9 1.5v7.93c0 .82.67 1.5 1.5 1.5zm-.96 52.08h43.39c.79 0 1.44-.64 1.44-1.44v-8.05c0-.79-.64-1.44-1.44-1.44H0v9.49c0 .79.64 1.44 1.44 1.44z"></path>
      <path style={{ fill: '#ae9ff3' }} d="M32.4 10.93L0 52.08h14.49l31.77-41.15z"></path>
      <path
        style={{ fill: textColour }}
        d="M73.3 64.1c7.26 0 13.99-2.78 17.99-6.86.75-.75.75-1.63 0-2.38l-4.35-4.28c-.88-.88-1.77-.88-2.78 0-1.7 1.49-5.43 4.07-10.46 4.07-6.38 0-11.47-4.35-12.22-11.27h29.6c1.56 0 2.44-.48 2.44-2.24.48-14.66-9.3-24.58-22-24.58-13.31 0-21.93 10.93-21.93 23.83C49.6 54.66 59.92 64.1 73.3 64.1zM61.96 35.25c0-3.53 3.26-9.23 9.78-9.23 6.92 0 9.3 6.04 9.3 8.35 0 .41-.34.88-.95.88H61.96zM169.04 63.01h-8.28c-.81 0-1.44-.63-1.44-1.44V1.44c0-.81.63-1.44 1.44-1.44h8.28c.81 0 1.44.63 1.44 1.44v60.12c0 .82-.63 1.45-1.44 1.45zm-61.03 0h8.28c.72 0 1.44-.63 1.44-1.44V27.45h15.21v34.11c0 .81.63 1.44 1.44 1.44h8.28c.81 0 1.44-.63 1.44-1.44v-33.3c0-.45.36-.81.9-.81h7.92c.81 0 1.44-.54 1.44-1.26v-7.2c0-.81-.63-1.35-1.44-1.35H145c-.54 0-.9-.45-.9-.9V6.75c0-.81-.63-1.44-1.44-1.44h-8.28c-.81 0-1.44.63-1.44 1.44v10.89h-15.21V6.75c0-.81-.72-1.44-1.44-1.44h-8.28c-.81 0-1.44.63-1.44 1.44v9.99c0 .45-.36.9-.9.9h-8.01c-.81 0-1.35.54-1.35 1.35v7.02c0 .81.54 1.44 1.35 1.44h8.01c.54 0 .9.36.9.81v33.3c0 .82.63 1.45 1.44 1.45z"></path>
      <path
        style={{ fill: textColour }}
        d="M121.95 92.32l-4.2 6.07h2.22l9.59-14.26h-2.18l-4.08 6.24-2.33-6.24h-2.24zM113.7 83.7c-1.49 0-2.82.78-3.82 1.98h-.03l1.61-10.23h-1.93l-2.96 18.59h1.95l.23-1.55h.06c.57 1.12 1.7 1.9 3.1 1.9 3.1 0 5.23-2.56 5.69-5.43.44-2.76-1-5.26-3.9-5.26zm1.95 5.32c-.32 1.98-1.75 3.65-3.85 3.65-2.04 0-2.9-1.75-2.59-3.65.29-1.87 1.75-3.59 3.74-3.59 2.1 0 3.02 1.63 2.7 3.59z"></path>
      <path
        style={{ fill: textColour }}
        d="M193.35 75.45h-6.69c-.46 0-.85.33-.92.79l-2.71 17.16c-.05.34.21.65.55.65h3.43c.32 0 .59-.23.64-.55l.77-4.87c.07-.45.46-.79.92-.79h2.12c4.41 0 6.95-2.13 7.62-6.36.3-1.85.01-3.3-.85-4.32-.95-1.11-2.64-1.71-4.88-1.71m.77 6.27c-.37 2.4-2.2 2.4-3.98 2.4h-1.01l.71-4.49c.04-.27.28-.47.55-.47h.46c1.21 0 2.35 0 2.94.69.36.42.46 1.03.33 1.87M145.61 75.45h-6.69c-.46 0-.85.33-.92.79l-2.71 17.16c-.05.34.21.65.55.65h3.2c.46 0 .85-.33.92-.79l.73-4.63c.07-.45.46-.79.92-.79h2.12c4.41 0 6.95-2.13 7.62-6.36.3-1.85.01-3.3-.85-4.32-.97-1.11-2.65-1.71-4.89-1.71m.77 6.27c-.37 2.4-2.2 2.4-3.98 2.4h-1.01l.71-4.49c.04-.27.28-.47.55-.47h.46c1.21 0 2.35 0 2.94.69.36.42.46 1.03.33 1.87M165.61 81.65h-3.21c-.28 0-.51.2-.55.47l-.14.9-.22-.32c-.69-1.01-2.24-1.34-3.79-1.34-3.54 0-6.57 2.68-7.15 6.45-.31 1.88.13 3.67 1.19 4.92.98 1.15 2.37 1.63 4.04 1.63 2.85 0 4.44-1.83 4.44-1.83l-.14.89c-.05.34.21.65.55.65h2.89c.46 0 .85-.33.92-.79l1.73-10.97a.565.565 0 00-.56-.66m-4.46 6.24c-.31 1.83-1.76 3.06-3.62 3.06-.93 0-1.67-.3-2.15-.87-.47-.56-.65-1.36-.5-2.25.29-1.81 1.77-3.08 3.59-3.08.91 0 1.65.3 2.14.87.48.57.68 1.38.54 2.27M213.36 81.65h-3.21c-.27 0-.51.2-.55.47l-.14.9-.22-.32c-.69-1.01-2.24-1.34-3.79-1.34-3.54 0-6.57 2.68-7.15 6.45-.31 1.88.13 3.67 1.19 4.92.98 1.15 2.37 1.63 4.04 1.63 2.85 0 4.44-1.83 4.44-1.83l-.14.89c-.05.34.21.65.55.65h2.89c.46 0 .85-.33.92-.79l1.73-10.97c.04-.36-.22-.66-.56-.66m-4.47 6.24c-.31 1.83-1.76 3.06-3.62 3.06-.93 0-1.67-.3-2.15-.87-.47-.56-.65-1.36-.5-2.25.29-1.81 1.77-3.08 3.59-3.08.91 0 1.65.3 2.14.87.49.57.68 1.38.54 2.27M182.69 81.65h-3.22c-.31 0-.6.15-.77.41l-4.44 6.55-1.88-6.29a.933.933 0 00-.89-.66h-3.17c-.38 0-.65.38-.53.74l3.55 10.41-3.34 4.71c-.26.37 0 .88.45.88h3.22c.31 0 .59-.15.76-.4l10.71-15.46c.26-.39 0-.89-.45-.89M217.13 75.93l-2.75 17.48c-.05.34.21.64.55.64h2.76c.46 0 .85-.33.92-.79l2.71-17.16a.556.556 0 00-.55-.64h-3.09c-.27 0-.5.2-.55.47"></path>
    </svg>
  );
};

export default ZettleLogo;
