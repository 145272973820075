import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { TextField, Typography, withStyles } from '@material-ui/core';

import { DeleteOutline as DeleteIcon } from '@material-ui/icons';

import { Menu, ValidationErrors, UploadMenuReducerAction, ZettleUploadItem, EnumZettleUploadItemType } from '../..';

import CustomSortableTable, { SortableTableHeader, SortableTableRow } from '../../../../../../CustomSortableTable';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  menu: Menu;
  validate: (step: number) => void;
  errors: ValidationErrors;
  dispatch: React.Dispatch<{ type: UploadMenuReducerAction; value: any }>;
}

const filter = createFilterOptions<string>();

const StepCategories = ({ classes, menu, errors, validate, dispatch }: Props): React.ReactElement => {
  const createRow = (item: ZettleUploadItem): SortableTableRow => {
    const options = [...new Set(menu.items.filter((i) => i.type === item.type).map((i) => i.category))];
    return {
      key: item.name,
      columns: [
        { key: 'name', label: item.name },
        { key: 'variants', label: item.variants.length },
        {
          key: 'category',
          label: '',
          component: (
            <Autocomplete
              className={classes.textField}
              id="itemCategoryList"
              options={options}
              value={item.category}
              onChange={(_, newValue) => {
                if (newValue?.startsWith('Add new')) {
                  dispatch({ type: UploadMenuReducerAction.ITEM_CATEGORY, value: { item, category: newValue?.replace('Add new -- ', '') || '' } });
                } else {
                  dispatch({ type: UploadMenuReducerAction.ITEM_CATEGORY, value: { item, category: newValue || '' } });
                }
                validate(2);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                  filtered.push(`Add new -- ${params.inputValue}`);
                }
                return filtered;
              }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option;
              }}
              renderInput={(params) => (
                <TextField
                  className={classes.textField}
                  {...params}
                  size="small"
                  data-qa={`category-${item.name.toLocaleLowerCase().replaceAll(' ', '_')}-textfield`}
                  // label="Category"
                  variant="outlined"
                  error={item.category === ''}
                />
              )}
            />
          ),
        },
      ],
      actions: [
        {
          label: 'Delete',
          endIcon: <DeleteIcon />,
          destructive: true,
          onClick: () => {
            dispatch({ type: UploadMenuReducerAction.ITEM_DELETE, value: item });
            validate(2);
          },
        },
      ],
    };
  };

  const headers: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'variants', label: 'Variants', align: 'center' },
    { key: 'category', label: 'Category' },
  ];

  return (
    <>
      <div className={classes.root}>
        {errors.categories && (
          <Typography className={classes.text} color="error">
            {errors.categories}
          </Typography>
        )}
        <CustomSortableTable
          title="Dishes"
          description="Please select a category for each item. Start typing in the field to create a new category and add it."
          orderdBy="name"
          ordered="asc"
          counter="filtered"
          headers={headers}
          rows={menu.items.filter((i) => i.type === EnumZettleUploadItemType.DISH).map((item: ZettleUploadItem) => createRow(item))}
        />
        <CustomSortableTable
          title="Drinks"
          description="Please select a category for each item. Start typing in the field to create a new category and add it."
          orderdBy="name"
          ordered="asc"
          counter="filtered"
          headers={headers}
          rows={menu.items.filter((i) => i.type === EnumZettleUploadItemType.DRINK).map((item: ZettleUploadItem) => createRow(item))}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepCategories);
