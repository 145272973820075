import { capitaliseFirstOnly, numbersOnly } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';
import { SelfOnboardOrganisation } from '../../../../components/Global/Organisations';
import { validateEmailAddress } from '../../../../utils/validators';

export interface ValidationErrors {
  name: ValidationType;
  email: ValidationType;
  phone: ValidationType;
  firstName: ValidationType;
  lastName: ValidationType;
  organisationAdminEmail: ValidationType;
  locationName: ValidationType;
  locationEmail: ValidationType;
  locationPhone: ValidationType;
  locationAddressLine1: ValidationType;
  locationCity: ValidationType;
  locationCountry: ValidationType;
  locationPostcode: ValidationType;
}

export const validationErrors = {
  name: 'Please provide a name',
  email: 'Please provide an email address',
  invalidEmail: 'Please provide a valid email address',
  phone: 'Please provide a phone number',
  firstName: 'Please provide a first name',
  lastName: 'Please provide a last name',
  organisationAdminEmail: 'Please provide organisation admin email',
  locationName: 'Please provide location name',
  locationEmail: 'Please provide email',
  locationPhone: 'Please provide phone number',
  locationAddressLine1: 'Please provide address',
  locationCity: 'Please provide city',
  locationCountry: 'Please provide country',
  locationPostcode: 'Please provide postcode',
};

export interface Validation {
  name: (name: string) => ValidationType;
  email: (email: string) => ValidationType;
  phone: (phone: string) => ValidationType;
  firstName: (firstName: string) => ValidationType;
  lastName: (lastName: string) => ValidationType;
  organisationAdminEmail: (email: string) => ValidationType;
  locationName: (name: string) => ValidationType;
  locationEmail: (email: string) => ValidationType;
  locationPhone: (phone: string) => ValidationType;
  locationAddressLine1: (address: string) => ValidationType;
  locationCity: (city: string) => ValidationType;
  locationCountry: (country: string) => ValidationType;
  locationPostcode: (postcode: string) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
  email: (email) => (!email ? validationErrors.email : validateEmailAddress(email) ? false : validationErrors.invalidEmail),
  phone: (phone) => (!phone ? validationErrors.phone : false),
  firstName: (firstName) => (!firstName ? validationErrors.firstName : false),
  lastName: (lastName) => (!lastName ? validationErrors.lastName : false),
  organisationAdminEmail: (email) => (!email ? validationErrors.organisationAdminEmail : validateEmailAddress(email) ? false : validationErrors.invalidEmail),
  locationName: (name: string) => (!name ? validationErrors.locationName : false),
  locationEmail: (email: string) => (!email ? validationErrors.locationEmail : validateEmailAddress(email) ? false : validationErrors.invalidEmail),
  locationPhone: (phone: string) => (!phone ? validationErrors.locationPhone : false),
  locationAddressLine1: (address: string) => (!address ? validationErrors.locationAddressLine1 : false),
  locationCity: (city: string) => (!city ? validationErrors.locationCity : false),
  locationCountry: (country: string) => (!country ? validationErrors.locationCountry : false),
  locationPostcode: (postcode: string) => (!postcode ? validationErrors.locationPostcode : false),
};

export enum SelfOnboardOrganisationReducerAction {
  NAME,
  EMAIL,
  PHONE,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_ORG_ADMIN_EMAIL,
  SET_LOCATION_NAME,
  SET_LOCATION_EMAIL,
  SET_LOCATION_PHONE,
  SET_LOCATION_ADDRESS,
  SET_LOCATION_CITY,
  SET_LOCATION_COUNTRY,
  SET_LOCATION_POSTCODE,
}

export const selfOnboardOrganisationReducer = (state: SelfOnboardOrganisation, action: { type: SelfOnboardOrganisationReducerAction; value: any }): SelfOnboardOrganisation => {
  switch (action.type) {
    case SelfOnboardOrganisationReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case SelfOnboardOrganisationReducerAction.EMAIL:
      return { ...state, email: action.value.toLowerCase() };
    case SelfOnboardOrganisationReducerAction.PHONE:
      return { ...state, phone: `${numbersOnly(action.value)}` };
    case SelfOnboardOrganisationReducerAction.SET_FIRST_NAME:
      return {
        ...state,
        organisationAdmin: {
          ...state.organisationAdmin,
          firstName: action.value,
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_LAST_NAME:
      return {
        ...state,
        organisationAdmin: {
          ...state.organisationAdmin,
          lastName: action.value,
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_ORG_ADMIN_EMAIL:
      return {
        ...state,
        organisationAdmin: {
          ...state.organisationAdmin,
          email: action.value,
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_LOCATION_NAME:
      return {
        ...state,
        location: {
          ...state.location,
          name: capitaliseFirstOnly(action.value),
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_LOCATION_EMAIL:
      return {
        ...state,
        location: {
          ...state.location,
          email: action.value,
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_LOCATION_PHONE:
      return {
        ...state,
        location: {
          ...state.location,
          phone: `${numbersOnly(action.value)}`,
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_LOCATION_ADDRESS:
      return {
        ...state,
        location: {
          ...state.location,
          addressLine1: action.value,
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_LOCATION_CITY:
      return {
        ...state,
        location: {
          ...state.location,
          city: action.value,
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_LOCATION_COUNTRY:
      return {
        ...state,
        location: {
          ...state.location,
          country: action.value,
        },
      };
    case SelfOnboardOrganisationReducerAction.SET_LOCATION_POSTCODE:
      return {
        ...state,
        location: {
          ...state.location,
          postcode: action.value,
        },
      };
    default:
      throw new Error();
  }
};
