import React, { createContext, useCallback, useEffect, useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

import { HierarchySelectedItem } from '../../layouts/Dashboard/components/Sidebar/components/HierarchyList';
import { getLocationQuery, getOrganisationQuery } from './queries';

interface AppContextItems {
  selectedHierarchy: HierarchySelectedItem | undefined;
  setSelectedHierarchy: React.Dispatch<React.SetStateAction<HierarchySelectedItem | undefined>>;
  selectedHierarchyItem: HierarchyItem | undefined;
  userRole: string;
  systemId: string;
}

interface HierarchyItem {
  id: string;
  name: string;
}

export const AppContext = createContext<AppContextItems | null>(null);

type Props = { children: React.ReactNode };

const StateProvider = ({ children }: Props): React.ReactElement => {
  const [selectedHierarchy, setSelectedHierarchy] = useState<HierarchySelectedItem>();
  const [selectedHierarchyItem, setSelectedHierarchyItem] = useState<HierarchyItem>();
  const [userRole, setUserRole, removeUserRole] = useLocalStorage<string>('user_role', '');
  const [systemId, setSystemId, removeSystemId] = useLocalStorage<string>('system_id', '');

  const appContextItems: AppContextItems = {
    selectedHierarchy,
    selectedHierarchyItem,
    setSelectedHierarchy,
    userRole,
    systemId,
  };

  const getSelectedHierarchyItem = useCallback(async () => {
    if (selectedHierarchy) {
      setUserRole(selectedHierarchy.route.key);
      setSystemId(selectedHierarchy.key);
      switch (selectedHierarchy.route.key) {
        case 'owner':
          setSelectedHierarchyItem(await getOrganisationQuery(selectedHierarchy.key));
          break;
        case 'manager':
          setSelectedHierarchyItem(await getLocationQuery(selectedHierarchy.key));
          break;
        default:
          break;
      }
    } else {
      setSelectedHierarchyItem(undefined);
      removeUserRole();
      removeSystemId();
    }
  }, [selectedHierarchy, removeSystemId, removeUserRole, setSystemId, setUserRole]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getSelectedHierarchyItem();
    }
    return () => {
      mounted = false;
    };
  }, [selectedHierarchy, getSelectedHierarchyItem]);

  return <AppContext.Provider value={appContextItems}>{children}</AppContext.Provider>;
};

export default StateProvider;
