import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&:first-of-type': {
        marginTop: 0,
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    switch: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    buttonContainer: {
      display: 'flex',
      marginTop: theme.spacing(3),
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      flex: '1 1 auto',
    },
    button: {
      flex: '1 1 auto',
      '&:first-of-type': {
        marginRight: theme.spacing(1),
      },
      '&:last-of-type': {
        marginLeft: theme.spacing(1),
      },
      '&:only-child': {
        margin: 0,
      },
    },
  });

export default styles;
