import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, TextField, Button, withStyles, makeStyles } from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import CustomSortableTable, { SortableTableAction, SortableTableHeader, SortableTableRow } from '../../CustomSortableTable';
import { GET_MENU_LOCATION_STOCK, UPDATE_MENU_LOCATION_STOCK } from './queries';
import styles from './styles';
import { formatDate } from '../../../utils/dateUtils';
import { useSnackbar } from 'notistack';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  location_id: string;
  inventory_restock_type: InventoryRestockEnum;
}

interface MenuLocationStockItem {
  id: string;
  menu_id: string;
  dish_id?: string;
  drink_id?: string;
  dish_item?: {
    name: string;
    category_assignments: {
      category_id: string;
      category: {
        name: string;
      };
    }[];
  };
  drink_item?: {
    name: string;
    category_assignments: {
      category_id: string;
      category: {
        name: string;
      };
    }[];
  };
  total_stock: number;
  limit_per_day: { day: string; item_limit: number }[];
  item_per_day_left: number;
}

enum InventoryRestockEnum {
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const getCurrentDay = () => {
  const dayIndex = new Date().getDay();
  return daysOfWeek[dayIndex === 0 ? 6 : dayIndex - 1];
};

const useStyles = makeStyles({
  button: {
    margin: '0 4px',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  textField: {
    width: '80px',
  },
});

const ViewStockComponent = ({ classes, location_id, inventory_restock_type }: Props): React.ReactElement => {
  const stock_left_type = inventory_restock_type;
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, error, refetch } = useQuery(GET_MENU_LOCATION_STOCK, {
    variables: { locationId: location_id },
    fetchPolicy: 'network-only',
  });
  const customStyles = useStyles();

  const [updateMenuLocationStock] = useMutation(UPDATE_MENU_LOCATION_STOCK, {
    onCompleted: () => enqueueSnackbar('Item has been updated', { variant: 'success' }),
  });

  const [items, setItems] = useState<MenuLocationStockItem[]>([]);
  const [editedItems, setEditedItems] = useState(
    new Map<
      string,
      {
        total_stock?: number;
        limit_per_day: { day: string; item_limit: number }[];
        item_per_day_left?: number;
      }
    >()
  );

  useEffect(() => {
    if (data && data.menu_location_stock) {
      setItems(data.menu_location_stock);
    }
  }, [data]);

  const handleInputChange = (id: string, key: string, value: number) => {
    setEditedItems((prev) => {
      const item = items.find((i) => i.id === id);
      const existingLimits = item?.limit_per_day || daysOfWeek.map((day) => ({ day, item_limit: 0 }));
      const newMap = new Map(prev);
      const editedItem = newMap.get(id) || { limit_per_day: existingLimits };
      if (daysOfWeek.includes(key)) {
        editedItem.limit_per_day = editedItem.limit_per_day.map((day) =>
          day.day === key
            ? {
                ...day,
                item_limit: value,
              }
            : day
        );
      } else if (key === 'limit_per_day' && stock_left_type === InventoryRestockEnum.DAILY) {
        editedItem.limit_per_day = editedItem.limit_per_day.map((day) => ({ ...day, item_limit: value }));
      } else {
        //@ts-ignore
        editedItem[key] = value;
      }
      newMap.set(id, editedItem);
      return newMap;
    });
  };

  const handleSave = async (id: string) => {
    const editedItem = editedItems.get(id);
    if (editedItem) {
      const item = items.find((i) => i.id === id);
      await updateMenuLocationStock({
        variables: {
          id,
          total_stock: editedItem.total_stock ?? item?.total_stock,
          limit_per_day:
            stock_left_type === InventoryRestockEnum.WEEKLY
              ? daysOfWeek.map((day) => ({
                  day,
                  item_limit: editedItem.limit_per_day?.find((d) => d.day === day)?.item_limit ?? item?.limit_per_day.find((d) => d.day === day)?.item_limit ?? 0,
                }))
              : editedItem.limit_per_day || item?.limit_per_day,
          item_per_day_left: editedItem.item_per_day_left ?? item?.item_per_day_left,
        },
      });
      setEditedItems((prev) => {
        const newMap = new Map(prev);
        newMap.delete(id);
        return newMap;
      });
      refetch();
    }
  };

  const handleRestock = async (id: string) => {
    const item = items.find((i) => i.id === id);
    if (item) {
      const currentDay = getCurrentDay();
      const currentDayLimit = item.limit_per_day.find((day) => day.day === currentDay)?.item_limit ?? 0;
      await updateMenuLocationStock({
        variables: {
          id,
          total_stock: item.total_stock,
          limit_per_day: item.limit_per_day,
          item_per_day_left: currentDayLimit,
        },
      });
      refetch();
    }
  };

  const handleBulkSave = async () => {
    for (const [id, changes] of editedItems) {
      const item = items.find((i) => i.id === id);
      await updateMenuLocationStock({
        variables: {
          id,
          total_stock: changes.total_stock ?? item?.total_stock,
          limit_per_day:
            stock_left_type === InventoryRestockEnum.WEEKLY
              ? daysOfWeek.map((day) => ({
                  day,
                  item_limit: changes.limit_per_day?.find((d) => d.day === day)?.item_limit ?? item?.limit_per_day.find((d) => d.day === day)?.item_limit ?? 0,
                }))
              : changes.limit_per_day || item?.limit_per_day,
          item_per_day_left: changes.item_per_day_left ?? item?.item_per_day_left,
        },
      });
    }
    setEditedItems(new Map());
    refetch();
  };

  const handleRestockAll = async () => {
    const currentDay = getCurrentDay();
    for (const item of items) {
      const currentDayLimit = item.limit_per_day.find((day) => day.day === currentDay)?.item_limit ?? 0;
      await updateMenuLocationStock({
        variables: {
          id: item.id,
          total_stock: item.total_stock,
          limit_per_day: item.limit_per_day,
          item_per_day_left: currentDayLimit,
        },
      });
    }
    refetch();
  };

  // Group items by category
  const groupItemsByCategory = (items: MenuLocationStockItem[]) => {
    const groupedItems: { [key: string]: MenuLocationStockItem[] } = {};

    items.forEach((item) => {
      const categoryName = item.dish_item?.category_assignments[0].category.name || item.drink_item?.category_assignments[0].category.name;
      if (categoryName) {
        if (!groupedItems[categoryName]) {
          groupedItems[categoryName] = [];
        }
        groupedItems[categoryName].push(item);
      }
    });

    return groupedItems;
  };

  const groupedItems = groupItemsByCategory(items);

  const headers: SortableTableHeader[] = [{ key: 'item_name', label: 'Item Name', align: 'left' }];

  const collapsibleHeaders: SortableTableHeader[] = [
    { key: 'item_name', label: 'Item Name', align: 'left' },
    { key: 'total_stock', label: 'Total Stock', align: 'right' },
    { key: 'item_per_day_left', label: 'Items Per Day Left', align: 'right' },
    ...(stock_left_type === InventoryRestockEnum.WEEKLY ? daysOfWeek.map((day) => ({ key: day, label: day })) : [{ key: 'limit_per_day', label: 'Limit Per Day' }]),
    { key: 'actions', label: 'Actions', align: 'center' },
  ];

  const rows: SortableTableRow[] = Object.keys(groupedItems).map((category) => ({
    key: category,
    columns: [
      {
        key: 'category_name',
        label: `${category}`,
        colSpan: headers.length,
        className: customStyles.tableHeader,
      },
    ],
    collapsible: [
      {
        title: category,
        headers: collapsibleHeaders,
        counter: groupedItems[category].length,
        rows: groupedItems[category].map((item) => ({
          key: item.id,
          columns: collapsibleHeaders
            .map((h) => {
              const editedItem = editedItems.get(item.id);
              if (stock_left_type === InventoryRestockEnum.WEEKLY && daysOfWeek.includes(h.key)) {
                const dayLimit = (editedItem?.limit_per_day || item.limit_per_day).find((day) => day.day === h.key)?.item_limit;
                return {
                  key: h.key,
                  label: dayLimit,
                  component: (
                    <TextField
                      type="number"
                      value={dayLimit}
                      onChange={(e) => handleInputChange(item.id, h.key, parseInt(e.target.value, 10))}
                      className={customStyles.textField}
                    />
                  ),
                };
              } else if (stock_left_type === InventoryRestockEnum.DAILY && h.key === 'limit_per_day') {
                const dayLimit = editedItem?.limit_per_day ? editedItem.limit_per_day[0].item_limit : item.limit_per_day[0].item_limit;
                return {
                  key: h.key,
                  label: dayLimit,
                  component: (
                    <TextField
                      type="number"
                      value={dayLimit}
                      onChange={(e) => handleInputChange(item.id, h.key, parseInt(e.target.value, 10))}
                      className={customStyles.textField}
                    />
                  ),
                };
              }
              return {
                key: h.key,
                label:
                  h.key === 'item_name'
                    ? item.dish_item?.name || item.drink_item?.name
                    : h.key === 'total_stock' || h.key === 'item_per_day_left'
                      ? editedItem?.[h.key] ?? item[h.key]
                      : //@ts-ignore
                        item[h.key],
                component:
                  h.key === 'total_stock' ? (
                    <TextField
                      type="number"
                      value={editedItem?.total_stock ?? item.total_stock}
                      onChange={(e) => handleInputChange(item.id, h.key, parseInt(e.target.value, 10))}
                      className={customStyles.textField}
                    />
                  ) : undefined,
              };
            })
            .concat([
              {
                key: 'save_button',
                label: '',
                component: (
                  <Button onClick={() => handleSave(item.id)} disabled={!editedItems.has(item.id)} variant="contained" color="primary" className={customStyles.button}>
                    Save
                  </Button>
                ),
              },
              {
                key: 'restock_button',
                label: '',
                component: (
                  <Button onClick={() => handleRestock(item.id)} variant="contained" color="secondary" className={customStyles.button}>
                    Restock
                  </Button>
                ),
              },
            ]),
        })),
      },
    ],
  }));

  const createCsvLines = (rows: SortableTableRow[]) => {
    return rows.flatMap((r) => {
      const cat = r.columns.map((c) => (c.format ? c.format(c.label) : c.label));
      const items = r.collapsible!.flatMap((c) => c.rows.map((colR) => colR.columns.map((i) => (i.format ? i.format(i.label) : i.label))));
      return [cat, ...items];
    });
  };

  const handleDownloadCsv = (rows: SortableTableRow[]) => {
    const element = document.createElement('a');
    const csvLines = createCsvLines(rows);
    const file = new Blob(
      [
        collapsibleHeaders
          .filter((h) => !h.hidden)
          .map((h) => h.key)
          .join(),
        '\n',
        csvLines.map((i) => i.join(',')).join('\n'),
      ],
      { type: 'text/csv;charset=utf-8' }
    );
    element.href = URL.createObjectURL(file);
    const dateFrom = formatDate(new Date(), 'YYYY_MM_DD');
    element.download = `stock-export-${dateFrom}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const actions: SortableTableAction[] = [
    {
      key: 'export',
      label: 'Export to CSV',
      onClick: handleDownloadCsv,
      icon: <DownloadIcon />,
    },
    {
      key: 'bulk_save',
      label: 'Save All',
      onClick: handleBulkSave,
    },
    {
      key: 'restock_all',
      label: 'Restock All',
      onClick: handleRestockAll,
    },
  ];

  if (error) return <p>Error: {error.message}</p>;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CustomSortableTable
          noSorting
          title="Menu Location Stock"
          searchable={['dish_item.name', 'drink_item.name']}
          counter={rows.length}
          headers={headers}
          rows={rows}
          loading={loading}
          footer={{ actions }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ViewStockComponent);
