import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Grid, GridSize, Paper, Typography, withStyles } from '@material-ui/core';

import { AddCircle as AddCircleIcon } from '@material-ui/icons';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  label?: string;
  icon?: React.ReactElement;
  gridSize?: GridSize;
  fill?: boolean;
  small?: boolean;
  onClick: () => void;
}

const GridActionCard = ({ classes, label, icon, gridSize = 3, fill = false, small = false, onClick }: Props): React.ReactElement => {
  return (
    <Grid item xs={gridSize} zeroMinWidth>
      <Paper className={`${classes.root} ${fill ? classes.fill : ''} ${small ? classes.small : ''}`} onClick={onClick}>
        <div className={classes.icon}>{icon ? icon : <AddCircleIcon />}</div>
        {label && <Typography className={classes.label}>{label}</Typography>}
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(GridActionCard);
