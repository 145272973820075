import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useQuery } from '@apollo/client';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';

import { categoryStepsInitQuery, Colour } from './queries';
import ColourPickerItem from './item';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  label: string;
  colour: string;
  error: string | boolean;
  onChange: (id: string) => void;
  onBlur?: () => undefined;
}

const ColourPicker = ({ classes, label, colour, error, onChange, onBlur }: Props): React.ReactElement => {
  const [colours, setColours] = useState<Colour[]>([]);

  const { data: categoryStepsInitData } = useQuery(categoryStepsInitQuery(), { fetchPolicy: 'cache-first' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryStepsInitData) {
      setColours(categoryStepsInitData.colours);
    }
    return () => {
      mounted = false;
    };
  }, [categoryStepsInitData]);

  return (
    <>
      <FormControl className={classes.root} error={error as boolean}>
        <InputLabel id="colours-select-label">{label}</InputLabel>
        <Select
          labelId="colours-select-label"
          id="colours-select"
          value={colour}
          onChange={(event: React.ChangeEvent<any>) => onChange(event.target.value)}
          onBlur={onBlur ? onBlur : undefined}>
          {colours.map((c: Colour) => {
            return (
              <MenuItem value={c.id}>
                <ColourPickerItem colour_id={c.id} />
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
  );
};

export default withStyles(styles)(ColourPicker);
