import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { OpeningHours } from '../../index';

interface ViewHoursProps {
  title: string;
  hours: OpeningHours[];
}

const ViewHours: React.FC<ViewHoursProps> = ({ title, hours }) => {
  return (
    <Box style={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      {hours?.map(({ day, open, close }) => (
        <Box key={day} style={{ marginBottom: 2 }}>
          <Typography variant="h6">{day}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1">Open: {open || 'Not specified'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Close: {close || 'Not specified'}</Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default ViewHours;
