import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    list: {
      flexGrow: 1,
    },
    listItem: {
      cursor: 'pointer',
      color: theme.palette.grey[700],
      '&:hover': {
        '& $listItemIcon': {
          color: theme.palette.primary.main,
        },
        '& $listItemText': {
          color: theme.palette.primary.main,
        },
      },
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    listItemIcon: {
      color: theme.palette.grey[700],
      minWidth: 24,
    },
    listItemText: {
      fontWeight: 500,
      color: theme.palette.grey[700],
      marginLeft: theme.spacing(1),
      height: 24,
      marginTop: 0,
      marginBottom: 0,
    },
    listDivider: {
      margin: theme.spacing(1),
    },
    menu: {
      position: 'absolute',
      marginLeft: theme.spacing(2),
      zIndex: 99,
    },
    menuList: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    paper: {
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
  });

export default styles;
