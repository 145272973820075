import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _createLocationUserInitQuery = () => ({
  enum_user_types: [
    {
      user_type: types.string,
      description: types.string,
    },
  ],
});

export const createLocationUserInitQuery = () => gql`
  ${query(_createLocationUserInitQuery())}
`;
