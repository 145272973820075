import React, { useContext, useReducer } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { List, ListItem, ListItemIcon, ListItemText, Typography, withStyles, Grid, GridSize } from '@material-ui/core';

import { WithStyles } from '@material-ui/core/styles';

import { CheckOutlined as SelectedIcon, ChevronRightOutlined as ExpandIcon } from '@material-ui/icons';

import { AppContext } from '../../../../../../components/StateProvider';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  hierarchy: Hierarchy;
}

export interface Hierarchy {
  routes: HierarchyRoute[];
  items: HierarchyItem[];
}

interface HierarchyRoute {
  key: string;
  label: string;
  home?: string;
  selected?: number;
}

interface HierarchyItem {
  key: string;
  label: string;
  items?: HierarchyItem[];
  selected?: number;
}

export interface HierarchySelectedItem {
  routeIndex: number;
  itemIndex: number;
  key: string;
  route: HierarchyRoute;
}

export enum HierarchyReducerAction {
  ROUTE,
}

export const hierarchyReducer = (state: HierarchyRoute[], action: { type: HierarchyReducerAction; value: any }): HierarchyRoute[] => {
  switch (action.type) {
    case HierarchyReducerAction.ROUTE:
      const { routeIndex, itemIndex } = action.value;
      const routes = [...state];
      if (routeIndex < routes.length) {
        routes.slice(routeIndex + 1).forEach((i) => (i.selected = undefined));
      }
      routes[routeIndex].selected = itemIndex;
      return routes;
    default:
      throw new Error();
  }
};

const HierarchyList = ({ classes, history, hierarchy }: Props): React.ReactElement => {
  const { selectedHierarchy, setSelectedHierarchy } = useContext(AppContext)!;

  const [routes, dispatchRoutes] = useReducer(hierarchyReducer, hierarchy.routes);

  const columnSize: GridSize = Math.floor(12 / hierarchy.routes.length) as GridSize;

  const getRouteItems = (routeIndex: number): HierarchyItem[] => {
    if (routeIndex === 0) {
      return hierarchy.items;
    }
    if (routes[routeIndex - 1].selected !== undefined) {
      const item = Array(routeIndex)
        .fill(0)
        .map((i, index) => routes[index].selected)
        .reduce((a, b) => a[b as number].items || [], hierarchy.items);
      if (item) {
        return item;
      }
    }
    return [];
  };

  const renderRoute = (routeIndex: number): HierarchyItem[] => getRouteItems(routeIndex);

  const handleRoute = (routeIndex: number, itemIndex: number, key: string) => {
    if (selectedHierarchy?.key === key) {
      setSelectedHierarchy(undefined);
    } else {
      const route = routes[routeIndex];
      setSelectedHierarchy({
        routeIndex,
        itemIndex,
        key,
        route,
      });
      if (route.home) {
        history.replace(route.home);
      }
    }
  };

  return (
    <div className={classes.root} style={{ minWidth: routes.length * 300 }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            {routes.map((r) => (
              <Grid key={r.key} item className={classes.listItem} xs={columnSize}>
                <Typography className={classes.listTitle}>{r.label}:</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            {routes.map((r, routeIndex: number) => (
              <Grid key={r.key} item className={classes.listContainer} xs={columnSize}>
                <List className={classes.list}>
                  {renderRoute(routeIndex).map((i, itemIndex: number) => (
                    <ListItem
                      key={i.key}
                      className={`${routes[routeIndex].selected === itemIndex ? classes.active : ''} ${selectedHierarchy && selectedHierarchy.key === i.key ? classes.selected : ''}`}
                      onMouseEnter={() => dispatchRoutes({ type: HierarchyReducerAction.ROUTE, value: { routeIndex, itemIndex } })}
                      onClick={() => handleRoute(routeIndex, itemIndex, i.key)}>
                      <ListItemText className={classes.listItemText} primary={i.label} />
                      {(i.items || (selectedHierarchy && selectedHierarchy.key === i.key)) && (
                        <ListItemIcon className={classes.listItemIcon}>{selectedHierarchy && selectedHierarchy.key === i.key ? <SelectedIcon /> : <ExpandIcon />}</ListItemIcon>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(withStyles(styles)(HierarchyList));
