import React, { useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Fade, withStyles } from '@material-ui/core';

import { Logo } from '../../../components/Logos';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
}

const Splash = ({ classes, history }: Props): React.ReactElement => {
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setTimeout(() => {
        history.replace('/sign-in');
      }, 5000);
    }
    return () => {
      mounted = false;
    };
  }, [history]);

  return (
    <div className={classes.root}>
      <Fade in timeout={2700}>
        <div className={classes.icon}>
          <Logo />
        </div>
      </Fade>
    </div>
  );
};

export default withRouter(withStyles(styles)(Splash));
