import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _editLocationUserInitQuery = (user_id: string, location_id: string) => ({
  users_location_by_pk: params(
    {
      id: rawString(user_id),
    },
    {
      full_name: types.string,
      location: {
        name: types.string,
        organisation: {
          name: types.string,
        },
        users: params(
          {
            where: { pin: { _is_null: false } },
          },
          [
            {
              pin: types.string,
            },
          ]
        ),
      },
    }
  ),
});

type X = Unpacked<typeof _editLocationUserInitQuery>;
export type LocationUser = X['users_location_by_pk'];

export const editLocationUserInitQuery = (user_id: string, location_id: string) => gql`
  ${query(_editLocationUserInitQuery(user_id, location_id))}
`;
