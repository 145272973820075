import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editOrganisationUserInitQuery = (id: string) => ({
  users_organisation_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      first_name: types.string,
      last_name: types.string,
      full_name: types.string,
      email: types.string,
      active: types.boolean,
    }
  ),
});

type X = Unpacked<typeof _editOrganisationUserInitQuery>;
export type OrganisationUser = X['users_organisation_by_pk'];

export const editOrganisationUserInitQuery = (id: string) => gql`
  ${query(_editOrganisationUserInitQuery(id))}
`;
