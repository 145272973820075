import { ValidationType } from '../../../../react-app-env';

export interface Category {
  name: string;
  colour_id: string;
  active: boolean;
  score: number;
}

export enum CategoryType {
  DISH = 'DISH',
  DRINK = 'DRINK',
}

export interface Colour {
  id: string;
  hex: string;
  name: string;
  pattern?: string;
}

export interface ValidationErrors {
  name: ValidationType;
  colour_id: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  colour_id: 'Please provide a colour',
};

export interface Validation {
  name: (name: string) => ValidationType;
  colour_id: (value: string) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
  colour_id: (value) => (!value ? validationErrors.colour_id : false),
};

export enum CategoryReducerAction {
  NAME,
  COLOUR,
  SCORE,
  ACTIVE,
  INIT,
}

export const categoryReducer = (state: Category, action: { type: CategoryReducerAction; value: any }): Category => {
  switch (action.type) {
    case CategoryReducerAction.NAME:
      return { ...state, name: action.value };
    case CategoryReducerAction.COLOUR:
      return { ...state, colour_id: action.value };
    case CategoryReducerAction.SCORE:
      return { ...state, score: action.value };
    case CategoryReducerAction.ACTIVE:
      return { ...state, active: action.value };
    case CategoryReducerAction.INIT:
      const { name, colour_id, active, score } = action.value;
      const obj = {
        name,
        colour_id,
        active,
        score,
      };
      return { ...(obj as Category) };
    default:
      throw new Error();
  }
};
