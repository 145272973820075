import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import { FormControl, FormControlLabel, FormHelperText, Switch, TextField, withStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { LocationPrinter, ValidationErrors, LocationPrinterReducerAction, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  printer: LocationPrinter;
  locations: string[];
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: LocationPrinterReducerAction; value: any }>;
}

const filter = createFilterOptions<string>();

const StepDetails = ({ classes, printer, locations, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback(
    (field: string) => {
      const errorItems: UnknownObject = {};
      if (field === 'name') {
        errorItems.first_name = validation.name(printer.name);
      }
      if (field === 'location') {
        errorItems.location = validation.location(printer.location);
      }
      setErrors({ ...errors, ...errorItems });
    },
    [printer, errors, setErrors]
  );

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={printer.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationPrinterReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}
        />

        <Autocomplete
          className={classes.textField}
          id="locationList"
          options={locations}
          value={printer.location}
          onBlur={() => validate('tag')}
          onChange={(event, newValue) => {
            if (newValue?.startsWith('Add')) {
              dispatch({ type: LocationPrinterReducerAction.LOCATION, value: newValue?.replace('Add -- ', '') || '' });
            } else {
              dispatch({ type: LocationPrinterReducerAction.LOCATION, value: newValue || '' });
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push(`Add -- ${params.inputValue}`);
            }
            return filtered;
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option;
          }}
          renderInput={(params) => (
            <TextField
              className={classes.textField}
              {...params}
              data-qa="location-textfield"
              label="Location"
              variant="outlined"
              error={errors.location as boolean}
              helperText={errors.location || '20 character limit'}
            />
          )}
        />
        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Print copy to till"
            labelPlacement="end"
            control={
              <Switch
                checked={printer.printCopyToTill}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationPrinterReducerAction.COPY, value: event.target.checked as boolean })}
              />
            }
          />
          <FormHelperText>This will print an additional copy to the till printer</FormHelperText>
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
