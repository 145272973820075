import React, { useEffect, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Paper, Switch, TextField, Typography } from '@material-ui/core';
import { Location, LocationReducerAction } from '../..'; // Ensure correct import path for your actions

interface TimePeriod {
  start: string;
  end: string;
}

interface DayState {
  closed: boolean;
  open24Hours: boolean;
  timePeriods: TimePeriod[];
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    dayContainer: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      marginBottom: theme.spacing(2),
      borderRadius: 6,
    },
    timePeriodContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
    timeInput: {
      marginRight: theme.spacing(1),
    },
    addButton: {
      marginTop: theme.spacing(1),
    },
    textSection: {
      marginRight: theme.spacing(3),
    },
    switchContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
    },
    stickyButtonContainer: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      zIndex: 1000,
    },
    stickyButton: {
      paddingInline: theme.spacing(4),
    },
  });

interface OpeningHoursProps extends WithStyles<typeof styles> {
  location: Location;
  dispatch: React.Dispatch<{ type: LocationReducerAction; value: any }>;
  handleSave?: () => void;
  isLocationCreate: boolean;
}

const OpeningHours: React.FC<OpeningHoursProps> = ({ classes, location, dispatch, handleSave, isLocationCreate }) => {
  const [initialStateLocation, setInitialStateLocation] = useState<Location | null>(null);
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    setInitialStateLocation(location);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialStateLocation && JSON.stringify(initialStateLocation) !== JSON.stringify(location)) {
      setStateChanged(true);
    } else {
      setStateChanged(false);
    }
  }, [location, initialStateLocation]);

  const initialState: DayState = {
    closed: true,
    open24Hours: false,
    timePeriods: [{ start: '', end: '' }],
  };

  const [days, setDays] = useState<{ [key: string]: DayState }>({
    Monday: initialState,
    Tuesday: initialState,
    Wednesday: initialState,
    Thursday: initialState,
    Friday: initialState,
    Saturday: initialState,
    Sunday: initialState,
  });

  useEffect(() => {
    const updatedDays = { ...days };
    if (location.ordering_system_open_hours) {
      location.ordering_system_open_hours.forEach(({ day, open, close }) => {
        updatedDays[day] = {
          closed: !open && !close,
          open24Hours: open === '00:00' && close === '23:59',
          timePeriods: [{ start: open || '', end: close || '' }],
        };
      });
    }
    setDays(updatedDays);
    // eslint-disable-next-line
  }, [location.ordering_system_open_hours]);

  const handleSwitchChange = (day: string, key: 'closed' | 'open24Hours') => {
    const updatedDay = {
      ...days[day],
      closed: key === 'closed' ? !days[day].closed : false,
      open24Hours: key === 'open24Hours' ? !days[day].open24Hours : false,
      timePeriods: key === 'closed' || key === 'open24Hours' ? [{ start: '', end: '' }] : days[day].timePeriods,
    };
    setDays((prevState) => ({
      ...prevState,
      [day]: updatedDay,
    }));

    if (updatedDay.closed) {
      dispatch({
        type: LocationReducerAction.SET_ORDERING_SYSTEM_OPEN_HOURS,
        value: { day, timeType: 'open', value: '' },
      });
      dispatch({
        type: LocationReducerAction.SET_ORDERING_SYSTEM_OPEN_HOURS,
        value: { day, timeType: 'close', value: '' },
      });
    } else if (updatedDay.open24Hours) {
      dispatch({
        type: LocationReducerAction.SET_ORDERING_SYSTEM_OPEN_HOURS,
        value: { day, timeType: 'open', value: '00:00' },
      });
      dispatch({
        type: LocationReducerAction.SET_ORDERING_SYSTEM_OPEN_HOURS,
        value: { day, timeType: 'close', value: '23:59' },
      });
    }
  };

  const handleTimeChange = (day: string, index: number, field: 'start' | 'end', value: string) => {
    const updatedPeriods = [...days[day].timePeriods];
    updatedPeriods[index] = { ...updatedPeriods[index], [field]: value };
    setDays((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        timePeriods: updatedPeriods,
      },
    }));

    if (index === 0) {
      dispatch({
        type: LocationReducerAction.SET_ORDERING_SYSTEM_OPEN_HOURS,
        value: { day, timeType: field === 'start' ? 'open' : 'close', value },
      });
    }
  };

  const addTimePeriod = (day: string) => {
    setDays((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        timePeriods: [...prevState[day].timePeriods, { start: '', end: '' }],
      },
    }));
  };

  const removeTimePeriod = (day: string, index: number) => {
    const updatedPeriods = days[day].timePeriods.filter((_, i) => i !== index);
    setDays((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        timePeriods: updatedPeriods,
      },
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Box className={classes.textSection}>
          <Typography variant="h5" className={classes.title}>
            Opening Hours
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your customers can only place orders if your store is "open" at the time they checkout. Set your opening times for each day here.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Add multiple time periods if you close and re-open during one day. For example, you could be open from 9 AM to 1 PM and then 5 PM to 10 PM.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={8}>
        <Paper className={classes.container}>
          {Object.keys(days).map((day) => (
            <Box key={day} className={classes.dayContainer}>
              <Typography variant="subtitle1">{day}</Typography>

              {!days[day].closed && !days[day].open24Hours && (
                <>
                  {days[day].timePeriods.map((period, index) => (
                    <Box key={index} className={classes.timePeriodContainer}>
                      <TextField
                        type="time"
                        value={period.start}
                        onChange={(e) => handleTimeChange(day, index, 'start', e.target.value)}
                        className={classes.timeInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Typography>to</Typography>
                      <TextField
                        type="time"
                        value={period.end}
                        onChange={(e) => handleTimeChange(day, index, 'end', e.target.value)}
                        className={classes.timeInput}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {days[day].timePeriods.length > 1 && (
                        <Button variant="text" color="secondary" onClick={() => removeTimePeriod(day, index)}>
                          Remove
                        </Button>
                      )}
                    </Box>
                  ))}
                  <Button variant="outlined" color="primary" size="small" className={classes.addButton} onClick={() => addTimePeriod(day)}>
                    Add another time period
                  </Button>
                </>
              )}

              <Box className={classes.switchContainer}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Switch checked={days[day].closed} onChange={() => handleSwitchChange(day, 'closed')} color="primary" />
                  </Grid>
                  <Grid item>
                    <Typography>Closed</Typography>
                  </Grid>
                  <Grid item>
                    <Switch checked={days[day].open24Hours} onChange={() => handleSwitchChange(day, 'open24Hours')} color="primary" />
                  </Grid>
                  <Grid item>
                    <Typography>Open 24 hours</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}
        </Paper>
      </Grid>
      {stateChanged && !isLocationCreate && (
        <Box className={classes.stickyButtonContainer}>
          <Button variant="contained" color="primary" className={classes.stickyButton} onClick={handleSave}>
            Save changes
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default withStyles(styles)(OpeningHours);
