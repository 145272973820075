import { capitaliseFirstOnly, numberToMoney } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';

export interface CodeVoucher {
  name: string;
  code: string;
  value: number;
  voucher_type: CodeVoucherType;
  single_use: boolean;
  can_expire: boolean;
  start_date: Date;
  end_date: Date;
  category_ids: string[];
}

export enum CodeVoucherType {
  PERCENT = 'P',
  AMOUNT = 'A',
}

export interface Category {
  id: string;
  name: string;
  menu: {
    name: string;
  };
}

export interface UsedCode {
  id: string;
  code: string;
}

export interface ValidationErrors {
  name: ValidationType;
  code: ValidationType;
  codeLength: ValidationType;
  codeUsed: ValidationType;
  value: ValidationType;
  valueInvalid: ValidationType;
  start_date: ValidationType;
  end_date: ValidationType;
  dateInvalid: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  code: 'Please provide an voucher code',
  codeLength: 'Code must be between 4 and 10 characters',
  codeUsed: 'This code has already been used. If you want to use this code please edit the existing code',
  value: 'Please provide an voucher value',
  valueInvalid: 'Please provide an voucher value greater than zero',
  start_date: 'Please provide a start date',
  end_date: 'Please provide an end date',
  dateInvalid: 'End date must be after start date',
};

export interface Validation {
  name: (value: string) => ValidationType;
  code: (value: string, used_codes: UsedCode[]) => ValidationType;
  value: (value: number) => ValidationType;
  start_date: (sDate: Date, eDate: Date) => ValidationType;
  end_date: (eDate: Date, sDate: Date) => ValidationType;
}

export const validation: Validation = {
  name: (value) => (!value ? validationErrors.name : false),
  code: (value, used_codes) => {
    if (!value) return validationErrors.value;
    if (value.length > 10 || value.length < 4) return validationErrors.codeLength;
    if (used_codes.find((i) => i.code === value)) return validationErrors.codeUsed;
    return false;
  },
  value: (value) => {
    if (!value) return validationErrors.value;
    if (value === 0) return validationErrors.valueInvalid;
    return false;
  },
  start_date: (sDate, eDate) => {
    if (!sDate) return validationErrors.start_date;
    if (sDate.getTime() > eDate.getTime()) return validationErrors.dateInvalid;
    return false;
  },
  end_date: (eDate, sDate) => {
    if (!eDate) return validationErrors.end_date;
    if (sDate.getTime() > eDate.getTime()) return validationErrors.dateInvalid;
    return false;
  },
};

export const getVoucherValueString = (value: number, vType: CodeVoucherType, locale: string, currency: string): string => {
  if (vType === CodeVoucherType.PERCENT) {
    return `${value / 100}%`;
  }
  if (vType === CodeVoucherType.AMOUNT) {
    return numberToMoney(value, currency, locale, true);
  }
  return `${value}`;
};

export enum CodeVoucherReducerAction {
  NAME,
  CODE,
  VALUE,
  SINGLE_USE,
  CAN_EXPIRE,
  VOUCHER_TYPE,
  START_DATE,
  END_DATE,
  UPDATE_CATEGORY_IDS,
  INIT,
}

export const codeVoucherReducer = (state: CodeVoucher, action: { type: CodeVoucherReducerAction; value: any }): CodeVoucher => {
  switch (action.type) {
    case CodeVoucherReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case CodeVoucherReducerAction.CODE:
      return { ...state, code: `${action.value}`.toUpperCase() };
    case CodeVoucherReducerAction.VALUE:
      return { ...state, value: Math.round(+action.value * 100) };
    case CodeVoucherReducerAction.SINGLE_USE:
      return { ...state, single_use: action.value as boolean };
    case CodeVoucherReducerAction.CAN_EXPIRE:
      return { ...state, can_expire: action.value as boolean };
    case CodeVoucherReducerAction.VOUCHER_TYPE:
      return { ...state, voucher_type: action.value as CodeVoucherType };
    case CodeVoucherReducerAction.START_DATE:
      const sDate = action.value as Date;
      return { ...state, start_date: sDate };
    case CodeVoucherReducerAction.END_DATE:
      const eDate = action.value as Date;
      return { ...state, end_date: eDate };
    case CodeVoucherReducerAction.UPDATE_CATEGORY_IDS:
      return { ...state, category_ids: action.value as string[] };
    case CodeVoucherReducerAction.INIT:
      const { name, code, value, voucher_type, single_use, can_expire, start_date, end_date, category_ids } = action.value;
      const obj = {
        name,
        code,
        value,
        voucher_type,
        single_use,
        can_expire,
        start_date: new Date(start_date),
        end_date: new Date(end_date),
        category_ids,
      };
      return { ...(obj as CodeVoucher) };
    default:
      throw new Error();
  }
};
