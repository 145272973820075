import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    colourContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      height: theme.spacing(3),
    },
    colour: {
      marginRight: theme.spacing(1),
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: theme.spacing(0.5),
    },
    text: {
      lineHeight: `${theme.spacing(3)}px`,
    },
    small: {
      paddingTop: 0,
      paddingBottom: 0,
      height: theme.spacing(2),
      '& $colour': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
      },
      '& $text': {
        lineHeight: `${theme.spacing(2)}px`,
      },
    },
  });

export default styles;
