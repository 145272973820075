import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _createLocationInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      tax_rate: types.number,
    }
  ),
});

type X = Unpacked<typeof _createLocationInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const createLocationInitQuery = (id: string) => gql`
  ${query(_createLocationInitQuery(id))}
`;
