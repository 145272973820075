import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { FormControl, FormHelperText, FormLabel, InputLabel, MenuItem, Select, Typography, withStyles } from '@material-ui/core';

import { WithStyles } from '@material-ui/core/styles';

import { timeToFormattedString } from '../../utils/stringUtils';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  label: string;
  format: TimePickerFormat;
  time?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  handleTimeChange: (newTime: string) => void;
  onValidate?: [fieldName: string, validate: (field: string, value: string) => void];
}

interface TimePicker {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  time: string;
}

export enum TimePickerReducerAction {
  HOURS,
  MINUTES,
  SECONDS,
  MILLISECONDS,
  TIME,
  INIT,
}

export interface ValidationErrors {
  past: string;
  future: string;
  invalid: string;
}

export const validationErrors: ValidationErrors = {
  past: 'Date needs to be in the past',
  future: 'Date needs to be in the future',
  invalid: 'Invalid date',
};

interface TimePickerValidate {
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

export type TimePickerFormat = 'H' | 'HM' | 'HMS' | 'HMSM';

const timePickerReducer = (state: TimePicker, action: { type: TimePickerReducerAction; value: any }): TimePicker => {
  switch (action.type) {
    case TimePickerReducerAction.HOURS:
      return { ...state, hours: action.value as number };
    case TimePickerReducerAction.MINUTES:
      return { ...state, minutes: action.value as number };
    case TimePickerReducerAction.SECONDS:
      return { ...state, seconds: action.value as number };
    case TimePickerReducerAction.MILLISECONDS:
      return { ...state, milliseconds: action.value as number };
    case TimePickerReducerAction.TIME:
      return { ...state, time: action.value };
    case TimePickerReducerAction.INIT:
      const { hours, minutes, seconds, milliseconds, time } = action.value;
      const obj = {
        hours,
        minutes,
        seconds,
        milliseconds,
        time,
      };
      return { ...(obj as TimePicker) };
    default:
      throw new Error();
  }
};

const CustomTimePicker = ({ classes, label, format = 'HM', time, disabled = false, error, helperText, handleTimeChange, onValidate }: Props): React.ReactElement => {
  const [nTime, dispatch] = useReducer(timePickerReducer, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    time: '00:00:00:000',
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && time) {
      const t: number[] = time.split(':').map((i) => Number(i));
      const timeSplit: number[] = Array(4)
        .fill(0)
        .map((i, index) => t[index] || i);
      const [hours, minutes, seconds, milliseconds] = timeSplit;

      dispatch({
        type: TimePickerReducerAction.INIT,
        value: {
          hours,
          minutes,
          seconds,
          milliseconds,
          time,
        },
      });
    }
    return () => {
      mounted = false;
    };
  }, [time]);

  const [errorText, setErrorText] = useState<string>('');

  const handleValidation = useCallback(
    (value: string) => {
      if (onValidate) {
        onValidate[1](onValidate[0], value);
      }
    },
    [onValidate]
  );

  const handleChange = (type: TimePickerReducerAction, value: number) => {
    dispatch({ type, value });
    switch (type) {
      case TimePickerReducerAction.HOURS:
        validate({ hours: value });
        break;
      case TimePickerReducerAction.MINUTES:
        validate({ minutes: value });
        break;
      case TimePickerReducerAction.SECONDS:
        validate({ seconds: value });
        break;
      case TimePickerReducerAction.MILLISECONDS:
        validate({ milliseconds: value });
        break;
      default:
        break;
    }
  };

  const validate = useCallback(
    (values: TimePickerValidate) => {
      setErrorText('');
      const hours = `${values.hours !== undefined ? values.hours : nTime.hours}`.padStart(2, '0');
      const minutes = `${values.minutes !== undefined ? values.minutes : nTime.minutes}`.padStart(2, '0');
      const seconds = `${values.seconds !== undefined ? values.seconds : nTime.seconds}`.padStart(2, '0');
      const milliseconds = `${values.milliseconds !== undefined ? values.milliseconds : nTime.milliseconds}`.padStart(3, '0');

      const value = `${hours}:${minutes}:${seconds}:${milliseconds}`;
      dispatch({ type: TimePickerReducerAction.TIME, value });
      handleTimeChange(value);
      handleValidation(value);
    },
    [nTime, handleTimeChange, handleValidation]
  );

  return (
    <>
      <div className={classes.root}>
        <FormControl component="fieldset" error={errorText !== '' || error} disabled>
          <FormLabel className={classes.label} component="label">
            {label}
          </FormLabel>
          <div className={classes.dates}>
            <FormControl variant="outlined" className={classes.hours} error={errorText !== '' || error} disabled={disabled}>
              <InputLabel id="hours-select-label">Hours</InputLabel>
              <Select
                labelId="hours-select-label"
                label="Hours"
                autoWidth
                value={nTime.hours}
                MenuProps={{ classes: { paper: classes.hoursMenu } }}
                onChange={(event: React.ChangeEvent<any>) => handleChange(TimePickerReducerAction.HOURS, event.target.value)}>
                {Array(24)
                  .fill(0)
                  .map((i, index) => (
                    <MenuItem key={i + index} value={i + index}>
                      {i + index}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {['HM', 'HMS', 'HMSM'].includes(format) && (
              <FormControl variant="outlined" className={classes.minutes} error={errorText !== '' || error} disabled={disabled}>
                <InputLabel id="minutes-select-label">Minutes</InputLabel>
                <Select
                  labelId="minutes-select-label"
                  label="Minutes"
                  autoWidth
                  value={nTime.minutes}
                  MenuProps={{ classes: { paper: classes.minutesMenu } }}
                  onChange={(event: React.ChangeEvent<any>) => handleChange(TimePickerReducerAction.MINUTES, event.target.value)}>
                  {Array(60)
                    .fill(0)
                    .map((i, index) => (
                      <MenuItem key={i + index} value={i + index}>
                        {i + index}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            {['HMS', 'HMSM'].includes(format) && (
              <FormControl variant="outlined" className={classes.seconds} error={errorText !== '' || error} disabled={disabled}>
                <InputLabel id="seconds-select-label">Seconds</InputLabel>
                <Select
                  labelId="seconds-select-label"
                  label="Seconds"
                  autoWidth
                  value={nTime.seconds}
                  MenuProps={{ classes: { paper: classes.secondsMenu } }}
                  onChange={(event: React.ChangeEvent<any>) => handleChange(TimePickerReducerAction.SECONDS, event.target.value)}>
                  {Array(60)
                    .fill(0)
                    .map((i, index) => (
                      <MenuItem key={i + index} value={i + index}>
                        {i + index}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            {['HMSM'].includes(format) && (
              <FormControl variant="outlined" className={classes.milliseconds} error={errorText !== '' || error} disabled={disabled}>
                <InputLabel id="milliseconds-select-label">Milliseconds</InputLabel>
                <Select
                  labelId="milliseconds-select-label"
                  label="Milliseconds"
                  autoWidth
                  value={nTime.milliseconds}
                  MenuProps={{ classes: { paper: classes.millisecondsMenu } }}
                  onChange={(event: React.ChangeEvent<any>) => handleChange(TimePickerReducerAction.MILLISECONDS, event.target.value)}>
                  {Array(1000)
                    .fill(0)
                    .map((i, index) => (
                      <MenuItem key={i + index} value={i + index}>
                        {i + index}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </div>
          <Typography className={classes.time} variant="subtitle2">
            {timeToFormattedString(nTime.time, format)}
          </Typography>

          {errorText !== '' ? (
            <FormHelperText className={classes.error} error>
              {errorText}
            </FormHelperText>
          ) : (
            <FormHelperText className={classes.error} error>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(CustomTimePicker);
